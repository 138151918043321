import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { PlusCircle } from 'react-feather';
import GenericActions from '../../../actions/genericActions';
import actions from '../actions';
import Header from '../../Header';
import HostApplicationsTable from '../../../components/Contact/HostApplicationsTable';
// import AccountsTable from '../../components/Accounts/AccountsTable';

class HostApplicationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
        status: ['New'],
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    // console.log("Set Property", property, value);
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  updateList() {
    const { getAllHostApplications } = this.props;
    const { filter } = this.state;
    getAllHostApplications(filter);
  }

  render() {
    const { hostApplications, hostApplicationsListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/contact" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>Host Applications</h1>
              </div>
            </div>
            <HostApplicationsTable
              list={hostApplications.data}
              pagination={hostApplications.metadata}
              isLoading={hostApplicationsListRequest.working}
              isSuccess={hostApplicationsListRequest.success}
              isFailed={hostApplicationsListRequest.failed}
              onFilter={(arr) => this.handleChange('status', arr)}
              onSearch={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

HostApplicationPage.propTypes = {
  getAllHostApplications: PropTypes.func.isRequired,
  hostApplications: PropTypes.objectOf(PropTypes.shape),
  hostApplicationsListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
HostApplicationPage.defaultProps = {
  hostApplications: {
    data: [],
    metadata: { pageNum: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.hostApplications, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(HostApplicationPage);
