import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { Edit, Copy, PlusCircle, Gift } from 'react-feather';
import Checkbox from '../Forms/formik-checkbox';
import { EVENT_STATUS } from '../../config';
import EventHostAvatar from './Widgets/EventHostAvatar';
import GoogleDocFileLink from './Widgets/GoogleDocFileLink';
import EventStatus from './Widgets/EventStatus';
import EventLocationAddress from '../EventLocationAddress';
import './eventsTableRow.css';
// import QuickbooksInvoice from '../QuickbooksInvoice';
// import { EVENT_TYPE } from '../../utils';
import EventType from './Widgets/EventType';
// import GoogleImportButton from './Widgets/GoogleImportButton';
import { EVENT_TYPE } from '../../utils';
import PaymentMethodIcon from '../PaymentMethodIcon';
import InvoicingStatusIcon from '../PaymentMethodIcon/InvoicingStatusIcon';

const EventsTableRow = ({
  event,
  onCreateGoogleDoc,
  onPublishEvent,
  onApproveEvent,
  // onSendInvoice,
  // onDeleteEvent,
  // onImportGoogle,
  onToggleRowSelection,
  onCloneEvent,
  onRemoveHost,
  onAddHost,
  onShowPaymentModal,
  className,
  isSelected,
}) => (
  <tr
    className={classnames(
      className,
      'status',
      event.status === EVENT_STATUS.DRAFT ? 'draft' : '',
      event.status === EVENT_STATUS.READY ? 'ready' : '',
      event.status === EVENT_STATUS.PENDING ? 'pending' : '',
      event.status === EVENT_STATUS.COMPLETE ? 'complete' : '',
      event.status === EVENT_STATUS.INVOICED ? 'invoiced' : '',
      event.status === EVENT_STATUS.CANCELED ? 'canceled' : '',
    )}
  >
    <td className="d-none d-sm-table-cell text-center">
      <Checkbox name="" onChange={() => onToggleRowSelection()} isChecked={isSelected} />
    </td>
    <td className="text-center d-none d-lg-table-cell">
      <EventType
        type={event.eventType}
        liveStreamIsValid={
          event.liveStreamEventCode !== null &&
          event.liveStreamEventCode !== '' &&
          event.liveStreamPointsMultiplier !== null &&
          event.liveStreamNumberOfQuestions !== null
        }
      />
    </td>
    <td>
      <Link to={`/events/${event.id}`} className="action">
        {event.name}
      </Link>{' '}
      {event.eventType === EVENT_TYPE.LIVE && <span>({event.liveStreamEventCode})</span>}
      {event.eventType !== EVENT_TYPE.LIVE && (
        <>
          <EventLocationAddress iconOnly location={event.location} />
          <div>
            <small className="text-muted">
              {event.location?.city} {event.location?.state}
            </small>
          </div>
          <div className="event-date d-md-none" title={moment(event.dateTimeStart).format('ddd, MMM Do, YYYY h:mma')}>
            {moment(event.dateTimeStart).format('dd MM/DD/YY')}
          </div>
        </>
      )}
    </td>
    {/* <td className="d-none text-right d-sm-table-cell"> */}
    {/* <div className="event-wk">{moment(event.dateTimeStart).format('w')}</div> */}
    {/* </td> */}
    <td className="d-none d-sm-table-cell">
      <div title={moment(event.dateTimeStart).format('ddd, MMM Do, YYYY h:mma')}>{moment(event.dateTimeStart).format('ddd, M/D/YY h:mma')}</div>
    </td>
    <td className="text-center d-none d-md-table-cell">
      {event.numberOfPlayers || 0}
      {' | '}
      {event.numberOfTeams || 0}
      {' | '}
      {event.numberOfRegisteredTeams || 0}
    </td>
    <td className="d-none d-sm-table-cell">
      {event.hosts.map((host) => (
        <EventHostAvatar key={host.id} host={host} event={event} onClickHost={onRemoveHost} />
      ))}
      <PlusCircle className="clickable-badge" size={15} onClick={() => onAddHost(event)} />
    </td>
    <td className="align-middle text-center">
      {event.status !== EVENT_STATUS.IMPORTED && <GoogleDocFileLink event={event} onCreateGoogleDoc={() => onCreateGoogleDoc(event)} />}
    </td>
    {/* <td className="align-middle text-center">
      <PaymentMethodIcon name={event.paymentMethod} event={event} />
      {event.bonusPoints > 0 && (
        <span title={`Bonus Points: ${event.bonusPointsReason}`}>
          <Gift size={15} />
        </span>
      )}
    </td> */}
    <td className="d-none d-sm-table-cell align-middle">
      <EventStatus event={event} onClickDraft={() => onPublishEvent(event)} onClickPending={() => onApproveEvent(event)} />
    </td>
    <td className="align-right text-right">
      <span className="eventGross">{event.eventGross ? `$${event.eventGross}` : '$0'}</span>{' '}
      <PaymentMethodIcon event={event} onClickPayment={() => onShowPaymentModal(event)} />
      <InvoicingStatusIcon event={event} />
      {event.bonusPoints > 0 && (
        <span title={`Bonus Points: ${event.bonusPointsReason}`}>
          <Gift size={15} />
        </span>
      )}
    </td>
    {/* <td className="d-none d-sm-table-cell text-right">
      {moment(event.modified).format('MM/DD/YY')}
    </td> */}
    <td className="actions">
      {/* <GoogleImportButton event={event} onImportGoogle={onImportGoogle} /> */}
      <Button className="d-none d-sm-inline" onClick={() => onCloneEvent(event)} title="Clone this Event" color="link">
        <Copy color="#104170" size={20} />
      </Button>
      <Link to={`/events/${event.id}/edit`} title="Edit this Event" className="action">
        <Edit color="#999" size={20} />
      </Link>
    </td>
  </tr>
);

EventsTableRow.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape).isRequired,
  onCreateGoogleDoc: PropTypes.func.isRequired,
  onPublishEvent: PropTypes.func.isRequired,
  onApproveEvent: PropTypes.func.isRequired,
  // onSendInvoice: PropTypes.func.isRequired,
  // onDeleteEvent: PropTypes.func.isRequired,
  onCloneEvent: PropTypes.func.isRequired,
  onToggleRowSelection: PropTypes.func.isRequired,
  onRemoveHost: PropTypes.func.isRequired,
  onAddHost: PropTypes.func.isRequired,
  onShowPaymentModal: PropTypes.func.isRequired,
  // onImportGoogle: PropTypes.func.isRequired,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
};
EventsTableRow.defaultProps = {
  className: '',
  isSelected: false,
};

export default EventsTableRow;
