import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, difference } from 'lodash';
import Pagination from 'rc-pagination';
import { Link } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
// import Edit from 'react-feather/dist/icons/edit';
import Spinner from '../Spinner';

const LIMITS = [20, 50, 100];
const APPLICATION_STATUSES = [
  { value: 'New', label: 'New' },
  { value: 'Working', label: 'Working with Host' },
  { value: 'Hired', label: 'Hired as a Host' },
  { value: 'Declined', label: 'Application Declined' },
  { value: 'Test', label: 'Test' },
];
class HostApplicationsTable extends Component {
  constructor() {
    super();
    this.state = {
      filters: {
        status: ['New'],
      },
    };
  }

  updateStatuses = (status) => {
    const { filters } = this.state;
    if (filters.status.includes(status)) filters.status = difference(filters.status, [status]);
    else filters.status.push(status);
    this.setState({ filters }, this.updateList);
  };

  updateList = () => {
    const { filters } = this.state;
    const { onFilter } = this.props;
    onFilter(filters.status);
  };

  render() {
    const { filters } = this.state;
    const { list, pagination, onPageSizeChange, onPageNumberChange, onSearch, isLoading, isSuccess, isFailed, errorMessage } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-sm-4">
            <input type="text" placeholder="Search..." className="form-control" onChange={(e) => onSearch(e.target.value)} />
          </div>
          <div className="col-sm-8">
            {APPLICATION_STATUSES.map((status) => (
              <span key={status.value}>
                <Button
                  className="status-button mb-2"
                  color={filters.status.includes(status.value) ? 'primary' : 'link'}
                  size="sm"
                  onClick={() => this.updateStatuses(status.value)}
                >
                  {status.label}
                </Button>{' '}
              </span>
            ))}
          </div>
        </div>
        <Table striped hover responsive size="sm" className="element-list">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Location</th>
              <th>Video</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isFailed && (
              <tr>
                <td colSpan="2" className="danger">
                  {errorMessage}
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan="2">
                  <Spinner />
                </td>
              </tr>
            )}
            {isSuccess &&
              map(list, (item, i) => (
                <tr key={i}>
                  <td>
                    <Link to={`/contact/applications/${item.id}`} className="action">
                      {item.name}
                    </Link>
                    {/* {item.name} */}
                  </td>
                  <td>{item.email}</td>
                  <td>
                    {item.city}
                    {', '}
                    {item.state}
                  </td>
                  <td>
                    {item.youtubeURL && (
                      <a href={item.youtubeURL} target="_blank" rel="noreferrer noopener">
                        Video
                      </a>
                    )}
                  </td>
                  <td>{item.type}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="row query-footer">
          <div className="page-size form-inline col-sm-6">
            <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
              {map(LIMITS, (opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
            {' per page'}
          </div>
          <div className="col-sm-6 text-right">
            <Pagination
              onChange={onPageNumberChange}
              showLessItems
              current={pagination.pageNumber}
              total={pagination.totalCount}
              pageSize={pagination.pageSize || 20}
            />
          </div>
        </div>
      </div>
    );
  }
}
HostApplicationsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
HostApplicationsTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  list: [],
  pagination: {
    pageNumber: 1,
    pageSize: 20,
    totalCount: 1000,
  },
};

export default HostApplicationsTable;
