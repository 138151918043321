import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ordinal from 'ordinal';
import { map } from 'lodash';
import Placement from '../Placement';
import FinalAnswer from '../FinalAnswer';
import './DesktopTableRow.css';

const DesktopTableRow = ({ eventTeam, isEven }) => {
  const [isExpand, setExpand] = useState(false);

  const subRows = map(eventTeam.weekEvents, (event, idx) => (
    <div key={idx} className={`table-desktop-sub-row${idx % 2 === 0 ? ' even' : ''}`}>
      <div className="col-sm-2 indicator-container">
        <div className="indicator" />
        {moment(event.event.dateTimeStart).format('MMM Do')}
      </div>
      <div className="col-sm-2">{event.name}</div>
      <div className="col-sm-2">{event.event.name}</div>
      <div className="col-sm-1 text-center">
        <FinalAnswer answer={event.finalAnswer} />
      </div>
      <div className="col-sm-1">{`${event.totalScore} pts`}</div>
      <div className="col-sm-2 text-center">{ordinal(event.placement)}</div>
      {/* <div className="col-sm-2">&nbsp;</div> */}
    </div>
  ));
  return (
    <>
      <div className={`table-desktop-row${isEven ? ' even' : ''}`}>
        <div className="table-flex table-flex-align-center col-sm-2 align-middle first-col">
          <img
            onClick={() => {
              setExpand(!isExpand);
            }}
            onKeyDown={() => {
              setExpand(!isExpand);
            }}
            src={isExpand ? '/i/expand-arrow.png' : '/i/collapse-arrow.png'}
            alt="expand or collapse"
          />
          <div>{moment(eventTeam.event.dateTimeStart).day('Sunday').week(eventTeam.event.weekNum).format('MMM Do, YYYY')}</div>
        </div>
        <div className="col-sm-2 table-l-60">{eventTeam.eventsForWeek.length}</div>
        <div className="col-sm-4 align-middle">{eventTeam.event.name}</div>
        <div className="col-sm-2 align-middle">
          <Placement position={eventTeam.highestPlacement} className="placement-center" />
        </div>
        <div className="col-sm-2">{`${eventTeam.points}pts`}</div>
      </div>
      {isExpand ? (
        <div className="table-desktop-sub-table">
          <div className="table-desktop-sub-row table-desktop-sub-row-header">
            <div className="col-sm-2 indicator-container">
              <div className="indicator" />
              Date
            </div>
            <div className="col-sm-2">Team Name</div>
            <div className="col-sm-2">Event Name</div>
            <div className="col-sm-1 text-center">Final Answer</div>
            <div className="col-sm-1">Total Score</div>
            <div className="col-sm-2 text-center">Placement</div>
            {/* <div className="col-sm-2">Qualifying Event</div> */}
          </div>
          {subRows}
        </div>
      ) : null}
    </>
  );
};

DesktopTableRow.propTypes = {
  eventTeam: PropTypes.objectOf(PropTypes.shape),
  isEven: PropTypes.bool,
};
DesktopTableRow.defaultProps = {
  eventTeam: {},
  isEven: false,
};

export default DesktopTableRow;
