import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import TournamentsTable from '../../components/Tournaments/table';

class TournamentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
        isActive: true,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  toggleActive = (tournament, activeState) => {
    const { submitEditTournament } = this.props;
    const t = tournament;
    t.isActive = activeState;
    submitEditTournament(t.id, t);
  };

  updateList() {
    const { getAllTournaments } = this.props;
    const { filter } = this.state;
    getAllTournaments(filter);
  }

  render() {
    const { tournaments, tournamentListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/teams" />
        <div className="container-fluid">
          <div className="content-box">
            <h1>Tournaments</h1>
            <div className="row">
              <div className="col text-right">
                <Link to="/tournaments/create" className="btn btn-success text-right">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <TournamentsTable
              list={tournaments.data}
              pagination={tournaments.metadata}
              isLoading={tournamentListRequest.working}
              isSuccess={tournamentListRequest.success}
              isFailed={tournamentListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onFilter={(val) => this.handleChange('isActive', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
              onToggleActive={this.toggleActive}
            />
          </div>
        </div>
      </div>
    );
  }
}

TournamentsPage.propTypes = {
  getAllTournaments: PropTypes.func.isRequired,
  // getTournamentDetails: PropTypes.func.isRequired,
  tournaments: PropTypes.objectOf(PropTypes.shape),
  // tournamentDetails: PropTypes.objectOf(PropTypes.shape),
  tournamentListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
  submitEditTournament: PropTypes.func.isRequired,
};
TournamentsPage.defaultProps = {
  tournaments: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
  // tournamentDetails: {},
};

const mapStateToProps = (state) => extend({}, state.tournaments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(TournamentsPage);
