import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import accountActions from '../Accounts/actions';
import Header from '../Header/index';
import CreateForm from '../../components/Locations/CreateForm';

const LocationCreatePage = ({ submitCreateLocation, locationDetails, locationCreateRequest, accounts, getAllAccounts }) => (
  <div className="location-page page-container">
    <Header currentPage="/locations" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create a Location</h1>
        <CreateForm
          location={locationDetails}
          onSubmit={submitCreateLocation}
          accounts={accounts.data}
          searchAccounts={(value) => getAllAccounts({ name: value })}
          loading={locationCreateRequest.working}
          success={locationCreateRequest.success}
          failed={locationCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

LocationCreatePage.propTypes = {
  submitCreateLocation: PropTypes.func.isRequired,
  locationCreateRequest: PropTypes.objectOf(PropTypes.shape),
  // location: PropTypes.objectOf(PropTypes.shape),
  locationDetails: PropTypes.objectOf(PropTypes.shape),
  getAllAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape),
};
LocationCreatePage.defaultProps = {
  accounts: {},
  // location: {},
  locationDetails: {},
  locationCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.locations, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, accountActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LocationCreatePage);
