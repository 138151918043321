import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getHostApplications(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.HOST_APPLICATIONS}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

// /**
//  * Create account
//  * @param {object} accountData - the account data
//  */
// function createAccount(accountData) {
//   const token = getJWT();
//   if (!token) return reject('NOT_LOGGED_IN');
//   return axios.post(`${ENDPOINTS.ACCOUNTS}`, accountData, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }).catch(err => globals.handleAPIError(err))
//     .then(res => res.data);
// }

/**
 * Get application details by ID
 * @param {string} id - the account ID
 */
function getHostApplicationDetails(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.HOST_APPLICATIONS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update Host Application
 * @param {string} id - the Application ID
 * @param {object} newData - the Application data
 */
function updateHostApplication(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.HOST_APPLICATIONS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

// /**
//  * Delete account
//  * @param {string} id - the account ID
//  */
// function deleteAccount(id) {
//   const token = getJWT();
//   if (!token) return reject('NOT_LOGGED_IN');
//   return axios.delete(`${ENDPOINTS.ACCOUNTS}/${id}`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }).catch(err => globals.handleAPIError(err))
//     .then(res => res.data);
// }

export default {
  getHostApplications,
  getHostApplicationDetails,
  updateHostApplication,
};
