import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';

export default [
  {
    title: 'Locations',
    path: '/locations',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create Location',
    path: '/locations/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Location',
    path: '/locations/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Location Details',
    path: '/locations/:id',
    component: View,
    options: { private: true },
  },
];
