import API from '../../utils/api/locations';
import REPORTING from '../../utils/api/reporting';

const ACTION_PREFIX = 'LOCATIONS';

const getAllLocations = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getLocations(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getLocationDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getLocation(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreateLocation = (account) => (dispatch) => {
  // console.log("Submit Create Location");
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createLocation(account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditLocation = (id, account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateLocation(id, account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

const getEventStats = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_REPORT_STARTED` });

  REPORTING.getEventStats(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_REPORT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_REPORT_FAILED`, payload: errorMsg }));
};

export default {
  getAllLocations,
  getLocationDetails,
  submitCreateLocation,
  submitEditLocation,
  getEventStats,
};
