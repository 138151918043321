import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import allReducers from './reducers';
import routes from './routes';
import Authenticator from './Authenticator';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-pagination/assets/index.css';
import './styles/styles.css';
import { getJWT } from './utils/api/helpers';
import { SWRConfig } from 'swr';
import { fetcher, loadingMiddleware } from './utils/swr_config';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
} else if (window.location.protocol !== 'https:') {
  /* This is a hack to redirect to https on production */
  // window.location.href = 'https:'
  // + window.location.href.substring(window.location.protocol.length);
}

const store = createStore(allReducers, applyMiddleware(...middlewares));

const renderRoute = (route, props) => {
  window.scrollTo(0, 0); // Reset scroll to top
  const Component = route.component;

  const { match } = props;
  return (
    <div className="dynamic-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{route.title}</title>
        <meta name="description" content={route.description} />
        <meta name="keywords" content={route.keywords} />
      </Helmet>

      <SWRConfig value={{ fetcher, use: [loadingMiddleware] }}>
        <Authenticator>
          <Component routeParams={match.params} {...props} />
        </Authenticator>
      </SWRConfig>
      <ToastContainer position="bottom-right" />
      <Footer />
    </div>
  );
};

const createRoutes = () =>
  routes.exactRoutes.map((route) =>
    route.private ? (
      <PrivateRoute exact key={route.path} path={route.path} component={(props) => renderRoute(route, props)} />
    ) : (
      <Route exact key={route.path} path={route.path} component={(props) => renderRoute(route, props)} />
    ),
  );

const createRedirectRoutes = () => routes.redirectRoutes.map((item) => <Redirect key={item.from} from={item.from} to={item.to} />);

const userToken = getJWT();

if (userToken) {
  store.dispatch({
    type: 'AUTH_SUCCESS',
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div className="main-container">
        <Route path="/" />
        <Switch>
          {createRedirectRoutes()}
          {createRoutes()}
          <Redirect from="*" to="/404" />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
// registerServiceWorker();
