import classnames from 'classnames';
import { ErrorMessage, Field, withFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { FRONTEND_URL } from '../../config';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import HostSelector from '../../containers/Hosts/HostSelector';
import LocationSelector from '../../containers/Locations/LocationSelector';
import { EVENT_TYPE, EVENT_TYPES, PAYMENT_METHODS, PAYMENT_TYPE } from '../../utils';
import AccountLocationsTags from '../Events/Widgets/AccountLocationsTags';
import Checkbox from '../Forms/formik-checkbox';
import Spinner from '../Spinner';
import API from '../../utils/api/accounts';
// import BillToAccountDisplay from '../Accounts/BillToAccount';

const days = [
  { value: 'Sun', label: 'Sunday' },
  { value: 'Mon', label: 'Monday' },
  { value: 'Tue', label: 'Tuesday' },
  { value: 'Wed', label: 'Wednesday' },
  { value: 'Thu', label: 'Thursday' },
  { value: 'Fri', label: 'Friday' },
  { value: 'Sat', label: 'Saturday' },
];
const EventTemplateEditForm = ({
  values,
  entity,
  // touched,
  // errors,
  // dirty,
  isSubmitting,
  // handleChange,
  setFieldValue,
  // handleBlur,
  handleSubmit,

  getAccountDetails,

  loading,
  success,
  failed,
  errorMessage,
  accountDetails,
  // handleReset,
}) => {
  // componentDidMount() {
  //   // const { searchAccounts, searchLocations } = props;
  //   // searchAccounts();
  //   // searchLocations();
  // }

  // componentDidUpdate(prevProps) {
  //   const { resetForm, success } = props;
  //   if (prevProps.success === false && success === true) {
  //     resetForm();
  //   }
  // }
  const [accountDeets, setAccountDeets] = useState({});

  const selectAccount = (account) => {
    if (account) getAccountDetails(account.id);
    setFieldValue('account', account);
    setFieldValue('accountId', account ? account.id : null);
  };

  const selectLocation = (location) => {
    setFieldValue('location', location);
    setFieldValue('locationId', location ? location.id : null);
  };

  const getAccountDetailsLocal = async (id) => {
    const acct = await API.getAccount(id);
    setAccountDeets(acct);
  };
  useEffect(() => {
    if (entity.account?.id) {
      getAccountDetailsLocal(entity.account.id);
      setFieldValue('billingAccountId', entity.billingAccountId);
    }
  }, [entity]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {success && <div className="alert alert-success">Event Template Edited</div>}
        {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="accountId">Account</label>
          <AccountSelector selectedAccount={values.account} onUpdate={selectAccount} />
          {values.account && <AccountLocationsTags locations={accountDetails.locations} onClickLocation={selectLocation} />}
          <ErrorMessage name="account" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="locationId">Location</label>
          <LocationSelector selectedLocation={values.location} onUpdate={selectLocation} />
          <ErrorMessage name="location" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        {/* {values.name} - {values.url} */}
        <div className="form-group col-md-4">
          <label htmlFor="url">Event URL</label>
          <Field type="url" name="url" className="form-control" />
          <small className="form-text text-muted">
            Links from the{' '}
            <a href={`${FRONTEND_URL}where`} target="noreferrer noopener">
              /where
            </a>{' '}
            page
          </small>
          <ErrorMessage name="url" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="url">Website Notes</label>
          <Field name="notes" className="form-control" />
          <small className="form-text text-muted">
            Links from the{' '}
            <a href={`${FRONTEND_URL}where`} target="noreferrer noopener">
              /where
            </a>{' '}
            page
          </small>
          <ErrorMessage name="notes" component="div" className="alert alert-danger" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="hostDetails">Host Notes</label>
          <ReactQuill value={values.hostDetails || ''} onChange={(v) => setFieldValue('hostDetails', v)} rows="3" />
          <small className="form-text text-muted">
            Shows in the{' '}
            <a href={`${FRONTEND_URL}hosts`} target="noreferrer noopener">
              Host Panel
            </a>
          </small>
          <ErrorMessage name="hostDetails" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="adminDetails">Admin Notes</label>
          <ReactQuill value={values.adminDetails || ''} onChange={(v) => setFieldValue('adminDetails', v)} rows="3" />
          <small className="form-text text-muted">Internal only. Shows on the edit page and on the event view page.</small>
          <ErrorMessage name="adminDetails" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="name">Event Name</label>
          <Field className="form-control" name="name" />
          <small id="nameHelpBlock" className="form-text text-muted">
            Leave blank to default to Account Name
          </small>
          <ErrorMessage name="name" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="googleSheetPrefix">Google Sheet Prefix</label>
          <Field className="form-control" name="googleSheetPrefix" />
          <small id="googleSheetHelpBlock" className="form-text text-muted">
            Sheet will have format &quot;PREFIX - YYYYMMDD&quot;. Leave blank to default to Account Name
          </small>
          <ErrorMessage name="googleSheetPrefix" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="eventType">Event Type</label>
          <Select
            options={EVENT_TYPES}
            name="eventType"
            value={EVENT_TYPES.filter(({ value }) => value === values.eventType)}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ value }) => value}
            onChange={({ value }) => setFieldValue('eventType', value)}
            classNamePrefix="select"
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="hosts">Hosts</label>
          <HostSelector
            selectedHosts={values.hosts}
            onUpdate={(v) => {
              setFieldValue('hosts', v);
            }}
          />
          <ErrorMessage name="hosts" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-sm-4">
          <label htmlFor="firstEventDate">First Event Date</label>
          <Field className="form-control" type="date" name="firstEventDate" />
          <ErrorMessage name="firstEventDate" component="div" className="alert alert-danger" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-3">
          <label htmlFor="name">Day of Week</label>
          <Select
            name="dayOfWeek"
            value={days.filter(({ value }) => value === values.dayOfWeek)}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ value }) => value}
            onChange={({ value }) => setFieldValue('dayOfWeek', value)}
            options={days}
            classNamePrefix="select"
          />
          <ErrorMessage name="dayOfWeek" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="time">Time</label>
          <Field className="form-control" type="time" name="time" />
          <ErrorMessage name="time" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="eventGross">Event Cost</label>
          <Field className="form-control" type="eventGross" name="eventGross" />
          <ErrorMessage name="eventGross" component="div" className="alert alert-danger" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-3">
          <Checkbox name="Active" onChange={(v) => setFieldValue('isActive', v)} isChecked={values.isActive} />
          <ErrorMessage name="isActive" component="div" className="alert alert-danger" />
        </div>
        <div className="form-group col-md-3">
          <Checkbox name="Auto Generate" onChange={(v) => setFieldValue('isAutoGenerate', v)} isChecked={values.isAutoGenerate} />
          <ErrorMessage name="isAutoGenerate" component="div" className="alert alert-danger" />
        </div>
      </div>
      <h3>Billing</h3>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="paymentMethod">Payment Method</label>
          <Field className="form-control" component="select" name="paymentMethod">
            {PAYMENT_METHODS.map((m) => (
              <option key={m.value} value={m.value}>
                {m.label}
              </option>
            ))}
          </Field>
          <ErrorMessage name="paymentMethod" component="div" className="alert alert-danger" />
        </div>
        {values.paymentMethod === PAYMENT_TYPE.ACCOUNT && (
          <div className="form-group col-md-6">
            <Checkbox
              name="Create Invoice Item on Event Complete"
              onChange={(e) => setFieldValue('isCreateInvoice', e)}
              isChecked={values.isCreateInvoice}
            />
            <ErrorMessage name="isCreateInvoice" component="div" className="alert alert-danger" />
            <br />
            <Checkbox
              name="Automatically Send Invoice"
              onChange={(e) => setFieldValue('isAutoSendInvoice', e)}
              isChecked={values.isAutoSendInvoice}
            />
            <ErrorMessage name="isAutoSendInvoice" component="div" className="alert alert-danger" />
          </div>
        )}
      </div>
      {values.paymentMethod === PAYMENT_TYPE.ACCOUNT && (
        <div className="form-row">
          <div className="form-group col-md-6">
            {' '}
            <div>Bill to Stripe Account</div>
            <div>
              {accountDeets?.billingAccounts &&
                accountDeets.billingAccounts.map((b) => (
                  <div key={b.id}>
                    <Checkbox name={b.name} onChange={() => setFieldValue('billingAccountId', b.id)} isChecked={values.billingAccountId === b.id} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <div className={classnames(values.eventType === EVENT_TYPE.LIVE ? '' : 'd-none')}>
        <h3>Live Stream Properties</h3>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="liveStreamNumberOfQuestions"># of Questions</label>
            <Field className="form-control" name="liveStreamNumberOfQuestions" />
            <ErrorMessage name="liveStreamNumberOfQuestions" component="div" className="alert alert-danger" />
            <br />
            <label htmlFor="liveStreamPointsMultiplier">Points Multiplier</label>
            <Field className="form-control" name="liveStreamPointsMultiplier" />
            <ErrorMessage name="liveStreamPointsMultiplier" component="div" className="alert alert-danger" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <Link className="btn btn-default" to="/event-templates">
            Cancel
          </Link>{' '}
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <Button type="submit" color="primary">
              Submit
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

EventTemplateEditForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  entity: PropTypes.objectOf(PropTypes.shape),

  getAccountDetails: PropTypes.func.isRequired,
  accountDetails: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
EventTemplateEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  accountDetails: { locations: [] },
  entity: {},
  values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity }) => ({
    name: entity.name || '',
    accountId: entity.accountId || '',
    locationId: entity.locationId || '',
    googleSheetPrefix: entity.googleSheetPrefix || '',
    adminDetails: entity.adminDetails || '',
    hostDetails: entity.hostDetails || '',
    url: entity.url || '',
    notes: entity.notes || '',
    account: entity.account || '',
    location: entity.location || '',
    isActive: entity.isActive,
    isAutoGenerate: entity.isAutoGenerate,
    dayOfWeek: entity.dayOfWeek,
    time: entity.time || '19:00',
    hosts: entity.hosts || [],
    eventType: entity.eventType || EVENT_TYPE.RECURRING,
    eventGross: entity.eventGross || 0,
    isCreateInvoice: entity.isCreateInvoice || false,
    isAutoSendInvoice: entity.isAutoSendInvoice || false,
    invoiceToEmail: entity.invoiceToEmail || '',
    paymentMethod: entity.paymentMethod || 'account',
    firstEventDate: moment(entity.firstEventDate).format('YYYY-MM-DD') || `${moment(new Date()).format('YYYY-MM-DD')}` || '',
  }),

  validationSchema: Yup.object().shape({
    account: Yup.object().required('Please Select an Account'),
    location: Yup.object().required('Please Select a Location'),
    dayOfWeek: Yup.string().required('Please Select a Day'),
    time: Yup.string().required('Please enter a time'),
    eventGross: Yup.number().required('Please enter an Event Cost'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const v = values;
    if (values.name === '') v.name = values.account.name;
    if (values.googleSheetPrefix === '') v.googleSheetPrefix = values.account.name;
    if (values.paymentMethod !== PAYMENT_TYPE.ACCOUNT) {
      // do nothing
      v.billingAccountId = null;
      v.isCreateInvoice = false;
      v.isAutoSendInvoice = false;
    }
    props.onSubmit(props.entity.id, v);
    setSubmitting(false);
  },
  displayName: 'Event Template Edit Form',
})(EventTemplateEditForm);
