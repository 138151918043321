import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Alert, Button, Spinner } from 'reactstrap';

import { PAYMENT_METHODS, PAYMENT_TYPE } from '../../utils';
import './styles.css';
import StripeLink from '../StripeLink';

class LogPaymentForm extends Component {
  componentDidMount() {
    // const { searchAccounts, searchLocations } = this.props;
    // searchAccounts();
    // searchLocations();
  }

  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;

    if (prevProps.success === false && success === true) resetForm();
  }

  render() {
    const {
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      // handleChange,
      setFieldValue,
      // handleBlur,
      handleSubmit,
      onCancel,
      onSendStripeInvoiceItem,
      onSendStripeInvoice,
      onVoidStripeInvoice,
      onMarkStripeInvoicePaid,
      loading,
      success,
      failed,
      errorMessage,
      eventDetails,
    } = this.props;

    // const { isBonus } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && <div className="alert alert-success">Payment Info Updated</div>}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        <div className="row">
          <div className="col-lg-8">
            {/* Payment Method Dropdown (Cash, Check, Quickbooks, Stripe) */}
            <div className="form-row">
              <div className="form-group col-sm-12">
                <Select
                  options={PAYMENT_METHODS}
                  name="paymentMethod"
                  value={PAYMENT_METHODS.filter(({ value }) => value === values.paymentMethod)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  onChange={({ value }) => setFieldValue('paymentMethod', value)}
                  classNamePrefix="select"
                />
              </div>
            </div>
            {eventDetails.paymentMethod === PAYMENT_TYPE.ACCOUNT && !eventDetails.billingAccount.stripeCustomerId && (
              <div>Cannot invoice via stripe until the account or event has an Bill To Email set</div>
            )}
            {eventDetails.paymentMethod === PAYMENT_TYPE.ACCOUNT && eventDetails.billingAccount.stripeCustomerId && (
              <div>
                {!eventDetails.stripeInvoiceItemId && (
                  <div className="marginSpacing">
                    <Button onClick={() => onSendStripeInvoiceItem(eventDetails.id)}>Create Stripe Invoice Item</Button>
                  </div>
                )}
                {eventDetails.stripeInvoiceItemId && (
                  <div className="marginSpacing">
                    Invoice Item Created <StripeLink type="invoice-item" id={eventDetails.stripeInvoiceItemId} />
                  </div>
                )}
                {eventDetails.stripeInvoiceItemId && !eventDetails.stripeInvoiceId && (
                  <div className="marginSpacing">
                    <Button onClick={() => onSendStripeInvoice(eventDetails.id)}>Invoice Now!</Button>
                  </div>
                )}
                {eventDetails.stripeInvoiceId && (
                  <div className="marginSpacing">
                    Invoice Sent? {eventDetails.stripeInvoiceSentOn ? `On ${eventDetails.stripeInvoiceSentOn}` : 'No'} (
                    <StripeLink type="invoice" id={eventDetails.stripeInvoiceId} linkText={eventDetails.stripeInvoiceNum} />)
                  </div>
                )}
                {eventDetails.isInvoicePaid && <div className="marginSpacing">Invoice Paid on {eventDetails.stripeInvoicePaidOn}</div>}
                {eventDetails.stripeInvoiceId && !eventDetails.isInvoicePaid && <div className="marginSpacing">Invoice Not Yet Paid</div>}
              </div>
            )}
            {/* <div>
              {eventDetails.paymentMethod} - {values.paymentMethod}
            </div> */}
            {eventDetails.paymentMethod !== PAYMENT_TYPE.ACCOUNT && (
              <div className="form-row">
                <div className="form-group col-sm-2">
                  <label htmlFor="isInvoicePaid">Paid</label>
                  <Field className="form-control" type="checkbox" name="isInvoicePaid" />
                </div>
                <div className="form-group col-sm-8">
                  <label htmlFor="stripeInvoicePaidOn">Paid On</label>
                  <Field className="form-control" type="date" name="stripeInvoicePaidOn" />
                  {errors.stripeInvoicePaidOn && touched.stripeInvoicePaidOn && (
                    <div className="alert alert-danger">{errors.stripeInvoicePaidOn}</div>
                  )}
                </div>
                <div className="form-group col-sm-12">
                  <label htmlFor="paymentInfo">Payment Note</label>
                  <Field className="form-control" name="paymentInfo" />
                  {errors.paymentInfo && touched.paymentInfo && <div className="alert alert-danger">{errors.paymentInfo}</div>}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-8">
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <>
                <Button type="submit" color="primary" disabled={!dirty}>
                  Save
                </Button>{' '}
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
        {eventDetails.paymentMethod !== PAYMENT_TYPE.ACCOUNT &&
          eventDetails.stripeInvoiceItemId &&
          eventDetails.invoiceStatus !== 'voided' &&
          eventDetails.invoiceStatus !== 'paid' && (
            <div className="form-row">
              <div className="form-group col-sm-12">
                <Alert color="warning">Warning! There is already an invoice item id, meaning the user has, or will receive an invoice.</Alert>
                <Button color="success" onClick={() => onMarkStripeInvoicePaid(eventDetails.id)}>
                  Mark Stripe Invoice Paid
                </Button>{' '}
                <Button color="danger" onClick={() => onVoidStripeInvoice(eventDetails.id)}>
                  Void Stripe Invoice
                </Button>
                &nbsp;
              </div>
            </div>
          )}
      </form>
    );
  }
}
LogPaymentForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  eventDetails: PropTypes.objectOf(PropTypes.shape),

  onSendStripeInvoiceItem: PropTypes.func.isRequired,
  onSendStripeInvoice: PropTypes.func.isRequired,
  onVoidStripeInvoice: PropTypes.func.isRequired,
  onMarkStripeInvoicePaid: PropTypes.func.isRequired,

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
LogPaymentForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // routeParams: {},
  eventDetails: {},
  dirty: false,
  // accounts: [],
  //
  // locations: [],
  // accountDetails: { locations: [] },

  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ eventDetails }) => ({
    isInvoicePaid: eventDetails.isInvoicePaid || false,
    paymentInfo: eventDetails.paymentInfo || '',
    paymentMethod: eventDetails.paymentMethod !== '' ? eventDetails.paymentMethod : PAYMENT_TYPE.ACCOUNT,
    stripeInvoicePaidOn: eventDetails.stripeInvoicePaidOn
      ? `${moment(eventDetails.stripeInvoicePaidOn).format('YYYY-MM-DD')}`
      : `${moment(new Date()).format('YYYY-MM-DD')}`,
  }),

  validationSchema: Yup.object().shape({
    // stripeInvoicePaidOn: Yup.date().required('Please Select a Payment Date'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    let vals = {};
    if (values.paymentMethod === PAYMENT_TYPE.ACCOUNT) {
      vals = {
        paymentInfo: '',
        paymentMethod: PAYMENT_TYPE.ACCOUNT,
      };
    } else {
      vals = {
        ...values,
      };
    }
    // console.log('submitting', props.eventDetails.id, vals);
    props.onSubmit(props.eventDetails.id, vals);
    setSubmitting(false);
  },
  displayName: 'Log Payment Form',
})(LogPaymentForm);
