import { ErrorMessage, Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import Spinner from '../Spinner';

const BillingAccountCreateForm = ({
  // values,
  // touched,
  // errors,
  dirty,
  isSubmitting,
  // handleChange,
  // setFieldValue,
  // handleBlur,
  handleSubmit,

  loading,
  success,
  failed,
  errorMessage,
  billingAccountDetails,
  setFieldValue,
  // handleReset,
}) => {
  useEffect(() => {
    const query = qs.parse(window.location.search, {
      arrayFormat: 'bracket',
    });
    if (query['name']) {
      setFieldValue('name', query['name']);
    }
    if (query['accountId']) {
      setFieldValue('accountId', query['accountId']);
    }
  }, []);
  // componentDidMount() {

  //   setQueryObj(query);
  // }

  // componentDidUpdate(prevProps) {
  //   const { resetForm, success } = this.props;
  //   if (prevProps.success === false && success === true) {
  //     resetForm();
  //   }
  // }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {success && (
          <div className="alert alert-success">
            Billing Acccount Created
            <br />
            <Link to={`/billing-accounts/${billingAccountDetails.id}/edit`}>Edit Your Billing Account</Link>
          </div>
        )}

        {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="name">Name</label>
          <Field className="form-control" name="name" />
          <ErrorMessage name="name" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <Link className="btn btn-default" to="/billing-accounts">
            Cancel
          </Link>{' '}
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <Button type="submit" color="primary" disabled={!dirty}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};
BillingAccountCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  billingAccountDetails: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  // values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
BillingAccountCreateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  dirty: false,
  errorMessage: '',
  billingAccountDetails: { locations: [] },
  // values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  mapPropsToValues: ({ name }) => ({
    name: name || '',
    accountId: null,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const copy = { ...values };
    // console.log(values);
    if (copy.accountId) {
      copy.accounts = [{ id: values.accountId }];
      delete copy.accountId;
    }
    // console.log(copy);
    props.onSubmit(copy);
    setSubmitting(false);
  },
  displayName: 'Billing Account Creation Form',
})(BillingAccountCreateForm);
