import ContactHome from './index';
import Applications from './Applications';
import ViewApplication from './Applications/view';
// import Create from './create';
// import Edit from './edit';
// import View from './view';

export default [
  {
    title: 'Contact Forms',
    path: '/contact',
    component: ContactHome,
    options: { private: true },
  },
  {
    title: 'Host Applications',
    path: '/contact/applications',
    component: Applications,
    options: { private: true },
  },
  {
    title: 'Host Applications',
    path: '/contact/applications/:id',
    component: ViewApplication,
    options: { private: true },
  },
  // {
  //   title: 'Create Account',
  //   path: '/accounts/create',
  //   component: Create,
  //   options: { private: true },
  // },
  // {
  //   title: 'Edit Account',
  //   path: '/accounts/:id/edit',
  //   component: Edit,
  //   options: { private: true },
  // },
  // {
  //   title: 'Account Details',
  //   path: '/accounts/:id',
  //   component: View,
  //   options: { private: true },
  // },
];
