import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getBillingAccounts(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.BILLING_ACCOUNTS}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

function getRegions(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.REGIONS}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create billingAccount
 * @param {object} billingAccountData - the billingAccount data
 */
function createBillingAccount(billingAccountData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.BILLING_ACCOUNTS}`, billingAccountData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get billingAccount by ID
 * @param {string} id - the billingAccount ID
 */
function getBillingAccount(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.BILLING_ACCOUNTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update billingAccount
 * @param {string} id - the billingAccount ID
 * @param {object} newData - the billingAccount data
 */
function updateBillingAccount(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  // console.log('update billingAccount', newData);
  return axios
    .put(`${ENDPOINTS.BILLING_ACCOUNTS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

function sendBillingAccountInvoices(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  // console.log('update billingAccount', newData);
  return axios
    .put(
      `${ENDPOINTS.BILLING_ACCOUNTS}/${id}/send-invoice`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Delete billingAccount
 * @param {string} id - the billingAccount ID
 */
function deleteBillingAccount(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.BILLING_ACCOUNTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getRegions,
  getBillingAccounts,
  getBillingAccount,
  createBillingAccount,
  updateBillingAccount,
  deleteBillingAccount,
  sendBillingAccountInvoices,
};
