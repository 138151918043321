import React from 'react';
import PropTypes from 'prop-types';
import { MapPin } from 'react-feather';

const EventLocationAddress = ({ location, className, iconOnly }) => (
  <span className={className}>
    {iconOnly === false && `${location.address} ${location.city} ${location.state} ${location.zip} `}
    <a
      href={`http://maps.google.com/?q=${location.address} ${location.city} ${location.state} ${location.zip}`}
      title={`${location.address} ${location.city} ${location.state} ${location.zip}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <MapPin size={15} />
    </a>
  </span>
);
EventLocationAddress.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape),
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
};
EventLocationAddress.defaultProps = {
  className: 'address',
  iconOnly: false,
  location: {
    address: '',
    city: '',
    state: '',
    zip: '',
  },
};

export default EventLocationAddress;
