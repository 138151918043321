const defaultState = {
  login: {},
  contact: {},
  lead: {},

  userRegistrationData: { team: {}, user: {} },

  // Form Request Status
  getRegistrationRequest: {},
  registerRequest: {},
  completeProfileRequest: {},
  passwordResetRequest: {},
  passwordUpdateRequest: {},
  updateProfileRequest: {},
  updateTeamProfileRequest: {},

  // Validations
  emailValidation: {},
  teamUsernameValidation: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    /* Field Actions */
    case 'VALIDATE_EMAIL_STARTED':
      return { ...state, emailValidation: { working: true } };
    case 'VALIDATE_EMAIL_FAILED':
      return {
        ...state,
        emailValidation: { failed: true, errorMessage: action.payload },
      };
    case 'VALIDATE_EMAIL_SUCCESS':
      return {
        ...state,
        emailValidation: {
          success: true,
        },
      };

    case 'VALIDATE_TEAM_USERNAME_STARTED':
      return { ...state, teamUsernameValidation: { working: true } };
    case 'VALIDATE_TEAM_USERNAME_FAILED':
      return {
        ...state,
        teamUsernameValidation: { failed: true, errorMessage: action.payload },
      };
    case 'VALIDATE_TEAM_USERNAME_SUCCESS':
      return {
        ...state,
        teamUsernameValidation: {
          success: true,
          username: action.payload.team.teamUsername,
        },
      };

    /* Form Actions */
    case 'FORM_CONTACT_STARTED':
      return { ...state, contact: { working: true } };
    case 'FORM_CONTACT_FAILED':
      return { ...state, contact: { failed: true } };
    case 'FORM_CONTACT_SUCCESS':
      return { ...state, contact: { success: true } };

    case 'FORM_LEAD_STARTED':
      return { ...state, lead: { working: true } };
    case 'FORM_LEAD_FAILED':
      return { ...state, lead: { failed: true } };
    case 'FORM_LEAD_SUCCESS':
      return { ...state, lead: { success: true } };

    case 'FORM_LOGIN_STARTED':
      return { ...state, login: { working: true } };
    case 'FORM_LOGIN_FAILED':
      return { ...state, login: { failed: true, errorMessage: action.payload } };
    case 'FORM_LOGIN_SUCCESS':
      return { ...state, login: { success: true } };

    // load request from DB
    case 'GET_REGISTRATION_STARTED':
      return { ...state, getRegistrationRequest: { working: true } };
    case 'GET_REGISTRATION_FAILED':
      return {
        ...state,
        getRegistrationRequest: { failed: true, error: action.payload },
      };
    case 'GET_REGISTRATION_SUCCESS':
      return {
        ...state,
        getRegistrationRequest: {
          success: true,
        },
        userRegistrationData: action.payload,
      };

    case 'FORM_REGISTRATION_STARTED':
      return { ...state, registerRequest: { working: true } };
    case 'FORM_REGISTRATION_FAILED':
      return {
        ...state,
        registerRequest: { failed: true, errorMessage: action.payload },
      };
    case 'FORM_REGISTRATION_SUCCESS':
      return {
        ...state,
        registerRequest: {
          success: true,
          team: action.payload,
        },
      };

    case 'FORM_COMPLETE_PROFILE_STARTED':
      return { ...state, completeProfileRequest: { working: true } };
    case 'FORM_COMPLETE_PROFILE_FAILED':
      return {
        ...state,
        completeProfileRequest: { failed: true, errorMessage: action.payload },
      };
    case 'FORM_COMPLETE_PROFILE_SUCCESS':
      return { ...state, completeProfileRequest: { success: true } };

    case 'FORM_REQUEST_PASS_RESET_STARTED':
      return { ...state, passwordResetRequest: { working: true } };
    case 'FORM_REQUEST_PASS_RESET_FAILED':
      return {
        ...state,
        passwordResetRequest: { failed: true, errorMessage: action.payload },
      };
    case 'FORM_REQUEST_PASS_RESET_SUCCESS':
      return { ...state, passwordResetRequest: { success: true } };

    case 'FORM_UPDATE_PASSWORD_STARTED':
      return { ...state, passwordUpdateRequest: { working: true } };
    case 'FORM_UPDATE_PASSWORD_FAILED':
      return { ...state, passwordUpdateRequest: { failed: true } };
    case 'FORM_UPDATE_PASSWORD_SUCCESS':
      return { ...state, passwordUpdateRequest: { success: true } };

    case 'FORM_UPDATE_PROFILE_STARTED':
      return { ...state, updateProfileRequest: { working: true } };
    case 'FORM_UPDATE_PROFILE_FAILED':
      return {
        ...state,
        updateProfileRequest: { failed: true, errorMessage: action.payload },
      };
    case 'FORM_UPDATE_PROFILE_SUCCESS':
      return { ...state, updateProfileRequest: { success: true } };

    case 'UPDATE_TEAM_PROFILE_STARTED':
      return { ...state, updateTeamProfileRequest: { working: true } };
    case 'UPDATE_TEAM_PROFILE_FAILED':
      return {
        ...state,
        updateTeamProfileRequest: {
          failed: true,
          errorMessage: action.payload,
        },
      };
    case 'UPDATE_TEAM_PROFILE_SUCCESS':
      return {
        ...state,
        // userProfile: {
        //   ...state.userProfile,
        //   registeredTeam: action.payload,
        // },
        updateTeamProfileRequest: { success: true },
      };

    default:
      return state;
  }
};
