import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import { XCircle } from 'react-feather';
// import { Table } from 'reactstrap';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import CreateLeadMessageForm from '../../components/Leads/CreateLeadMessageForm';
import './style.css';

const LEAD_STATUSES = [
  { key: 'new', value: 'New' },
  { key: 'contacted', value: 'Contacted' },
  { key: 'booked', value: 'Booked' },
  { key: 'waiting', value: 'Waiting on Customer' },
  { key: 'lost', value: 'Lost' },
];
class ViewLeadsPage extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     filter: {
  //       pageSize: 50,
  //       pageNumber: 1,
  //     },
  //   };
  // }

  componentDidMount() {
    this.getLead();
  }

  // handleChange = (property, value) => {
  //   const { filter } = this.state;
  //   // console.log("Set Property", property, value);
  //   filter[property] = value;
  //   this.setState({ filter }, this.updateList);
  // };

  getLead() {
    const { getLeadDetails, routeParams } = this.props;
    // const { filter } = this.state;
    getLeadDetails(routeParams.id);
  }

  createMessage = (messageObj) => {
    const { addLeadMessage, leadDetail } = this.props;
    // leadDetail.status = messageObj.status;
    // leadDetail.leadCommunications.push(messageObj);
    // console.log('Lead Detail', leadDetail);
    addLeadMessage(leadDetail.id, messageObj);
  };

  deleteMessage = (messageId) => {
    // console.log(messageId);
    const { deleteLeadMessage, leadDetail } = this.props;
    deleteLeadMessage(leadDetail.id, messageId);
  };

  render() {
    const { leadDetail } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/contact" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-6">
                <h3>Quote Details</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>Creation Date</td>
                      <td>{moment(leadDetail.created).format('ddd, MMM Do, YYYY h:mma')}</td>
                    </tr>
                    <tr>
                      <td>Event Name</td>
                      <td>{leadDetail.eventName}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{leadDetail.name}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{leadDetail.email}</td>
                    </tr>
                    <tr>
                      <td>Event Type</td>
                      <td>{leadDetail.eventType}</td>
                    </tr>
                    <tr>
                      <td>Est. Teams</td>
                      <td>{leadDetail.numberOfTeams}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{leadDetail.status}</td>
                    </tr>
                    <tr>
                      <td>Referral Code</td>
                      <td>{leadDetail.referralCode}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-6">
                <h3>Message</h3>
                <pre>{leadDetail.message}</pre>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h3>Communication Log</h3>
                {leadDetail.leadCommunications &&
                  leadDetail.leadCommunications.length > 0 &&
                  leadDetail.leadCommunications.map((msg) => (
                    <div key={msg.id}>
                      <div className="message">{msg.message}</div>
                      <div className="message-info">
                        Status:
                        {msg.status}
                        {' - '}
                        {moment(msg.created).format('dddd, MMM Do, YYYY @ h:mm a')}
                        {'   '}
                        <span className="delete">
                          <XCircle className="deleteButton" size="10" onClick={() => this.deleteMessage(msg.id)} />
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-sm-6">
                <h3>Enter New Activity</h3>
                <CreateLeadMessageForm statuses={LEAD_STATUSES} leadDetail={leadDetail} onSubmit={this.createMessage} />
              </div>
              <div className="col-sm-12">
                <br />
                <Link to="/leads">Back to List</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewLeadsPage.propTypes = {
  getLeadDetails: PropTypes.func.isRequired,
  leadDetail: PropTypes.objectOf(PropTypes.shape),
  addLeadMessage: PropTypes.func.isRequired,
  deleteLeadMessage: PropTypes.func.isRequired,
  // leadsListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
ViewLeadsPage.defaultProps = {
  leadDetail: {},
};

const mapStateToProps = (state) => extend({}, state.leads, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(ViewLeadsPage);
