import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
// import Spinner from '../../components/Spinner';
import RegisteredTeamEditForm from '../../components/RegisteredTeams/EditForm';

class RegisteredTeamEditPage extends Component {
  componentDidMount() {
    const { getRegisteredTeamDetails, routeParams } = this.props;
    getRegisteredTeamDetails(routeParams.id);
  }

  render() {
    const { submitEditRegisteredTeam, registeredTeamDetails, registeredTeamEditRequest } = this.props;
    return (
      <div className="location-page page-container">
        <Header currentPage="/location" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit a Registered Team</h1>
            <RegisteredTeamEditForm
              entity={registeredTeamDetails}
              onSubmit={submitEditRegisteredTeam}
              loading={registeredTeamEditRequest.working}
              success={registeredTeamEditRequest.success}
              failed={registeredTeamEditRequest.failed}
              errorMessage={registeredTeamEditRequest.errorMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

RegisteredTeamEditPage.propTypes = {
  submitEditRegisteredTeam: PropTypes.func.isRequired,
  getRegisteredTeamDetails: PropTypes.func.isRequired,
  registeredTeamEditRequest: PropTypes.objectOf(PropTypes.shape),
  registeredTeamDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
RegisteredTeamEditPage.defaultProps = {
  registeredTeamDetails: {},
  registeredTeamEditRequest: {},
};

const mapStateToProps = (state) => extend({}, state.registeredTeams, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegisteredTeamEditPage);
