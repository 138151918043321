import React from 'react';
import PropTypes from 'prop-types';
import { map, includes } from 'lodash';
import classnames from 'classnames';
import { CheckCircle, Circle } from 'react-feather';
import moment from 'moment';

import { Table } from 'reactstrap';
import Spinner from '../Spinner';
import './styles.css';

// import EditQuestionModal from './EditQuestionModal';

// const LIMITS = [100, 300, 500];

const LiveStreamResponseTable = ({
  liveStreamResponses,
  isLoading,
  isSuccess,
  isFailed,
  errorMessage,
  columnsVisible,
  // onSearch,
  markCorrect,
  markIncorrect,
  // markOthersWrong,
}) => (
  <div>
    <Table striped hover responsive size="sm" className="element-list">
      <thead>
        <tr>
          <th>Timecode</th>
          <th className={classnames(includes(columnsVisible, 'name') ? '' : 'd-none')}>Name</th>
          <th className={classnames(includes(columnsVisible, 'email') ? '' : 'd-none')}>Email</th>
          {/* <th className={classnames(includes(columnsVisible, 'teamUsername') ? '' : 'd-none')}>Registered Team</th> */}
          <th className={classnames(includes(columnsVisible, 'grading') ? '' : 'd-none')} />
          <th>Answers ({liveStreamResponses.length})</th>
          {/* <th className="text-right">Counts</th> */}
          <th className="text-right">Points</th>
          <th className="text-right">Answer Rank</th>
        </tr>
      </thead>
      <tbody>
        {isFailed && (
          <tr>
            <td colSpan="10" className="danger">
              {errorMessage}
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td colSpan="10">
              <Spinner />
            </td>
          </tr>
        )}
        {isSuccess &&
          map(liveStreamResponses, (item) => (
            <tr key={item.id}>
              <td>
                <span title={moment(item.submitted).format('ddd, MMM Do, YYYY h:mm.ss a')}>{moment(item.submitted).format('h:mm.ss.SSS a')}</span>
              </td>
              <td className={classnames(includes(columnsVisible, 'name') ? '' : 'd-none')}>{item.name}</td>
              <td className={classnames(includes(columnsVisible, 'email') ? '' : 'd-none')}>{item.email}</td>
              {/* <td className={classnames(includes(columnsVisible, 'teamUsername') ? '' : 'd-none')}>{item.teamUsername}</td> */}
              <td
                className={classnames(
                  'text-center',
                  `question-${item.question}`,
                  item.isCorrect === true ? 'correct' : '',
                  item.isCorrect === false ? 'incorrect' : '',
                  item.isCorrect === null ? 'unmarked' : '',
                )}
              >
                {/* <span title="Edit this question number">
                      <Edit
                        color="gray"
                        size={20}
                        onClick={() => this.editQuestion(item)}
                        className={classnames(includes(columnsVisible, 'grading') ? '' : 'd-none')}
                      />
                      {'  '}
                    </span> */}
                {/* {item.question} */}
              </td>
              <td
                className={classnames(
                  'text-left',
                  item.isCorrect === true ? 'correct' : '',
                  item.isCorrect === false ? 'incorrect' : '',
                  item.isCorrect === null ? 'unmarked' : '',
                )}
              >
                {item.isCorrect === true && <CheckCircle className="clickable" onClick={() => markIncorrect(item)} color="green" size={20} />}
                {item.isCorrect === false && <CheckCircle className="clickable" onClick={() => markCorrect(item)} color="red" size={20} />}
                {item.isCorrect === null && <Circle className="clickable" onClick={() => markCorrect(item)} color="gray" size={20} />} {item.answer}
              </td>
              {/* <td className="text-right">{item.isCorrect === true ? 'Y' : ''}</td> */}
              {/* <td className="text-right">{item.countsTowardLeaderboard === true && <div>Yes</div>}</td> */}
              <td className="text-right">{item.points}</td>
              <td className="text-right">{item.answerRank}</td>
            </tr>
          ))}
      </tbody>
    </Table>
    {/* <EditQuestionModal
          onCancel={this.onCancelQuestionModal}
          onComplete={this.onCompleteEditQuestion}
          question={responseToEdit && responseToEdit.question}
          setValue={this.updateQuestionNumber}
          show={showQuestionModal}
        /> */}
  </div>
);

LiveStreamResponseTable.propTypes = {
  liveStreamResponses: PropTypes.arrayOf(PropTypes.shape),
  columnsVisible: PropTypes.arrayOf(String),
  markCorrect: PropTypes.func.isRequired,
  markIncorrect: PropTypes.func.isRequired,
  // markOthersWrong: PropTypes.func.isRequired,
  // updateLiveStreamResponse: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
LiveStreamResponseTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  columnsVisible: ['grading'],
  liveStreamResponses: [],
};

export default LiveStreamResponseTable;
