import List from './index';
import Create from './create';
import Edit from './edit';
import Details from './view';

export default [
  {
    title: 'Users',
    path: '/users',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create User',
    path: '/users/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit User',
    path: '/users/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Event Details',
    path: '/users/:id',
    component: Details,
    options: { private: true },
  },
];
