import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'react-feather';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import AccountsTable from '../../components/Accounts/AccountsTable';

class AccountsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    // console.log("Set Property", property, value);
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  updateList() {
    const { getAllAccounts } = this.props;
    const { filter } = this.state;
    getAllAccounts(filter);
  }

  render() {
    const { accounts, accountListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/accounts" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>Accounts</h1>
              </div>
              <div className="col text-right">
                <Link to="/accounts/create" className="btn btn-success">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <AccountsTable
              list={accounts.data}
              pagination={accounts.metadata}
              isLoading={accountListRequest.working}
              isSuccess={accountListRequest.success}
              isFailed={accountListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

AccountsPage.propTypes = {
  getAllAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape),
  accountListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
AccountsPage.defaultProps = {
  accounts: { data: [], metadata: { pageNum: 0, pageSize: 20, totalCount: 0 } },
};

const mapStateToProps = (state) => extend({}, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(AccountsPage);
