const defaultState = {
  isSidebarOpen: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'LEADERBOARD_DATA_FETCHED':
      return { ...state, leaderboard: action.payload };
    case 'REGULAR_EVENTS_FETCHED':
      return { ...state, regularEvents: action.payload };
    case 'SEMIREGULAR_EVENTS_FETCHED':
      return { ...state, semiRegularEvents: action.payload };
    case 'PRIVATE_EVENTS_FETCHED':
      return { ...state, privateEvents: action.payload };

    case 'HISTORICAL_TOURNAMENT_FETCHED':
      return { ...state, tournamentHistory: action.payload };

    case 'PHOTO_GALLERY_FETCHED':
      return { ...state, tournamentPhotoGallery: action.payload };

    case 'AUTH_STARTED':
      return {
        ...state,
        authStarted: true,
        authFailed: null,
        authenticated: false,
      };
    case 'AUTH_FAILED':
      return { ...state, authFailed: true, authenticated: false };
    case 'AUTH_SUCCESS':
      return { ...state, authenticated: true };

    case 'FETCH_MY_PROFILE_STARTED':
      return { ...state, fetchProfileStarted: true };
    case 'FETCH_MY_PROFILE_FAILED':
      return { ...state, fetchProfileStarted: null };
    case 'FETCH_MY_PROFILE_SUCCESS':
      return {
        ...state,
        userProfile: action.payload,
        fetchProfileStarted: null,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        userProfile: null,
        authenticated: null,
        authFailed: true,
      };

    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen };

    case 'GET_REGISTERED_TEAM_HISTORY_STARTED':
      return {
        ...state,
        getRegisteredTeamStart: true,
        teamHistory: { data: [] },
      };
    case 'GET_REGISTERED_TEAM_HISTORY_FAILED':
      return {
        ...state,
        getRegisteredTeamStart: null,
        getRegisteredTeamFailed: true,
        errorMessage: action.payload,
      };
    case 'GET_REGISTERED_TEAM_HISTORY_SUCCESS':
      return {
        ...state,
        getRegisteredTeamSuccess: true,
        getRegisteredTeamStart: null,
        getRegisteredTeamFailed: null,
        teamHistory: action.payload,
      };

    case 'GET_LOCAL_EMAIL_STARTED':
      return { ...state, getLocalEmailStart: true, email: null };
    case 'GET_LOCAL_EMAIL_SUCCESS':
      return { ...state, getLocalEmailStart: null, email: action.payload };

    default:
      return state;
  }
};
