import { extend } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { bindActionCreators } from 'redux';
// import { Table } from 'reactstrap';
import moment from 'moment';
import GenericActions from '../../actions/genericActions';
import EventsMissingHostsTable from '../../components/Home/EventsMissingHostsTable';
import PendingApplicationsTable from '../../components/Home/PendingApplications';
import PendingLeadsTable from '../../components/Home/PendingLeadsTable';
import SocialEventsHTML from '../../components/Home/SocialEventsHTML';
import UpcomingEventsTable from '../../components/Home/UpcomingEventsTable';
import UpcomingPrivateEventsTable from '../../components/Home/UpcomingPrivateEventsTable';
import Header from '../Header';
import Actions from './actions';
import './style.css';

class HomePage extends Component {
  componentDidMount() {
    const {
      getUpcomingEventTemplates,
      getUpcomingPrivateEvents,
      getHostReport,
      getLeads,
      getEventsMissingHosts,
      getHostApplications,
      // getEventStats,
      getEventsForSocial,
    } = this.props;
    getUpcomingPrivateEvents({
      eventTypes: ['private', 'fundraiser', 'live'],
      pageSize: 10,
      startDate: moment().subtract('1 day').format('YYYY-MM-DD'),
    });
    getUpcomingEventTemplates({
      eventTypes: ['recurring', 'semiregular', 'tournament'],
      pageSize: 10,
      startDate: moment().subtract('1 day').format('YYYY-MM-DD'),
    });

    getEventsForSocial({});
    getLeads({ status: ['new', 'contacted', 'waiting'] });
    getHostApplications({ status: ['New', 'Working'], pageSize: 5 });
    getEventsMissingHosts();
    // getEventStats({
    //   startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    //   endDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    // });
    getHostReport();
  }

  render() {
    const {
      upcomingEventTemplates,
      upcomingPrivateEvents,
      pendingLeads,
      eventsMissingHosts,
      pendingApplications,
      // attendanceStats,
      hostReport,
      // incomeStats,
      // eventStats,
      socialEvents,
    } = this.props;
    return (
      <div className="home-page page-container">
        <Header currentPage="/" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <h2>Upcoming Public Events</h2>
              <div className="box">
                <UpcomingEventsTable events={upcomingEventTemplates} />
                <div>
                  <Link to="/events?quickFilter=allactive">View All Active Events</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <h2>Upcoming Private Events</h2>
              <div className="box">
                <UpcomingPrivateEventsTable events={upcomingPrivateEvents} />
                <div>
                  <Link to="/events?quickFilter=private">View All Private Events</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <h2>Events Missing Hosts</h2>
              <div className="box">
                <EventsMissingHostsTable events={eventsMissingHosts} />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 col-xs-12">
              <h2>Attendance Last 12 Weeks</h2>
              <div className="box">
                <Chart chartType="ColumnChart" data={attendanceStats} />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              <h2>Event Count Last 12 Weeks</h2>
              <div className="box">
                <Chart chartType="ColumnChart" data={eventStats} />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              <h2>Income Gross Last 12 Weeks</h2>
              <div className="box">
                <Chart chartType="ColumnChart" data={incomeStats} />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <h2>Pending Leads</h2>
              <div className="box">
                <PendingLeadsTable leads={pendingLeads} />
                <div>
                  <Link to="/leads">View All Leads</Link>
                </div>
              </div>
              <h2>Pending Host Applications</h2>
              <div className="box">
                <PendingApplicationsTable applications={pendingApplications} />
                <div>
                  <Link to="/contact/applications">View All Applications</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              <h2>Host Report</h2>
              <div className="box">
                <Table striped size="sm" hover responsive>
                  <tbody>
                    {hostReport &&
                      hostReport.length > 0 &&
                      hostReport.map((item) => (
                        <tr key={`${item.firstName}${item.lastName}`}>
                          <th>
                            {item.firstName} {item.lastName}
                          </th>
                          <th>{item.eventCount}</th>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <h2>Social Events</h2>
              <div className="box">
                {/* <pre>{JSON.stringify(socialEvents, null, 2)}</pre> */}
                <SocialEventsHTML socialEvents={socialEvents} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  getUpcomingEventTemplates: PropTypes.func.isRequired,
  getUpcomingPrivateEvents: PropTypes.func.isRequired,
  getLeads: PropTypes.func.isRequired,
  getHostApplications: PropTypes.func.isRequired,
  getEventsMissingHosts: PropTypes.func.isRequired,
  getHostReport: PropTypes.func.isRequired,
  getEventStats: PropTypes.func.isRequired,
  getEventsForSocial: PropTypes.func.isRequired,
  upcomingEventTemplates: PropTypes.objectOf(PropTypes.shape),
  upcomingPrivateEvents: PropTypes.objectOf(PropTypes.shape),
  pendingLeads: PropTypes.objectOf(PropTypes.shape),
  pendingApplications: PropTypes.objectOf(PropTypes.shape),
  eventsMissingHosts: PropTypes.objectOf(PropTypes.shape),
  eventStats: PropTypes.arrayOf(PropTypes.shape),
  incomeStats: PropTypes.arrayOf(PropTypes.shape),
  // attendanceStats: PropTypes.arrayOf(PropTypes.shape),
  hostReport: PropTypes.arrayOf(PropTypes.shape),
  socialEvents: PropTypes.arrayOf(PropTypes.shape),
};
HomePage.defaultProps = {
  upcomingEventTemplates: {},
  upcomingPrivateEvents: {},
  pendingLeads: { data: [] },
  pendingApplications: { data: [] },
  eventsMissingHosts: {},
  incomeStats: [],
  attendanceStats: [],
  eventStats: [],
  hostReport: [],
  socialEvents: [],
};

const mapStateToProps = (state) => extend({}, state.global, state.dashboard);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, Actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(HomePage);
