import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/Users/CreateForm';

const UserCreatePage = ({ submitCreateUser, userDetails, userCreateRequest }) => (
  <div className="user-page page-container">
    <Header currentPage="/user" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create a User</h1>
        <CreateForm
          userDetails={userDetails}
          onSubmit={submitCreateUser}
          loading={userCreateRequest.working}
          success={userCreateRequest.success}
          failed={userCreateRequest.failed}
          errorMessage={userCreateRequest.errorMessage}
        />
      </div>
    </div>
  </div>
);

UserCreatePage.propTypes = {
  submitCreateUser: PropTypes.func.isRequired,
  userCreateRequest: PropTypes.objectOf(PropTypes.shape),
  userDetails: PropTypes.objectOf(PropTypes.shape),
};
UserCreatePage.defaultProps = {
  userCreateRequest: {},
  userDetails: {},
};

const mapStateToProps = (state) => extend({}, state.users, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(UserCreatePage);
