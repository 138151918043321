import { Field, FieldArray, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import moment from 'moment';
import { Plus, X } from 'react-feather';
import useEventTemplates from '../../hooks/useEventTemplates';
import './styles.css';
import _ from 'lodash';

const QuickCreateEventTemplateForm = ({
  values,
  touched,
  errors,
  dirty,
  isSubmitting,
  // handleChange,
  setFieldValue,
  // handleBlur,
  handleSubmit,
  // onCancel,
  loading,
  success,
  failed,
  errorMessage,
}) => {
  const { eventTemplates } = useEventTemplates();
  const [eventDates, setEventDates] = useState(values.eventDates || []);
  // }
  //   componentDidMount() {
  //     // const { searchAccounts, searchLocations } = this.props;
  //     // searchAccounts();
  //     // searchLocations();
  //   }

  //   componentDidUpdate(prevProps) {
  //     const { resetForm, success } = this.props;

  //     if (prevProps.success === false && success === true) resetForm();
  //   }

  // const { isBonus } = this.state;

  // import React, { useState } from 'react';
  // import { Field, withFormik, FieldArray } from 'formik';
  // import PropTypes from 'prop-types';
  // import Select from 'react-select';
  // import { Button, Spinner } from 'reactstrap';
  // import * as Yup from 'yup';

  // import useEventTemplates from '../../hooks/useEventTemplates';
  // import './styles.css';

  const handleAddDate = () => {
    const maxDate = values.eventDates.length ? values.eventDates[values.eventDates.length - 1] : moment().format('YYYY-MM-DD');
    setEventDates([...eventDates, maxDate]);
    setFieldValue(`eventDates.${eventDates.length}`, maxDate);
  };

  const handleRemoveDate = (index) => {
    const updatedDates = [...eventDates];
    updatedDates.splice(index, 1);
    setEventDates(updatedDates);
    setFieldValue('eventDates', updatedDates);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {success && <div className="alert alert-success">Events Created</div>}
        {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      </div>

      <div className="row" style={{ paddingBottom: '8px' }}>
        <div className="col-lg-8">
          {/* Payment Method Dropdown (Cash, Check, Quickbooks, Stripe) */}
          <div className="form-row">
            <div className="form-group col-sm-12">
              <Select
                options={eventTemplates}
                name="eventTemplateId"
                value={eventTemplates.filter(({ id }) => id === values.eventTemplateId)}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.name}
                onChange={(params) => setFieldValue('eventTemplateId', params.id)}
                classNamePrefix="select"
                placeholder="Select Event Template"
              />
              {errors.eventTemplateId && touched.eventTemplateId && <div className="alert alert-danger">{errors.eventTemplateId}</div>}
            </div>
          </div>
          <FieldArray
            name="eventDates"
            render={() => (
              <>
                {eventDates.map((date, index) => (
                  <div className="form-row" key={index}>
                    <div className="form-group col-sm-12 form-inline">
                      <Field className="form-control" type="date" name={`eventDates.${index}`} />
                      <Button onClick={() => handleRemoveDate(index)} outline style={{ marginLeft: '4px' }}>
                        <X />
                      </Button>
                    </div>
                    {errors[`eventDates.${index}`] && touched[`eventDates.${index}`] && (
                      <div className="alert alert-danger">{errors[`eventDates.${index}`]}</div>
                    )}
                  </div>
                ))}
                {/* <div>{JSON.stringify(eventDates)}</div> */}
                <Button onClick={handleAddDate} color="primary">
                  <Plus /> Add Date
                </Button>
              </>
            )}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <>
              <Button type="submit" color="primary" disabled={!dirty || !errors} className="float-right">
                Save
              </Button>{' '}
            </>
          )}
        </div>
      </div>
    </form>
  );
};

QuickCreateEventTemplateForm.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),

  values: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

QuickCreateEventTemplateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  dirty: false,
  values: {},
  errors: {},
  touched: {},
  isSubmitting: false,
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    eventTemplateId: '',
    eventDates: [moment().format('YYYY-MM-DD')],
  }),

  validationSchema: Yup.object().shape({
    // stripeInvoicePaidOn: Yup.date().required('Please Select a Payment Date'),
    eventTemplateId: Yup.string().required('Please Select an Event Template'),
    eventDates: Yup.array().of(Yup.date().required('Please Select a Date')),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const vals = { ...values, eventDates: _.uniq(values.eventDates) };
    props.onSubmit(vals);
    setSubmitting(false);
  },
  displayName: 'Quick Create Event Template Form',
})(QuickCreateEventTemplateForm);
