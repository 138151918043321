import React, { Component } from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { find, uniqBy, xorBy } from 'lodash';
import Spinner from '../Spinner';
import groups from '../../data/groups.json';

class UserCreateForm extends Component {
  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) {
      resetForm();
    }
  }

  toggleGroup = (group, exists) => {
    const { values, setFieldValue } = this.props;
    if (exists) setFieldValue('groups', xorBy(values.groups, [group], 'id'));
    else setFieldValue('groups', uniqBy([...values.groups, group], 'id'));
  };

  render() {
    const {
      values,
      // touched,
      // errors,
      // dirty,
      isSubmitting,
      // handleChange,
      // setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      userDetails,
      // handleReset,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && (
            <div className="alert alert-success">
              User Created
              <br />
              <Link to={`/users/${userDetails.id}/edit`}>Edit this User</Link>
            </div>
          )}
          {failed && <div className="alert alert-danger">{errorMessage}</div>}
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="username" className="required">
                  Email Address
                </label>
                <Field type="email" name="username" className="form-control" />
                <ErrorMessage name="username" component="div" className="alert alert-danger" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName">First Name</label>
                <Field type="text" name="firstName" className="form-control" />
                <ErrorMessage name="firstName" component="div" className="alert alert-danger" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastName">Last Name</label>
                <Field type="text" name="lastName" className="form-control" />
                <ErrorMessage name="lastName" component="div" className="alert alert-danger" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="nickname">Nickname</label>
                <Field type="text" name="nickname" className="form-control" />
                <ErrorMessage name="nickname" component="div" className="alert alert-danger" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="googleFolderId">Google Folder Id</label>
                <Field type="text" name="googleFolderId" className="form-control" />
                <ErrorMessage name="googleFolderId" component="div" className="alert alert-danger" />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <label>Groups</label>{' '}
            {groups.map((group) => (
              <div key={group.id}>
                <Button
                  disabled={loading}
                  color={find(values.groups, group) ? 'info' : 'link'}
                  size="sm"
                  onClick={() => this.toggleGroup(group, find(values.groups, group))}
                >
                  {group.name}
                </Button>{' '}
              </div>
            ))}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Link className="btn btn-default" to="/users">
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
UserCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  userDetails: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
UserCreateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // routeParams: {},
  userDetails: {},

  values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  mapPropsToValues: () => ({
    username: '',
    firstName: '',
    lastName: '',
    nickname: '',
    googleFolderId: '',
    groups: [],
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string().email('Please enter a valid email address').required('Please enter an email address'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    // console.log("Submit", values.groups);
    setSubmitting(false);
  },
  displayName: 'User Creation Form',
})(UserCreateForm);
