import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Pagination from 'rc-pagination';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import Edit from 'react-feather/dist/icons/edit';
import Spinner from '../Spinner';

const LIMITS = [20, 50, 100];
const AccountsTable = ({ list, pagination, isLoading, isSuccess, isFailed, errorMessage, onPageSizeChange, onPageNumberChange, onSearch }) => (
  <div>
    <input type="text" placeholder="Search..." className="form-control" onChange={(e) => onSearch(e.target.value)} />
    <Table striped hover responsive size="sm" className="element-list">
      <thead>
        <tr>
          <th>Name</th>
          <th>Billing Accounts</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {isFailed && (
          <tr>
            <td colSpan="2" className="danger">
              {errorMessage}
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td colSpan="2">
              <Spinner />
            </td>
          </tr>
        )}
        {isSuccess &&
          map(list, (item) => (
            <tr key={item.id}>
              <td>
                <Link to={`/accounts/${item.id}`} className="action">
                  {item.name}
                </Link>
              </td>
              <td>
                {item.billingAccounts?.map((e) => (
                  <div key={e.id}>
                    <Link to={`/billing-accounts/${e.id}`} className="action">
                      {e.name}
                    </Link>
                  </div>
                ))}
              </td>
              <td className="actions">
                <Link to={`/accounts/${item.id}/edit`} className="action">
                  <Edit color="#999" size={15} />
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <div className="row query-footer">
      <div className="page-size form-inline col-sm-6">
        <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
          {map(LIMITS, (opt) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </select>
        {' per page'}
      </div>
      <div className="col-sm-6 text-right">
        <Pagination
          onChange={onPageNumberChange}
          showLessItems
          current={pagination.pageNumber}
          total={pagination.totalCount}
          pageSize={pagination.pageSize || 20}
        />
      </div>
    </div>
  </div>
);

AccountsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
AccountsTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  list: [],
  pagination: {
    pageNumber: 1,
    pageSize: 20,
    totalCount: 1000,
  },
};

export default AccountsTable;
