import React from 'react';
import './style.css';
import PropTypes from 'prop-types';
import { FilePlus, Send } from 'react-feather';

const InvoicingStatusIcon = ({ event }) => (
  <>
    {event.isCreateInvoice && (
      <span title="When an event is marked complete, an invoice item will be created automatically">
        <FilePlus color="green" size="14" />
      </span>
    )}
    {!event.isCreateInvoice && (
      <span title="When an event is marked complete, an invoice item will NOT be created automatically">
        <FilePlus color="lightgray" size="14" />
      </span>
    )}{' '}
    {event.isAutoSendInvoice && (
      <span title={`If an invoice item exists for this event, it will be sent automatically every ${event.account?.stripeInvoiceFrequency} weeks`}>
        <Send color="green" size="14" />
      </span>
    )}
    {!event.isAutoSendInvoice && (
      <span title="If an invoice item exists for this event, it will NOT be sent automatically">
        <Send color="lightgray" size="14" />
      </span>
    )}
  </>
);

InvoicingStatusIcon.defaultProps = {
  event: {},
  // onSendInvoice: null,
};
InvoicingStatusIcon.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};
export default InvoicingStatusIcon;
