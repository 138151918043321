const defaultState = {
  // Form Request Status
  billingAccounts: { data: [], metadata: {} },
  billingAccountDetails: {},
  billingAccountListRequest: {},
  billingAccountDetailRequest: {},
  billingAccountCreateRequest: {},
  billingAccountEditRequest: {},
  billingAccountDeleteRequest: {},
  billingAccountInvoicingRequest: {},

  regions: { data: [], metadata: {} },
  regionListRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'BILLINGACCOUNTS_LIST_STARTED':
      return { ...state, billingAccountListRequest: { working: true } };
    case 'BILLINGACCOUNTS_LIST_FAILED':
      return { ...state, billingAccountListRequest: { failed: true } };
    case 'BILLINGACCOUNTS_LIST_SUCCESS':
      return {
        ...state,
        billingAccounts: action.payload,
        billingAccountListRequest: { success: true },
      };

    case 'REGION_LIST_STARTED':
      return { ...state, regionListRequest: { working: true } };
    case 'REGION_LIST_FAILED':
      return { ...state, regionListRequest: { failed: true } };
    case 'REGION_LIST_SUCCESS':
      return {
        ...state,
        regions: action.payload,
        regionListRequest: { success: true },
      };

    case 'BILLINGACCOUNTS_DETAIL_STARTED':
      return { ...state, billingAccountDetailRequest: { working: true } };
    case 'BILLINGACCOUNTS_DETAIL_FAILED':
      return { ...state, billingAccountDetailRequest: { failed: true } };
    case 'BILLINGACCOUNTS_DETAIL_SUCCESS':
      return {
        ...state,
        billingAccountDetails: action.payload,
        billingAccountDetailRequest: { success: true },
      };

    case 'BILLINGACCOUNTS_CREATE_STARTED':
      return { ...state, billingAccountCreateRequest: { working: true } };
    case 'BILLINGACCOUNTS_CREATE_FAILED':
      return { ...state, billingAccountCreateRequest: { failed: true } };
    case 'BILLINGACCOUNTS_CREATE_SUCCESS':
      return {
        ...state,
        billingAccountDetails: action.payload,
        billingAccountCreateRequest: { success: true },
      };

    case 'BILLINGACCOUNTS_EDIT_STARTED':
      return { ...state, billingAccountEditRequest: { working: true } };
    case 'BILLINGACCOUNTS_EDIT_FAILED':
      return { ...state, billingAccountEditRequest: { failed: true } };
    case 'BILLINGACCOUNTS_EDIT_SUCCESS':
      return {
        ...state,
        billingAccountDetails: action.payload,
        billingAccountEditRequest: { success: true },
      };

    case 'BILLINGACCOUNTS_INVOICING_STARTED':
      return { ...state, billingAccountInvoicingRequest: { working: true } };
    case 'BILLINGACCOUNTS_INVOICING_FAILED':
      return { ...state, billingAccountInvoicingRequest: { failed: true } };
    case 'BILLINGACCOUNTS_INVOICING_SUCCESS':
      return {
        ...state,
        billingAccountDetails: action.payload,
        billingAccountInvoicingRequest: { success: true },
      };

    default:
      return state;
  }
};
