import { createBrowserHistory } from 'history';
import { extend } from 'lodash';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import InvoicesTable from '../../components/Invoices/InvoicesTable';
import Header from '../Header';
import actions from './actions';

const InvoicingPage = () => {
  const setBrowserURL = (filterObj) => {
    const history = createBrowserHistory();
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify(filterObj, { arrayFormat: 'bracket' }),
    });
    // console.log('setting browser url', filterObj);
  };

  return (
    <div className="page-container">
      <Header currentPage="/invoices" />
      <div className="container-fluid position-relative">
        <div className="content-box">
          <h1>Invoices</h1>
          <InvoicesTable setBrowserURL={setBrowserURL} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => extend({}, state.events, state.invoices, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(InvoicingPage);
