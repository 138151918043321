import { find } from 'lodash';

const defaultState = {
  // Form Request Status
  events: {},
  eventDetails: {},
  eventHistory: [],
  eventBonusHistoryRequest: {},
  eventListRequest: {},
  eventDetailRequest: {},
  eventCreateRequest: {},
  eventEditRequest: {},
  eventDeleteRequest: {},
  eventGoogleImportRequest: {},
  eventBatchUpdateRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'EVENTS_LIST_STARTED':
      return { ...state, eventListRequest: { working: true } };
    case 'EVENTS_LIST_FAILED':
      return { ...state, eventListRequest: { failed: true } };
    case 'EVENTS_LIST_SUCCESS':
      // console.log('Action Payload', action.payload);
      return {
        ...state,
        needRefreshEventList: false,
        // events: { // mapping an uncommon return from the API
        //   data: action.payload.events,
        //   metadata: {
        //     totalCount: action.payload.count,
        //     pageSize: action.payload.pageSize,
        //     pageNum: action.payload.pageNum,
        //   },
        // },
        events: action.payload,
        eventListRequest: { success: true },
      };

    case 'EVENTS_DETAIL_STARTED':
      return { ...state, eventDetailRequest: { working: true } };
    case 'EVENTS_DETAIL_FAILED':
      return { ...state, eventDetailRequest: { failed: true } };
    case 'EVENTS_DETAIL_SUCCESS':
      return {
        ...state,
        eventDetails: action.payload,
        eventDetailRequest: { success: true },
      };
    case 'EVENTS_HISTORY_STARTED':
      return { ...state, eventBonusHistoryRequest: { working: true } };
    case 'EVENTS_HISTORY_FAILED':
      return { ...state, eventBonusHistoryRequest: { failed: true } };
    case 'EVENTS_HISTORY_SUCCESS':
      return {
        ...state,
        eventHistory: action.payload,
        eventBonusHistoryRequest: { success: true },
      };

    case 'EVENTS_CREATE_STARTED':
      return { ...state, eventCreateRequest: { working: true } };
    case 'EVENTS_CREATE_FAILED':
      return { ...state, eventCreateRequest: { failed: true } };
    case 'EVENTS_CREATE_SUCCESS':
      return {
        ...state,
        needRefreshEventList: true,
        eventDetails: action.payload,
        eventCreateRequest: { success: true },
      };

    case 'EVENTS_EDIT_STARTED':
      return { ...state, eventEditRequest: { working: true } };
    case 'EVENTS_EDIT_FAILED':
      return {
        ...state,
        eventEditRequest: { failed: true, errorMessage: action.payload },
      };
    case 'EVENTS_EDIT_SUCCESS':
      return {
        ...state,
        // events: {
        //   ...state.events,
        //   data: state.events.data.map((event) => {
        //     if (event.id === action.payload.id) {
        //       return action.payload;
        //     }
        //     return event;
        //   }),
        // },
        eventDetails: action.payload,
        eventEditRequest: { success: true },
      };

    case 'EVENTS_BATCH_UPDATE_STARTED':
      return { ...state, eventBatchUpdateRequest: { working: true } };
    case 'EVENTS_BATCH_UPDATE_FAILED':
      return { ...state, eventBatchUpdateRequest: { failed: true } };
    case 'EVENTS_BATCH_UPDATE_SUCCESS':
      return {
        ...state,
        events: {
          ...state.events,
          data: state.events.data.map((event) => {
            const match = find(action.payload, { id: event.id });
            return match || event;
          }),
        },
        eventBatchUpdateRequest: { success: true },
      };

    case 'EVENTS_DELETE_STARTED':
      return { ...state, eventDeleteRequest: { working: true } };
    case 'EVENTS_DELETE_FAILED':
      return { ...state, eventDeleteRequest: { failed: true } };
    case 'EVENTS_DELETE_SUCCESS':
      return {
        ...state,
        needRefreshEventList: true,
        eventDetails: action.payload,
        eventEditRequest: { success: true },
      };

    case 'EVENTS_GOOGLE_IMPORT_STARTED':
      return { ...state, eventGoogleImportRequest: { working: true } };
    case 'EVENTS_GOOGLE_IMPORT_FAILED':
      return { ...state, eventGoogleImportRequest: { failed: true } };
    case 'EVENTS_GOOGLE_IMPORT_SUCCESS':
      return {
        ...state,
        needRefreshEventList: true,
        eventDetails: action.payload,
        eventGoogleImportRequest: { success: true },
      };

    case 'EVENTS_REFRESH_LIST':
      return { ...state, needRefreshEventList: true };

    default:
      return state;
  }
};
