import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { EVENT_STATUS } from '../../../config';

class EventStatus extends Component {
  clickStatus = () => {
    const { onClickDraft, onClickPending, event } = this.props;
    switch (event.status) {
      case EVENT_STATUS.DRAFT:
        return onClickDraft ? onClickDraft(event) : false;
      case EVENT_STATUS.PENDING:
        return onClickPending ? onClickPending(event) : false;
      default:
        return false;
    }
  };

  render() {
    const { event } = this.props;
    if (event.status === EVENT_STATUS.CANCELED) {
      return (
        <Badge pill style={{ cursor: 'pointer' }} color="danger" title={`Cancellation Reason: ${event.notes}`}>
          Canceled
        </Badge>
      );
    }

    let title = ''; // `Status: ${event.status}`;
    if (event.status === EVENT_STATUS.DRAFT) title = 'In Draft. Click to Publish this Event';
    if (event.status === EVENT_STATUS.PENDING) title = 'Pending Approval. Click to Complete this Event';

    switch (event.status) {
      case EVENT_STATUS.DRAFT:
        return (
          <Badge pill onClick={this.clickStatus} style={{ cursor: 'pointer' }} color="secondary" title={title}>
            Draft
          </Badge>
        );
      case EVENT_STATUS.READY:
        return (
          <Badge pill style={{ cursor: 'pointer' }} color="success" title={title}>
            Ready
          </Badge>
        );
      case EVENT_STATUS.PENDING:
        return (
          <Badge pill onClick={this.clickStatus} style={{ cursor: 'pointer' }} color="warning" title={title}>
            Pending
          </Badge>
        );
      case EVENT_STATUS.COMPLETE:
        return (
          <Badge pill color="primary" title={title}>
            Complete
          </Badge>
        );
      default:
        return <span />;
    }
  }
}

EventStatus.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape).isRequired,
  onClickDraft: PropTypes.func,
  onClickPending: PropTypes.func,
};

EventStatus.defaultProps = {
  onClickDraft: null,
  onClickPending: null,
};

export default EventStatus;
