import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Pagination from 'rc-pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CheckCircle, Edit } from 'react-feather';
import { Table } from 'reactstrap';
// import Edit from 'react-feather/dist/icons/edit';
import Spinner from '../Spinner';

const LIMITS = [100, 300, 500];
const LiveStreamTable = ({
  list,
  pagination,
  isLoading,
  isSuccess,
  isFailed,
  errorMessage,
  onSearch,
  onPageSizeChange,
  onPageNumberChange,
  toggleActiveState,
}) => (
  <div>
    <input type="text" placeholder="Search..." className="form-control" onChange={(e) => onSearch(e.target.value)} />
    <Table striped hover responsive size="sm" className="element-list">
      <thead>
        <tr>
          <th>Name</th>
          <th>EventCode</th>
          <th>Date</th>
          <th># of Responses</th>
          <th>Active</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {isFailed && (
          <tr>
            <td colSpan="10" className="danger">
              {errorMessage}
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td colSpan="10">
              <Spinner />
            </td>
          </tr>
        )}
        {isSuccess &&
          map(list, (item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.eventCode}</td>
              <td>{moment(item.dateTimeStart).format('ddd, MMM Do, YYYY h:mma')}</td>
              <td>
                <Link to={`/live-streams/${item.id}`}>{item.liveStreamResponses.length}</Link>
              </td>
              <td>
                {item.isActive ? (
                  <CheckCircle onClick={() => toggleActiveState(item, false)} color="green" size={20} />
                ) : (
                  <CheckCircle onClick={() => toggleActiveState(item, true)} color="lightgray" size={20} />
                )}
              </td>
              <td className="actions">
                <Link to={`/live-streams/${item.id}/edit`} className="action">
                  <Edit color="#999" size={15} />
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <div className="row query-footer">
      <div className="page-size form-inline col-sm-6">
        <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
          {map(LIMITS, (opt) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </select>
        {' per page'}
      </div>
      <div className="col-sm-6 text-right">
        <Pagination
          onChange={onPageNumberChange}
          showLessItems
          current={pagination.pageNumber}
          total={pagination.totalCount}
          pageSize={pagination.pageSize || 20}
        />
      </div>
    </div>
  </div>
);

LiveStreamTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  toggleActiveState: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
LiveStreamTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  list: [],
  pagination: {},
};

export default LiveStreamTable;
