import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  // className,
  // ...props
}) => (
  <div>
    <label htmlFor={id}>
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames('form-control')}
        // increaseArea="20%"
      />
      {label}
    </label>
    {touched[name] && <div>{errors[name]}</div>}
  </div>
);

Checkbox.propTypes = {
  field: PropTypes.objectOf(PropTypes.shape),
  form: PropTypes.objectOf(PropTypes.shape),
  id: PropTypes.string,
  label: PropTypes.string,
  // className: PropTypes.string,
  // value: PropTypes.string,
};
Checkbox.defaultProps = {
  field: {},
  form: {},
  id: '',
  label: '',
  // className: '',
  // value: '',
};
export default Checkbox;
