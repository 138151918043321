import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const AccountLocationsTags = ({ locations, onClickLocation }) =>
  locations.map((location) => (
    <Badge key={location.id} onClick={() => onClickLocation(location)} color="secondary" className="clickable-badge">
      {location.name}
    </Badge>
  ));

AccountLocationsTags.propTypes = {
  onClickLocation: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape),
};
AccountLocationsTags.defaultProps = {
  locations: [],
};
export default AccountLocationsTags;
