import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import useEvent from '../../hooks/useEvent';
import QuickCreateEventTemplateForm from './QuickCreateEventTemplateForm';
import toastActions from '../../actions/toastActions';

const QuickCreateModal = ({ onCancel, show, className }) => {
  const { quickCreateEventFromTemplate } = useEvent();
  const onFormSubmit = async (vals) => {
    // mutateEvent(await updateEvent(vals));
    // await createEvent(vals);
    const r = await quickCreateEventFromTemplate(vals);
    if (r) {
      toastActions.displaySuccessToast(` ${r.eventsCreated} Events Created!`);
      onCancel();
    }
  };

  // const onClickCancel = () => {
  //   onCancel();
  // };

  useEffect(() => {
    if (show) {
      // mutateEvent();
    }
  }, [show]);

  return (
    <Modal isOpen={show} toggle={onCancel} className={className}>
      <ModalHeader toggle={onCancel}>Quick Create Event</ModalHeader>
      <ModalBody>
        {/* {loading && <Spinner />} */}
        {/* {error && <div>{error.message || JSON.stringify(error)}</div>} */}
        <QuickCreateEventTemplateForm onSubmit={onFormSubmit} />
      </ModalBody>
    </Modal>
  );
};
QuickCreateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // onComplete: PropTypes.func.isRequired,
  show: PropTypes.bool,
  className: PropTypes.string,
};
QuickCreateModal.defaultProps = {
  eventId: null,
  show: false,
  className: '',
};
export default QuickCreateModal;
