import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { extend } from 'lodash';
import { connect } from 'react-redux';
import actions from '../../containers/Events/actions';
import HostActions from '../../containers/Hosts/actions';
import GenericActions from '../../actions/genericActions';
import HostSelector from '../../containers/Hosts/HostSelector';

class AddHostModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedHostList: [],
    };
    this.toggle = this.toggle.bind(this);
    // this.updateHosts = this.updateHosts.bind(this);
    this.initHostList = this.initHostList.bind(this);
    this.completeSelection = this.completeSelection.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { show, initialHostsList } = this.props;
    if (!prevProps.show && show) {
      this.initHostList();
    }
    if (prevProps.initialHostsList !== initialHostsList) {
      this.setState({ selectedHostList: initialHostsList }); // eslint-disable-line
    }
  }

  initHostList = () => {
    this.setState({ selectedHostList: [] });
  };

  updateHosts = (hosts) => {
    // const { selectedHostList } = this.props;
    this.setState({ selectedHostList: hosts });
  };

  completeSelection() {
    const { onComplete } = this.props;
    const { selectedHostList } = this.state;
    onComplete(selectedHostList);
  }

  toggle() {
    const { onCancel } = this.props;
    this.initHostList();
    onCancel();
  }

  render() {
    // const { hosts } = this.state;
    const { show, className } = this.props;
    const { selectedHostList } = this.state;
    return (
      <Modal isOpen={show} toggle={this.toggle} className={className}>
        <ModalHeader toggle={this.toggle}>Edit Hosts for Event</ModalHeader>
        <ModalBody>
          <HostSelector selectedHosts={selectedHostList} onUpdate={this.updateHosts} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.completeSelection}>
            Update Hosts
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AddHostModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  // onUpdateHostList: PropTypes.func.isRequired,
  // event: PropTypes.objectOf(PropTypes.shape),
  initialHostsList: PropTypes.arrayOf(PropTypes.shape),
  show: PropTypes.bool,
  className: PropTypes.string,
};
AddHostModal.defaultProps = {
  initialHostsList: [],
  show: false,
  className: '',
};

const mapStateToProps = (state) => extend({}, state.hosts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, HostActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(AddHostModal);
