import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/Accounts/EditForm';

class AccountEditPage extends Component {
  componentDidMount() {
    const { getAccountDetails, routeParams } = this.props;
    getAccountDetails(routeParams.id);
  }

  render() {
    const { submitEditAccount, accountDetails, accountDetailRequest, accountEditRequest } = this.props;
    return (
      <div className="account-page page-container">
        <Header currentPage="/accounts" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit an Account</h1>
            {/* <div>Account Details: {JSON.stringify(accountDetails)}</div> */}
            {accountDetailRequest.working && <Spinner />}
            {accountDetailRequest.success && (
              <EditForm
                entity={accountDetails}
                onSubmit={submitEditAccount}
                loading={accountEditRequest.working}
                success={accountEditRequest.success}
                failed={accountEditRequest.failed}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AccountEditPage.propTypes = {
  submitEditAccount: PropTypes.func.isRequired,
  getAccountDetails: PropTypes.func.isRequired,
  accountDetailRequest: PropTypes.objectOf(PropTypes.shape),
  accountEditRequest: PropTypes.objectOf(PropTypes.shape),
  accountDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
AccountEditPage.defaultProps = {
  accountDetails: {},
  accountEditRequest: {},
  accountDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(AccountEditPage);
