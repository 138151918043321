import { toast } from 'react-toastify';
// import API from '../utils/api/global';

const displayErrorToast = (errorMessage) => {
  toast(errorMessage, {
    autoClose: false,
    type: toast.TYPE.ERROR,
  });
};

const displaySuccessToast = (successMessage) => {
  toast(successMessage, {
    type: toast.TYPE.SUCCESS,
  });
};

export default {
  displayErrorToast,
  displaySuccessToast,
};
