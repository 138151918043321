import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import HostMultiValueLabel from '../../components/SelectComponents/HostMultiValueLabel';

class HostSelector extends Component {
  componentDidMount() {
    const { getAllHosts } = this.props;
    getAllHosts();
  }

  onChange = (values) => {
    const { onUpdate } = this.props;
    onUpdate(values);
  };

  render() {
    const { hosts, selectedHosts } = this.props;

    return (
      <Select
        components={{ MultiValueLabel: HostMultiValueLabel }}
        isMulti
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => `${opt.firstName} ${opt.lastName}`}
        value={selectedHosts}
        onChange={this.onChange}
        closeMenuOnSelect={false}
        options={hosts.data}
        classNamePrefix="select"
      />
    );
  }
}

HostSelector.propTypes = {
  getAllHosts: PropTypes.func.isRequired,
  hosts: PropTypes.objectOf(PropTypes.shape),
  // hostListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  selectedHosts: PropTypes.arrayOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
};
HostSelector.defaultProps = {
  selectedHosts: [],
  hosts: { data: [], metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 } },
};

const mapStateToProps = (state) => extend({}, state.hosts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(HostSelector);
