import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import './style.css';
import Spinner from '../../Spinner';

const HostEventBonusDetail = ({ eventHistory, isLoading, isSuccess }) => (
  <div>
    <h3>Location Media History</h3>
    <p>This shows the last 10 bonus and final categories used at this location.</p>
    <div className="table-responsive">
      <table className="table table-hover history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Photo Round</th>
            <th>Final Category</th>
            {/* <th className="hidden-xs">Final Question</th> */}
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            eventHistory.map((event) => (
              <tr key={event.eventId}>
                <td>{moment(event.dateTimeStart).format('MM/DD/YYYY')}</td>
                <td>{event.photoRound}</td>
                <td>{event.finalCategory}</td>
                {/* <td className="final-question hidden-xs">{event.finalQuestion}</td> */}
              </tr>
            ))}
          {isSuccess && eventHistory.length <= 0 && (
            <tr>
              <td colSpan="4">No Event History for This Location</td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan="4">
                <Spinner />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);
HostEventBonusDetail.propTypes = {
  eventHistory: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};
HostEventBonusDetail.defaultProps = {
  isLoading: false,
  isSuccess: false,
};
export default HostEventBonusDetail;
