import { combineReducers } from 'redux';
import genericReducer from './genericReducer';
import formsReducer from './formReducers';
import eventsReducer from '../containers/Events/reducer';
import eventTemplatesReducer from '../containers/EventTemplates/reducer';
import accountsReducer from '../containers/Accounts/reducer';
import paymentsReducer from '../containers/Payments/reducer';
import locationsReducer from '../containers/Locations/reducer';
import teamsReducer from '../containers/RegisteredTeams/reducer';
import usersReducer from '../containers/Users/reducer';
import hostsReducer from '../containers/Hosts/reducer';
import dashboardReducer from '../containers/Home/reducer';
import liveStreamReducer from '../containers/LiveStream/reducer';
import hostApplicationsReducer from '../containers/Contact/reducer';
import leadsReducer from '../containers/Leads/reducer';
import tournamentsReducer from '../containers/Tournaments/reducer';
import invoicesReducer from '../containers/Invoicing/reducer';
import billingAccountsReducer from '../containers/BillingAccounts/reducer';

const allReducers = combineReducers({
  global: genericReducer,
  forms: formsReducer,
  events: eventsReducer,
  eventTemplates: eventTemplatesReducer,
  accounts: accountsReducer,
  locations: locationsReducer,
  registeredTeams: teamsReducer,
  users: usersReducer,
  hosts: hostsReducer,
  payments: paymentsReducer,
  hostApplications: hostApplicationsReducer,
  dashboard: dashboardReducer,
  leads: leadsReducer,
  liveStream: liveStreamReducer,
  tournaments: tournamentsReducer,
  invoices: invoicesReducer,
  billingAccounts: billingAccountsReducer,
});

// export const mapStateToProps = (state) => {
//   return {
//     global: state.global,
//     forms: state.forms,
//     hosts: state.hosts
//   }
// };

export default allReducers;
