import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { extend } from 'lodash';
// import Select from 'react-select';
import PropTypes from 'prop-types';
import // XCircle,
'react-feather';
// import { Table } from 'reactstrap';
import Select from 'react-select';
import GenericActions from '../../../actions/genericActions';
import actions from '../actions';
import Header from '../../Header';

const APPLICATION_STATUSES = [
  { value: 'New', label: 'New' },
  { value: 'Working', label: 'Working with Host' },
  { value: 'Hired', label: 'Hired as a Host' },
  { value: 'Declined', label: 'Application Declined' },
  { value: 'Test', label: 'Test' },
];

class ViewApplicationPage extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     status: APPLICATION_STATUSES[0],
  //   };
  // }

  componentDidMount() {
    this.getApplication();
  }

  getApplication() {
    const { getHostApplicationDetails, routeParams } = this.props;
    // const { filter } = this.state;
    getHostApplicationDetails(routeParams.id);
  }

  setApplicationStatus = (property) => {
    const { value } = property;
    const { submitEditHostApplication, routeParams } = this.props;
    // console.log('Set Property', value);
    submitEditHostApplication(routeParams.id, { type: value });
  };

  render() {
    const { hostApplicationDetails } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/contact" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-6">
                <h3>Application Details</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{hostApplicationDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>{moment(hostApplicationDetails.created).format('ddd, MMM Do, YYYY h:mma')}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{hostApplicationDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>{`${hostApplicationDetails.city}, ${hostApplicationDetails.state}`}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{hostApplicationDetails.email}</td>
                    </tr>
                    <tr>
                      <td>Have a Laptop</td>
                      <td>{hostApplicationDetails.hasLaptop}</td>
                    </tr>
                    <tr>
                      <td>Has Sound Equipment</td>
                      <td>{hostApplicationDetails.hasSoundEquipment}</td>
                    </tr>
                    <tr>
                      <td>Days Available</td>
                      <td>{hostApplicationDetails.availableDays && hostApplicationDetails.availableDays.join(', ')}</td>
                    </tr>
                    <tr>
                      <td>Youtube URL</td>
                      <td>
                        <a href={hostApplicationDetails.youtubeURL} target="noreferrer noopener">
                          {hostApplicationDetails.youtubeURL}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3>Application Status</h3>
                <Select
                  value={APPLICATION_STATUSES.filter(({ value }) => value === hostApplicationDetails.type)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  onChange={this.setApplicationStatus}
                  // closeMenuOnSelect={false}
                  options={APPLICATION_STATUSES}
                  classNamePrefix="select"
                />
              </div>
              <div className="col-sm-6">
                <h3>Comments</h3>
                <pre>{hostApplicationDetails.comments}</pre>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Link to="/contact/applications">Back to List</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewApplicationPage.propTypes = {
  getHostApplicationDetails: PropTypes.func.isRequired,
  hostApplicationDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
  submitEditHostApplication: PropTypes.func.isRequired,
};
ViewApplicationPage.defaultProps = {
  hostApplicationDetails: {},
};

const mapStateToProps = (state) => extend({}, state.hostApplications, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(ViewApplicationPage);
