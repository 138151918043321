import { extend } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import AccountEventsTable from '../../components/Accounts/AccountEventsTable';
import Header from '../Header';
import actions from './actions';

class AccountsDetailPage extends Component {
  componentDidMount() {
    this.updateList();
  }

  updateList() {
    const { getAccountDetails, routeParams } = this.props;
    getAccountDetails(routeParams.id);
  }

  render() {
    const { accountDetails } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/accounts" />
        <div className="container-fluid">
          <div className="content-box">
            <h3>Account Details</h3>

            <Link to={`/accounts/${accountDetails.id}/edit`} className="btn btn-success">
              Edit
            </Link>

            <div>
              Account:
              {accountDetails.name}
            </div>
            <h3>Billing Accounts</h3>
            <div>
              {accountDetails?.billingAccounts &&
                accountDetails.billingAccounts.map((a) => (
                  <div key={a.id}>
                    <Link to={`/billing-accounts/${a.id}`}>{a.name}</Link>
                  </div>
                ))}
            </div>

            <h3>Locations</h3>
            <div>
              {accountDetails?.locations &&
                accountDetails.locations.map((l) => (
                  <div key={l.id}>
                    <Link to={`/locations/${l.id}`}>{l.name}</Link>
                  </div>
                ))}
            </div>

            <h3>Users</h3>
            <div>
              {accountDetails?.users &&
                accountDetails.users.map((u) => (
                  <div key={u.id}>
                    <Link to={`/users/${u.id}`}>
                      {u.name} - {u.username}
                    </Link>
                  </div>
                ))}
            </div>

            <h3>Account Events</h3>
            <div>
              <AccountEventsTable accountId={accountDetails.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountsDetailPage.propTypes = {
  getAccountDetails: PropTypes.func.isRequired,
  accountDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
AccountsDetailPage.defaultProps = {
  accountDetails: {},
};

const mapStateToProps = (state) => extend({}, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(AccountsDetailPage);
