import API from '../utils/api/global';
import genericActions from './genericActions';

const submitLogin = (un, pw, isRemember) => (dispatch) => {
  dispatch({ type: 'FORM_LOGIN_STARTED' });
  API.login(un, pw, isRemember, (success, message) => {
    if (success) {
      dispatch({ type: 'FORM_LOGIN_SUCCESS' });
      // dispatch(genericActions.checkAuth());
      return dispatch(genericActions.getMyProfile());
    }
    return dispatch({ type: 'FORM_LOGIN_FAILED', payload: message });
  });
};

export default {
  submitLogin,
  isEmailAvailable: (email) => (dispatch) => {
    dispatch({ type: 'VALIDATE_EMAIL_STARTED' });
    API.isEmailAvailable(email, (success, data) => {
      // dispatch({ type: 'VALIDATE_EMAIL_END', payload: { ...data, success: !err } });
      if (success) return dispatch({ type: 'VALIDATE_EMAIL_SUCCESS' });
      return dispatch({ type: 'VALIDATE_EMAIL_FAILED', payload: data.message });
    });
  },
  validateTeamUsername: (username) => (dispatch) => {
    dispatch({ type: 'VALIDATE_TEAM_USERNAME_STARTED' });
    API.validateTeamUsername(username, (success, data) => {
      if (!success)
        return dispatch({
          type: 'VALIDATE_TEAM_USERNAME_FAILED',
          payload: data.message,
        });
      return dispatch({ type: 'VALIDATE_TEAM_USERNAME_SUCCESS' });
    });
  },

  submitContact: (form) => (dispatch) => {
    dispatch({ type: 'FORM_CONTACT_STARTED' });
    API.contact(form, (err) => {
      if (err) return dispatch({ type: 'FORM_CONTACT_FAILED' });
      return dispatch({ type: 'FORM_CONTACT_SUCCESS' });
    });
  },
  submitLead: (form) => (dispatch) => {
    dispatch({ type: 'FORM_LEAD_STARTED' });
    API.lead(form, (err) => {
      if (err) return dispatch({ type: 'FORM_LEAD_FAILED' });
      return dispatch({ type: 'FORM_LEAD_SUCCESS' });
    });
  },
  submitRegistration: (teamName, email) => (dispatch) => {
    dispatch({ type: 'FORM_REGISTRATION_STARTED' });
    API.quickRegister(teamName, email, (success, result) => {
      if (success) return dispatch({ type: 'FORM_REGISTRATION_SUCCESS', payload: result });
      return dispatch({ type: 'FORM_REGISTRATION_FAILED', payload: result });
    });
  },
  submitCompleteRegistration: (key, email, teamName, teamUsername, pwd) => (dispatch) => {
    dispatch({ type: 'FORM_COMPLETE_PROFILE_STARTED' });
    API.completeQuickRegister(key, teamName, teamUsername, pwd, (err, data) => {
      if (err || data.message) {
        return dispatch({
          type: 'FORM_COMPLETE_PROFILE_FAILED',
          payload: data.message,
        });
      }
      dispatch({ type: 'FORM_COMPLETE_PROFILE_SUCCESS' });
      return dispatch(submitLogin(email, pwd, false));
    });
  },
  submitRequestPasswordReset: (email) => (dispatch) => {
    dispatch({ type: 'FORM_REQUEST_PASS_RESET_STARTED' });
    API.requestPassResetCode(email, (err, data) => {
      if (err || data.message)
        return dispatch({
          type: 'FORM_REQUEST_PASS_RESET_FAILED',
          payload: (err && err.message) || data.message,
        });
      return dispatch({ type: 'FORM_REQUEST_PASS_RESET_SUCCESS' });
    });
  },
  submitUpdatePassword: (pwd, resetCode) => (dispatch) => {
    dispatch({ type: 'FORM_UPDATE_PASSWORD_STARTED' });
    API.resetPassword(pwd, resetCode, (err) => {
      if (err)
        return dispatch({
          type: 'FORM_UPDATE_PASSWORD_FAILED',
          payload: err.message,
        });
      return dispatch({ type: 'FORM_UPDATE_PASSWORD_SUCCESS' });
    });
  },

  submitUpdateUserProfile: (pwd, pwdconfirm, currpwd) => (dispatch) => {
    dispatch({ type: 'FORM_UPDATE_PROFILE_STARTED' });
    API.updateMyProfile(pwd, pwdconfirm, currpwd, (success, msg) => {
      if (success === true) {
        dispatch({ type: 'FORM_UPDATE_PROFILE_SUCCESS' });
        return dispatch(genericActions.getMyProfile());
      }
      return dispatch({ type: 'FORM_UPDATE_PROFILE_FAILED', payload: msg });
    });
  },
  submitUpdateTeamProfile: (id, data) => (dispatch) => {
    dispatch({ type: 'FORM_UPDATE_TEAM_PROFILE_STARTED' });
    API.updateTeamProfile(id, data, (err, profileData) => {
      if (err)
        return dispatch({
          type: 'FORM_UPDATE_TEAM_PROFILE_FAILED',
          payload: err.message,
        });
      dispatch({
        type: 'FORM_UPDATE_TEAM_PROFILE_SUCCESS',
        payload: profileData,
      });
      return dispatch(genericActions.getMyProfile());
    });
  },
  getRegistrationByKey: (key) => (dispatch) => {
    dispatch({ type: 'GET_REGISTRATION_STARTED' });
    API.getQuickRegister(key, (err, data) => {
      if (err || !data.team)
        return dispatch({
          type: 'GET_REGISTRATION_FAILED',
          payload: (err && err.message) || data.message,
        });
      return dispatch({ type: 'GET_REGISTRATION_SUCCESS', payload: data });
    });
  },
};
