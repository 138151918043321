import React from 'react';
import PropTypes from 'prop-types';

const FinalAnswer = ({ answer }) => {
  if (answer === true) return <img src="https://s3.amazonaws.com/whattrivia/img/right_circle.png" title="Correct Answer" alt="Correct" />;
  if (answer === false) return <img src="https://s3.amazonaws.com/whattrivia/img/wrong_circle.png" title="Incorrect Answer" alt="Incorrect" />;
  return <img src="https://s3.amazonaws.com/whattrivia/img/no_v2.png" title="No Answer Submitted" alt="No Answer" />;
};

FinalAnswer.defaultProps = {
  answer: null,
};
FinalAnswer.propTypes = {
  answer: PropTypes.bool,
};
export default FinalAnswer;
