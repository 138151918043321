import React, { Component } from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import { map } from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Spinner from '../Spinner';

class LiveStreamEditForm extends Component {
  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) {
      resetForm();
    }
  }

  // const _handleSelect = (selectChoice) => {
  //   setFieldValue('imaginaryThingId', selectChoice.value);
  // };

  render() {
    const {
      // values,
      // touched,
      // errors,
      // dirty,
      isSubmitting,
      // handleChange,
      // setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      // handleReset,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && <div className="alert alert-success">LiveStream Edited</div>}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="name">Name</label>
            <Field className="form-control" name="name" />
            <ErrorMessage name="name" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="dateTimeStart">Start Date</label>
            <Field className="form-control" type="datetime-local" name="dateTimeStart" />
            <ErrorMessage name="dateTimeStart" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="eventCode">Event Code</label>
            <Field className="form-control" name="eventCode" />
            <ErrorMessage name="eventCode" component="div" className="alert alert-danger" />
          </div>
          <div onClick={() => this.createLiveStreamCode()}>Gen</div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="answers">Answers</label>
            <Field className="form-control" name="answerText" component="textarea" />
            <ErrorMessage name="answers" component="div" className="alert alert-danger" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Link className="btn btn-default" to="/live-streams">
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
LiveStreamEditForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  // values added by Formik
  // values: PropTypes.objectOf(PropTypes.shape),
  // entity: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
LiveStreamEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',

  // values: {},
  // entity: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity }) => ({
    name: entity.name || '',
    eventCode: entity.eventCode || '',
    dateTimeStart: moment(entity.dateTimeStart).format('YYYY-MM-DDTHH:mm') || `${moment(new Date()).format('YYYY-MM-DD')}T20:00:00`,
    answers: entity.answers || '',
    answerText: entity.answerText || '',
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const answerRows = values.answerText.split('\n');
    const answers = [];
    map(answerRows, (answerText, i) => {
      answers.push({ question: i, answers: answerText });
    });
    props.onSubmit(props.entity.id, { ...values, answers });
    setSubmitting(false);
  },
  displayName: 'LiveStream Edit Form',
})(LiveStreamEditForm);
