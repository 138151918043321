import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import RegularEventDetails from '../ViewComponents/RegularEventDetails';
import { EVENT_STATUS } from '../../../config';

const EventDetail = ({ eventDetails, triggerGoogleImport, eventHistory, eventBonusHistoryRequest }) => (
  <div>
    <div className="row">
      <div className="col-12">
        <Button
          disabled={eventDetails.status === EVENT_STATUS.DRAFT}
          className={eventDetails.importedDataIsValid && eventDetails.bonus.isValid ? 'btn-success' : 'btn-primary'}
          onClick={triggerGoogleImport}
        >
          Import from Google
        </Button>
        <RegularEventDetails eventBonusHistoryRequest={eventBonusHistoryRequest} eventDetails={eventDetails} eventHistory={eventHistory} />
      </div>
    </div>
  </div>
);

EventDetail.propTypes = {
  eventDetails: PropTypes.objectOf(PropTypes.shape),
  triggerGoogleImport: PropTypes.func,
  eventHistory: PropTypes.arrayOf(PropTypes.shape),
  eventBonusHistoryRequest: PropTypes.objectOf(PropTypes.shape),
};

EventDetail.defaultProps = {
  eventDetails: {},
  triggerGoogleImport: () => {},
  eventHistory: [],
  eventBonusHistoryRequest: {},
};

export default EventDetail;
