import { extend } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import BillingAccountEventsTable from '../../components/BillingAccounts/BillingAccountEventsTable';
import Header from '../Header';
import actions from './actions';
import StripeLink from '../../components/StripeLink';

const BillingAccountsDetailPage = ({ billingAccountDetails, getBillingAccountDetails, routeParams, sendBillingAccountInvoice }) => {
  useEffect(() => {
    updateList();
  }, []);

  const updateList = () => {
    getBillingAccountDetails(routeParams.id);
  };

  function invoiceNow() {
    // const { sendBillingAccountInvoice, routeParams } = this.props;
    sendBillingAccountInvoice(routeParams.id);
  }

  return (
    <div className="page-container">
      <Header currentPage="/billingaaccounts" />
      <div className="container-fluid">
        <div className="content-box">
          <h3>Billing Account Details</h3>

          <Link to={`/billing-accounts/${billingAccountDetails.id}/edit`} className="btn btn-success">
            Edit
          </Link>

          <Table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>{billingAccountDetails.name}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{billingAccountDetails.invoiceToEmail}</td>
              </tr>
              <tr>
                <td>Stripe Customer Id:</td>
                <td>
                  <StripeLink type="customer" id={billingAccountDetails.stripeCustomerId} linkText={billingAccountDetails.stripeCustomerId} />
                </td>
              </tr>
            </tbody>
          </Table>

          <h3>Connected to Accounts</h3>
          <div>
            {billingAccountDetails?.accounts &&
              billingAccountDetails.accounts.map((a) => (
                <div key={a.id}>
                  <Link to={`/accounts/${a.id}`}>{a.name}</Link>
                </div>
              ))}
          </div>

          <h3>Open Invoices</h3>
          <Button onClick={invoiceNow}>Send Invoice Now</Button>
          <BillingAccountEventsTable billingAccountId={billingAccountDetails.id} />
        </div>
      </div>
    </div>
  );
};

BillingAccountsDetailPage.propTypes = {
  getBillingAccountDetails: PropTypes.func.isRequired,
  sendBillingAccountInvoice: PropTypes.func.isRequired,
  billingAccountDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
BillingAccountsDetailPage.defaultProps = {
  billingAccountDetails: {},
};

const mapStateToProps = (state) => extend({}, state.billingAccounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(BillingAccountsDetailPage);
