import React, { Component } from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import Spinner from '../Spinner';
import HostSelector from '../../containers/Hosts/HostSelector';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import LocationSelector from '../../containers/Locations/LocationSelector';
import AccountLocationsTags from './Widgets/AccountLocationsTags';
// import Checkbox from '../Forms/formik-checkbox';

import { EVENT_TYPES, PAYMENT_METHODS, PAYMENT_TYPE } from '../../utils';
import './styles.css';
import { STRIPE_DASHBOARD_URL, FRONTEND_URL } from '../../config';
// import BillToAccountDisplay from '../Accounts/BillToAccount';

class EventCreateForm extends Component {
  componentDidMount() {
    // const { searchAccounts, searchLocations } = this.props;
    // searchAccounts();
    // searchLocations();
  }

  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;

    if (prevProps.success === false && success === true) resetForm();
  }

  selectAccount = (account) => {
    const { getAccountDetails, setFieldValue } = this.props;
    if (account) getAccountDetails(account.id);
    setFieldValue('account', account);
  };

  selectLocation = (location) => {
    const { setFieldValue } = this.props;
    setFieldValue('location', location);
  };

  render() {
    const {
      values,
      touched,
      errors,
      // dirty,
      isSubmitting,
      // handleChange,
      setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      eventDetails,
      accountDetails,
    } = this.props;

    // const { isBonus } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && (
            <div className="alert alert-success">
              Event Created
              <br />
              <Link to={`/events/${eventDetails.id}/edit`}>Edit Your Event</Link>
            </div>
          )}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="accountId">Account</label>
                <AccountSelector selectedAccount={values.account} onUpdate={this.selectAccount} />
                {values.account && <AccountLocationsTags locations={accountDetails.locations} onClickLocation={this.selectLocation} />}
                {errors.account && touched.account && <div className="alert alert-danger">{errors.account}</div>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="locationId">Location</label>
                <LocationSelector selectedLocation={values.location} onUpdate={this.selectLocation} />
                {errors.location && touched.location && <div className="alert alert-danger">{errors.location}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-sm-4">
                <label htmlFor="dateTimeStart">Date</label>
                <Field className="form-control" type="datetime-local" name="dateTimeStart" />
                {errors.dateTimeStart && touched.dateTimeStart && <div className="alert alert-danger">{errors.dateTimeStart}</div>}
              </div>
              <div className="form-group col-sm-2">
                <label htmlFor="eventGross">Cost</label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Field className="form-control" type="number" name="eventGross" />
                </InputGroup>
                {errors.eventGross && touched.eventGross && <div className="alert alert-danger">{errors.eventGross}</div>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="hosts">Hosts</label>
                <HostSelector
                  selectedHosts={values.hosts}
                  onUpdate={(v) => {
                    setFieldValue('hosts', v);
                  }}
                />
                {errors.hosts && touched.hosts && <div className="alert alert-danger">{errors.hosts}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="url">Event URL</label>
                <Field type="url" name="url" className="form-control" />
                <small className="form-text text-muted">
                  Links from the{' '}
                  <a href={`${FRONTEND_URL}where`} target="noreferrer noopener">
                    /where
                  </a>{' '}
                  page
                </small>
                {errors.url && touched.url && <div className="alert alert-danger">{errors.fburl}</div>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="url">Website Notes</label>
                <Field name="notes" className="form-control" />
                <small className="form-text text-muted">
                  Links from the{' '}
                  <a href={`${FRONTEND_URL}where`} target="noreferrer noopener">
                    /where
                  </a>{' '}
                  page
                </small>
                {errors.notes && touched.notes && <div className="alert alert-danger">{errors.notes}</div>}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="hostDetails">Host Notes</label>
                <ReactQuill value={values.hostDetails} onChange={(v) => setFieldValue('hostDetails', v)} rows="3" />
                <small className="form-text text-muted">
                  Shows in the{' '}
                  <a href={`${FRONTEND_URL}hosts`} target="noreferrer noopener">
                    Host Panel
                  </a>
                </small>
                {errors.hostDetails && touched.hostDetails && <div className="alert alert-danger">{errors.hostDetails}</div>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="adminDetails">Admin Notes</label>
                <ReactQuill value={values.adminDetails} onChange={(v) => setFieldValue('adminDetails', v)} rows="3" />
                <small className="form-text text-muted">Internal only. Shows here and the event view page.</small>
                {errors.adminDetails && touched.adminDetails && <div className="alert alert-danger">{errors.adminDetails}</div>}
              </div>
            </div>

            {/* <h3>Bonus</h3>
            <div className="form-row">
              <div className="form-group col-md-2">
                <FormGroup check inline>
                  <Label check>
                    Add{' '}
                    <Field
                      className="form-control"
                      type="radio"
                      name="bonusPointsIsMultiplier"
                      value="0"
                      checked={values.bonusPointsIsMultiplier === false}
                      onChange={() => {
                        setFieldValue('bonusPointsIsMultiplier', false)
                      }}
                    />
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    Multiply{' '}
                    <Field
                      className="form-control"
                      type="radio"
                      name="bonusPointsIsMultiplier"
                      value="1"
                      checked={values.bonusPointsIsMultiplier === true}
                      onChange={() => {
                        setFieldValue('bonusPointsIsMultiplier', true)
                      }}
                    />
                  </Label>
                </FormGroup>

              </div>
              <div className="form-group col-md-2">
                <label htmlFor="bonusPoints">
                  {values.bonusPointsIsMultiplier === false && 'Add Points'}
                  {values.bonusPointsIsMultiplier === true && 'Multiply By'}
                </label>
                <Field className="form-control" type="number" name="bonusPoints" />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="bonusPointsReason">Special Bonus Reason</label>
                <Field className="form-control" name="bonusPointsReason" />
              </div>
            </div> */}
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              Event Type:
              <br />
              <Select
                options={EVENT_TYPES}
                name="eventType"
                value={EVENT_TYPES.filter(({ value }) => value === values.eventType)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                onChange={({ value }) => setFieldValue('eventType', value)}
                classNamePrefix="select"
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Custom Event Name</label>
              <Field name="name" className="form-control" />
              {errors.name && touched.name && <div className="alert alert-danger">{errors.name}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="googleSheetPrefix">Google Sheet Prefix</label>
              <Field name="googleSheetPrefix" className="form-control" />
              {errors.googleSheetPrefix && touched.googleSheetPrefix && <div className="alert alert-danger">{errors.googleSheetPrefix}</div>}
            </div>
            <h3>Payment Method</h3>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="paymentMethod">Payment Method</label>
                <Field className="form-control" component="select" name="paymentMethod">
                  {PAYMENT_METHODS.map((m) => (
                    <option key={m.value} value={m.value}>
                      {m.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="paymentMethod" component="div" className="alert alert-danger" />
              </div>
              <div className="form-group col-sm-12">
                {values.paymentMethod === 'ACCOUNT' && values.account.paymentMethod && (
                  <>
                    <h3>Account Payment Defaults</h3>
                    <div>Payment Method: {values.account.paymentMethod}</div>
                    <div>Bill To Email: {values.account.invoiceToEmail}</div>
                    {values.account.stripeCustomerId && (
                      <div>
                        Stripe Customer:{' '}
                        <a href={`${STRIPE_DASHBOARD_URL}/customers/${values.account.stripeCustomerId}`} rel="noopener noreferrer" target="_blank">
                          {values.account.stripeCustomerId}
                        </a>
                      </div>
                    )}
                  </>
                )}
                {values.paymentMethod === PAYMENT_TYPE.ACCOUNT && (
                  <>
                    <label htmlFor="invoiceToEmail">Bill To Email</label>
                    <Field name="invoiceToEmail" className="form-control" />
                    {errors.invoiceToEmail && touched.invoiceToEmail && <div className="alert alert-danger">{errors.invoiceToEmail}</div>}
                  </>
                )}
              </div>
            </div>{' '}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Link className="btn btn-default" to="/events">
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
EventCreateForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  eventDetails: PropTypes.objectOf(PropTypes.shape),

  // searchHosts: PropTypes.func.isRequired,
  // searchAccounts: PropTypes.func.isRequired,
  // searchLocations: PropTypes.func.isRequired,
  getAccountDetails: PropTypes.func.isRequired,

  // accounts: PropTypes.arrayOf(PropTypes.shape),
  // locations: PropTypes.arrayOf(PropTypes.shape),
  accountDetails: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
EventCreateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // routeParams: {},
  eventDetails: {},

  // accounts: [],
  //
  // locations: [],
  accountDetails: { locations: [] },

  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ accountId, locationId, account, location, name }) => ({
    name: name || '',
    accountId: accountId || null,
    locationId: locationId || null,
    account: account || {},
    location: location || {},
    isActive: false,
    isEventPublic: true,
    hosts: [],
    googleDocURL: '',
    googleSheetPrefix: '',
    hostDetails: '',
    adminDetails: '',
    eventType: 'recurring',
    eventGross: 0,
    bonusPoints: 0,
    bonusPointsReason: '',
    bonusPointsIsMultiplier: false,
    // isCreateInvoice: false,
    // isAutoSendInvoice: false,
    invoiceToEmail: '',
    // quickbooksInvoiceId: '',
    // quickbooksInvoiceNum: '',
    isInvoicePaid: false,
    isInvoiceSent: false,
    paymentMethod: 'ACCOUNT',
    liveStreamNumberOfQuestions: 20,
    liveStreamMultiplier: 2,
    dateTimeStart: `${moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}T18:00:00` || '',
  }),

  validationSchema: Yup.object().shape({
    account: Yup.object().required('Please Select an Account'),
    location: Yup.object().required('Please Select a Location'),
    dateTimeStart: Yup.date().required('Please Select a Date'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const vals = {
      ...values,
      accountId: values.account.id,
      locationId: values.location.id,
    };
    if (values.name === '') vals.name = values.account.name;
    if (values.googleSheetPrefix === '') vals.googleSheetPrefix = vals.name;
    props.onSubmit(vals);
    // console.log(vals);
    setSubmitting(false);
  },
  displayName: 'Event Creation Form',
})(EventCreateForm);
