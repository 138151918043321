import { useState } from 'react';
import useSWR from 'swr';
import { ENDPOINTS } from '../config';
import qs from 'query-string';

export default function useEventTemplates() {
  const [pageSizeState, setPageSizeState] = useState(25);
  const [pageIndexState, setPageIndexState] = useState(1);
  const [orderByState, setOrderByState] = useState('name');
  const [orderByDirState, setOrderByDirState] = useState('ASC');
  // const [searchString, setSearchString] = useState('');
  const [filterState, setFilterState] = useState('isActive=true');

  const buildKey = (baseURL, params = {}) => `${baseURL}?${qs.stringify(params, { arrayFormat: 'brackets' })}`;

  const swrKey = buildKey(ENDPOINTS.EVENT_TEMPLATES, {
    pageNumber: pageIndexState,
    pageSize: pageSizeState,
    orderBy: orderByState,
    orderDir: orderByDirState,
  });
  const { data, error, loading, mutate } = useSWR(`${swrKey}&${filterState}`);

  const updateFilterState = (obj) => {
    // console.log('updating filter state!', obj);
    setFilterState(qs.stringify(obj, { arrayFormat: 'brackets' }));
  };

  return {
    // TODO: clean this up
    eventTemplates: data?.data?.data || [],
    pagination: data?.data?.metadata || {},
    mutate,
    error,
    loading,
    setPageSizeState,
    setPageIndexState,
    setOrderByState,
    setOrderByDirState,
    updateFilterState,
    // setSearchString,
  };
}
