import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/BillingAccounts/EditForm';

class BillingAccountEditPage extends Component {
  componentDidMount() {
    const { getBillingAccountDetails, routeParams } = this.props;
    getBillingAccountDetails(routeParams.id);
  }

  render() {
    const { submitEditBillingAccount, billingAccountDetails, billingAccountDetailRequest, billingAccountEditRequest } = this.props;
    return (
      <div className="billingAccount-page page-container">
        <Header currentPage="/billing-accounts" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit a Billing Account</h1>
            {/* <div>BillingAccount Details: {JSON.stringify(billingAccountDetails)}</div> */}
            {billingAccountDetailRequest.working && <Spinner />}
            {billingAccountDetailRequest.success && (
              <EditForm
                entity={billingAccountDetails}
                onSubmit={submitEditBillingAccount}
                loading={billingAccountEditRequest.working}
                success={billingAccountEditRequest.success}
                failed={billingAccountEditRequest.failed}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

BillingAccountEditPage.propTypes = {
  submitEditBillingAccount: PropTypes.func.isRequired,
  getBillingAccountDetails: PropTypes.func.isRequired,
  billingAccountDetailRequest: PropTypes.objectOf(PropTypes.shape),
  billingAccountEditRequest: PropTypes.objectOf(PropTypes.shape),
  billingAccountDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
BillingAccountEditPage.defaultProps = {
  billingAccountDetails: {},
  billingAccountEditRequest: {},
  billingAccountDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.billingAccounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(BillingAccountEditPage);
