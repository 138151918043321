import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/BillingAccounts/CreateForm';

const BillingAccountCreatePage = ({ submitCreateBillingAccount, billingAccountDetails, billingAccountCreateRequest }) => (
  <div className="billingAccount-page page-container">
    <Header currentPage="/billing-accounts" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create a Billing Account</h1>
        <CreateForm
          billingAccount={billingAccountDetails}
          onSubmit={submitCreateBillingAccount}
          billingAccountDetails={billingAccountDetails}
          loading={billingAccountCreateRequest.working}
          success={billingAccountCreateRequest.success}
          failed={billingAccountCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

BillingAccountCreatePage.propTypes = {
  submitCreateBillingAccount: PropTypes.func.isRequired,
  billingAccountCreateRequest: PropTypes.objectOf(PropTypes.shape),
  billingAccountDetails: PropTypes.objectOf(PropTypes.shape),
  // billingAccount: PropTypes.objectOf(PropTypes.shape),
};
BillingAccountCreatePage.defaultProps = {
  billingAccountDetails: {},
  billingAccountCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.billingAccounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(BillingAccountCreatePage);
