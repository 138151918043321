import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';

export default [
  {
    title: 'Billing Accounts',
    path: '/billing-accounts',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create Billing Account',
    path: '/billing-accounts/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Billing Account',
    path: '/billing-accounts/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Billing Account Details',
    path: '/billing-accounts/:id',
    component: View,
    options: { private: true },
  },
];
