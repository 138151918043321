import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
// function getAllLiveStreams(query) {
//   const token = getJWT();
//   if (!token) return reject('NOT_LOGGED_IN');
//   return axios.get(`${ENDPOINTS.LIVE_STREAM}?${qs.stringify(query,
// { arrayFormat: 'bracket' })}`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }).catch((err) => {
//     console.log(err);
//     return globals.handleAPIError(err);
//   })
//     .then((res) => {
//       // console.log('data', res.data);
//       return res.data;
//     });
// }

/**
 * Get list
 * @param {object} query - the query
 */
function getLiveStreamResponses(eventCode, questionId = 1) {
  const query = {
    question: questionId,
  };
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.LIVE_STREAM}/${eventCode}/answers?${qs.stringify(query)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get list
 * @param {object} query - the query
 */
function getLiveStreamScoreboard(eventCode, scoreboardKey) {
  let url = `${ENDPOINTS.LIVE_STREAM}/${eventCode}/scoreboard-admin`;
  if (scoreboardKey) url += `?key=${scoreboardKey}`;
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

// /**
//  * Get list
//  * @param {object} query - the query
//  */
// function generateScoreboard(eventCode) {
//   const token = getJWT();
//   if (!token) return reject('NOT_LOGGED_IN');
//   return axios
//     .post(
//       `${ENDPOINTS.LIVE_STREAM}/${eventCode}/scoreboard`,
//       {},
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     )
//     .catch((err) => globals.handleAPIError(err))
//     .then((res) => res.data);
// }

/**
 * Update Live Stream
 * @param {int} id
 * @param {object} data - the live stream data
 */
function updateLiveStreamResponse(eventCode, responseId, data, updateAll = false) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  // console.log('updateLiveStreamResponse', data);
  return axios
    .patch(`${ENDPOINTS.LIVE_STREAM}/${eventCode}/answers/${responseId}?updateAll=${updateAll}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

function gradeQuestion(eventCode, questionNumber) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(
      `${ENDPOINTS.LIVE_STREAM}/${eventCode}/questions/${questionNumber}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

// /**
//  * Update Live Stream
//  * @param {int} id
//  */
// function markOthersWrong(id) {
//   const token = getJWT();
//   if (!token) return reject('NOT_LOGGED_IN');
//   return axios
//     .put(
//       `${ENDPOINTS.LIVE_STREAM}/${id}?updateWrong=true`,
//       {},
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     )
//     .catch((err) => globals.handleAPIError(err))
//     .then((res) => res.data);
// }

export default {
  getLiveStreamResponses,
  // getLiveStreamDetails,
  // createLiveStream,
  // updateLiveStream,
  getLiveStreamScoreboard,
  // generateScoreboard,
  updateLiveStreamResponse,
  gradeQuestion,
  // markOthersWrong,
};
