import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';
import Leaderboard from './leaderboard';

export default [
  {
    title: 'Registered Teams',
    path: '/teams',
    component: List,
    options: { private: true },
  },
  {
    title: 'Leaderboards',
    path: '/teams/leaderboard',
    component: Leaderboard,
    options: { private: true },
  },
  {
    title: 'Create Registered Team',
    path: '/teams/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Registered Team',
    path: '/teams/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Registered Team Details',
    path: '/teams/:id',
    component: View,
    options: { private: true },
  },
];
