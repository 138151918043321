import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/Payments/CreateForm';

const PaymentCreatePage = ({ submitCreatePayment, paymentDetails, paymentCreateRequest }) => (
  <div className="payment-page page-container">
    <Header currentPage="/payments" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create an Payment</h1>
        <CreateForm
          payment={paymentDetails}
          onSubmit={submitCreatePayment}
          loading={paymentCreateRequest.working}
          success={paymentCreateRequest.success}
          failed={paymentCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

PaymentCreatePage.propTypes = {
  submitCreatePayment: PropTypes.func.isRequired,
  paymentCreateRequest: PropTypes.objectOf(PropTypes.shape),
  paymentDetails: PropTypes.objectOf(PropTypes.shape),
  // payment: PropTypes.objectOf(PropTypes.shape),
};
PaymentCreatePage.defaultProps = {
  paymentDetails: {},
  paymentCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.payments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(PaymentCreatePage);
