const defaultState = {
  // Form Request Status
  eventTemplates: { data: [], metadata: {} },
  eventTemplateDetails: {},
  eventTemplateListRequest: {},
  eventTemplateDetailRequest: {},
  eventTemplateCreateRequest: {},
  eventTemplateEditRequest: {},
  eventTemplateDeleteRequest: {},

  needRefreshEventList: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'EVENT_TEMPLATES_LIST_STARTED':
      return { ...state, eventTemplateListRequest: { working: true } };
    case 'EVENT_TEMPLATES_LIST_FAILED':
      return { ...state, eventTemplateListRequest: { failed: true } };
    case 'EVENT_TEMPLATES_LIST_SUCCESS':
      return {
        ...state,
        needRefreshEventList: false,
        eventTemplates: action.payload,
        eventTemplateListRequest: { success: true },
      };

    case 'EVENT_TEMPLATES_DETAIL_STARTED':
      return { ...state, eventTemplateDetailRequest: { working: true } };
    case 'EVENT_TEMPLATES_DETAIL_FAILED':
      return { ...state, eventTemplateDetailRequest: { failed: true } };
    case 'EVENT_TEMPLATES_DETAIL_SUCCESS':
      return {
        ...state,
        eventTemplateDetails: action.payload,
        eventTemplateDetailRequest: { success: true },
      };

    case 'EVENT_TEMPLATES_CREATE_STARTED':
      return { ...state, eventTemplateCreateRequest: { working: true } };
    case 'EVENT_TEMPLATES_CREATE_FAILED':
      return { ...state, eventTemplateCreateRequest: { failed: true } };
    case 'EVENT_TEMPLATES_CREATE_SUCCESS':
      return {
        ...state,
        needRefreshEventList: true,
        eventTemplateDetails: action.payload,
        eventTemplateCreateRequest: { success: true },
      };

    case 'EVENT_TEMPLATES_EDIT_STARTED':
      return { ...state, eventTemplateEditRequest: { working: true } };
    case 'EVENT_TEMPLATES_EDIT_FAILED':
      return { ...state, eventTemplateEditRequest: { failed: true } };
    case 'EVENT_TEMPLATES_EDIT_SUCCESS':
      return {
        ...state,
        eventTemplateDetails: action.payload,
        eventTemplateEditRequest: { success: true },
      };

    case 'EVENT_TEMPLATES_DELETE_STARTED':
      return { ...state, eventTemplateDeleteRequest: { working: true } };
    case 'EVENT_TEMPLATES_DELETE_FAILED':
      return { ...state, eventTemplateDeleteRequest: { failed: true } };
    case 'EVENT_TEMPLATES_DELETE_SUCCESS':
      return {
        ...state,
        // eventTemplateDetails: action.payload,
        eventTemplateDeleteRequest: { success: true },
      };

    case 'EVENT_TEMPLATES_REFRESH_LIST':
      return { ...state, needRefreshEventList: true };

    default:
      return state;
  }
};
