// import { map } from 'lodash';
import API from '../../utils/api/invoices';
import EVENT_API from '../../utils/api/events';
import toastActions from '../../actions/toastActions';

const ACTION_PREFIX = 'INVOICES';

const refreshEventList = () => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_REFRESH_LIST` });
};

const getAllEventInvoices = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });
  API.getInvoices(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const sendInvoiceNow = (id, props) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  EVENT_API.updateEvent(id, props)
    .then((data) => {
      toastActions.displaySuccessToast(`Invoice Queued for Send!`);
      dispatch(refreshEventList());
      return dispatch({
        type: `${ACTION_PREFIX}_EDIT_SUCCESS`,
        payload: data,
      });
    })
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

export default {
  getAllEventInvoices,
  sendInvoiceNow,
};
