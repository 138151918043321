import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import RegisteredTeamsTable from '../../components/RegisteredTeams/table';

class RegisteredTeamsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
        isActive: true,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  updateList() {
    const { getAllRegisteredTeams } = this.props;
    const { filter } = this.state;
    getAllRegisteredTeams(filter);
  }

  render() {
    const { registeredTeams, registeredTeamListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/teams" />
        <div className="container-fluid">
          <div className="content-box">
            <h1>RegisteredTeams</h1>
            <RegisteredTeamsTable
              list={registeredTeams.data}
              pagination={registeredTeams.metadata}
              isLoading={registeredTeamListRequest.working}
              isSuccess={registeredTeamListRequest.success}
              isFailed={registeredTeamListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onFilter={(val) => this.handleChange('isActive', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

RegisteredTeamsPage.propTypes = {
  getAllRegisteredTeams: PropTypes.func.isRequired,
  // getRegisteredTeamDetails: PropTypes.func.isRequired,
  registeredTeams: PropTypes.objectOf(PropTypes.shape),
  // registeredTeamDetails: PropTypes.objectOf(PropTypes.shape),
  registeredTeamListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
RegisteredTeamsPage.defaultProps = {
  registeredTeams: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
  // registeredTeamDetails: {},
};

const mapStateToProps = (state) => extend({}, state.registeredTeams, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegisteredTeamsPage);
