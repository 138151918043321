import { map } from 'lodash';
import moment from 'moment';

const defaultState = {
  // Form Request Status
  upcomingEventTemplates: {},
  upcomingEventTemplatesRequest: {},

  upcomingPrivateEvents: {},
  upcomingPrivateEventsRequest: {},

  pendingLeads: {},
  pendingLeadsRequest: {},

  hostReport: [],
  hostReportRequest: {},

  pendingApplications: {},
  pendingApplicationsRequest: {},

  eventsMissingHosts: {},
  eventsMissingHostRequest: {},

  socialEventsRequest: {},

  eventStats: [],
  attendanceStats: [],
  socialEvents: [],
  incomeStats: [],
  eventStatsRequest: [],
};

// const getRow = rowData => ;

const createAttendanceDataTable = (data) => {
  const rows = map(data.data, (r) => [moment().year(r.year).week(r.week).day('monday').format('MM/DD/YY'), r.players, r.teams, r.registeredTeams]);

  const dataTable = [
    [
      { id: 'date', label: 'Week Of', type: 'string' },
      { id: 'players', label: 'Players', type: 'number' },
      { id: 'teams', label: 'Teams', type: 'number' },
      { id: 'registeredTeams', label: 'Registered Teams', type: 'number' },
    ],
    ...rows,
  ];

  return dataTable;
};

const createEventDataTable = (data) => {
  const rows = map(data.data, (r) => [moment().year(r.year).week(r.week).day('monday').format('MM/DD/YY'), r.numberOfEvents]);

  const dataTable = [
    [
      { id: 'date', label: 'Week', type: 'string' },
      { id: 'events', label: 'Completed Events', type: 'number' },
      // { id: 'teams', label: 'Teams', type: 'number' },
    ],
    ...rows,
  ];

  return dataTable;
};

const createIncomeDataTable = (data) => {
  const rows = map(data.data, (r) => [moment().year(r.year).week(r.week).day('monday').format('MM/DD/YY'), r.grossIncome]);

  const dataTable = [
    [
      { id: 'date', label: 'Week', type: 'string' },
      { id: 'income', label: 'Income', type: 'number' },
    ],
    ...rows,
  ];

  return dataTable;
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'DASHBOARD_RECURRING_LIST_STARTED':
      return { ...state, upcomingEventTemplatesRequest: { working: true } };
    case 'DASHBOARD_RECURRING_LIST_FAILED':
      return { ...state, upcomingEventTemplatesRequest: { failed: true } };
    case 'DASHBOARD_RECURRING_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        upcomingEventTemplates: action.payload,
        upcomingEventTemplatesRequest: { success: true },
      };
    case 'DASHBOARD_PRIVATE_LIST_STARTED':
      return { ...state, upcomingPrivateEventsRequest: { working: true } };
    case 'DASHBOARD_PRIVATE_LIST_FAILED':
      return { ...state, upcomingPrivateEventsRequest: { failed: true } };
    case 'DASHBOARD_PRIVATE_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        upcomingPrivateEvents: action.payload,
        upcomingPrivateEventsRequest: { success: true },
      };

    case 'DASHBOARD_LEADS_LIST_STARTED':
      return { ...state, pendingLeadsRequest: { working: true } };
    case 'DASHBOARD_LEADS_LIST_FAILED':
      return { ...state, pendingLeadsRequest: { failed: true } };
    case 'DASHBOARD_LEADS_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        pendingLeads: action.payload,
        pendingLeadsRequest: { success: true },
      };
    case 'DASHBOARD_EVENTHOSTS_LIST_STARTED':
      return { ...state, eventsMissingHostRequest: { working: true } };
    case 'DASHBOARD_EVENTHOSTS_LIST_FAILED':
      return { ...state, eventsMissingHostRequest: { failed: true } };
    case 'DASHBOARD_EVENTHOSTS_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        eventsMissingHosts: action.payload,
        eventsMissingHostRequest: { success: true },
      };

    case 'DASHBOARD_SOCIAL_EVENTS_LIST_STARTED':
      return { ...state, socialEventsRequest: { working: true } };
    case 'DASHBOARD_SOCIAL_EVENTS_LIST_FAILED':
      return { ...state, socialEventsRequest: { failed: true } };
    case 'DASHBOARD_SOCIAL_EVENTS_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        socialEvents: action.payload,
        socialEventsRequest: { success: true },
      };

    case 'DASHBOARD_HOSTAPPLICATION_LIST_STARTED':
      return { ...state, pendingApplicationsRequest: { working: true } };
    case 'DASHBOARD_HOSTAPPLICATION_LIST_FAILED':
      return { ...state, pendingApplicationsRequest: { failed: true } };
    case 'DASHBOARD_HOSTAPPLICATION_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        pendingApplications: action.payload,
        pendingApplicationsRequest: { success: true },
      };
    case 'DASHBOARD_EVENTSTATS_LIST_STARTED':
      return { ...state, eventStatsRequest: { working: true } };
    case 'DASHBOARD_EVENTSTATS_LIST_FAILED':
      return { ...state, eventStatsRequest: { failed: true } };
    case 'DASHBOARD_EVENTSTATS_LIST_SUCCESS':
      return {
        ...state,
        // needRefreshEventList: false,
        attendanceStats: createAttendanceDataTable(action.payload),
        incomeStats: createIncomeDataTable(action.payload),
        eventStats: createEventDataTable(action.payload),
        eventStatsRequest: { success: true },
      };
    case 'DASHBOARD_HOSTREPORT_LIST_STARTED':
      return { ...state, hostReportRequest: { working: true } };
    case 'DASHBOARD_HOSTREPORT_LIST_FAILED':
      return { ...state, hostReportRequest: { failed: true } };
    case 'DASHBOARD_HOSTREPORT_LIST_SUCCESS':
      return {
        ...state,
        hostReport: action.payload,
        hostReportRequest: { success: true },
      };

    case 'EVENTS_REFRESH_LIST':
      return { ...state, needRefreshEventList: true };

    default:
      return state;
  }
};
