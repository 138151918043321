const defaultState = {
  // Form Request Status
  accounts: { data: [], metadata: {} },
  accountDetails: {},
  accountListRequest: {},
  accountDetailRequest: {},
  accountCreateRequest: {},
  accountEditRequest: {},
  accountDeleteRequest: {},

  regions: { data: [], metadata: {} },
  regionListRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'ACCOUNTS_LIST_STARTED':
      return { ...state, accountListRequest: { working: true } };
    case 'ACCOUNTS_LIST_FAILED':
      return { ...state, accountListRequest: { failed: true } };
    case 'ACCOUNTS_LIST_SUCCESS':
      return {
        ...state,
        accounts: action.payload,
        accountListRequest: { success: true },
      };

    case 'REGION_LIST_STARTED':
      return { ...state, regionListRequest: { working: true } };
    case 'REGION_LIST_FAILED':
      return { ...state, regionListRequest: { failed: true } };
    case 'REGION_LIST_SUCCESS':
      return {
        ...state,
        regions: action.payload,
        regionListRequest: { success: true },
      };

    case 'ACCOUNTS_DETAIL_STARTED':
      return { ...state, accountDetailRequest: { working: true } };
    case 'ACCOUNTS_DETAIL_FAILED':
      return { ...state, accountDetailRequest: { failed: true } };
    case 'ACCOUNTS_DETAIL_SUCCESS':
      return {
        ...state,
        accountDetails: action.payload,
        accountDetailRequest: { success: true },
      };

    case 'ACCOUNTS_CREATE_STARTED':
      return { ...state, accountCreateRequest: { working: true } };
    case 'ACCOUNTS_CREATE_FAILED':
      return { ...state, accountCreateRequest: { failed: true } };
    case 'ACCOUNTS_CREATE_SUCCESS':
      return {
        ...state,
        accountDetails: action.payload,
        accountCreateRequest: { success: true },
      };

    case 'ACCOUNTS_EDIT_STARTED':
      return { ...state, accountEditRequest: { working: true } };
    case 'ACCOUNTS_EDIT_FAILED':
      return { ...state, accountEditRequest: { failed: true } };
    case 'ACCOUNTS_EDIT_SUCCESS':
      return {
        ...state,
        accountDetails: action.payload,
        accountEditRequest: { success: true },
      };

    default:
      return state;
  }
};
