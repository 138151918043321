import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Button = ({ className, onClick, children, link, to, disabled }) =>
  link ? (
    <Link to={to} className={`btn ${className}`}>
      {children}
    </Link>
  ) : (
    <button type="button" className={`btn ${className}`} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );

Button.defaultProps = {
  className: '',
  onClick: null,
  to: '',
  disabled: false,
  link: false,
  children: '',
};
Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  link: PropTypes.bool,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
