import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
// import ordinal from 'ordinal';
import Select from 'react-select';
// import { Table } from 'reactstrap';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
// import Button from '../../components/Button';
import Header from '../Header';
import './styles.css';
import RegisteredTeamHistoryDesktop from '../../components/RegisteredTeams/history';

const currentYear = new Date().getFullYear();
const YEARS = Array.from({ length: 7 }, (_, index) => {
  const year = currentYear - 5 + index;
  return { id: year, name: year };
});

class RegisteredTeamsDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: {},
    };
  }

  componentDidMount() {
    this.onSelectChange(moment().format('Y'));
  }

  onSelectChange = (v) => {
    this.setState({ selectedYear: v }, this.updateList);
  };

  updateList() {
    const { getRegisteredTeamDetails, getRegisteredTeamHistory, routeParams } = this.props;
    const { selectedYear } = this.state;
    const historyFilter = {
      year: selectedYear,
    };
    getRegisteredTeamDetails(routeParams.id);
    getRegisteredTeamHistory(routeParams.id, historyFilter);
  }

  render() {
    const { registeredTeamDetails, teamHistory } = this.props;
    const { selectedYear } = this.state;
    return (
      <div className="page-container">
        <Header currentPage="/teams" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col-8">
                <h3>Registered Team Details</h3>

                <div>{`Name: ${registeredTeamDetails.name}`}</div>
                <div>{`Username: ${registeredTeamDetails.teamUsername}`}</div>
                <div>{`Active: ${registeredTeamDetails.isActive}`}</div>
                <div>{`Pending: ${registeredTeamDetails.isPending}`}</div>
                <div>{`Pending Email: ${registeredTeamDetails.pendingEmail}`}</div>
                <h3>Team Members</h3>
                <ul>
                  {registeredTeamDetails.registeredTeamMembers &&
                    registeredTeamDetails.registeredTeamMembers.map((member) => (
                      <li key={member.id}>
                        {member.user.username}
                        {' Last Login: '}
                        {member.user.lastLogin ? moment(member.user.lastLogin).format('dddd, MMM Do YYYY @ h:mm a') : 'N/a'}
                      </li>
                    ))}
                </ul>

                <h3>Team Username History</h3>
                <ul>
                  {registeredTeamDetails.registeredTeamUsernameHistories &&
                    registeredTeamDetails.registeredTeamUsernameHistories.map((un) => <li key={un.id}>{un.teamUsername}</li>)}
                </ul>

                <h3>History</h3>
                <div className="row">
                  <div className="col-3">
                    <Select
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => opt.name}
                      value={YEARS.filter(({ id }) => id === selectedYear)}
                      onChange={(v) => this.onSelectChange(v.id)}
                      options={YEARS}
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div>
                  {/* {JSON.stringify(teamHistory)} */}
                  <RegisteredTeamHistoryDesktop history={teamHistory.data} />
                </div>
              </div>
              <div className="col-4">
                <Link to={`/teams/${registeredTeamDetails.id}/edit`} className="btn btn-success">
                  Edit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RegisteredTeamsDetailPage.propTypes = {
  getRegisteredTeamDetails: PropTypes.func.isRequired,
  getRegisteredTeamHistory: PropTypes.func.isRequired,
  registeredTeamDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
  teamHistory: PropTypes.objectOf(PropTypes.shape),
};
RegisteredTeamsDetailPage.defaultProps = {
  registeredTeamDetails: {},
  teamHistory: {},
};

const mapStateToProps = (state) => extend({}, state.registeredTeams, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegisteredTeamsDetailPage);
