import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
// import Spinner from '../../components/Spinner';
import TournamentEditForm from '../../components/Tournaments/EditForm';

class TournamentEditPage extends Component {
  componentDidMount() {
    const { getTournamentDetails, routeParams } = this.props;
    getTournamentDetails(routeParams.id);
  }

  render() {
    const { submitEditTournament, tournamentDetails, tournamentEditRequest } = this.props;
    return (
      <div className="tournaments-page page-container">
        <Header currentPage="/tournaments" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit Tournament</h1>
            <TournamentEditForm
              entity={tournamentDetails}
              onSubmit={submitEditTournament}
              loading={tournamentEditRequest.working}
              success={tournamentEditRequest.success}
              failed={tournamentEditRequest.failed}
              errorMessage={tournamentEditRequest.errorMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

TournamentEditPage.propTypes = {
  submitEditTournament: PropTypes.func.isRequired,
  getTournamentDetails: PropTypes.func.isRequired,
  tournamentEditRequest: PropTypes.objectOf(PropTypes.shape),
  tournamentDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
TournamentEditPage.defaultProps = {
  tournamentDetails: {},
  tournamentEditRequest: {},
};

const mapStateToProps = (state) => extend({}, state.tournaments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(TournamentEditPage);
