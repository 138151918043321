import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/LiveStream/CreateForm';

const LiveStreamCreatePage = ({ submitCreateLiveStream, liveStreamDetails, liveStreamCreateRequest }) => (
  <div className="liveStream-page page-container">
    <Header currentPage="/live-streams" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create a LiveStream</h1>
        <CreateForm
          liveStream={liveStreamDetails}
          onSubmit={submitCreateLiveStream}
          loading={liveStreamCreateRequest.working}
          success={liveStreamCreateRequest.success}
          failed={liveStreamCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

LiveStreamCreatePage.propTypes = {
  submitCreateLiveStream: PropTypes.func.isRequired,
  liveStreamCreateRequest: PropTypes.objectOf(PropTypes.shape),
  // liveStream: PropTypes.objectOf(PropTypes.shape),
  liveStreamDetails: PropTypes.objectOf(PropTypes.shape),
};
LiveStreamCreatePage.defaultProps = {
  // liveStream: {},
  liveStreamDetails: {},
  liveStreamCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.liveStream, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LiveStreamCreatePage);
