import jwtDecode from 'jwt-decode';
import { has, intersection } from 'lodash';
import moment from 'moment';
import { EVENT_STATUS } from '../config';

const DAY_NAMES = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const EVENT_CODE_LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'J',
  'K',
  'M',
  'N',
  'P',
  'R',
  'S',
  'T',
  'U',
  'W',
  'X',
  'Y',
  'Z',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
];
const generateLiveStreamCode = () => {
  let code = '';
  for (let i = 0; i < 4; i += 1) {
    code += EVENT_CODE_LETTERS[Math.round(Math.random() * EVENT_CODE_LETTERS.length - 1)];
  }
  return code; // setFieldValue('eventCode', code);
};

const TOURNAMENT_EVENT_TYPES = [
  { value: 'semi', label: 'Semi-Final' },
  { value: 'finals', label: 'Final' },
];

const GROUPS = {
  HOST: 7,
  ADMIN: 3,
  OWNER: 6,
  PLAYER: 8,
  MACHINE: 9,
};

const EVENT_STATUSES = [
  { value: EVENT_STATUS.DRAFT, label: 'Draft' },
  { value: EVENT_STATUS.READY, label: 'Ready' },
  { value: EVENT_STATUS.PENDING, label: 'Pending' },
  { value: EVENT_STATUS.COMPLETE, label: 'Complete' },
  { value: EVENT_STATUS.CANCELED, label: 'Canceled' },
];

const EVENT_TYPE = {
  // PUBLIC: 'public',
  RECURRING: 'recurring',
  SEMI_REGULAR: 'semiregular',
  PRIVATE: 'private',
  FUNDRAISER: 'fundraiser',
  // HIDDEN: 'hidden',
  LIVE: 'live',
  TOURNAMENT: 'tournament',
};

const EVENT_TYPES = [
  // { value: EVENT_TYPE.PUBLIC, label: 'Public' },
  { value: EVENT_TYPE.RECURRING, label: 'Recurring' },
  { value: EVENT_TYPE.SEMI_REGULAR, label: 'Semi-Regular' },
  { value: EVENT_TYPE.PRIVATE, label: 'Private' },
  { value: EVENT_TYPE.FUNDRAISER, label: 'Fundraiser' },
  // { value: EVENT_TYPE.HIDDEN, label: 'Hidden' },
  { value: EVENT_TYPE.LIVE, label: 'Live' },
  { value: EVENT_TYPE.TOURNAMENT, label: 'Tournament' },
];

const PAYMENT_TYPE = {
  ACCOUNT: 'ACCOUNT',
  CASH: 'CASH',
  CHECK_TO_HOST: 'CHECK_TO_HOST',
  CHECK_MAILED: 'CHECK_MAILED',
  VENMO: 'VENMO',
};
const PAYMENT_METHODS = [
  { value: PAYMENT_TYPE.ACCOUNT, label: 'Bill to Account' },
  { value: PAYMENT_TYPE.CASH, label: 'Cash to Host' },
  { value: PAYMENT_TYPE.CHECK_TO_HOST, label: 'Check to Host' },
  { value: PAYMENT_TYPE.CHECK_MAILED, label: 'Check Mailed' },
  { value: PAYMENT_TYPE.VENMO, label: 'Venmo' },
];

const POLLING_API_INTERVAL = 5000;

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss zz';

const dayName = (dayIndex) => DAY_NAMES[dayIndex];

const shortDayName = (dayIndex) => DAY_NAMES[dayIndex].substring(0, 3);

const eventsByWeekday = (events) =>
  events.reduce((acc, event) => {
    if (has(event, 'active') && !event.active) {
      return acc;
    }

    acc[event.dayIndex] = acc[event.dayIndex] || [];
    acc[event.dayIndex].push(event);
    return acc;
  }, {});

const upcomingDatesByYear = (dates) =>
  dates.reduce((acc, date) => {
    const momentDate = moment(date, DATE_FORMAT);
    const year = momentDate.year();
    const month = momentDate.month();

    if (momentDate < moment.now()) {
      return acc;
    }

    acc[year] = acc[year] || {};

    acc[year][month] = acc[year][month] || [];
    acc[year][month].push(date);
    return acc;
  }, {});

const getPlacementBadgeImage = (placement) => {
  if (placement < 1 || placement > 3) {
    return null;
  }

  const badges = ['gold', 'silver', 'bronze'];
  return `https://s3.amazonaws.com/whattrivia/img/team_profile/${badges[placement - 1]}_badge.png`;
};

const getRoles = (token) => {
  if (token) {
    return jwtDecode(token).roles;
  }
  return [];
};

const rolesMatched = (allowedRoles, presentRoles) => intersection(allowedRoles, presentRoles).length > 0;

export {
  EVENT_STATUSES,
  EVENT_TYPE,
  EVENT_TYPES,
  PAYMENT_METHODS,
  PAYMENT_TYPE,
  POLLING_API_INTERVAL,
  TOURNAMENT_EVENT_TYPES,
  GROUPS,
  dayName,
  eventsByWeekday,
  generateLiveStreamCode,
  getPlacementBadgeImage,
  getRoles,
  rolesMatched,
  shortDayName,
  upcomingDatesByYear,
};
