import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'react-feather';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import LiveStreamTable from '../../components/LiveStream/LiveStreamTable';

class LiveStreamsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 100,
        pageNumber: 1,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  toggleActiveState = (liveStreamObj, newState) => {
    const { submitEditLiveStream } = this.props;
    const l = liveStreamObj;
    l.isActive = newState;
    submitEditLiveStream(liveStreamObj.id, l);
  };

  updateList() {
    const { getAllLiveStreams } = this.props;
    const { filter } = this.state;
    getAllLiveStreams(filter);
  }

  render() {
    const { liveStream, liveStreamListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/live-streams" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>LiveStreams</h1>
              </div>
              <div className="col text-right">
                <Link to="/live-streams/create" className="btn btn-success">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <LiveStreamTable
              list={liveStream.data}
              pagination={liveStream.metadata}
              isLoading={liveStreamListRequest.working}
              isSuccess={liveStreamListRequest.success}
              isFailed={liveStreamListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
              toggleActiveState={this.toggleActiveState}
            />
          </div>
        </div>
      </div>
    );
  }
}

LiveStreamsPage.propTypes = {
  getAllLiveStreams: PropTypes.func.isRequired,
  submitEditLiveStream: PropTypes.func.isRequired,
  liveStream: PropTypes.objectOf(PropTypes.shape),
  liveStreamListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
LiveStreamsPage.defaultProps = {
  liveStream: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.liveStream, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LiveStreamsPage);
