import { toNumber } from 'lodash';
import API from '../../utils/api/liveStream';

const ACTION_PREFIX = 'LIVE_STREAM';

// const getAllLiveStreams = query => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

//   API.getAllLiveStreams(query)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
// };

const getLiveStreamResponses = (eventCode, questionId) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_RESPONSE_STARTED` });
  return API.getLiveStreamResponses(eventCode, questionId)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_RESPONSE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_RESPONSE_FAILED`, payload: errorMsg }));
};

const getLiveStreamScoreboard = (eventCode, scoreboardKey) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_GETSCOREBOARD_STARTED` });
  API.getLiveStreamScoreboard(eventCode, scoreboardKey)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_GETSCOREBOARD_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_GETSCOREBOARD_FAILED`,
        payload: errorMsg,
      }),
    );
};

// const generateScoreboard = (eventCode) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_GENSCOREBOARD_STARTED` });
//   API.generateScoreboard(eventCode)
//     .then((data) =>
//       dispatch({
//         type: `${ACTION_PREFIX}_GENSCOREBOARD_SUCCESS`,
//         payload: data,
//       }),
//     )
//     .catch((errorMsg) =>
//       dispatch({
//         type: `${ACTION_PREFIX}_GENSCOREBOARD_FAILED`,
//         payload: errorMsg,
//       }),
//     );
// };

// const submitCreateLiveStream = account => (dispatch) => {
//   // console.log("Submit Create Location");
//   dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
//   API.createLiveStream(account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
// };

// const submitEditLiveStream = (id, properties) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
//   API.updateLiveStream(id, properties)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`,
// payload: createAnswerText(data) }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
// };

const updateLiveStreamResponse = (eventCode, id, properties, updateAll) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_RESPONSE_UPDATE_STARTED` });
  // console.log('Update', id, properties);
  return API.updateLiveStreamResponse(eventCode, id, properties, updateAll)
    .then((data) => {
      dispatch({
        type: `${ACTION_PREFIX}_RESPONSE_UPDATE_SUCCESS`,
        payload: data,
      });
      return dispatch(getLiveStreamResponses(data.eventId, toNumber(data.question)));
    })
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_RESPONSE_UPDATE_FAILED`,
        payload: errorMsg,
      }),
    );
};

const gradeQuestion = (eventCode, questionNumber) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_QUESTION_GRADE_STARTED` });
  return API.gradeQuestion(eventCode, questionNumber)
    .then((data) => {
      dispatch({
        type: `${ACTION_PREFIX}_QUESTION_GRADE_SUCCESS`,
        payload: data,
      });
      return dispatch(getLiveStreamResponses(eventCode, questionNumber));
    })
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_QUESTION_GRADE_FAILED`,
        payload: errorMsg,
      }),
    );
};

// const updateWrongAnswers = (id, eventCode, questionId) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_RESPONSE_WRONGUPDATE_STARTED` });
//   return API.markOthersWrong(id)
//     .then((data) => {
//       dispatch({
//         type: `${ACTION_PREFIX}_RESPONSE_WRONGUPDATE_SUCCESS`,
//         payload: data,
//       });
//       return dispatch(getLiveStreamResponses(eventCode, questionId));
//     })
//     .catch((errorMsg) =>
//       dispatch({
//         type: `${ACTION_PREFIX}_RESPONSE_WRONGUPDATE_FAILED`,
//         payload: errorMsg,
//       }),
//     );
// };

export default {
  getLiveStreamResponses,
  updateLiveStreamResponse,
  gradeQuestion,
  // updateWrongAnswers,
  // generateScoreboard,
  getLiveStreamScoreboard,
};
