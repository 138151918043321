import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/Tournaments/CreateForm';

const TournamentCreatePage = ({ submitCreateTournament, tournamentCreateRequest }) => (
  <div className="tournaments-page page-container">
    <Header currentPage="/tournaments" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create a Tournament</h1>
        <CreateForm
          // entity={tournamentDetails}
          onSubmit={submitCreateTournament}
          loading={tournamentCreateRequest.working}
          success={tournamentCreateRequest.success}
          failed={tournamentCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

TournamentCreatePage.propTypes = {
  submitCreateTournament: PropTypes.func.isRequired,
  tournamentCreateRequest: PropTypes.objectOf(PropTypes.shape),
};
TournamentCreatePage.defaultProps = {
  tournamentCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.tournaments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(TournamentCreatePage);
