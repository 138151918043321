import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { extend } from 'lodash';
import GenericActions from '../../actions/genericActions';
import formActions from '../../actions/formActions';
import Header from '../Header';
import LoginForm from '../../components/Forms/LoginForm';
import RequestPasswordResetForm from '../../components/Forms/RequestPasswordResetForm';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPassword: false,
    };
  }

  componentDidMount() {
    const { getLocalEmail } = this.props;
    getLocalEmail();
  }

  render() {
    const { authenticated, location } = this.props;
    if (authenticated) {
      const { from } = location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }
    const { email, submitLogin, login, passwordResetRequest, submitRequestPasswordReset } = this.props;
    const { showResetPassword } = this.state;

    return (
      <div className="login-page page-container">
        <Header currentPage="/login" />
        <div>
          <h1 className="page-title">Log In</h1>
          <div className="container-fluid">
            <div className="content-box">
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <h2 className="section-title">Log in into your account</h2>
                  <LoginForm
                    email={email}
                    rememberMe={email !== ''}
                    onSubmit={submitLogin}
                    loading={login.working}
                    success={login.success}
                    failed={login.failed}
                    errorMessage={login.errorMessage}
                  />
                  <div
                    className="col-xs-12 reset-password-button"
                    role="presentation"
                    onClick={() => this.setState({ showResetPassword: !showResetPassword })}
                  >
                    I Forgot My Password!
                  </div>
                  {showResetPassword && (
                    <div className="col-xs-12">
                      <h2 className="section-title">Forgotten Password</h2>
                      <p>Enter your email address below. You will receive an email with a link to reset your password.</p>
                      <RequestPasswordResetForm
                        loading={passwordResetRequest.working}
                        failed={passwordResetRequest.failed}
                        success={passwordResetRequest.success}
                        error={passwordResetRequest.errorMessage}
                        onSubmit={submitRequestPasswordReset}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-xs-12" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  submitRequestPasswordReset: PropTypes.func.isRequired,
  getLocalEmail: PropTypes.func.isRequired,
  //
  authenticated: PropTypes.bool,
  email: PropTypes.string,
  login: PropTypes.objectOf(PropTypes.shape),
  location: PropTypes.objectOf(PropTypes.shape),
  passwordResetRequest: PropTypes.objectOf(PropTypes.shape),
};
LoginPage.defaultProps = {
  authenticated: false,
  email: '',
  login: {},
  location: {},
  passwordResetRequest: {},
};

const mapStateToProps = (state) => extend({}, state.forms, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, formActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LoginPage);
