import React, { Component } from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Spinner from '../Spinner';
import { PAYMENT_METHODS } from '../../utils';

class AccountCreateForm extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) {
      resetForm();
    }
  }

  render() {
    const {
      // values,
      // touched,
      // errors,
      dirty,
      isSubmitting,
      // handleChange,
      // setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      accountDetails,
      // handleReset,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && (
            <div className="alert alert-success">
              Event Created
              <br />
              <Link to={`/accounts/${accountDetails.id}/edit`}>Edit Your Account</Link>
            </div>
          )}

          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="name">Name</label>
            <Field className="form-control" name="name" />
            <ErrorMessage name="name" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="name">Account Type</label>
            <Field className="form-control" component="select" name="type">
              <option value="1">Public</option>
              <option value="2">Corporate</option>
              <option value="3">Fundraiser</option>
              <option value="4">Other</option>
            </Field>
            <ErrorMessage name="type" component="div" className="alert alert-danger" />
          </div>
        </div>
        <h3>Billing</h3>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="paymentMethod">Usual Payment Method</label>
            <Field className="form-control" component="select" name="paymentMethod">
              {PAYMENT_METHODS.map((m) => (
                <option key={m.value} value={m.value}>
                  {m.label}
                </option>
              ))}
            </Field>
            <ErrorMessage name="paymentMethod" component="div" className="alert alert-danger" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Link className="btn btn-default" to="/accounts">
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary" disabled={!dirty}>
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
AccountCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  accountDetails: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  // values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
AccountCreateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  dirty: false,
  errorMessage: '',
  accountDetails: { locations: [] },
  // values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  mapPropsToValues: ({ name, type, paymentMethod }) => ({
    name: name || '',
    type: type || 1,
    paymentMethod: paymentMethod || 'cash',
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },
  displayName: 'Account Creation Form',
})(AccountCreateForm);
