import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import DesktopTableRow from './DesktopTableRow';
import './style.css';

const RegisteredTeamHistoryDesktop = ({ history, className }) => {
  if (history && history.length < 1) {
    return <div className={`alert-info ${className}`}>Your team has no history during the selected time period!</div>;
  }

  return (
    <div className={`history-table ${className}`}>
      <div className="table-header">
        <div className="col-sm-2 sort-icon">
          Week Of
          <img src="/i/table-sort-arrow.png" alt="table-sort-arrow" />
        </div>
        <div className="col-sm-2">Total Appearances</div>
        <div className="col-sm-4">Winning Event</div>
        <div className="col-sm-2">Highest Placement</div>
        <div className="col-sm-2">Tournament Points</div>
      </div>
      {map(history, (eventTeam, idx) => (
        <DesktopTableRow key={eventTeam.id} eventTeam={eventTeam} isEven={idx % 2 === 1} />
      ))}
    </div>
  );
};

RegisteredTeamHistoryDesktop.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape),
  className: PropTypes.string,
};
RegisteredTeamHistoryDesktop.defaultProps = {
  history: [],
  className: '',
};

export default RegisteredTeamHistoryDesktop;
