import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import LiveStreamScoreboardTable from '../../components/LiveStream/LiveStreamScoreboardTable';
import actions from './actions';

class LiveStreamScoreboardPage extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     updateAll: true,
  //   };
  // }

  componentDidMount() {
    const { getLiveStreamScoreboard, eventCode, scoreboardKey } = this.props;
    // console.log('Get Event Code', eventCode);
    getLiveStreamScoreboard(eventCode, scoreboardKey);
  }

  render() {
    const {
      liveStreamScoreboard,
      liveStreamGetScoreboardRequest,
      // event,
    } = this.props;
    return (
      <LiveStreamScoreboardTable
        liveStreamScoreboard={liveStreamScoreboard}
        isSuccess={liveStreamGetScoreboardRequest.success}
        isFailed={liveStreamGetScoreboardRequest.failed}
      />
    );
  }
}

LiveStreamScoreboardPage.propTypes = {
  getLiveStreamScoreboard: PropTypes.func.isRequired,
  liveStreamScoreboard: PropTypes.objectOf(PropTypes.shape),
  liveStreamGetScoreboardRequest: PropTypes.objectOf(PropTypes.shape),
  // event: PropTypes.objectOf(PropTypes.shape),
  eventCode: PropTypes.string,
  scoreboardKey: PropTypes.string,
};
LiveStreamScoreboardPage.defaultProps = {
  liveStreamGetScoreboardRequest: {},
  liveStreamScoreboard: {},
  eventCode: '',
  scoreboardKey: '',
};

const mapStateToProps = (state) => extend({}, state.liveStream, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LiveStreamScoreboardPage);
