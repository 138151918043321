import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import GenericActions from '../../actions/genericActions';
import Header from '../Header';

const NotFoundPage = () => (
  <div className="tournament-page page-container">
    <Header currentPage="/" />
    <h1 className="page-title">Page Not Found</h1>
  </div>
);

const mapStateToProps = (state) => extend({}, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(NotFoundPage);
