import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { STRIPE_DASHBOARD_URL } from '../../../config';
import EventStatus from '../Widgets/EventStatus';
import EventType from '../Widgets/EventType';

const format = 'MM/DD/YY h:mma';
const EventInfo = ({ eventDetails }) => {
  if (!eventDetails || !eventDetails.account) return <span />;
  return (
    <div>
      <h2>Event Details</h2>
      <table className="table table-sm">
        <tr>
          <td>Event Name</td>
          <td>{eventDetails.name}</td>
        </tr>
        <tr>
          <td>Account</td>
          <td>{eventDetails.account.name}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td>
            {eventDetails.location.name}
            <p className="small">
              {eventDetails.location.address && (
                <>
                  {eventDetails.location.address}
                  <br />
                </>
              )}
              {eventDetails.location.city} {eventDetails.location.state}
            </p>
          </td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{moment(eventDetails.dateTimeStart).format('ddd, MMM Do, YYYY @ h:mma')}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>
            <EventType
              showName
              type={eventDetails.eventType}
              liveStreamIsValid={
                eventDetails.liveStreamEventCode !== null &&
                eventDetails.liveStreamEventCode !== '' &&
                eventDetails.liveStreamPointsMultiplier !== null &&
                eventDetails.liveStreamNumberOfQuestions !== null
              }
            />
            {eventDetails.eventType === 'live' && <div>Event Code: {eventDetails.liveStreamEventCode}</div>}
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td>
            <EventStatus event={eventDetails} />
          </td>
        </tr>
        <tr>
          <td>Google Doc</td>
          <td>
            {eventDetails.googleDocURL && (
              <a href={eventDetails.googleDocURL} rel="noopener noreferrer" target="_blank">
                Link
              </a>
            )}
          </td>
        </tr>
      </table>
      <h3>Payment Information</h3>
      <table className="table table-sm">
        <tr>
          <td>Cost</td>
          <td>${eventDetails.eventGross}</td>
        </tr>
        <tr>
          <td>Payment Method</td>
          <td>{eventDetails.paymentMethod}</td>
        </tr>
        <tr>
          <td>Payment Note</td>
          <td>{eventDetails.paymentInfo}</td>
        </tr>
        {eventDetails.stripeInvoiceItemId && (
          <tr>
            <td>Invoice Item:</td>
            <td>
              {eventDetails.stripeInvoiceItemId ? (
                <>
                  <a
                    target="_blank"
                    title="View Customer in Stripe Dashboard"
                    rel="noopener noreferrer"
                    href={`${STRIPE_DASHBOARD_URL}/customers/${eventDetails.stripeCustomerId}`}
                  >
                    {eventDetails.stripeInvoiceItemId}
                  </a>
                  <div className="small">Created: {moment(eventDetails.stripeInvoiceItemCreatedOn).format(format)}</div>
                </>
              ) : (
                <span title="Will be created when event is marked complete">Not Yet Created</span>
              )}
            </td>
          </tr>
        )}

        <tr>
          <td>Invoice:</td>
          <td>
            {eventDetails.stripeInvoiceId && (
              <>
                <a
                  target="_blank"
                  title="View Invoice in Stripe Dashboard"
                  rel="noopener noreferrer"
                  href={`${STRIPE_DASHBOARD_URL}/invoices/${eventDetails.stripeInvoiceId}`}
                >
                  {eventDetails.stripeInvoiceNum}
                </a>
                <div className="small">Sent: {eventDetails.stripeInvoiceSentOn ? moment(eventDetails.stripeInvoiceSentOn).format(format) : 'No'}</div>
                <div className="small">Paid: {eventDetails.stripeInvoicePaidOn ? moment(eventDetails.stripeInvoicePaidOn).format(format) : 'No'}</div>
              </>
            )}
          </td>
        </tr>

        <tr>
          <td>Invoice Paid?</td>
          <td>{eventDetails.isInvoicePaid === true ? <Badge color="success">Yes</Badge> : <Badge color="danger">No</Badge>}</td>
        </tr>
      </table>
    </div>
  );
};

EventInfo.propTypes = {
  eventDetails: PropTypes.objectOf(PropTypes.shape),
};

EventInfo.defaultProps = {
  eventDetails: {},
};

export default EventInfo;
