import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Table } from 'reactstrap';
import Spinner from '../Spinner';
import './styles.css';

// const LIMITS = [100, 300, 500];
const LiveStreamScoreboardTable = ({ liveStreamScoreboard, isFailed, isLoading, errorMessage, isSuccess }) => (
  <div>
    <Table striped hover responsive size="sm" className="element-list">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Team Name</th>
          <th>Email</th>
          <th>Points</th>
        </tr>
      </thead>
      <tbody>
        {isFailed && (
          <tr>
            <td colSpan="10" className="danger">
              {errorMessage}
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td colSpan="10">
              <Spinner />
            </td>
          </tr>
        )}
        {isSuccess &&
          map(liveStreamScoreboard.leaderboard, (item, i) => (
            <tr key={i}>
              <td>{item.rank}</td>
              <td>{item.teamName}</td>
              <td>{item.email}</td>
              <td>{item.points}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  </div>
);
LiveStreamScoreboardTable.propTypes = {
  liveStreamScoreboard: PropTypes.objectOf(PropTypes.shape),
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
LiveStreamScoreboardTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  liveStreamScoreboard: {},
};

export default LiveStreamScoreboardTable;
