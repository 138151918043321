import React from 'react';
import PropTypes from 'prop-types';
import EventResultsTable from '../../HostTools/EventResults';
import HostEventBonusDetail from '../EventBonusDetail';

const RegularEventDetails = ({ eventDetails, eventHistory, eventBonusHistoryRequest }) => (
  <>
    {eventDetails.bonus && (
      <dl className="row">
        <dt className="col-sm-2">Photo Round</dt>
        <dd className="col-sm-10">{eventDetails.bonus.photoRoundName}</dd>

        <dt className="col-sm-2">Final Question</dt>
        <dd className="col-sm-10">{eventDetails.bonus.finalQuestions.map((q) => q.question)}</dd>

        <dt className="col-sm-2">Tie Breakers</dt>
        <dd className="col-sm-10">{eventDetails.bonus.tieBreakers && eventDetails.bonus.tieBreakers.map((q) => q.question)}</dd>
      </dl>
    )}
    <br />
    <div>{eventDetails.numberOfTeams > 1 && <EventResultsTable event={eventDetails} />}</div>
    <div>
      <HostEventBonusDetail eventHistory={eventHistory} isLoading={eventBonusHistoryRequest.working} isSuccess={eventBonusHistoryRequest.success} />
    </div>
  </>
);

RegularEventDetails.propTypes = {
  eventDetails: PropTypes.objectOf(PropTypes.shape),
  eventHistory: PropTypes.arrayOf(PropTypes.shape),
  eventBonusHistoryRequest: PropTypes.objectOf(PropTypes.shape),
};

RegularEventDetails.defaultProps = {
  eventDetails: {},
  eventBonusHistoryRequest: {},
  eventHistory: [],
};

export default RegularEventDetails;
