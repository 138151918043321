import React from 'react';
import PropTypes from 'prop-types';
// import Button from '../../Button';
import LiveStreamScoreboardPage from '../../../containers/LiveStream/scoreboard';

const EventLiveScoreboard = ({ eventCode, scoreboardKey }) => (
  <div>
    <div className="row">
      <div className="col-12">
        <LiveStreamScoreboardPage eventCode={eventCode} scoreboardKey={scoreboardKey} />
      </div>
    </div>
  </div>
);

EventLiveScoreboard.propTypes = {
  eventCode: PropTypes.string,
  scoreboardKey: PropTypes.string,
};

EventLiveScoreboard.defaultProps = {
  eventCode: '',
  scoreboardKey: '',
};

export default EventLiveScoreboard;
