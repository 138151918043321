import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import accountActions from '../Accounts/actions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/Locations/EditForm';

class LocationEditPage extends Component {
  componentDidMount() {
    const { getLocationDetails, routeParams } = this.props;
    getLocationDetails(routeParams.id);
  }

  render() {
    const { submitEditLocation, locationDetails, locationDetailRequest, locationEditRequest, accounts, getAllAccounts } = this.props;
    return (
      <div className="location-page page-container">
        <Header currentPage="/locations" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit a Location</h1>
            {locationDetailRequest.working && <Spinner />}
            {locationDetailRequest.success && (
              <EditForm
                entity={locationDetails}
                onSubmit={submitEditLocation}
                accounts={accounts.data}
                searchAccounts={(value) => getAllAccounts({ name: value })}
                loading={locationEditRequest.working}
                success={locationEditRequest.success}
                failed={locationEditRequest.failed}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LocationEditPage.propTypes = {
  submitEditLocation: PropTypes.func.isRequired,
  getLocationDetails: PropTypes.func.isRequired,
  locationDetailRequest: PropTypes.objectOf(PropTypes.shape),
  locationEditRequest: PropTypes.objectOf(PropTypes.shape),
  locationDetails: PropTypes.objectOf(PropTypes.shape),
  getAllAccounts: PropTypes.func.isRequired,
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape),
};
LocationEditPage.defaultProps = {
  accounts: {},
  locationDetails: {},
  locationEditRequest: {},
  locationDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.locations, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, accountActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LocationEditPage);
