import API from '../../utils/api/billingAccounts';

const ACTION_PREFIX = 'BILLINGACCOUNTS';

const getAllBillingAccounts = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getBillingAccounts(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getBillingAccountDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getBillingAccount(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreateBillingAccount = (billingAccount) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createBillingAccount(billingAccount)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditBillingAccount = (id, billingAccount) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateBillingAccount(id, billingAccount)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

const sendBillingAccountInvoice = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_INVOICING_STARTED` });
  API.sendBillingAccountInvoices(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_INVOICING_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_INVOICING_FAILED`, payload: errorMsg }));
};

const getAllRegions = (query) => (dispatch) => {
  dispatch({ type: `REGION_LIST_STARTED` });

  API.getRegions(query)
    .then((data) => dispatch({ type: `REGION_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `REGION_LIST_FAILED`, payload: errorMsg }));
};

export default {
  getAllRegions,
  getAllBillingAccounts,
  getBillingAccountDetails,
  submitCreateBillingAccount,
  submitEditBillingAccount,
  sendBillingAccountInvoice,
};
