import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Avatar from 'react-avatar';
import './styles.css';
import { Badge } from 'reactstrap';

class EventHostAvatar extends Component {
  clickHost = () => {
    const { onClickHost, host, event } = this.props;
    if (onClickHost !== null) {
      onClickHost(host, event);
    }
  };

  render() {
    const { host, onClickHost } = this.props;
    return (
      <span title={`${host.nickname} - Click to Remove`} style={{ paddingRight: '2px' }}>
        {/* <Avatar size={30}  textSizeRatio={1.75} name={hostName} /> */}
        <Badge
          onClick={this.clickHost}
          style={{ backgroundColor: host.color }}
          className="clickable-avatar label label-primary"
          title={`${host.firstName} ${host.lastName} ${onClickHost ? '\nClick to Remove' : ''}`}
        >
          {host.nickname}
        </Badge>
      </span>
    );
  }
}

EventHostAvatar.propTypes = {
  host: PropTypes.objectOf(PropTypes.shape).isRequired,
  event: PropTypes.objectOf(PropTypes.shape),
  onClickHost: PropTypes.func,
};
EventHostAvatar.defaultProps = {
  onClickHost: null,
  event: null,
};

export default EventHostAvatar;
