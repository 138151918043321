import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getEvents(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.EVENTS}?${qs.stringify(query, { arrayFormat: 'bracket' })}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create event
 * @param {object} eventData - the event data
 */
function createEvent(eventData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  // console.log("Creating Event", eventData);
  return axios
    .post(`${ENDPOINTS.EVENTS}`, eventData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get event by ID
 * @param {string} id - the event ID
 */
function getEvent(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.EVENTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get event bonus data
 * @param id {int} - Event ID
 */
function getEventBonusData(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.EVENTS}/${id}/bonus`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get event bonus data
 * @param id {int} - Event ID
 */
function getEventBonusHistory(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.EVENTS}/${id}/history`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update event
 * @param {string} id - the event ID
 * @param {object} newData - the event data
 */
function updateEvent(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.EVENTS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Patch multiple events
 * @param {string} ids - the event IDs to be patched
 * @param {object} patches - the patch array
 */
function patchEvents(ids, patches) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .patch(
      `${ENDPOINTS.EVENTS}`,
      { ids, actions: patches },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Delete event
 * @param {string} id - the event ID
 */
function deleteEvent(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.EVENTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get events for logged in host
 * @param id {int} - Event ID
 */
function triggerGoogleImport(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  // console.log("Creating Event", eventData);
  return axios
    .post(
      `${ENDPOINTS.EVENTS}/${id}/import`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getEvents,
  getEvent,
  getEventBonusData,
  getEventBonusHistory,
  createEvent,
  updateEvent,
  patchEvents,
  deleteEvent,
  triggerGoogleImport,
};
