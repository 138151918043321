// import Cookies from 'universal-cookie';
import { ENDPOINTS } from '../../config';
import { getJWT, getUserEmail, removeJWT, removeUserEmail, setJWT, setUserEmail } from './helpers';
import toastActions from '../../actions/toastActions';
// const cookies = new Cookies();

const unauthorizedRedirect = (res) => {
  if (res.status === 401) {
    removeJWT();
    window.location.href = '/login';
  }
  return res;
};

/**
 * Check if token exists
 * @param cb {Function} - The callback function
 */
function checkAuth(cb) {
  const token = getJWT();
  cb(token && token !== 'null');
}

/**
 * get local email to fill the login input email
 * @param cb {Function} - The callback function
 */
function getLocalEmail(cb) {
  const email = getUserEmail();
  cb(email);
}

/**
 * Get my profile
 * @param cb {Function} - The callback function
 */
function getMyProfile(cb) {
  const token = getJWT();
  fetch(ENDPOINTS.PROFILE, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(unauthorizedRedirect)
    .then((res) => res.json())
    .then((json) => (json.code ? cb(json) : cb(null, json)))
    .catch(cb);
}

/**
 * Update my profile
 * @param id {Number} - User ID
 * @param pwd {String} - Password
 * @param pwdconfirm {String} - Confirmed Password
 * @param currpwd {String} - Current Password for Validation
 * @param cb {Function} - The callback function
 */
function updateMyProfile(pwd, pwdconfirm, currpwd, cb) {
  const token = getJWT();
  fetch(ENDPOINTS.PROFILE, {
    method: 'PUT',
    body: JSON.stringify({ user: { pwd, pwdconfirm, currpwd } }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(unauthorizedRedirect)
    .then((res) => {
      // console.log(res);
      if (res.status === 200) cb(true, '');
      else cb(false, 'Current Password Invalid');
    })
    .catch(cb);
}

/**
 * Login user
 * @param credentials {Object} - Object with un/pw
 * @param isRemember {Boolean} - isRemember un locally
 * @param cb {Function} - The callback function
 */
function login(un, pw, isRemember, cb) {
  if (isRemember) setUserEmail(un);

  fetch(ENDPOINTS.LOGIN, {
    method: 'POST',
    body: JSON.stringify({ un, pw }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        // not found means the email is valid and available
        res.json().then((json) => {
          if (json.jwt) {
            try {
              setJWT(json.jwt);

              if (isRemember === false) removeUserEmail();
              cb(true); // successful login
            } catch (error) {
              cb(false, 'Could not set token in local storage');
            }
          } else cb(false, 'JWT Not Found in Response');
        });
      } else if (res.status === 401) cb(false, 'Invalid Login');
    })
    .catch(cb);
}

/**
 * Request password reset code
 * @param email {String} - The user email
 * @param cb {Function} - The callback function
 */
function requestPassResetCode(email, cb) {
  fetch(ENDPOINTS.RESET_PASSWORD, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((json) => (json.code ? cb(json) : cb(null, json)))
    .catch(cb);
}

/**
 * Reset password
 * @param pwd {String} - The new password
 * @param resetCode {String} - The password reset code
 * @param cb {Function} - The callback function
 */
function resetPassword(pwd, resetCode, cb) {
  fetch(ENDPOINTS.RESET_PASSWORD, {
    method: 'PUT',
    body: JSON.stringify({ pwd, resetCode }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((json) => (json.code ? cb(json) : cb(null, json)))
    .catch(cb);
}

/**
 * Logout user
 * @param cb {Function} - The callback function
 */
function logout(cb) {
  removeJWT();
  cb();
}

/**
 * validate email
 * @param email {Object} - The object with the email
 * @param cb {Function} - The callback function
 */
function isEmailAvailable(email, cb) {
  const emailEncoded = encodeURIComponent(email);
  fetch(`${ENDPOINTS.VALIDATE}?email=${emailEncoded}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    // .then(res => res.json())
    .then((res) => {
      if (res.status === 200) {
        // not found means the email is valid and available
        cb(true, {});
      } else if (res.status === 400) cb(false, { message: 'Email Exists' });
      else cb(false, { message: 'Invalid Email Address' });
    })
    .catch(cb);
}

/**
 * validate team username
 * @param username {String} - The object with the team username
 * @param cb {Function} - The callback function
 */
function validateTeamUsername(username, cb) {
  const teamName = encodeURIComponent(username);
  fetch(`${ENDPOINTS.VALIDATE}?teamName=${teamName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        cb(true);
      } else {
        res.json().then((json) => {
          cb(false, json.message);
        });
      }
    })
    .catch(cb);
}

/**
 * quick register
 * @param teamName {String}
 * @param email {String} - Email of User
 * @param cb {Function} - The callback function
 */
function quickRegister(teamName, email, cb) {
  fetch(ENDPOINTS.REGISTER, {
    method: 'POST',
    body: JSON.stringify({ teamName, email }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        res.json().then((json) => {
          // console.log('Team', json);
          cb(true, json.team);
        });
      } else if (res.status === 409) {
        cb(false, 'Email Address Exists');
      } else {
        res.json().then((json) => {
          cb(false, { message: `Invalid Request: ${json.message}` });
        });
      }
    })
    .catch(cb);
}

/**
 * quick register by key
 * @param key {String} - The quick register key
 * @param cb {Function} - The callback function
 */
function getQuickRegister(key, cb) {
  fetch(`${ENDPOINTS.REGISTER}/${key}`)
    .then((res) => res.json())
    .then((json) => (json.code ? cb(json) : cb(null, json)))
    .catch(cb);
}

/**
 * complete quick register
 * @param key {String} - The quick register key
 * @param name {String} - Team Name
 * @param teamUsername {String} - Username they'll use at list
 * @param pw {String} - Password for their account
 * @param cb {Function} - The callback function
 */
function completeQuickRegister(key, name, teamUsername, pw, cb) {
  fetch(`${ENDPOINTS.REGISTER}/${key}`, {
    method: 'POST',
    body: JSON.stringify({
      team: { name, teamUsername },
      user: { pw },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((json) => (json.code ? cb(json) : cb(null, json)))
    .catch(cb);
}

function handleAPIError(error) {
  console.log('handle api error', error);
  if (error.response.data) toastActions.displayErrorToast(error.response.data.message[0].message);
  else toastActions.displayErrorToast(error.message);
}

export default {
  login,
  checkAuth,
  requestPassResetCode,
  resetPassword,
  logout,
  getMyProfile,
  validateTeamUsername,
  isEmailAvailable,
  quickRegister,
  getQuickRegister,
  completeQuickRegister,
  getLocalEmail,
  updateMyProfile,
  handleAPIError,
};
