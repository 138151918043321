import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import ordinal from 'ordinal';
import { getPlacementBadgeImage } from '../../utils';

const Placement = ({ position, className = 'no-left-margin' }) =>
  position < 4 ? (
    <div className={`appearances__badge ${className}`} style={{ backgroundImage: `url(${getPlacementBadgeImage(position)})` }}>
      {ordinal(position)}
    </div>
  ) : (
    <div className={className}>{ordinal(position)}</div>
  );

Placement.propTypes = {
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};
Placement.defaultProps = {
  className: 'no-left-margin',
};

export default Placement;
