import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { User, Users, Award, Video, DollarSign, Calendar } from 'react-feather';
import { EVENT_TYPE, EVENT_TYPES } from '../../../utils';

const EventType = ({ showName, type, liveStreamIsValid }) => (
  <>
    {type === EVENT_TYPE.RECURRING && (
      <span title="Recurring Event">
        <Users color="green" />
      </span>
    )}
    {type === EVENT_TYPE.SEMI_REGULAR && (
      <span title="Public Event that Doesn't Happen Weekly">
        <Calendar color="purple" />
      </span>
    )}
    {type === EVENT_TYPE.PRIVATE && (
      <span title="Private Event">
        <User color="red" />
      </span>
    )}
    {type === EVENT_TYPE.FUNDRAISER && (
      <span title="Fundraiser Event">
        <DollarSign color="lightgreen" />
      </span>
    )}
    {type === EVENT_TYPE.LIVE && (
      <span title="Live Event">
        <Video color={liveStreamIsValid ? 'blue' : 'red'} />
      </span>
    )}
    {type === EVENT_TYPE.TOURNAMENT && (
      <span title="Tournament">
        <Award color="goldenrod" />
      </span>
    )}
    {type && showName && <span> {find(EVENT_TYPES, { value: type }).label}</span>}
  </>
);

EventType.propTypes = {
  type: PropTypes.string,
  showName: PropTypes.bool,
  liveStreamIsValid: PropTypes.bool,
};

EventType.defaultProps = {
  type: '',
  showName: false,
  liveStreamIsValid: null,
};

export default EventType;
