import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import GenericActions from '../../actions/genericActions';
import actions from './actions';

class RegionsSelector extends Component {
  componentDidMount() {
    const { getAllRegions } = this.props;
    getAllRegions({ pageSize: 50 });
  }

  onChange = (values) => {
    const { onUpdate } = this.props;
    onUpdate(values);
  };

  onInputChange = (searchString, action) => {
    const { getAllRegions } = this.props;
    if (action.action === 'input-change') getAllRegions({ name: searchString, pageSize: 50 });
  };

  render() {
    const { regions, selectedRegion, regionListRequest } = this.props;

    return (
      <Select
        classNamePrefix="select"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        isClearable
        isLoading={regionListRequest.working}
        value={selectedRegion}
        onChange={this.onChange}
        options={regions.data}
        onInputChange={this.onInputChange}
      />
    );
  }
}

RegionsSelector.propTypes = {
  getAllRegions: PropTypes.func.isRequired,
  regions: PropTypes.objectOf(PropTypes.shape),
  regionListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  selectedRegion: PropTypes.objectOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
};
RegionsSelector.defaultProps = {
  selectedRegion: {},
  regions: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.accounts, state.regions, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegionsSelector);
