import { createBrowserHistory } from 'history';
import { extend, isEqual, pick, remove } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component } from 'react';
import { PlusCircle } from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import AddHostModal from '../../components/Events/AddHostModal';
import EventsFilter from '../../components/Events/EventsFilter';
import EventsSidebar from '../../components/Events/EventsSidebar';
import EventsTable from '../../components/Events/EventsTable';
import PaymentModal from '../../components/Events/PaymentModal';
import QuickCreateModal from '../../components/Events/QuickCreateModal';
import { EVENT_STATUS } from '../../config';
import AccountActions from '../Accounts/actions';
import Header from '../Header';
import LocationActions from '../Locations/actions';
import actions from './actions';
import './events.css';

const fetchPaginationFieldsFromLocalStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem('eventsPagination'));
  } catch (err) {
    return {};
  }
};

class EventsPage extends Component {
  constructor(props) {
    super(props);

    const paginationFields = fetchPaginationFieldsFromLocalStorage() || {};
    this.state = {
      pagination: {
        pageSize: paginationFields.pageSize || 50,
        pageNumber: paginationFields.pageNumber || 1,
      },
      filter: {
        orderBy: 'dateTimeStart',
        orderDir: 'ASC',
      },
      separateWeekRows: true,
      // isFilterVisible: false,
      showHostModal: false,
      eventToAddHostsTo: null,

      showPaymentModal: false,
      eventForPaymentModal: null,

      showQuickCreateModal: false,

      // selectedHostList: [],
      isSidebarExpanded: false,
      selectedEventIds: [],
    };
    this.setFilter = this.setFilter.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.updateSelectedEvents = this.updateSelectedEvents.bind(this);
    this.batchUpdateEvents = this.batchUpdateEvents.bind(this);
    this.history = createBrowserHistory();
  }

  componentDidUpdate(prevProps) {
    const { needRefreshEventList, events, eventBatchUpdateRequest } = this.props;
    if (!prevProps.needRefreshEventList && needRefreshEventList) {
      // console.log("UPDATE!");
      this.updateList();
      this.onCancelHostModal();
    }

    if (!(prevProps.eventBatchUpdateRequest.working && eventBatchUpdateRequest.success) && !isEqual(prevProps.events.data, events.data)) {
      this.updateSelectedEvents([]);
    }
  }

  setFilter(filterObj) {
    const { pagination } = this.state;
    pagination.pageNumber = 1;
    this.setState({ filter: filterObj, pagination }, this.updateList);
    const newFilterObj = {
      ...filterObj,
      accounts: filterObj.accounts ? filterObj.accounts.id : [],
      locations: filterObj.locations ? filterObj.locations.id : [],
    };
    this.history.push({
      pathname: window.location.pathname,
      search: qs.stringify(newFilterObj, { arrayFormat: 'bracket' }),
    });

    try {
      window.localStorage.setItem('filters', JSON.stringify(filterObj));
    } catch (err) {
      // pass
    }
  }

  handleChange = (property, value) => {
    const { pagination } = this.state;
    pagination[property] = value;
    this.setState({ pagination }, this.updateList);
    try {
      window.localStorage.setItem('eventsPagination', JSON.stringify(pagination));
    } catch (err) {
      // pass
    }
  };

  toggleWeekSeparator = () => {
    this.setState((prevState) => ({
      separateWeekRows: !prevState.separateWeekRows,
    }));
  };

  cloneEvent = (event) => {
    const { submitCreateEvent } = this.props;
    const newEvent = pick(event, [
      'name',
      'accountId',
      'locationId',
      'dateTimeStart',
      'url',
      'notes',
      'eventType',
      'hostDetails',
      'adminDetails',
      'isEventPublic',
      'eventGross',
      'bonusPoints',
      'bonusPointsIsMultiplier',
      'bonusPointReason',
      'bonusPoints',
      'liveStreamPointsMultiplier',
      'liveStreamNumberOfQuestions',
      'invoiceToEmail',
      'paymentMethod',
      'tournamentId',
      'tournamentMaxTeams',
      'tournamentEventType',
      'hosts',
      'bonus',
    ]);
    newEvent.name = `Copy Of ${event.name}`;
    newEvent.status = EVENT_STATUS.DRAFT;
    submitCreateEvent(newEvent);
  };

  deleteEvent = (eventId) => {
    const { submitDeleteEvent } = this.props;
    submitDeleteEvent(eventId);
  };

  createGoogleSheet = (event) => {
    const { submitEditEvent } = this.props;
    const e = event;
    e.pendingAction = 'create-sheet';
    // console.log("Create Sheet", event);
    submitEditEvent(event.id, e, true);
  };

  importGoogle = (event) => {
    const { triggerGoogleImport } = this.props;
    const e = event;
    e.pendingAction = 'import-google';
    triggerGoogleImport(event.id);
  };

  approveEvent = (event) => {
    const { submitEditEvent } = this.props;
    const e = event;
    e.status = EVENT_STATUS.COMPLETE;
    submitEditEvent(event.id, e, true);
  };

  sendInvoice = (event) => {
    const { submitEditEvent } = this.props;
    const e = event;
    e.pendingAction = 'create-stripe-invoice-item';
    submitEditEvent(event.id, e, true);
  };

  publishEvent = (event) => {
    const { submitEditEvent } = this.props;
    const e = event;
    e.status = EVENT_STATUS.READY;
    submitEditEvent(event.id, e, true);
  };

  removeHost = (host, event) => {
    const { submitEditEvent } = this.props;
    if (event.hosts.includes(host)) {
      remove(event.hosts, (h) => h.id === host.id);

      submitEditEvent(event.id, event, true);
    } else {
      // console.log("Host Not Found", host, event);
    }
  };

  onCancelHostModal = () => {
    this.setState({ showHostModal: false, eventToAddHostsTo: null });
  };

  addHost = (event) => {
    // const { submitEditEvent } = this.props;
    // const { eventToAddHostsTo } = this.state;
    this.setState({ eventToAddHostsTo: event, showHostModal: true });

    // if(!event.hosts.includes(host)) {
    //   event.hosts.push(host);
    //   // console.log("Add Host", host, event);
    //   submitEditEvent(event.id, event, true);
    // }
    // else {
    //   console.log("Host exists in event", host, event);
    // }
  };

  managePayment = (event) => {
    this.setState({ eventForPaymentModal: event, showPaymentModal: true });
  };

  onQuickCreate = () => {
    this.setState({ showQuickCreateModal: true });
  };
  onCancelPaymentModal = () => {
    this.setState({ showPaymentModal: false });
    this.updateList();
  };

  onCancelQuickCreateModal = () => {
    this.setState({ showQuickCreateModal: false });
    this.updateList();
  };

  onCompletePaymentModal = () => {
    this.setState({ showPaymentModal: false });
    this.updateList();
  };

  onCompleteAddHost = (hosts) => {
    const { submitEditEvent } = this.props;
    const { eventToAddHostsTo } = this.state;
    // console.log("Complete Add Hosts", eventToAddHostsTo, hosts);
    eventToAddHostsTo.hosts = hosts;
    submitEditEvent(eventToAddHostsTo.id, eventToAddHostsTo, true);
  };

  updateList() {
    const { getAllEvents } = this.props;
    const { filter, pagination } = this.state;
    const filters = {
      ...filter,
      accounts: filter.accounts ? [filter.accounts.id] : [],
      locations: filter.locations ? [filter.locations.id] : [],
    };
    getAllEvents({ ...filters, ...pagination });
  }

  toggleSidebar() {
    this.setState((state) => ({
      isSidebarExpanded: !state.isSidebarExpanded,
    }));
  }

  updateSelectedEvents(eventIds) {
    this.setState((state) => ({
      selectedEventIds: eventIds,
      isSidebarExpanded: eventIds.length > 0 && (eventIds.length > state.selectedEventIds.length || state.isSidebarExpanded),
    }));
  }

  batchUpdateEvents(update) {
    const { submitBatchUpdateEvents } = this.props;
    const { selectedEventIds } = this.state;
    submitBatchUpdateEvents(selectedEventIds, update);
  }

  render() {
    const { filter } = this.state;
    const { events, eventListRequest } = this.props;
    const {
      separateWeekRows,
      showHostModal,
      eventToAddHostsTo,
      isSidebarExpanded,
      selectedEventIds,
      eventForPaymentModal,
      showPaymentModal,
      showQuickCreateModal,
    } = this.state;
    return (
      <div className="page-container">
        <Header currentPage="/events" />
        <div className="container-fluid position-relative">
          <div className={`content-box events-container ${isSidebarExpanded ? 'sidebar-expanded' : ''}`}>
            <div className="row">
              <div className="col text-right">
                <Button className="btn btn-primary" onClick={this.onQuickCreate}>
                  <PlusCircle /> Quick Create
                </Button>{' '}
                <Link to="/events/create" className="btn btn-success">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <EventsFilter
              filter={filter}
              loading={eventListRequest.working}
              onUpdateFilter={this.setFilter}
              separateWeekRows={separateWeekRows}
              onToggleWeekSeparator={this.toggleWeekSeparator}
            />
            <EventsTable
              list={events.data}
              separateWeekRows={separateWeekRows}
              pagination={events.metadata}
              isLoading={eventListRequest.working}
              isSuccess={eventListRequest.success}
              isFailed={eventListRequest.failed}
              onUpdateFilter={this.setFilter}
              filter={filter}
              selectedEventIds={selectedEventIds}
              onPublishEvent={this.publishEvent}
              onApproveEvent={this.approveEvent}
              onSendInvoice={this.sendInvoice}
              onCloneEvent={this.cloneEvent}
              onDeleteEvent={this.deleteEvent}
              onCreateGoogleDoc={this.createGoogleSheet}
              onRemoveHost={this.removeHost}
              onAddHost={this.addHost}
              onShowPaymentModal={this.managePayment}
              onSearch={(val) => this.handleChange('search', val)}
              onImportGoogle={this.importGoogle}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
              onUpdateSelectedEvents={this.updateSelectedEvents}
            />
            <AddHostModal
              onCancel={this.onCancelHostModal}
              onComplete={this.onCompleteAddHost}
              initialHostsList={eventToAddHostsTo && eventToAddHostsTo.hosts}
              show={showHostModal}
            />
            <PaymentModal
              onCancel={this.onCancelPaymentModal}
              onComplete={this.onCompletePaymentModal}
              eventId={eventForPaymentModal?.id}
              show={showPaymentModal}
            />
            <QuickCreateModal onCancel={this.onCancelQuickCreateModal} show={showQuickCreateModal} />
          </div>
          <EventsSidebar isExpanded={isSidebarExpanded} toggleSidebar={this.toggleSidebar} updateEvents={this.batchUpdateEvents} />
        </div>
      </div>
    );
  }
}

EventsPage.propTypes = {
  getAllEvents: PropTypes.func.isRequired,
  submitCreateEvent: PropTypes.func.isRequired,
  submitEditEvent: PropTypes.func.isRequired,
  submitDeleteEvent: PropTypes.func.isRequired,
  triggerGoogleImport: PropTypes.func.isRequired,
  eventListRequest: PropTypes.objectOf(PropTypes.shape),
  eventBatchUpdateRequest: PropTypes.objectOf(PropTypes.shape),
  events: PropTypes.objectOf(PropTypes.shape),
  needRefreshEventList: PropTypes.bool,
  submitBatchUpdateEvents: PropTypes.func.isRequired,

  // getAllAccounts: PropTypes.func.isRequired,
  // accounts: PropTypes.objectOf(PropTypes.shape),
  // getAllLocations: PropTypes.func.isRequired,
  // locations: PropTypes.objectOf(PropTypes.shape),
};
EventsPage.defaultProps = {
  eventListRequest: {},
  eventBatchUpdateRequest: {},
  // accounts: {},
  // locations: {},
  needRefreshEventList: false,
  events: {
    data: [],
    metadata: {
      pageSize: 20,
      pageNumber: 1,
      totalCount: 1,
    },
  },
};

const mapStateToProps = (state) => extend({}, state.events, state.locations, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, LocationActions, AccountActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventsPage);
