import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import GenericActions from '../../actions/genericActions';
import actions from './actions';

class AccountSelector extends Component {
  componentDidMount() {
    const { getAllAccounts } = this.props;
    getAllAccounts({ pageSize: 50 });
  }

  onChange = (values) => {
    const { onUpdate } = this.props;
    onUpdate(values);
  };

  onInputChange = (searchString, action) => {
    const { getAllAccounts } = this.props;
    if (action.action === 'input-change') getAllAccounts({ name: searchString, pageSize: 50 });
  };

  render() {
    const { accounts, selectedAccount, accountListRequest } = this.props;

    return (
      <Select
        classNamePrefix="select"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        isClearable
        isLoading={accountListRequest.working}
        value={selectedAccount}
        onChange={this.onChange}
        options={accounts.data}
        onInputChange={this.onInputChange}
      />
    );
  }
}

AccountSelector.propTypes = {
  getAllAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape),
  accountListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  selectedAccount: PropTypes.objectOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
};
AccountSelector.defaultProps = {
  selectedAccount: {},
  accounts: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(AccountSelector);
