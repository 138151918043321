import { extend } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
const UserSelector = ({ users, selectedUser, userListRequest, onUpdate, getAllUsers, groups }) => {
  // componentDidMount() {
  //   const { getAllUsers } = this.props;
  //   getAllUsers({ pageSize: 50, groups: [GROUPS.OWNER] });
  // }

  const [filter, setFilter] = useState({
    pageSize: 50,
    groups,
  });

  useEffect(() => {
    getAllUsers(filter);
  }, []);

  const onChange = (values) => {
    onUpdate(values);
  };

  const onInputChange = (searchString, action) => {
    if (action.action === 'input-change') {
      const f = { ...filter };
      if (searchString != null) {
        f.search = searchString;
      }
      setFilter(f);
    }
  };
  useEffect(() => {
    getAllUsers(filter);
  }, [filter]);

  return (
    <Select
      placeholder="Search for a User"
      classNamePrefix="select"
      getOptionValue={(opt) => opt.id}
      getOptionLabel={(opt) => opt.username}
      isClearable
      isLoading={userListRequest.working}
      value={selectedUser}
      onChange={onChange}
      options={users.data || []}
      onInputChange={onInputChange}
    />
  );
};

UserSelector.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  users: PropTypes.objectOf(PropTypes.shape),
  userListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  selectedUser: PropTypes.objectOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.number),
};
UserSelector.defaultProps = {
  selectedUser: {},
  users: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.users, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(UserSelector);
