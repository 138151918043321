import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
// import ReactJson from 'react-json-view';
import moment from 'moment';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';

class PaymentsDetailPage extends Component {
  componentDidMount() {
    this.updateList();
  }

  updateList() {
    const { getPaymentDetails, routeParams } = this.props;
    getPaymentDetails(routeParams.id);
  }

  render() {
    const { paymentDetails } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/payments" />
        <div className="container-fluid">
          <div className="content-box">
            <h3>Payment Details</h3>
            <div>{moment(paymentDetails.created).format('ddd, MMM Do, YYYY h:mma')}</div>
            <div>{`Name: ${paymentDetails.name}`}</div>
            <div>{`Email: ${paymentDetails.email}`}</div>
            <div>{`Amount: ${paymentDetails.amount}`}</div>
            <div>{`Invoices: ${paymentDetails.invoices}`}</div>
            <div>{`Comments: ${paymentDetails.comments}`}</div>
            <br />
            <Link to="/payments">Back to List</Link>
            {/* <ReactJson src={paymentDetails.charge} /> */}
            <br />
            <h3>Token Details</h3>
            <div>{paymentDetails.token}</div>
            <h3>Charge Details</h3>
            <div>{paymentDetails.charge}</div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentsDetailPage.propTypes = {
  getPaymentDetails: PropTypes.func.isRequired,
  paymentDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
PaymentsDetailPage.defaultProps = {
  paymentDetails: {},
};

const mapStateToProps = (state) => extend({}, state.payments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(PaymentsDetailPage);
