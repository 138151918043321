import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'reactstrap';
import './dashboard.css';

const PendingLeadsTable = ({ leads }) => (
  <div>
    <Table striped size="sm" hover responsive>
      <thead>
        <tr>
          <th>Event</th>
          <th>Name</th>
          <th>Teams</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {leads.data &&
          leads.data.length > 0 &&
          leads.data.map((lead) => (
            <tr key={lead.id}>
              <td>
                <Link to={`/leads/${lead.id}`}>{lead.eventName}</Link>
                <div className="event-date">{`${moment(lead.created).format('MM/DD/YY')} - ${lead.eventType}`}</div>
              </td>
              <td>
                {lead.name}
                <div className="event-date">{lead.email}</div>
              </td>
              <td>{lead.numberOfTeams}</td>
              <td>{lead.status}</td>
            </tr>
          ))}
        {leads.data && leads.data.length <= 0 && (
          <tr>
            <td colSpan="6">No pending leads</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

PendingLeadsTable.propTypes = {
  leads: PropTypes.objectOf(PropTypes.shape).isRequired,
};
PendingLeadsTable.defautProps = {
  leads: { data: [] },
};

export default PendingLeadsTable;
