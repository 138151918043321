import React from 'react';
import PropTypes from 'prop-types';
import { STRIPE_DASHBOARD_URL } from '../../config';

const StripeLink = ({ type, id, linkText }) => {
  let obj;
  switch (type) {
    case 'invoice':
      obj = (
        <a href={`${STRIPE_DASHBOARD_URL}/invoices/${id}`} rel="noreferrer noopener" target="_blank">
          {linkText || id}
        </a>
      );
      break;
    case 'customer':
      obj = (
        <a href={`${STRIPE_DASHBOARD_URL}/customers/${id}`} rel="noreferrer noopener" target="_blank">
          {linkText || id}
        </a>
      );
      break;
    case 'invoice-item':
      obj = (
        <a href={`${STRIPE_DASHBOARD_URL}/customers/${id}/upcoming_invoice`} rel="noreferrer noopener" target="_blank">
          {linkText || id}
        </a>
      );
      break;
    default:
      obj = <span>Invalid Stripe Link</span>;
  }
  return obj;
};
StripeLink.defaultProps = {
  linkText: null,
};
StripeLink.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default StripeLink;
