import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { Edit } from 'react-feather';
import Select from 'react-select';
import { EVENT_STATUS } from '../../config';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import useEvents from '../../hooks/useEvents';
import { PAYMENT_TYPE } from '../../utils';
import API from '../../utils/api/billingAccounts';
import PaymentModal from '../Events/PaymentModal';
import '../Events/eventsTableRow.css';
import PaymentMethodIcon from '../PaymentMethodIcon';
import StripeLink from '../StripeLink';
import './styles.css';
import LocationSelector from '../../containers/Locations/LocationSelector';

const LIMITS = [20, 50, 100];

const AGES_IN_DAYS = [
  { label: 'At Least 1 week', value: 7 },
  { label: 'At Least 2 weeks', value: 14 },
  { label: 'At Least 1 month', value: 30 },
  { label: 'At Least 2 months', value: 60 },
  { label: 'At Least 3 months', value: 90 },
];

const filterDefaults = {
  statuses: [EVENT_STATUS.COMPLETE],
  isInvoicePaid: false,
  ageInDays: 30,
};

const InvoicesTable = () => {
  const [filterAccount, setFilterAccount] = useState();
  const [filterLocation, setFilterLocation] = useState();
  // const [filterAge, setFilterAge] = useState(null);
  const [eventForPaymentModal, setEventForPaymentModal] = useState();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [filterObject, setFilterObject] = useState(filterDefaults);

  // useEffect(() => {
  //   const obj = qs.parse(window.location.search, { arrayFormat: 'bracket' });
  //   console.log('initial load', obj);
  //   setFilterObject(obj);
  // }, []);

  const { events, pagination, error, mutate, setOrderByDirState, updateFilterState, setPageSizeState, setPageIndexState } = useEvents(filterObject);

  const setFilterValue = (key, value) => {
    // console.log('set Filter value', filterObject, key, value);
    const filters = { ...filterObject, [key]: value };
    // console.log('updated filters', filters);
    setFilterObject(filters);
  };
  useEffect(() => {
    // console.log('account change', filterAccount);
    setFilterValue('accountId', filterAccount?.id || null);
  }, [filterAccount]);

  useEffect(() => {
    // console.log('account change', filterAccount);
    setFilterValue('locationId', filterLocation?.id || null);
  }, [filterLocation]);

  useEffect(() => {
    console.log('filterObject Change', filterObject);
    setOrderByDirState('ASC');
    updateFilterState(filterObject);
    // setBrowserURL(filterObject);
  }, [filterObject]);

  const sendInvoice = async (event) => {
    // console.log('send invoice', );
    // if (!event.stripeInvoiceItemId) {
    // }
    await API.sendBillingAccountInvoices(event.billingAccount?.id);
    mutate();
  };

  const managePayment = (event) => {
    setEventForPaymentModal(event);
    setShowPaymentModal(true);
  };

  const onCancelPaymentModal = () => {
    setShowPaymentModal(false);
    mutate();
  };

  const onCompletePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return (
    <div>
      {error && <div className="alert alert-danger">{error.messages || JSON.stringify(error)}</div>}
      <div className="row">
        <div className="col-md-3">
          <label>Account Filter</label>
          <AccountSelector selectedAccount={filterAccount} onUpdate={(acct) => setFilterAccount(acct)} />
        </div>
        <div className="col-md-3">
          <label>Location Filter</label>
          <LocationSelector selectedLocation={filterLocation} onUpdate={(l) => setFilterLocation(l)} />
        </div>
        <div className="col-md-3">
          <label>Days Past Due</label>
          <Select
            options={AGES_IN_DAYS}
            name="age"
            value={AGES_IN_DAYS.filter(({ value }) => value === filterObject.ageInDays)}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ value }) => value}
            onChange={({ value }) => setFilterValue('ageInDays', value)}
            classNamePrefix="select"
          />
        </div>
      </div>
      <br />
      <div className="row">
        <Table striped hover responsive size="sm" className="element-list">
          <thead>
            <tr>
              <th>Event</th>
              <th>Cost</th>
              <th>Days Past Due</th>
              <th>Payment Method</th>
              <th>Billing Account</th>
              <th>Invoice</th>
              <th>Sent on</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {events && events.length <= 0 && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={20}>
                  No invoices match search criteria.
                </td>
              </tr>
            )}
            {events &&
              events.length > 0 &&
              events.map((event) => (
                <tr
                  key={event.id}
                  className={classNames(
                    'status',
                    event.status === EVENT_STATUS.DRAFT ? 'draft' : '',
                    event.status === EVENT_STATUS.READY ? 'ready' : '',
                    event.status === EVENT_STATUS.PENDING ? 'pending' : '',
                    event.status === EVENT_STATUS.COMPLETE ? 'complete' : '',
                    event.status === EVENT_STATUS.CANCELED ? 'canceled' : '',
                  )}
                >
                  <td>
                    <Link to={`/events/${event.id}`} className="action">
                      {event.name}
                    </Link>
                    <br />
                    <span className="small">{moment(event.dateTimeStart).format('ddd, M/D/YY h:mma')}</span>
                  </td>
                  <td className="number">${event.eventGross}</td>
                  <td
                    className={classNames(
                      event.eventAgeInDays <= 0 && 'gray',
                      event.eventAgeInDays > 0 && event.eventAgeInDays <= 30 && 'black',
                      event.eventAgeInDays > 30 && event.eventAgeInDays <= 60 && 'orange',
                      event.eventAgeInDays > 60 && 'red',
                    )}
                  >
                    {event.eventAgeInDays} days
                  </td>
                  <td>{event.paymentMethod}</td>
                  {event.paymentMethod === PAYMENT_TYPE.ACCOUNT && (
                    <>
                      <td>
                        <Link to={`/billing-accounts/${event.billingAccount?.id}`} title="View Billing Account">
                          {event.billingAccount?.name}
                        </Link>
                      </td>

                      <td>
                        {!event.stripeInvoiceId && <Button onClick={() => sendInvoice(event)}>Send Invoice</Button>}
                        {event.stripeInvoiceId && (
                          <StripeLink
                            type="invoice"
                            id={event.stripeInvoiceId}
                            linkText={<span title={event.stripeInvoiceId}>{event.stripeInvoiceNum}</span>}
                          />
                        )}
                      </td>
                      <td>{event.stripeInvoiceSentOn && moment(event.stripeInvoiceSentOn).format('ddd, M/D/YY h:mma')}</td>
                    </>
                  )}
                  {event.paymentMethod !== PAYMENT_TYPE.ACCOUNT && <td colSpan={3}></td>}
                  {/* <td>{event.stripeInvoicePaidOn && moment(event.stripeInvoicePaidOn).format('ddd, M/D/YY h:mma')}</td> */}
                  <td>
                    <PaymentMethodIcon event={event} onClickPayment={() => managePayment(event)} />
                    &nbsp;
                    <Link to={`/events/${event?.id}/edit`} title="Edit this Event" className="action">
                      <Edit color="#999" size={20} />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="row query-footer">
        <div className="page-size form-inline col">
          <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => setPageSizeState(e.target.value)}>
            {LIMITS.map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>{' '}
          {'per page'}
        </div>
        <div className="col col-8">
          <Pagination
            onChange={setPageIndexState}
            showLessItems
            current={pagination.pageNumber}
            total={pagination.totalCount}
            pageSize={pagination.pageSize || 20}
          />
        </div>
        <div className="col text-right">{`Total Count: ${pagination.totalCount}`}</div>
      </div>
      <PaymentModal onCancel={onCancelPaymentModal} onComplete={onCompletePaymentModal} eventId={eventForPaymentModal?.id} show={showPaymentModal} />
    </div>
  );
};

InvoicesTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  initialFilterObject: PropTypes.objectOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
  setBrowserURL: PropTypes.func,
};
InvoicesTable.defaultProps = {
  list: [],
  isFailed: false,
  errorMessage: '',
};

export default InvoicesTable;
