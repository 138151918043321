import { ErrorMessage, Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Yup from 'yup';

import { find, uniqBy, xorBy } from 'lodash';
import { CompactPicker } from 'react-color';
import { Trash } from 'react-feather';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import groups from '../../data/groups.json';
import EventHostAvatar from '../Events/Widgets/EventHostAvatar';
import Spinner from '../Spinner';

const UserEditForm = ({
  values,
  entity,
  // touched,
  // errors,
  dirty,
  isSubmitting,
  // handleChange,
  setFieldValue,
  // handleBlur,
  handleSubmit,

  loading,
  success,
  failed,
  errorMessage,
  // resetForm,
  // userDetails,
  // handleReset,
}) => {
  // componentDidMount() {}

  // componentDidUpdate(prevProps) {
  //   const { resetForm, success } = this.props;
  //   if (prevProps.success === false && success === true) {
  //     resetForm();
  //   }
  // }

  // const doSubmit = (v) => {
  //   handleSubmit(v);
  //   resetForm();
  // };

  const toggleGroup = (group, exists) => {
    // console.log("Toggle Group", values.groups, group, exists);
    if (exists) setFieldValue('groups', xorBy(values.groups, [group], 'id'));
    else setFieldValue('groups', uniqBy([...values.groups, group], 'id'));
  };

  const handleChangeComplete = (color) => {
    setFieldValue('color', color.hex);
  };

  const addAccount = (a) => {
    console.log('entity', entity);
    const accountExists = entity.accounts?.find((acct) => a.id === acct.id);
    if (accountExists) {
      return false;
    }

    setFieldValue('accounts', [a, ...values.accounts]);
    return true;
  };
  const removeAccount = (acct) => {
    const accountExists = values.accounts?.find((a) => acct.id === a.id);
    if (accountExists) {
      const newAccounts = values.accounts.filter((o) => o.id !== acct.id);
      setFieldValue('accounts', newAccounts);
      console.log('removing account', newAccounts);
      return true;
    }
    return false;
  };

  // const _handleSelect = (selectChoice) => {
  //   setFieldValue('imaginaryThingId', selectChoice.value);
  // };
  // update = (e) => {
  // console.log(e);
  // };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {success && <div className="alert alert-success">User Edited</div>}
        {failed && <div className="alert alert-danger">{errorMessage}</div>}
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="username" className="required">
                Email Address
              </label>
              <Field type="email" name="username" className="form-control" />
              <ErrorMessage name="username" component="div" className="alert alert-danger" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstName">First Name</label>
              <Field id="firstName" type="text" name="firstName" className="form-control" />
              <ErrorMessage name="firstName" component="div" className="alert alert-danger" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastName">Last Name</label>
              <Field id="lastName" type="text" name="lastName" className="form-control" />
              <ErrorMessage name="lastName" component="div" className="alert alert-danger" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="nickname">Nickname</label>
              <Field id="nickname" type="text" name="nickname" className="form-control" />
              <ErrorMessage name="nickname" component="div" className="alert alert-danger" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="googleFolderId">Google Folder Id</label>
              <Field id="googleFolderId" type="text" name="googleFolderId" className="form-control" />
              <ErrorMessage name="googleFolderId" component="div" className="alert alert-danger" />
            </div>
            <div className="form-group col-md-6">
              Example: <EventHostAvatar host={{ nickname: values.nickname, color: values.color }} />
              <br />
              {/* <div>Values: {values.color}</div> */}
              <CompactPicker color={values.color} onChangeComplete={(c) => handleChangeComplete(c)} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <h3>Accounts</h3>
              <AccountSelector onUpdate={(a) => addAccount(a)} />
              {values.accounts &&
                values.accounts.map((a) => (
                  <div key={a.id}>
                    {a.name}{' '}
                    <span title="Remove this account" onClick={() => removeAccount(a)}>
                      <Trash />
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <label>Groups</label>{' '}
          {groups.map((group) => (
            <div key={group.id}>
              <Button
                disabled={loading}
                color={find(values.groups, ['id', group.id]) ? 'info' : 'link'}
                size="sm"
                onClick={() => toggleGroup(group, find(values.groups, ['id', group.id]))}
              >
                {group.name}
              </Button>{' '}
            </div>
          ))}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <Link className="btn btn-default" to="/users">
            Cancel
          </Link>{' '}
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <Button type="submit" color="primary" disabled={!dirty}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

UserEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  entity: PropTypes.objectOf(PropTypes.shape).isRequired,
  // userDetails: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
UserEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  dirty: false,
  errorMessage: '',
  // routeParams: {},
  // userDetails: {},

  values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity }) => ({
    username: entity.username || '',
    firstName: entity.firstName || '',
    lastName: entity.lastName || '',
    color: entity.color || '',
    nickname: entity.nickname || '',
    googleFolderId: entity.googleFolderId || '',
    groups: entity.groups || [],
    accounts: entity.accounts || [],
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string().email('Please enter a valid email address').required('Please enter an email address'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.entity.id, values);
    // console.log('Submit', values);
    setSubmitting(false);
  },
  displayName: 'User Edit Form',
})(UserEditForm);
