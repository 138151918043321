import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import accountActions from '../Accounts/actions';
import locationActions from '../Locations/actions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/Events/EditForm';

class EventEditPage extends Component {
  componentDidMount() {
    const { getEventDetails, routeParams } = this.props;
    getEventDetails(routeParams.id);
  }

  render() {
    const { submitEditEvent, eventDetails, eventDetailRequest, eventEditRequest } = this.props;
    return (
      <div className="event-page page-container">
        <Header currentPage="/event" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit an Event</h1>
            {eventDetailRequest.working && <Spinner />}
            {eventDetailRequest.success && (
              <EditForm
                entity={eventDetails}
                onSubmit={submitEditEvent}
                loading={eventEditRequest.working}
                success={eventEditRequest.success}
                failed={eventEditRequest.failed}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

EventEditPage.propTypes = {
  submitEditEvent: PropTypes.func.isRequired,
  getEventDetails: PropTypes.func.isRequired,
  eventDetailRequest: PropTypes.objectOf(PropTypes.shape),
  eventEditRequest: PropTypes.objectOf(PropTypes.shape),
  eventDetails: PropTypes.objectOf(PropTypes.shape),
  // getAllAccounts: PropTypes.func.isRequired,
  // getAllLocations: PropTypes.func.isRequired,
  // accounts: PropTypes.objectOf(PropTypes.shape),
  // locations: PropTypes.objectOf(PropTypes.shape),
  // getAccountDetails: PropTypes.func.isRequired,
  // accountDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
EventEditPage.defaultProps = {
  // accounts: {},
  // locations: {},
  // accountDetails: {},
  eventDetails: {},
  eventEditRequest: {},
  eventDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.events, state.accounts, state.locations, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, accountActions, locationActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventEditPage);
