import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Get JWT from cookies
 * @returns {string} the token
 */
export const getJWT = () => cookies.get('adminPageToken');

/**
 * Save JWT in cookies
 * @param {string} token - The token
 */
export const setJWT = (token) => cookies.set('adminPageToken', token, { path: '/' });

/**
 * Remove JWT from cookies
 */
export const removeJWT = () => cookies.remove('adminPageToken', { path: '/' });

/**
 * Save email in cookies
 * @param {string} email - The User's email
 */
export const setUserEmail = (email) => cookies.set('un', email, { path: '/' });

/**
 * Get email from cookies
 * @returns {string} the email
 */
export const getUserEmail = () => cookies.get('un');

/**
 * Remove Email from cookies
 */
export const removeUserEmail = () => cookies.remove('un', { path: '/' });

/**
 * Reject promise with error
 * @param {string} reason - the rejection reason
 */
export const reject = (reason) => Promise.reject(new Error(reason));

// export default {
//   // reject,
//   getJWT,
//   setJWT,
//   removeJWT,
//   setUserEmail,
//   getUserEmail,
//   removeUserEmail,
// };
