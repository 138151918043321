import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import formActions from '../../actions/formActions';
import Header from '../Header';
import UpdatePasswordForm from '../../components/Forms/UpdatePasswordForm';

const UpdatePasswordPage = ({ authenticated, passwordUpdateRequest, submitUpdatePassword, routeParams }) => {
  if (authenticated) window.location.href = '/';
  return (
    <div className="reset-password-page page-container">
      <Header currentPage="/login" />
      <h1 className="page-title">I Forgot My Password!</h1>
      <div className="container-fluid">
        <div className="content-box">
          <div className="row register-box">
            <div className="col-md-6 col-xs-12">
              <h2 className="section-title">Update your password</h2>
              <UpdatePasswordForm
                authenticated={authenticated}
                loading={passwordUpdateRequest.working}
                failed={passwordUpdateRequest.failed}
                success={passwordUpdateRequest.success}
                resetCode={routeParams.resetCode}
                onSubmit={submitUpdatePassword}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdatePasswordPage.propTypes = {
  submitUpdatePassword: PropTypes.func.isRequired,
  passwordUpdateRequest: PropTypes.objectOf(PropTypes.shape),
  authenticated: PropTypes.bool,
  routeParams: PropTypes.objectOf(PropTypes.shape),
};
UpdatePasswordPage.defaultProps = {
  passwordUpdateRequest: {},
  authenticated: false,
  routeParams: {},
};

const mapStateToProps = (state) => extend({}, state.forms, state.global);

const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, formActions, GenericActions), dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(UpdatePasswordPage);
