import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { extend } from 'lodash';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { PlusCircle } from 'react-feather';
import GenericActions from '../../actions/genericActions';
import Header from '../Header';

const ContactHomePage = () => (
  <div className="page-container">
    <Header currentPage="/contact" />
    <div className="container-fluid">
      <div className="content-box">
        <div className="row">
          <div className="col">
            <h1>Host Applications</h1>
          </div>
        </div>
        <div>
          <Link to="/contact/applications">Host Applications</Link>
        </div>
        <div>
          <Link to="/payments">Payments</Link>
        </div>
      </div>
    </div>
  </div>
);

// ContactHomePage.propTypes = {
//   getAllLeads: PropTypes.func.isRequired,
//   leads: PropTypes.objectOf(PropTypes.shape),
//   leadsListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
// };
// ContactHomePage.defaultProps = {
//   leads: { data: [], metadata: { pageNum: 0, pageSize: 20, totalCount: 0 } },
// };

const mapStateToProps = (state) => extend({}, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(ContactHomePage);
