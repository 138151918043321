import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getHosts(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.HOSTS}?${qs.stringify(query, { arrayFormat: 'bracket' })}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create host
 * @param {object} hostData - the host data
 */
function createHost(hostData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.HOSTS}`, hostData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get host by ID
 * @param {string} id - the host ID
 */
function getHost(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.HOSTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update host
 * @param {string} id - the host ID
 * @param {object} newData - the host data
 */
function updateHost(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.HOSTS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Delete host
 * @param {string} id - the host ID
 */
function deleteHost(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.HOSTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getHosts,
  getHost,
  createHost,
  updateHost,
  deleteHost,
};
