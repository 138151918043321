const defaultState = {
  // Form Request Status
  payments: { data: [], metadata: {} },
  paymentDetails: {},
  paymentListRequest: {},
  paymentDetailRequest: {},
  paymentCreateRequest: {},
  paymentEditRequest: {},
  paymentDeleteRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'PAYMENTS_LIST_STARTED':
      return { ...state, paymentListRequest: { working: true } };
    case 'PAYMENTS_LIST_FAILED':
      return { ...state, paymentListRequest: { failed: true } };
    case 'PAYMENTS_LIST_SUCCESS':
      return {
        ...state,
        payments: action.payload,
        paymentListRequest: { success: true },
      };

    case 'PAYMENTS_DETAIL_STARTED':
      return { ...state, paymentDetailRequest: { working: true } };
    case 'PAYMENTS_DETAIL_FAILED':
      return { ...state, paymentDetailRequest: { failed: true } };
    case 'PAYMENTS_DETAIL_SUCCESS':
      return {
        ...state,
        paymentDetails: action.payload,
        paymentDetailRequest: { success: true },
      };

    case 'PAYMENTS_CREATE_STARTED':
      return { ...state, paymentCreateRequest: { working: true } };
    case 'PAYMENTS_CREATE_FAILED':
      return { ...state, paymentCreateRequest: { failed: true } };
    case 'PAYMENTS_CREATE_SUCCESS':
      return {
        ...state,
        paymentDetails: action.payload,
        paymentCreateRequest: { success: true },
      };

    case 'PAYMENTS_EDIT_STARTED':
      return { ...state, paymentEditRequest: { working: true } };
    case 'PAYMENTS_EDIT_FAILED':
      return { ...state, paymentEditRequest: { failed: true } };
    case 'PAYMENTS_EDIT_SUCCESS':
      return {
        ...state,
        paymentDetails: action.payload,
        paymentEditRequest: { success: true },
      };

    default:
      return state;
  }
};
