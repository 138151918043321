import { map } from 'lodash';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { ENDPOINTS } from '../../config';
import useListAPIHook from '../../hooks/useListAPIHook';
import Spinner from '../Spinner';
import StripeLink from '../StripeLink';
import moment from 'moment';
import EventStatus from '../Events/Widgets/EventStatus';

const LIMITS = [20, 50, 100];
const AccountEventsTable = ({ accountId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, loading, error, pagination } = useListAPIHook(
    accountId
      ? `${ENDPOINTS.EVENTS}/?accountId=${accountId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=dateTimeStart&orderDir=DESC`
      : null,
  );

  return (
    <div>
      {/* <input type="text" placeholder="Search..." className="form-control" onChange={(e) => onSearch(e.target.value)} /> */}
      <Table striped hover responsive size="sm" className="element-list">
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Amount</th>
            <th>Event Status</th>
            <th>Payment Method</th>
            <th>Invoice</th>
            <th>Payment Status</th>
          </tr>
        </thead>
        <tbody>
          {error && (
            <tr>
              <td colSpan="10" className="danger">
                Error: {error}
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan="10">
                <Spinner />
              </td>
            </tr>
          )}
          {data &&
            map(data, (item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`/events/${item.id}`} className="action">
                    {item.name}
                  </Link>
                  <br />
                  <div className="small">{moment(item.dateTimeStart).format('ddd, MMM Do, YYYY h:mma')}</div>
                </td>

                <td>{`$${item.eventGross}`}</td>
                <td>
                  <EventStatus event={item} />
                </td>
                <td>{item.paymentMethod}</td>
                <td>
                  {item.stripeInvoiceId && (
                    <StripeLink
                      type="invoice"
                      id={item.stripeInvoiceId}
                      linkText={<span title={item.stripeInvoiceId}>{item.stripeInvoiceNum}</span>}
                    />
                  )}
                </td>
                <td>{item.isInvoicePaid ? 'Yes' : 'No'}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="row query-footer">
        <div className="page-size form-inline col-sm-6">
          <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => setPageSize(e.target.value)}>
            {map(LIMITS, (opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
            <option value={2}>2</option>
          </select>
          {' per page'}
        </div>
        <div className="col-sm-6 text-right">
          <Pagination
            onChange={(n) => setPageNumber(n)}
            showLessItems
            current={pagination.pageNumber}
            total={pagination.totalCount}
            pageSize={pagination.pageSize || 20}
          />
        </div>
      </div>
    </div>
  );
};

AccountEventsTable.propTypes = {
  accountId: PropTypes.number,
};
AccountEventsTable.defaultProps = {
  accountId: null,
};

export default AccountEventsTable;
