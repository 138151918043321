import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = (props) => {
  const { authenticated, location, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(componentProps) =>
        authenticated ? <Component {...componentProps} /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    />
  );
};

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.shape),
  component: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  authenticated: false,
  location: null,
};

const mapStateToProps = ({ global }) => ({
  authenticated: global.authenticated,
  // state: global
});

export default connect(mapStateToProps)(PrivateRoute);
