import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getEventTemplates(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.EVENT_TEMPLATES}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create eventTemplate
 * @param {object} eventTemplateData - the eventTemplate data
 */
function createEventTemplate(eventTemplateData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.EVENT_TEMPLATES}`, eventTemplateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get eventTemplate by ID
 * @param {string} id - the eventTemplate ID
 */
function getEventTemplate(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.EVENT_TEMPLATES}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update eventTemplate
 * @param {string} id - the eventTemplate ID
 * @param {object} newData - the eventTemplate data
 */
function updateEventTemplate(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.EVENT_TEMPLATES}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Delete eventTemplate
 * @param {string} id - the eventTemplate ID
 */
function deleteEventTemplate(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.EVENT_TEMPLATES}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getEventTemplates,
  getEventTemplate,
  createEventTemplate,
  updateEventTemplate,
  deleteEventTemplate,
};
