import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Spinner from '../../Spinner';
import Validators from '../../../utils/validators';
import '../style.css';
import './style.css';

const UpdatePasswordForm = (props) => {
  const { authenticated, values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, loading, success, failed, errorMessage } =
    props;

  if (success && !authenticated) {
    return (
      <div className="alert alert-success">
        Your password has been updated!
        <br />
        <Link to="/login">Log in</Link>
        {' to your account.'}
      </div>
    );
  }

  return (
    <form className="row form-block" onSubmit={handleSubmit}>
      {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      <div className="col-xs-12">
        <label htmlFor="pwd">
          Password
          <input
            id="pwd"
            name="pwd"
            type="password"
            className={`${errors.pwd && touched.pwd && 'error'}`}
            value={values.pwd}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        {errors.pwd && touched.pwd && <div className="alert alert-danger">{errors.pwd}</div>}
      </div>
      <div className="col-xs-12">
        <label htmlFor="verifyPwd">
          Confirm Password
          <input
            type="password"
            id="verifyPwd"
            className={`${errors.verifyPwd && touched.verifyPwd && 'error'}`}
            value={values.verifyPwd}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        {errors.verifyPwd && touched.verifyPwd && <div className="alert alert-danger">{errors.verifyPwd}</div>}
      </div>

      <div className="col-xs-12">
        {isSubmitting || loading ? (
          <Spinner />
        ) : (
          <button type="submit" className="btn btn-primary btn-block">
            Update Password
          </button>
        )}
      </div>
    </form>
  );
};

UpdatePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types

  authenticated: PropTypes.bool,

  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,

  errorMessage: PropTypes.string,
  resetCode: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types

  // --- values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};

UpdatePasswordForm.defaultProps = {
  authenticated: false,
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // --- values added by Formik
  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};

Yup.addMethod(Yup.string, 'matchesField', Validators.matchesField);

export default withFormik({
  mapPropsToValues: () => ({
    pwd: '',
    verifyPwd: '',
  }),

  validationSchema: Yup.object().shape({
    pwd: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(30, 'Password cannot be more than 30 characters')
      .required('Password is Required'),
    verifyPwd: Yup.string().matchesField(Yup.ref('pwd'), 'Passwords do not match').required('Please confirm your password'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values.pwd, props.resetCode); // omit(values, ['recaptcha']));
    setSubmitting(false);
  },
  displayName: 'Update Password Form',
})(UpdatePasswordForm);
