import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getLeads(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.LEADS}?${qs.stringify(query, { arrayFormat: 'bracket' })}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get lead by ID
 * @param {string} id - the lead ID
 */
function getLead(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.LEADS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update lead
 * @param {string} id - the lead ID
 * @param {object} newData - the account data
 */
function updateLead(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.LEADS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create event
 * @param {string} leadId - the lead ID
 * @param {object} eventData - the event data
 */
function createLeadMessage(leadId, leadData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.LEADS}/${leadId}/messages`, leadData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create Lead message
 * @param {string} leadId - the lead ID
 * @param {string} messageId - the message ID
 * @param {object} newData - the account data
 */
function updateLeadMessage(leadId, messageId, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.LEADS}/${leadId}/messages/${messageId}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create Lead message
 * @param {string} leadId - the lead ID
 * @param {string} messageId - the message ID
 */
function deleteLeadMessage(leadId, messageId) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.LEADS}/${leadId}/messages/${messageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getLeads,
  getLead,
  updateLead,
  // getLeadMessages,
  // getLeadMessage,
  createLeadMessage,
  updateLeadMessage,
  deleteLeadMessage,
};
