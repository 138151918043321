/**
 * Custom validator functions
 */

/**
 * Custom Yup method to compare 2 fields
 */
function matchesField(ref, msg) {
  return this.test({
    name: 'matchesField',
    exclusive: false,
    message: msg || '{path} must be the same as {reference}',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

// /**
//  * Live check team username
//  */
// function liveCheckTeamUsername(value) {
//   return new Promise((resolve) => Api.validateTeamUsername(value, (available) => resolve(available)));
// }

// /**
//  * Live check email availability
//  */
// function liveCheckEmailAvailability(value) {
//   return new Promise((resolve) => Api.isEmailAvailable(value, (available) => resolve(available)));
// }

export default {
  matchesField,
  // liveCheckTeamUsername,
  // liveCheckEmailAvailability,
};
