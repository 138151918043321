import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import LeadsTable from '../../components/Leads/LeadsTable';
import LeadsFilter from '../../components/Leads/LeadsFilter';

class LeadsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
      },
    };
  }

  // componentDidMount() {
  //   this.updateList();
  // }

  setFilter = (filterObj) => {
    this.setState({ filter: filterObj }, this.updateList);
  };

  handleChange = (property, value) => {
    const { filter } = this.state;
    // console.log("Set Property", property, value);
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  updateList() {
    const { getAllLeads } = this.props;
    const { filter } = this.state;
    // console.log(JSON.stringify(filter));
    getAllLeads(filter);
  }

  render() {
    const { leads, leadsListRequest } = this.props;
    const { filter } = this.state;
    return (
      <div className="page-container">
        <Header currentPage="/contact" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>Requests for Quote</h1>
              </div>
            </div>
            <LeadsFilter onUpdateFilter={this.setFilter} filter={filter} />
            <LeadsTable
              list={leads.data}
              pagination={leads.metadata}
              isLoading={leadsListRequest.working}
              isSuccess={leadsListRequest.success}
              isFailed={leadsListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

LeadsPage.propTypes = {
  getAllLeads: PropTypes.func.isRequired,
  leads: PropTypes.objectOf(PropTypes.shape),
  leadsListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
LeadsPage.defaultProps = {
  leads: { data: [], metadata: { pageNum: 0, pageSize: 20, totalCount: 0 } },
};

const mapStateToProps = (state) => extend({}, state.leads, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LeadsPage);
