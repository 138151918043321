import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../../Spinner';
import Validators from '../../../utils/validators';
import '../style.css';

const UserProfileForm = (props) => {
  const { emailAddress, values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, loading, success, failed, errorMessage } =
    props;
  return (
    <form className="row form-block" onSubmit={handleSubmit}>
      {success && <div className="alert alert-success">Your profile has been updated!</div>}
      {failed && <div className="alert alert-danger">{errorMessage}</div>}
      <div className="col-xs-12">
        <label htmlFor="pwd">
          Email Address
          <input id="email" type="email" value={emailAddress} disabled />
        </label>
        <p className="help-block">Editing Email is not yet enabled.</p>
      </div>
      <div className="col-xs-12">
        <label htmlFor="currpwd">
          Current Password
          <input
            id="currpwd"
            type="password"
            value={values.currpwd}
            className={`${errors.currpwd && touched.currpwd && 'error'}`}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        {errors.currpwd && touched.currpwd && <div className="alert alert-danger">{errors.currpwd}</div>}
      </div>
      <div className="col-xs-12">
        <label htmlFor="pwd">
          New Password
          <input
            id="pwd"
            type="password"
            value={values.pwd}
            className={`${errors.pwd && touched.pwd && 'error'}`}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        {errors.pwd && touched.pwd && <div className="alert alert-danger">{errors.pwd}</div>}
      </div>
      <div className="col-xs-12">
        <label htmlFor="verifyPwd">
          Verify password
          <input
            name="verifyPwd"
            id="verifyPwd"
            type="password"
            value={values.verifyPwd}
            className={`${errors.verifyPwd && touched.verifyPwd && 'error'}`}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        {errors.verifyPwd && touched.verifyPwd && <div className="alert alert-danger">{errors.verifyPwd}</div>}
      </div>

      <div className="col-xs-12">
        {isSubmitting || loading ? (
          <Spinner />
        ) : (
          <button type="submit" className="btn btn-primary btn-block">
            Update
          </button>
        )}
      </div>
    </form>
  );
};

UserProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  success: PropTypes.bool,
  emailAddress: PropTypes.string,

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};

UserProfileForm.defaultProps = {
  emailAddress: '',
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',

  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};

Yup.addMethod(Yup.string, 'matchesField', Validators.matchesField);

export default withFormik({
  mapPropsToValues: () => ({
    currpwd: '',
    pwd: '',
    verifyPwd: '',
  }),

  validationSchema: Yup.object().shape({
    currpwd: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(30, 'Password cannot be more than 30 characters')
      .required('Please Enter your current password'),
    pwd: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(30, 'Password cannot be more than 30 characters')
      .required('Please enter a new password'),
    verifyPwd: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(30, 'Password cannot be more than 30 characters')
      .matchesField(Yup.ref('pwd'), 'Passwords do not match')
      .required('Please confirm your new password'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values.pwd, values.verifyPwd, values.currpwd);
    setSubmitting(false);
  },
  displayName: 'Register Form',
})(UserProfileForm);
