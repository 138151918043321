import { extend } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import actions from './actions';

const BillingAccountSelector = ({ onUpdate, getAllBillingAccounts, billingAccounts, selectedBillingAccount, billingAccountListRequest }) => {
  const [filter, setFilter] = useState({
    pageSize: 50,
  });

  useEffect(() => {
    getAllBillingAccounts(filter);
  }, []);

  const onChange = (values) => {
    onUpdate(values);
  };

  const onInputChange = (searchString, action) => {
    if (action.action === 'input-change') {
      const f = { ...filter };
      if (searchString != null) {
        f.search = searchString;
      }
      setFilter(f);
    }
  };
  useEffect(() => {
    getAllBillingAccounts(filter);
  }, [filter]);

  return (
    <Select
      placeholder="Search for a Billing Account"
      classNamePrefix="select"
      getOptionValue={(opt) => opt.id}
      getOptionLabel={(opt) => opt.name}
      isClearable
      isLoading={billingAccountListRequest.working}
      value={selectedBillingAccount}
      onChange={onChange}
      options={billingAccounts.data || []}
      onInputChange={onInputChange}
    />
  );
};

BillingAccountSelector.propTypes = {
  getAllBillingAccounts: PropTypes.func.isRequired,
  billingAccounts: PropTypes.objectOf(PropTypes.shape),
  billingAccountListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  selectedBillingAccount: PropTypes.objectOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
};
BillingAccountSelector.defaultProps = {
  selectedBillingAccount: {},
  billingAccounts: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.billingAccounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(BillingAccountSelector);
