import List from './index';

export default [
  {
    title: 'Payment',
    path: '/invoices',
    component: List,
    options: { private: true },
  },
];
