import { map } from 'lodash';
import API from '../../utils/api/events';
import toastActions from '../../actions/toastActions';

const ACTION_PREFIX = 'EVENTS';

const createAnswerText = (data) => {
  let answers = '';
  map(data.liveStreamAnswers, (question) => {
    answers += `${question.answers}\n`;
  });
  const d = data;
  d.answerText = answers.trim();
  return d;
};

const refreshEventList = () => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_REFRESH_LIST` });
};

const getAllEvents = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });
  API.getEvents(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getEventDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getEvent(id)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_DETAIL_SUCCESS`,
        payload: createAnswerText(data),
      }),
    )
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreateEvent = (props) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createEvent(props)
    .then((data) => {
      toastActions.displaySuccessToast(`Event #${data.id} Created!`);
      return dispatch({
        type: `${ACTION_PREFIX}_CREATE_SUCCESS`,
        payload: data,
      });
    })
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditEvent = (id, props) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateEvent(id, props)
    .then((data) => {
      toastActions.displaySuccessToast(`Event #${id} Updated!`);
      dispatch(refreshEventList());
      return dispatch({
        type: `${ACTION_PREFIX}_EDIT_SUCCESS`,
        payload: createAnswerText(data),
      });
    })
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

const submitDeleteEvent = (eventId) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DELETE_STARTED` });
  API.deleteEvent(eventId)
    .then((data) => {
      dispatch(refreshEventList());
      toastActions.displaySuccessToast(`Event #${eventId} Deleted!`);
      return dispatch({
        type: `${ACTION_PREFIX}_DELETE_SUCCESS`,
        payload: data,
      });
    })
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DELETE_FAILED`, payload: errorMsg }));
};

const triggerGoogleImport = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_GOOGLE_IMPORT_STARTED` });
  API.triggerGoogleImport(id)
    .then((data) => {
      dispatch(refreshEventList());
      toastActions.displaySuccessToast(`Event #${id} Google Sheet Imported!`);
      return dispatch({
        type: `${ACTION_PREFIX}_GOOGLE_IMPORT_SUCCESS`,
        payload: data,
      });
    })
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_GOOGLE_IMPORT_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getEventHistory = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_HISTORY_STARTED` });
  API.getEventBonusHistory(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_HISTORY_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_HISTORY_FAILED`, payload: errorMsg }));
};

const submitBatchUpdateEvents = (eventIds, props) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_BATCH_UPDATE_STARTED` });
  const patches = map(props, (value, key) => ({
    op: 'replace',
    path: `/${key}`,
    value,
  }));
  API.patchEvents(eventIds, patches)
    .then((data) => {
      toastActions.displaySuccessToast(`Updated ${data.length} Events!`);
      return dispatch({
        type: `${ACTION_PREFIX}_BATCH_UPDATE_SUCCESS`,
        payload: data,
      });
    })
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_BATCH_UPDATE_FAILED`,
        payload: errorMsg,
      }),
    );
};

export default {
  getAllEvents,
  getEventDetails,
  submitCreateEvent,
  submitEditEvent,
  submitDeleteEvent,
  triggerGoogleImport,
  getEventHistory,
  submitBatchUpdateEvents,
};
