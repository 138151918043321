import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik/dist/index';
import * as Yup from 'yup';
import Spinner from '../../Spinner';
import '../style.css';
import './style.css';

const RequestPasswordResetForm = (props) => {
  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, loading, success, failed, errorMessage } = props;

  if (success) {
    return <div className="alert alert-success">Check your email for a password reset link.</div>;
  }

  return (
    <form className="row form-block" onSubmit={handleSubmit}>
      {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      <div className="col-xs-12">
        <label htmlFor="email">
          Email
          <input
            type="email"
            id="email"
            className={`${errors.email && touched.email && 'error'}`}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        {errors.email && touched.email && <div className="alert alert-danger">{errors.email}</div>}
      </div>

      <div className="col-xs-12">
        {isSubmitting || loading ? (
          <Spinner />
        ) : (
          <button type="submit" className="btn btn-primary btn-block">
            Request Password Reset Link
          </button>
        )}
      </div>
    </form>
  );
};

RequestPasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,

  errorMessage: PropTypes.string,

  // --- values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
RequestPasswordResetForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // --- values added by Formik
  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values.email); // omit(values, ['recaptcha']));
    setSubmitting(false);
  },
  displayName: 'Request Password Reset Form',
})(RequestPasswordResetForm);
