import { getRoles, rolesMatched } from '../utils';
import { getJWT } from '../utils/api/helpers';

// const PLAYERS = 'Players';
const HOSTS = 'Hosts';
// const OWNERS = 'Owners';
const ADMINS = 'Administrators';

const authorizationRoles = {
  hosts: [ADMINS, HOSTS],
};

export const userCanView = (route) => {
  const currentUserRoles = getRoles(getJWT());
  if (!currentUserRoles.length) {
    return false;
  }

  const allowedRoles = authorizationRoles[route];
  return rolesMatched(allowedRoles, currentUserRoles);
};

export default authorizationRoles;
