import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { PlusCircle } from 'react-feather';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import PaymentsTable from '../../components/Payments/PaymentsTable';

class PaymentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  updateList() {
    const { getAllPayments } = this.props;
    const { filter } = this.state;
    getAllPayments(filter);
  }

  render() {
    const { payments, paymentListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/payments" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>Payments</h1>
              </div>
              {/* <div className="col text-right">
                <Link to="/payments/create" className="btn btn-success">
                  <PlusCircle />
                  {' '}
New
                </Link>
              </div> */}
            </div>
            <PaymentsTable
              list={payments.data}
              pagination={payments.metadata}
              isLoading={paymentListRequest.working}
              isSuccess={paymentListRequest.success}
              isFailed={paymentListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

PaymentsPage.propTypes = {
  getAllPayments: PropTypes.func.isRequired,
  payments: PropTypes.objectOf(PropTypes.shape),
  paymentListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
PaymentsPage.defaultProps = {
  payments: { data: [], metadata: { pageNum: 0, pageSize: 20, totalCount: 0 } },
};

const mapStateToProps = (state) => extend({}, state.payments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(PaymentsPage);
