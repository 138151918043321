import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import Spinner from '../Spinner';
// import Select, { Option } from 'rc-select';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import RegionsSelector from '../../containers/Accounts/RegionsSelector';

class LocationCreateForm extends Component {
  componentDidMount() {
    const { searchAccounts } = this.props;
    searchAccounts();
  }

  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) {
      resetForm();
    }
  }
  // const _handleSelect = (selectChoice) => {
  //   setFieldValue('imaginaryThingId', selectChoice.value);
  // };
  // update = (e) => {
  //   console.log(e);
  // };

  render() {
    const {
      values,
      touched,
      errors,
      // dirty,
      isSubmitting,
      // handleChange,
      setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,

      // handleReset,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && <div className="alert alert-success">Location Created</div>}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="account" className="required">
                  Account
                </label>
                <AccountSelector selectedAccount={values.account} onUpdate={(v) => setFieldValue('account', v)} />
                {errors.account && touched.account && <div className="alert alert-danger">{errors.account}</div>}
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="name" className="required">
                  Location Name
                </label>
                <Field name="name" className="form-control" />
                {errors.name && touched.name && <div className="alert alert-danger">{errors.name}</div>}
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="name" className="required">
                  Region
                </label>
                <RegionsSelector selectedRegion={values.region} onUpdate={(v) => setFieldValue('region', v)} />
                {errors.region && touched.region && <div className="alert alert-danger">{errors.region}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="url">Website</label>
                <Field type="url" name="url" className="form-control" />
                {errors.url && touched.url && <div className="alert alert-danger">{errors.url}</div>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="fburl">Facebook URL</label>
                <Field type="url" name="fburl" className="form-control" />
                {errors.fburl && touched.fburl && <div className="alert alert-danger">{errors.fburl}</div>}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="address">Address</label>
                <Field name="address" className="form-control" />
                {errors.address && touched.address && <div className="alert alert-danger">{errors.address}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="city" className="required">
                  City
                </label>
                <Field name="city" className="form-control" />
                {errors.city && touched.city && <div className="alert alert-danger">{errors.city}</div>}
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="state" className="required">
                  State
                </label>
                <Field pattern="[A-Z]{2}" name="state" className="form-control" />
                {errors.state && touched.state && <div className="alert alert-danger">{errors.state}</div>}
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="zip">Zip</label>
                <Field pattern="[0-9]{5}" name="zip" className="form-control" />
                {errors.zip && touched.zip && <div className="alert alert-danger">{errors.zip}</div>}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <br />
              <ButtonGroup>
                <Button
                  color={values.isActive === true ? 'primary' : 'secondary'}
                  size="sm"
                  onClick={() => setFieldValue('isActive', true)}
                  active={values.isActive === true}
                >
                  Active
                </Button>
                <Button
                  color={values.isActive === false ? 'primary' : 'secondary'}
                  size="sm"
                  onClick={() => setFieldValue('isActive', false)}
                  active={values.isActive === false}
                >
                  Inactive
                </Button>
              </ButtonGroup>
              {errors.isActive && touched.isActive && <div className="alert alert-danger">{errors.isActive}</div>}
            </div>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label htmlFor="teamCapacity">Est. Team Capacity</label>
                <Field type="number" className="form-control" name="teamCapacity" />
                {errors.teamCapacity && touched.teamCapacity && <div className="alert alert-danger">{errors.teamCapacity}</div>}
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="playerCapacity">Est. Player Capacity</label>
                <Field type="number" className="form-control" name="playerCapacity" />
                {errors.playerCapacity && touched.playerCapacity && <div className="alert alert-danger">{errors.playerCapacity}</div>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="venueInformation">Venue Info</label>
              <Field type="textarea" className="form-control" rows="3" name="venueInformation" />
              {errors.venueInformation && touched.venueInformation && <div className="alert alert-danger">{errors.venueInformation}</div>}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Link className="btn btn-default" to="/locations">
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
LocationCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  // accountId: PropTypes.string,

  // accounts: PropTypes.arrayOf(PropTypes.shape),
  searchAccounts: PropTypes.func.isRequired,

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
LocationCreateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // routeParams: {},

  // accounts: [],

  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};
export default withFormik({
  mapPropsToValues: ({ name }) => ({
    name: name || '',
    account: '',
    region: '',
    url: '',
    fburl: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    isActive: true,
    venueInformation: '',
    teamCapacity: 0,
    playerCapacity: 0,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required('Please enter a Location Name'),
    account: Yup.object().required('Please Select an Account'),
    region: Yup.object().required('Please Select a Region'),
    url: Yup.string().url(),
    fburl: Yup.string().url(),
    city: Yup.string().required('Please enter a City'),
    state: Yup.string().required('Please enter a State'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const vals = { ...values, accountId: values.account.id, regionId: values.region.id };
    // console.log("Submit!", vals);
    props.onSubmit(vals);
    setSubmitting(false);
  },
  displayName: 'Location Creation Form',
})(LocationCreateForm);
