import List from './index';
import Create from './create';
import Edit from './edit';
import Details from './view';

export default [
  {
    title: 'Events',
    path: '/events',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create Event',
    path: '/events/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Event',
    path: '/events/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Event Details',
    path: '/events/:id',
    component: Details,
    options: { private: true },
  },
];
