import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { difference } from 'lodash';
import qs from 'query-string';
// import Select from 'react-select';

import { Button, Input, FormGroup, Label } from 'reactstrap';
import { EVENT_STATUS } from '../../config';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import LocationSelector from '../../containers/Locations/LocationSelector';
// import { EVENT_TYPES } from '../../utils';

import eventFilterPresets from './eventFilters';
import './eventsFilter.css';

const fetchFiltersFromURL = () => {
  const urlFilters = qs.parse(window.location.search, {
    arrayFormat: 'bracket',
  });
  return Object.keys(urlFilters).reduce((acc, key) => {
    if (key === 'status') {
      return {
        ...acc,
        status: urlFilters.status.map((val) => parseInt(val, 10)),
      };
    }

    if (key === 'isEventPublic') {
      return {
        ...acc,
        isEventPublic: urlFilters.isEventPublic === null ? null : Boolean(urlFilters.isEventPublic),
      };
    }

    return {
      ...acc,
      [key]: urlFilters[key],
    };
  }, {});
};

const fetchFiltersFromLocalStorage = () => {
  try {
    const filtersJSONString = window.localStorage.getItem('filters');
    return JSON.parse(filtersJSONString);
  } catch (err) {
    return {};
  }
};

const getQuickFilter = (urlFilters, localStorageFilters) => {
  if (urlFilters && urlFilters.quickFilter) {
    return urlFilters.quickFilter;
  }

  if (localStorageFilters && localStorageFilters.quickFilter) {
    return localStorageFilters.quickFilter;
  }

  return eventFilterPresets[0].key;
};

class EventsFilter extends Component {
  options = [
    { id: EVENT_STATUS.DRAFT, name: 'Draft' },
    { id: EVENT_STATUS.READY, name: 'Ready' },
    { id: EVENT_STATUS.PENDING, name: 'Pending' },
    { id: EVENT_STATUS.COMPLETE, name: 'Complete' },
    { id: EVENT_STATUS.CANCELED, name: 'Canceled' },
  ];

  constructor(props) {
    super(props);

    const filtersFromLocalStorage = fetchFiltersFromLocalStorage();
    const filtersFromURL = fetchFiltersFromURL();

    const { filter } = this.props;

    const quickFilter = getQuickFilter(filtersFromURL, filtersFromLocalStorage);

    this.state = {
      quickFilter,
      filters: {
        ...filter,
        ...eventFilterPresets[0].obj,
        ...filtersFromURL,
        ...filtersFromLocalStorage,
      },
      isVisible: false,
      dirty: false,
      // filters: {},
    };
    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidMount() {
    this.updateFilter();
  }

  toggleVisible = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  // setPublicFilter = (status) => {
  //   const { filters } = this.state;
  //   filters.isEventPublic = status;
  //   this.setState({ filters, quickFilter: null, dirty: true });
  // };

  // setEventType = (type) => {
  //   const { filters } = this.state;
  //   filters.eventType = type;
  //   this.setState({ filters, quickFilter: null, dirty: true });
  // };

  toggleStatus = (status) => {
    const { filters } = this.state;
    if (filters.status.includes(status)) filters.status = difference(filters.status, [status]);
    else filters.status.push(status);
    this.setState({ filters, quickFilter: null, dirty: true });
  };

  change = (property, value) => {
    const { filters } = this.state;
    filters[property] = value;
    this.setState({ filters, quickFilter: null, dirty: true });
  };

  quickLinks = (key) => {
    eventFilterPresets.map((preset) => {
      if (preset.key === key) {
        return this.setState({ quickFilter: preset.key, filters: preset.obj }, this.updateFilter);
      }

      return false;
    });
  };

  updateFilter = () => {
    const { onUpdateFilter } = this.props;
    const { filters, quickFilter } = this.state;
    const newFilters = { ...filters };
    if (filters.accounts) newFilters.accounts = filters.accounts;
    if (filters.locations) newFilters.locations = filters.locations;
    onUpdateFilter({ ...newFilters, quickFilter });
    this.setState({ dirty: false });
  };

  render() {
    const { isLoading, onToggleWeekSeparator, separateWeekRows } = this.props;
    const { filters, quickFilter, isVisible, dirty } = this.state;

    return (
      <div className="filter-pane">
        <div className="row">
          <div className="col">
            {eventFilterPresets.map((preset) => (
              <span key={preset.key}>
                <Button
                  disabled={isLoading}
                  color={quickFilter === preset.key ? 'success' : 'link'}
                  size="sm"
                  onClick={() => this.quickLinks(preset.key)}
                >
                  {preset.name}
                </Button>{' '}
              </span>
            ))}
          </div>
          <div className="col text-right">
            <Button color="link" onClick={() => this.toggleVisible()}>
              {isVisible ? '-' : '+'} Filters
            </Button>
          </div>
        </div>
        {isVisible && <hr style={{ width: '100%' }} />}
        <div className={`row ${isVisible ? '' : 'd-none'}`}>
          <div className="col col-12 col-md-2 col-lg-2 mt-2">
            <label htmlFor="startDate" className="mb-2">
              Start Date
            </label>
            <Input type="date" id="startDate" name="startDate" onChange={(v) => this.change('startDate', v.target.value)} value={filters.startDate} />
          </div>
          <div className="col col-12 col-md-2 col-lg-2 mt-2">
            <label htmlFor="endDate" className="mb-2">
              End Date
            </label>
            <Input type="date" id="endDate" name="endDate" onChange={(v) => this.change('endDate', v.target.value)} value={filters.endDate} />
          </div>
          <div className="col col-12 col-md-3 mt-2">
            <label className="mb-2">Account</label>
            <AccountSelector selectedAccount={filters.accounts} onUpdate={(v) => this.change('accounts', v)} />
          </div>
          <div className="col-12 col-md-3 mt-2">
            <label className="mb-2">Location</label>
            <LocationSelector selectedLocation={filters.locations} onUpdate={(v) => this.change('locations', v)} />
          </div>
          {/* <div className="col-12 col-md-2 mt-2">
            <label className="mb-2">Type</label>
            <Select
              options={[{ label: '', value: '' }, ...EVENT_TYPES]}
              name="eventType"
              value={EVENT_TYPES.filter(({ value }) => value === filters.eventType)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
              onChange={({ value }) => this.setEventType(value)}
              classNamePrefix="select"
            />
          </div> */}
        </div>
        <div className={`row mt-3 ${isVisible ? '' : 'd-none'}`}>
          <div className="filter-label">Status </div>
          {this.options.map((option) => (
            <span key={option.id}>
              <Button
                className="status-button mb-2"
                disabled={isLoading}
                color={filters.status.includes(option.id) ? 'primary' : 'link'}
                size="sm"
                onClick={() => this.toggleStatus(option.id)}
              >
                {option.name}
              </Button>{' '}
            </span>
          ))}

          {/* <div className="col col-md-4 mb-3 flex-align-center">
            <div className="filter-label">Visibility </div>
            <ButtonGroup>
              <Button
                className="event-type-button"
                color={filters.isEventPublic === null ? 'primary' : 'link'}
                size="sm"
                onClick={() => this.setPublicFilter(null)}
                active={filters.isEventPublic === null}
              >
                All Events
              </Button>
              <Button
                className="event-type-button"
                color={filters.isEventPublic === true ? 'primary' : 'link'}
                size="sm"
                onClick={() => this.setPublicFilter(true)}
                active={filters.isEventPublic === true}
              >
                Public Only
              </Button>
              <Button
                className="event-type-button"
                color={filters.isEventPublic === false ? 'primary' : 'link'}
                size="sm"
                onClick={() => this.setPublicFilter(false)}
                active={filters.isEventPublic === false}
              >
                Private Only
              </Button>
            </ButtonGroup>
          </div> */}
          <div className="col col-md-4 text-md-right">
            <FormGroup check inline className="mb-3">
              <Label check>
                <Input type="checkbox" checked={separateWeekRows} disabled={isLoading} onChange={onToggleWeekSeparator} /> Separate Weeks
              </Label>
            </FormGroup>
          </div>
        </div>
        <div className={`row ${isVisible ? '' : 'd-none'}`}>
          <div className="col-12 text-right mt-3">
            <Button disabled={!dirty} color={dirty ? 'success' : 'link'} size="sm" onClick={this.updateFilter}>
              Update List
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

EventsFilter.propTypes = {
  // startDate: PropTypes.string,
  // endDate: PropTypes.string,
  // status: PropTypes.string,
  filter: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  // isVisible: PropTypes.bool,
  onUpdateFilter: PropTypes.func.isRequired,
  separateWeekRows: PropTypes.bool,
  onToggleWeekSeparator: PropTypes.func.isRequired,
};

EventsFilter.defaultProps = {
  isLoading: false,
  // isVisible: false,
  // startDate: null,
  // endDate: null,
  separateWeekRows: false,
  // status: 'active',
};

export default EventsFilter;
