import List from './index';
// import Create from './create';
// import Edit from './edit';
import Details from './view';

export default [
  {
    title: 'Leads',
    path: '/leads',
    component: List,
    options: { private: true },
  },
  {
    title: 'Leads',
    path: '/leads/:id',
    component: Details,
    options: { private: true },
  },
  // {
  //   title: 'Create Account',
  //   path: '/accounts/create',
  //   component: Create,
  //   options: { private: true },
  // },
  // {
  //   title: 'Edit Account',
  //   path: '/accounts/:id/edit',
  //   component: Edit,
  //   options: { private: true },
  // },
  // {
  //   title: 'Account Details',
  //   path: '/accounts/:id',
  //   component: View,
  //   options: { private: true },
  // },
];
