import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table } from 'reactstrap';
import moment from 'moment';
import EventHostAvatar from '../Events/Widgets/EventHostAvatar';
import EventType from '../Events/Widgets/EventType';
import { EVENT_STATUS } from '../../config';
import '../Events/eventsTableRow.css';
import './dashboard.css';

const UpcomingEventsTable = ({ events }) => (
  <div>
    <Table striped size="sm" hover responsive>
      <tbody>
        {events.data &&
          events.data.map((event) => (
            <tr
              key={event.id}
              className={classnames(
                'status',
                event.status === EVENT_STATUS.DRAFT ? 'draft' : '',
                event.status === EVENT_STATUS.READY ? 'ready' : '',
                event.status === EVENT_STATUS.PENDING ? 'pending' : '',
                event.status === EVENT_STATUS.COMPLETE ? 'complete' : '',
                event.status === EVENT_STATUS.CANCELED ? 'canceled' : '',
              )}
            >
              <td>
                <EventType type={event.eventType} /> <Link to={`/events/${event.id}`}>{event.name}</Link>
                <div className="event-date">{moment(event.dateTimeStart).format('dd MM/DD/YY')}</div>
              </td>
              <td>
                {event.hosts.map((host) => (
                  <EventHostAvatar key={host.id} host={host} event={event} />
                ))}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  </div>
);

UpcomingEventsTable.propTypes = {
  events: PropTypes.objectOf(PropTypes.shape).isRequired,
};
UpcomingEventsTable.defautProps = {
  events: { data: [] },
};

export default UpcomingEventsTable;
