/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { extend } from 'lodash';
// import moment from 'moment';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import LiveStreamActions from '../LiveStream/actions';
import Header from '../Header';
import Button from '../../components/Button';
import EventInfo from '../../components/Events/EventDetailComponents/EventInfo';
import EventDetail from '../../components/Events/EventDetailComponents/EventDetail';
// import LiveStreamScoreboardTable from '../../components/LiveStream/LiveStreamScoreboardTable';
// import EventLive from '../../components/Events/EventDetailComponents/EventLive';
import './style.css';
import { EVENT_TYPE } from '../../utils';
// import { EVENT_TYPE } from '../../utils';
import EventLiveScoreboard from '../../components/Events/EventDetailComponents/EventLiveScoreboard';
import { EVENT_STATUS } from '../../config';

// import { EVENT_STATUS } from '../../config';

class EventsDetailPage extends Component {
  constructor() {
    super();
    this.state = {
      deleted: false,
    };
  }

  componentDidMount() {
    this.updateList();
  }

  onImport = () => {
    const { triggerGoogleImport, routeParams } = this.props;
    triggerGoogleImport(routeParams.id);
  };

  onClickDelete = (id) => {
    const { submitDeleteEvent } = this.props;
    submitDeleteEvent(id);
    this.setState({ deleted: true });
  };

  updateList() {
    const {
      getEventDetails,
      getEventHistory,
      // getLiveStreamResponses,
      // getLiveStreamScoreboard,
      routeParams,
    } = this.props;
    getEventDetails(routeParams.id);
    getEventHistory(routeParams.id);
    // getLiveStreamResponses(routeParams.id);
    // getLiveStreamScoreboard(routeParams.id);
  }

  render() {
    const {
      eventDetails,
      // submitDeleteEvent,
      eventHistory,
      eventBonusHistoryRequest,
      // liveStreamResponses,
      // liveStreamScoreboard,
    } = this.props;
    const { deleted } = this.state;

    if (deleted) return <Redirect to="/events" />;
    return (
      <div className="page-container">
        <Header currentPage="/events" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col-10">
                <div className="row">
                  <div className="col-8">
                    <EventInfo eventDetails={eventDetails} />
                  </div>
                  <div className="col-4">
                    <h3>Host Details:</h3>
                    {eventDetails.hostDetails ? (
                      <div className="content host-notes" dangerouslySetInnerHTML={{ __html: eventDetails.hostDetails }} />
                    ) : (
                      <div className="small">None</div>
                    )}

                    <h3>Admin Details:</h3>
                    {eventDetails.adminDetails ? (
                      <div className="content host-notes" dangerouslySetInnerHTML={{ __html: eventDetails.adminDetails }} />
                    ) : (
                      <div className="small">None</div>
                    )}

                    <h3>Public Notes:</h3>
                    {eventDetails.publicNotes ? (
                      <div className="content host-notes" dangerouslySetInnerHTML={{ __html: eventDetails.publicNotes }} />
                    ) : (
                      <div className="small">None</div>
                    )}

                    <h3>Internal Notes:</h3>
                    {eventDetails.internalNotes ? (
                      <div className="content host-notes" dangerouslySetInnerHTML={{ __html: eventDetails.internalNotes }} />
                    ) : (
                      <div className="small">None</div>
                    )}
                  </div>
                </div>
                {eventDetails.eventType === EVENT_TYPE.LIVE && eventDetails.status !== EVENT_STATUS.DRAFT && (
                  <div className="row">
                    <div className="col-12">
                      <h2>Live Stream Scoreboard</h2>
                      <EventLiveScoreboard
                        eventCode={eventDetails.liveStreamEventCode}
                        scoreboardKey={eventDetails.liveStreamMetadata?.scoreboardKey}
                      />
                    </div>
                  </div>
                )}
                {eventDetails.eventType !== EVENT_TYPE.LIVE && (
                  <div className="row">
                    <div className="col-12">
                      <h2>Event Participation</h2>
                      <EventDetail
                        eventDetails={eventDetails}
                        triggerGoogleImport={() => {
                          this.onImport();
                        }}
                        eventBonusHistoryRequest={eventBonusHistoryRequest}
                        eventHistory={eventHistory}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-2">
                <Link to={`/events/${eventDetails.id}/edit`} className="btn btn-success">
                  Edit
                </Link>{' '}
                <Button
                  className="btn-danger"
                  disabled={eventDetails.status !== EVENT_STATUS.DRAFT && eventDetails.status !== EVENT_STATUS.CANCELED}
                  onClick={() => this.onClickDelete(eventDetails.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EventsDetailPage.propTypes = {
  getEventDetails: PropTypes.func.isRequired,
  getEventHistory: PropTypes.func.isRequired,
  submitDeleteEvent: PropTypes.func.isRequired,
  // getLiveStreamResponses: PropTypes.func.isRequired,
  // getLiveStreamScoreboard: PropTypes.func.isRequired,
  // liveStreamResponses: PropTypes.objectOf(PropTypes.shape),
  // liveStreamScoreboard: PropTypes.objectOf(PropTypes.shape),

  triggerGoogleImport: PropTypes.func.isRequired,
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
  eventDetails: PropTypes.objectOf(PropTypes.shape),

  eventHistory: PropTypes.arrayOf(PropTypes.shape),
  eventBonusHistoryRequest: PropTypes.objectOf(PropTypes.shape),
};
EventsDetailPage.defaultProps = {
  eventDetails: {},
  eventHistory: [],
  eventBonusHistoryRequest: {},
  // liveStreamScoreboard: {},
  // liveStreamResponses: {},
};

const mapStateToProps = (state) => extend({}, state.events, state.liveStream, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, LiveStreamActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventsDetailPage);
