import React from 'react';
import PropTypes from 'prop-types';
import './dashboard.css';
import moment from 'moment/moment';

const SocialEventsHTML = ({ socialEvents }) => {
  let dow = null;
  const showDOW = (dt) => {
    if (moment(dt).format('dddd') !== dow) {
      dow = moment(dt).format('dddd');
      return true;
    }
    return false;
  };
  return (
    <div>
      {socialEvents &&
        socialEvents.map((event) => (
          <pre key={event.id}>
            {showDOW(event.dateTimeStart) && `\n\r${moment(event.dateTimeStart).format('dddd')}\n\r`}
            {`${event.name} - ${moment(event.dateTimeStart).format('h:mma')}\n\r`}
          </pre>
        ))}
    </div>
  );
};

SocialEventsHTML.propTypes = {
  socialEvents: PropTypes.objectOf(PropTypes.shape).isRequired,
};
SocialEventsHTML.defautProps = {
  socialEvents: { data: [] },
};

export default SocialEventsHTML;
