import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getRegisteredTeams(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.REGISTERED_TEAMS}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create registeredTeam
 * @param {object} registeredTeamData - the registeredTeam data
 */
function createRegisteredTeam(registeredTeamData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.REGISTERED_TEAMS}`, registeredTeamData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get registeredTeam by ID
 * @param {string} id - the registeredTeam ID
 */
function getRegisteredTeam(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.REGISTERED_TEAMS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get registeredTeam History by ID
 * @param {string} id - the registeredTeam ID
 * @param {obj} query - query string object
 */
function getRegisteredTeamHistory(id, query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.REGISTERED_TEAMS}/${id}/history?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Update registeredTeam
 * @param {string} id - the registeredTeam ID
 * @param {object} newData - the registeredTeam data
 */
function updateRegisteredTeam(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.REGISTERED_TEAMS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Delete registeredTeam
 * @param {string} id - the registeredTeam ID
 */
function deleteRegisteredTeam(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.REGISTERED_TEAMS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getRegisteredTeams,
  getRegisteredTeam,
  getRegisteredTeamHistory,
  createRegisteredTeam,
  updateRegisteredTeam,
  deleteRegisteredTeam,
};
