import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { Table } from 'reactstrap';
import { EVENT_STATUS } from '../../config';
import './dashboard.css';

const EventsMissingHostsTable = ({ events }) => (
  <div>
    <Table striped size="sm" hover responsive>
      <tbody>
        {events.data &&
          events.data.length > 0 &&
          events.data.map((event) => (
            <tr
              key={event.id}
              className={classnames(
                'status',
                event.status === EVENT_STATUS.DRAFT ? 'draft' : '',
                event.status === EVENT_STATUS.READY ? 'ready' : '',
                event.status === EVENT_STATUS.PENDING ? 'pending' : '',
                event.status === EVENT_STATUS.COMPLETE ? 'complete' : '',
                event.status === EVENT_STATUS.CANCELED ? 'canceled' : '',
              )}
            >
              <td>
                <Link to={`/events/${event.id}`}>{event.name}</Link>
                <div className="event-date">{moment(event.dateTimeStart).format('dd MM/DD/YY')}</div>
              </td>
            </tr>
          ))}
        {events.data && events.data.length <= 0 && (
          <tr>
            <td colSpan="6">No events missing hosts</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

EventsMissingHostsTable.propTypes = {
  events: PropTypes.objectOf(PropTypes.shape).isRequired,
};
EventsMissingHostsTable.defautProps = {
  events: { data: [] },
};

export default EventsMissingHostsTable;
