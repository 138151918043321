import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { ENDPOINTS } from '../config';

export default function useEvent(id) {
  const { fetcher } = useSWRConfig();
  const [upsertError, setUpsertError] = useState(null);
  const [upsertLoading, setUpsertLoading] = useState(null);

  const { data, error, loading, mutate } = useSWR(id ? `${ENDPOINTS.EVENTS}/${id}` : {});

  const handleRequest = async (config) => {
    setUpsertLoading(true);
    setUpsertError(null);
    return fetcher(config)
      .then((res) => {
        setUpsertLoading(false);
        mutate(res?.data);
        return res?.data;
      })
      .catch((error) => {
        if (error.response.data.details?.length > 0) {
          setUpsertError(error.response.data.details);
        } else {
          setUpsertError([error.response.data]);
        }
        setUpsertLoading(false);
        throw error;
      });
  };

  const updateEvent = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.EVENTS}/${id}`,
      method: 'put',
      data,
    }).then((e) => e);
  };

  const createEvent = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.EVENTS}`,
      method: 'post',
      data,
    }).then((e) => e);
  };

  const quickCreateEventFromTemplate = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.QUICK_CREATE_ENDPOINT}`,
      method: 'post',
      data,
    }).then((e) => e);
  };
  // const updateChallengeRequirements = async (data) => {
  //   return handleRequest({
  //     url: `${ENDPOINTS.CHALLENGES}/${id}/requirements`,
  //     method: 'patch',
  //     data,
  //   });
  // };

  return {
    event: data?.data || {},
    error: error || upsertError,
    loading,
    upsertLoading: upsertLoading,
    mutateEvent: mutate,
    updateEvent,
    createEvent,
    quickCreateEventFromTemplate,
  };
}
