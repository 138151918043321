import React from 'react';
import PropTypes from 'prop-types';
import { DollarSign } from 'react-feather';
import moment from 'moment';
import './style.css';
import { PAYMENT_METHODS } from '../../utils';

// import Spinner from '../Spinner';
// import { EVENT_STATUS } from '../../config';

// const iconSize = 18;
const PaymentMethodIcon = ({ onClickPayment, event }) => {
  if (!event.isInvoicePaid && event.stripeInvoiceItemId && !event.stripeInvoiceId) {
    return (
      <span title="Invoice Item Created, Waiting to Send Invoice">
        <DollarSign style={{ cursor: 'pointer' }} color="gold" onClick={() => onClickPayment(event)} />
      </span>
    );
  }

  if (!event.isInvoicePaid && event.stripeInvoiceItemId && event.stripeInvoiceId && !event.isInvoiceSent) {
    return (
      <span title="Invoice Created, Waiting for Stripe to Send">
        <DollarSign style={{ cursor: 'pointer' }} color="orange" onClick={() => onClickPayment(event)} />
      </span>
    );
  }

  if (!event.isInvoicePaid && event.stripeInvoiceItemId && event.stripeInvoiceId && event.isInvoiceSent) {
    return (
      <span title="Invoice Sent, Awaiting Payment">
        <DollarSign style={{ cursor: 'pointer' }} color="blue" onClick={() => onClickPayment(event)} />
      </span>
    );
  }

  if (event.isInvoicePaid) {
    return (
      <>
        {/* {event.paymentMethod} */}
        <span
          title={`${PAYMENT_METHODS.find((e) => e.value === event.paymentMethod)?.label || event.paymentMethod} on ${moment(
            event.stripeInvoicePaidOn,
          ).format('ddd, MMM Do, YYYY')}`}
        >
          <DollarSign style={{ cursor: 'pointer' }} color="green" onClick={() => onClickPayment(event)} />
        </span>
      </>
    );
  }

  return (
    <span title="Not Paid - Click to view Payment Options">
      <DollarSign style={{ cursor: 'pointer' }} color="lightgray" onClick={() => onClickPayment(event)} />
    </span>
  );
};
// if (event.pendingAction === 'create-stripe-invoice-item') {
//   return <Spinner />;
// }
// if (event.isInvoicePaid) {
//   return (
//     <span title="Paid">
//       <DollarSign color="green" size={iconSize} />
//     </span>
//   );
// }

// if (!event.stripeInvoiceItemId) {
//   return (
//     <span
//       onClick={onSendInvoice}
//       style={{ cursor: 'pointer' }}
//       title={`Create Invoice Item for ${event.invoiceToEmail || event.account.invoiceToEmail}`}
//     >
//       <Send color="gray" size={iconSize} />
//     </span>
//   );
// }

// if (event.stripeInvoiceItemId && !event.isInvoiceSent) {
//   return (
//     <span title="Invoice Item Created, Pending Invoice Creation">
//       <Clock color="gold" title="Invoice Pending" size={iconSize} />
//     </span>
//   );
// }

// if (event.stripeInvoiceItemId && event.isInvoiceSent) {
//   return (
//     <span title={`Invoice Sent to ${event.invoiceToEmail}`}>
//       <Mail color="orange" size={iconSize} />
//     </span>
//   );
// }

// return <></>;
// };
PaymentMethodIcon.defaultProps = {
  event: {},
  // onSendInvoice: null,
};
PaymentMethodIcon.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  onClickPayment: PropTypes.func.isRequired,
};
export default PaymentMethodIcon;
