import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ name, isChecked, onChange, disabled }) => (
  <label>
    <input type="checkbox" checked={isChecked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} /> {name}
  </label>
);
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
  isChecked: false,
  disabled: false,
};
export default Checkbox;
