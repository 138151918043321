import API from '../../utils/api/leads';

const ACTION_PREFIX = 'LEADS';

const getAllLeads = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getLeads(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getLeadDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getLead(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitEditLead = (id, lead) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateLead(id, lead)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

const addLeadMessage = (id, messageObj) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_MESSAGE_STARTED` });
  API.createLeadMessage(id, messageObj)
    .then((data) => {
      dispatch({
        type: `${ACTION_PREFIX}_CREATE_MESSAGE_SUCCESS`,
        payload: data,
      });
      return dispatch(getLeadDetails(id));
    })
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_CREATE_MESSAGE_FAILED`,
        payload: errorMsg,
      }),
    );
};

// const submitLeadMessage = (id, message, status) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_CREATE_LEAD_MESSAGE_STARTED` });
//   API.createLeadMessage(id, message, status)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}
// _CREATE_LEAD_MESSAGE_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}
// _CREATE_LEAD_MESSAGE_FAILED`, payload: errorMsg }));
// };

const deleteLeadMessage = (leadId, messageId) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DELETE_LEAD_MESSAGE_STARTED` });
  API.deleteLeadMessage(leadId, messageId)
    .then((data) => {
      dispatch({
        type: `${ACTION_PREFIX}_DELETE_LEAD_MESSAGE_SUCCESS`,
        payload: data,
      });
      return dispatch(getLeadDetails(leadId));
    })
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_DELETE_LEAD_MESSAGE_FAILED`,
        payload: errorMsg,
      }),
    );
};

// const getAccountDetails = id => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
//   API.getAccount(id)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
// };

// const submitCreateAccount = account => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
//   API.createAccount(account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
// };

// const submitEditAccount = (id, account) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
//   API.updateAccount(id, account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
// };

export default {
  getAllLeads,
  getLeadDetails,
  submitEditLead,
  addLeadMessage,
  deleteLeadMessage,
};
