import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/Users/EditForm';

class UserEditPage extends Component {
  componentDidMount() {
    const { getUserDetails, routeParams } = this.props;
    getUserDetails(routeParams.id);
  }

  render() {
    const { submitEditUser, userDetails, userDetailRequest, userEditRequest } = this.props;
    return (
      <div className="user-page page-container">
        <Header currentPage="/user" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit a User</h1>
            {userDetailRequest.working && <Spinner />}
            {userDetailRequest.success && (
              <EditForm
                entity={userDetails}
                onSubmit={submitEditUser}
                loading={userEditRequest.working}
                success={userEditRequest.success}
                failed={userEditRequest.failed}
                errorMessage={userEditRequest.errorMessage}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

UserEditPage.propTypes = {
  submitEditUser: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  userDetailRequest: PropTypes.objectOf(PropTypes.shape),
  userEditRequest: PropTypes.objectOf(PropTypes.shape),
  userDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
UserEditPage.defaultProps = {
  userDetails: {},
  userEditRequest: {},
  userDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.users, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(UserEditPage);
