import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';

export default [
  {
    title: 'Payments',
    path: '/payments',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create Payment',
    path: '/payments/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Payment',
    path: '/payments/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Payment Details',
    path: '/payments/:id',
    component: View,
    options: { private: true },
  },
];
