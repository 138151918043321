const defaultState = {
  // Form Request Status
  leads: { data: [], metadata: {} },
  // accountDetails: {},
  hostApplicationsListRequest: {},
  leadsListRequest: {},
  leadDetailRequest: {},
  leadDetail: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'LEADS_LIST_STARTED':
      return { ...state, leadsListRequest: { working: true } };
    case 'LEADS_LIST_FAILED':
      return { ...state, leadsListRequest: { failed: true } };
    case 'LEADS_LIST_SUCCESS':
      return {
        ...state,
        leads: action.payload,
        leadsListRequest: { success: true },
      };

    case 'LEADS_DETAIL_STARTED':
      return { ...state, leadDetailRequest: { working: true } };
    case 'LEADS_DETAIL_FAILED':
      return { ...state, leadDetailRequest: { failed: true } };
    case 'LEADS_DETAIL_SUCCESS':
      return {
        ...state,
        leadDetail: action.payload,
        leadDetailRequest: { success: true },
      };
    case 'LEADS_EDIT_STARTED':
      return { ...state, leadEditRequest: { working: true } };
    case 'LEADS_EDIT_FAILED':
      return { ...state, leadEditRequest: { failed: true } };
    case 'LEADS_EDIT_SUCCESS':
      return {
        ...state,
        leadDetail: action.payload,
        leadEditRequest: { success: true },
      };

    case 'LEADS_CREATE_MESSAGE_STARTED':
      return { ...state, leadCreateMessageRequest: { working: true } };
    case 'LEADS_CREATE_MESSAGE_FAILED':
      return { ...state, leadCreateMessageRequest: { failed: true } };
    case 'LEADS_CREATE_MESSAGE_SUCCESS':
      return {
        ...state,
        // leadDetail: action.payload,
        leadCreateMessageRequest: { success: true },
      };

    default:
      return state;
  }
};
