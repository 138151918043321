import EVENTS_API from '../../utils/api/events';
import LEADS_API from '../../utils/api/leads';
import REPORTING_API from '../../utils/api/reporting';
import HOSTAPPS_API from '../../utils/api/hostApplications';
// import genericActions from '../../actions/genericActions';

const ACTION_PREFIX = 'DASHBOARD';

const getUpcomingEventTemplates = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_RECURRING_LIST_STARTED` });
  EVENTS_API.getEvents(query)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_RECURRING_LIST_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_RECURRING_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getUpcomingPrivateEvents = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_PRIVATE_LIST_STARTED` });
  EVENTS_API.getEvents(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_PRIVATE_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_PRIVATE_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getLeads = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LEADS_LIST_STARTED` });
  LEADS_API.getLeads(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LEADS_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_LEADS_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getHostApplications = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_HOSTAPPLICATION_LIST_STARTED` });
  HOSTAPPS_API.getHostApplications(query)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_HOSTAPPLICATION_LIST_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_HOSTAPPLICATION_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getEventsMissingHosts = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EVENTHOSTS_LIST_STARTED` });
  REPORTING_API.getEventsMissingHosts(query)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_EVENTHOSTS_LIST_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_EVENTHOSTS_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getEventStats = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EVENTSTATS_LIST_STARTED` });
  REPORTING_API.getEventStats(query)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_EVENTSTATS_LIST_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_EVENTSTATS_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getHostReport = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_HOSTREPORT_LIST_STARTED` });
  REPORTING_API.getHostReport(query)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_HOSTREPORT_LIST_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_HOSTREPORT_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getEventsForSocial = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_SOCIAL_EVENTS_LIST_STARTED` });
  REPORTING_API.getSocialEvents(query)
    .then((data) =>
      dispatch({
        type: `${ACTION_PREFIX}_SOCIAL_EVENTS_LIST_SUCCESS`,
        payload: data,
      }),
    )
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_SOCIAL_EVENTS_LIST_FAILED`,
        payload: errorMsg,
      }),
    );
};

export default {
  getUpcomingEventTemplates,
  getUpcomingPrivateEvents,
  getLeads,
  getEventsMissingHosts,
  getHostApplications,
  getEventStats,
  getHostReport,
  getEventsForSocial,
};
