import API from '../../utils/api/hosts';

const ACTION_PREFIX = 'HOSTS';

const getAllHosts = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getHosts(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getHostDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getHost(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};
//
// const submitCreateHost = (account) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
//   API.createHost(account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data}))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg}));
// };
//
// const submitEditHost = (id, account) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
//   API.updateHost(id, account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data}))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg}));
// };

export default {
  getAllHosts,
  getHostDetails,
  // submitCreateHost,
  // submitEditHost,
};
