import axios from 'axios';
import { useEffect, useState } from 'react';
import { getJWT } from '../utils/api/helpers';

const useListAPIHook = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 50, totalCount: 0 });

  useEffect(() => {
    if (url) {
      const token = getJWT();
      // console.log('token!', token);
      let headers = null;
      if (token) {
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      axios
        .get(url, { headers })
        .then((res) => {
          setData(res.data.data);
          setPagination(res.data.metadata);
          setLoading(false);
        })
        .catch((e) => setError(e.message));
    }
  }, [url]);

  return { data, loading, error, pagination };
};

export default useListAPIHook;
