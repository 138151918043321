const defaultState = {
  // Form Request Status
  registeredTeams: { data: [], metadata: {} },
  leaderboard: {},
  teamHistory: {},
  registeredTeamDetails: {},
  registeredTeamListRequest: {},
  registeredTeamDetailRequest: {},
  registeredTeamCreateRequest: {},
  registeredTeamEditRequest: {},
  registeredTeamDeleteRequest: {},
  registeredTeamLeaderboardRequest: {},
  registeredTeamHistoryRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'REGTEAMS_LIST_STARTED':
      return { ...state, registeredTeamListRequest: { working: true } };
    case 'REGTEAMS_LIST_FAILED':
      return { ...state, registeredTeamListRequest: { failed: true } };
    case 'REGTEAMS_LIST_SUCCESS':
      return {
        ...state,
        registeredTeams: action.payload,
        registeredTeamListRequest: { success: true },
      };

    case 'REGTEAMS_DETAIL_STARTED':
      return { ...state, registeredTeamDetailRequest: { working: true } };
    case 'REGTEAMS_DETAIL_FAILED':
      return { ...state, registeredTeamDetailRequest: { failed: true } };
    case 'REGTEAMS_DETAIL_SUCCESS':
      return {
        ...state,
        registeredTeamDetails: action.payload,
        registeredTeamDetailRequest: { success: true },
      };

    case 'REGTEAMS_CREATE_STARTED':
      return { ...state, registeredTeamCreateRequest: { working: true } };
    case 'REGTEAMS_CREATE_FAILED':
      return { ...state, registeredTeamCreateRequest: { failed: true } };
    case 'REGTEAMS_CREATE_SUCCESS':
      return {
        ...state,
        registeredTeamDetails: action.payload,
        registeredTeamCreateRequest: { success: true },
      };

    case 'REGTEAMS_EDIT_STARTED':
      return { ...state, registeredTeamEditRequest: { working: true } };
    case 'REGTEAMS_EDIT_FAILED':
      return {
        ...state,
        registeredTeamEditRequest: {
          failed: true,
          errorMessage: action.payload,
        },
      };
    case 'REGTEAMS_EDIT_SUCCESS':
      return {
        ...state,
        registeredTeamDetails: action.payload,
        registeredTeamEditRequest: { success: true },
      };
    case 'REGTEAMS_LEADERBOARD_STARTED':
      return { ...state, registeredTeamLeaderboardRequest: { working: true } };
    case 'REGTEAMS_LEADERBOARD_FAILED':
      return { ...state, registeredTeamLeaderboardRequest: { failed: true } };
    case 'REGTEAMS_LEADERBOARD_SUCCESS':
      return {
        ...state,
        leaderboard: action.payload,
        registeredTeamLeaderboardRequest: { success: true },
      };

    case 'REGTEAMS_HISTORY_STARTED':
      return { ...state, registeredTeamHistoryRequest: { working: true } };
    case 'REGTEAMS_HISTORY_FAILED':
      return { ...state, registeredTeamHistoryRequest: { failed: true } };
    case 'REGTEAMS_HISTORY_SUCCESS':
      return {
        ...state,
        teamHistory: action.payload,
        registeredTeamHistoryRequest: { success: true },
      };

    default:
      return state;
  }
};
