import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import LiveStreamResponseTable from '../../components/LiveStream/LiveStreamResponseTable';
import actions from './actions';
import Button from '../../components/Button';

class LiveStreamDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateAll: true,
    };
  }

  // componentDidMount() {
  //   this.updateList();
  // }

  markCorrect = (response) => {
    const { updateAll } = this.state;
    const { updateLiveStreamResponse, resetPolling, eventCode } = this.props;
    const promise = updateLiveStreamResponse(eventCode, response.id, { isCorrect: true }, updateAll);
    resetPolling(promise);
  };

  markIncorrect = (response) => {
    const { updateAll } = this.state;
    const { updateLiveStreamResponse, resetPolling, eventCode } = this.props;
    const promise = updateLiveStreamResponse(eventCode, response.id, { isCorrect: false }, updateAll);
    resetPolling(promise);
  };

  // markOthersWrong = (response) => {
  //   const { updateWrongAnswers, resetPolling, eventCode } = this.props;
  //   const { id, eventId, question } = response;
  //   const promise = updateWrongAnswers(eventCode, id, eventId, toNumber(question));
  //   resetPolling(promise);
  // };

  gradeQuestion = (q) => {
    const { gradeQuestion, resetPolling, eventCode } = this.props;
    // const { question } = response;
    const promise = gradeQuestion(eventCode, q);
    resetPolling(promise);
  };

  // updateList() {
  //   const { getLiveStreamResponses, eventId } = this.props;
  //   getLiveStreamResponses(eventId);
  // }

  render() {
    const {
      liveStreamResponses,
      liveStreamResponseRequest,
      updateLiveStreamResponse,
      // generateScoreboardResponse,
      questionNumber,
      columnsVisible,
    } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <h3>Question {questionNumber}</h3>
          </div>
          <div className="col-md-6 text-right">
            <Button className="btn btn-info" onClick={() => this.gradeQuestion(questionNumber)}>
              Grade Question
            </Button>{' '}
            {/* <Button className="btn btn-danger">Close Question</Button> */}
          </div>
        </div>
        <LiveStreamResponseTable
          liveStreamResponses={liveStreamResponses.data}
          updateLiveStreamResponse={updateLiveStreamResponse}
          columnsVisible={columnsVisible}
          // onSearch={() => this.updateList}
          isLoading={liveStreamResponseRequest.working}
          isSuccess={liveStreamResponseRequest.success}
          isFailed={liveStreamResponseRequest.failed}
          errorMessage={liveStreamResponseRequest.errorMessage}
          markCorrect={this.markCorrect}
          markIncorrect={this.markIncorrect}
          // markOthersWrong={this.markOthersWrong}
        />
      </>
    );
  }
}

LiveStreamDetailPage.propTypes = {
  // getLiveStreamResponses: PropTypes.func.isRequired,
  eventCode: PropTypes.string.isRequired,
  liveStreamResponseRequest: PropTypes.objectOf(PropTypes.shape),
  liveStreamResponses: PropTypes.objectOf(PropTypes.shape),
  updateLiveStreamResponse: PropTypes.func.isRequired,
  // updateWrongAnswers: PropTypes.func.isRequired,
  columnsVisible: PropTypes.arrayOf(String),
  questionNumber: PropTypes.number.isRequired,
  // generateScoreboardResponse: PropTypes.objectOf(PropTypes.shape),
  // eventId: PropTypes.number,
  resetPolling: PropTypes.func.isRequired,
  gradeQuestion: PropTypes.func.isRequired,
};
LiveStreamDetailPage.defaultProps = {
  liveStreamResponseRequest: {},
  liveStreamResponses: {},
  // generateScoreboardResponse: {},
  columnsVisible: ['grading'],
  // eventId: null,
};

const mapStateToProps = (state) => extend({}, state.liveStream, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LiveStreamDetailPage);
