const defaultState = {
  // Form Request Status
  hosts: { data: [], metadata: {} },
  hostDetails: {},
  hostListRequest: {},
  hostDetailRequest: {},
  hostCreateRequest: {},
  hostEditRequest: {},
  hostDeleteRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'HOSTS_LIST_STARTED':
      return { ...state, hostListRequest: { working: true } };
    case 'HOSTS_LIST_FAILED':
      return { ...state, hostListRequest: { failed: true } };
    case 'HOSTS_LIST_SUCCESS':
      return {
        ...state,
        hosts: action.payload,
        hostListRequest: { success: true },
      };

    case 'HOSTS_DETAIL_STARTED':
      return { ...state, hostDetailRequest: { working: true } };
    case 'HOSTS_DETAIL_FAILED':
      return { ...state, hostDetailRequest: { failed: true } };
    case 'HOSTS_DETAIL_SUCCESS':
      return {
        ...state,
        hostDetails: action.payload,
        hostDetailRequest: { success: true },
      };

    case 'HOSTS_CREATE_STARTED':
      return { ...state, hostCreateRequest: { working: true } };
    case 'HOSTS_CREATE_FAILED':
      return { ...state, hostCreateRequest: { failed: true } };
    case 'HOSTS_CREATE_SUCCESS':
      return {
        ...state,
        hostDetails: action.payload,
        hostCreateRequest: { success: true },
      };

    case 'HOSTS_EDIT_STARTED':
      return { ...state, hostEditRequest: { working: true } };
    case 'HOSTS_EDIT_FAILED':
      return { ...state, hostEditRequest: { failed: true } };
    case 'HOSTS_EDIT_SUCCESS':
      return {
        ...state,
        hostDetails: action.payload,
        hostEditRequest: { success: true },
      };

    default:
      return state;
  }
};
