import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/Payments/EditForm';

class PaymentEditPage extends Component {
  componentDidMount() {
    const { getPaymentDetails, routeParams } = this.props;
    getPaymentDetails(routeParams.id);
  }

  render() {
    const { submitEditPayment, paymentDetails, paymentDetailRequest, paymentEditRequest } = this.props;
    return (
      <div className="payment-page page-container">
        <Header currentPage="/payments" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit an Payment</h1>
            {paymentDetailRequest.working && <Spinner />}
            {paymentDetailRequest.success && (
              <EditForm
                entity={paymentDetails}
                onSubmit={submitEditPayment}
                loading={paymentEditRequest.working}
                success={paymentEditRequest.success}
                failed={paymentEditRequest.failed}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

PaymentEditPage.propTypes = {
  submitEditPayment: PropTypes.func.isRequired,
  getPaymentDetails: PropTypes.func.isRequired,
  paymentDetailRequest: PropTypes.objectOf(PropTypes.shape),
  paymentEditRequest: PropTypes.objectOf(PropTypes.shape),
  paymentDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
PaymentEditPage.defaultProps = {
  paymentDetails: {},
  paymentEditRequest: {},
  paymentDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.payments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(PaymentEditPage);
