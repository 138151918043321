import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import GenericActions from './actions/genericActions';

class Authenticator extends Component {
  componentDidMount() {
    const { authenticated, fetchProfileStarted, userProfile, getMyProfile } = this.props;
    if (authenticated && fetchProfileStarted !== true && !userProfile) {
      getMyProfile();
    }
  }

  render() {
    const { isSidebarOpen, children } = this.props;
    return <div className={isSidebarOpen ? 'no-scroll' : ''}>{children}</div>;
  }
}

Authenticator.propTypes = {
  authenticated: PropTypes.bool,
  fetchProfileStarted: PropTypes.bool,
  getMyProfile: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool,
  userProfile: PropTypes.objectOf(PropTypes.shape),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func, PropTypes.arrayOf(PropTypes.func)]).isRequired,
};

Authenticator.defaultProps = {
  authenticated: false,
  isSidebarOpen: false,
  fetchProfileStarted: false,
  userProfile: null,
};

const mapStateToProps = (state) => extend({}, state.global);

const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, GenericActions), dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Authenticator);
