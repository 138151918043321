import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import accountActions from '../Accounts/actions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/EventTemplates/EditForm';

class EventTemplateEditPage extends Component {
  componentDidMount() {
    const { getEventTemplateDetails, routeParams } = this.props;
    getEventTemplateDetails(routeParams.id);
  }

  render() {
    const { submitEditEventTemplate, eventTemplateDetails, eventTemplateDetailRequest, eventTemplateEditRequest, getAccountDetails, accountDetails } =
      this.props;
    return (
      <div className="eventTemplate-page page-container">
        <Header currentPage="/event-templates" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit an Event Template</h1>
            {eventTemplateDetailRequest.working && <Spinner />}
            {eventTemplateDetailRequest.success && (
              <EditForm
                entity={eventTemplateDetails}
                getAccountDetails={getAccountDetails}
                accountDetails={accountDetails}
                onSubmit={submitEditEventTemplate}
                loading={eventTemplateEditRequest.working}
                success={eventTemplateEditRequest.success}
                failed={eventTemplateEditRequest.failed}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

EventTemplateEditPage.propTypes = {
  submitEditEventTemplate: PropTypes.func.isRequired,
  getEventTemplateDetails: PropTypes.func.isRequired,
  getAccountDetails: PropTypes.func.isRequired,
  eventTemplateDetailRequest: PropTypes.objectOf(PropTypes.shape),
  eventTemplateEditRequest: PropTypes.objectOf(PropTypes.shape),
  eventTemplateDetails: PropTypes.objectOf(PropTypes.shape),
  accountDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
EventTemplateEditPage.defaultProps = {
  accountDetails: {},
  eventTemplateDetails: {},
  eventTemplateEditRequest: {},
  eventTemplateDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.eventTemplates, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, accountActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventTemplateEditPage);
