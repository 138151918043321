const defaultState = {
  // Form Request Status
  users: { data: [], metadata: {} },
  userDetails: {},
  userListRequest: {},
  userDetailRequest: {},
  userCreateRequest: {},
  userCreateCalendarRequest: {},
  userEditRequest: {},
  userDeleteRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'USERS_LIST_STARTED':
      return { ...state, userListRequest: { working: true } };
    case 'USERS_LIST_FAILED':
      return { ...state, userListRequest: { failed: true } };
    case 'USERS_LIST_SUCCESS':
      return {
        ...state,
        users: action.payload,
        userListRequest: { success: true },
      };

    case 'USERS_DETAIL_STARTED':
      return { ...state, userDetailRequest: { working: true } };
    case 'USERS_DETAIL_FAILED':
      return { ...state, userDetailRequest: { failed: true } };
    case 'USERS_DETAIL_SUCCESS':
      return {
        ...state,
        userDetails: action.payload,
        userDetailRequest: { success: true },
      };

    case 'USERS_CREATE_STARTED':
      return { ...state, userCreateRequest: { working: true } };
    case 'USERS_CREATE_FAILED':
      return {
        ...state,
        userCreateRequest: {
          failed: true,
          errorMessage: action.payload,
        },
      };
    case 'USERS_CREATE_SUCCESS':
      return {
        ...state,
        userDetails: action.payload,
        userCreateRequest: { success: true },
      };

    case 'USERS_CREATE_CALENDAR_STARTED':
      return { ...state, userCreateCalendarRequest: { working: true } };
    case 'USERS_CREATE_CALENDAR_FAILED':
      return {
        ...state,
        userCreateCalendarRequest: {
          failed: true,
          errorMessage: action.payload,
        },
      };
    case 'USERS_CREATE_CALENDAR_SUCCESS':
      return {
        ...state,
        // userDetails: action.payload,
        userCreateCalendarRequest: { success: true },
      };

    case 'USERS_EDIT_STARTED':
      return { ...state, userEditRequest: { working: true } };
    case 'USERS_EDIT_FAILED':
      return { ...state, userEditRequest: { failed: true } };
    case 'USERS_EDIT_SUCCESS':
      return {
        ...state,
        userDetails: action.payload,
        userEditRequest: { success: true },
      };

    default:
      return state;
  }
};
