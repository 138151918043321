import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/RegisteredTeams/CreateForm';

const RegisteredTeamCreatePage = ({ submitCreateRegisteredTeam, createRegisteredTeamRequest }) => (
  <div className="location-page page-container">
    <Header currentPage="/location" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create a RegisteredTeam</h1>
        <CreateForm
          // entity={registeredTeamDetails}
          onSubmit={submitCreateRegisteredTeam}
          loading={createRegisteredTeamRequest.working}
          success={createRegisteredTeamRequest.success}
          failed={createRegisteredTeamRequest.failed}
        />
      </div>
    </div>
  </div>
);

RegisteredTeamCreatePage.propTypes = {
  submitCreateRegisteredTeam: PropTypes.func.isRequired,
  createRegisteredTeamRequest: PropTypes.objectOf(PropTypes.shape),
};
RegisteredTeamCreatePage.defaultProps = {
  createRegisteredTeamRequest: {},
};

const mapStateToProps = (state) => extend({}, state.registeredTeams, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegisteredTeamCreatePage);
