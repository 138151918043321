import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import EventHostAvatar from '../Events/Widgets/EventHostAvatar';

const HostMultiValueLabel = ({ props, data }) => (
  <components.MultiValueLabel {...props}>
    <EventHostAvatar hostName={`${data.nickname}`} host={data} />
  </components.MultiValueLabel>
);

HostMultiValueLabel.propTypes = {
  props: PropTypes.objectOf(PropTypes.shape),
  data: PropTypes.objectOf(PropTypes.shape).isRequired,
};

HostMultiValueLabel.defaultProps = {
  props: {},
};

export default HostMultiValueLabel;
