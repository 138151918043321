import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend, remove } from 'lodash';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'react-feather';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import EventTemplatesTable from '../../components/EventTemplates/EventTemplatesTable';
import AddHostModal from '../../components/Events/AddHostModal';
import 'react-confirm-alert/src/react-confirm-alert.css';

class EventTemplatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
        isActive: true,
        isAutoGenerate: true,
      },
      showHostModal: false,
      eventToAddHostsTo: null,
      // selectedHostList: [],
    };
  }

  componentDidMount() {
    this.updateList();
  }

  componentDidUpdate(prevProps) {
    const { needRefreshEventList } = this.props;
    if (!prevProps.needRefreshEventList && needRefreshEventList) {
      // console.log("UPDATE!");
      this.updateList();
      this.onCancelHostModal();
    }
  }

  removeHost = (host, event) => {
    const { submitEditEventTemplate } = this.props;
    if (event.hosts.includes(host)) {
      remove(event.hosts, (h) => h.id === host.id);

      submitEditEventTemplate(event.id, event, true);
    } else {
      // console.log("Host Not Found", host, event);
    }
  };

  deleteEvent = (id) => {
    const { submitDeleteEventTemplate } = this.props;
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => submitDeleteEventTemplate(id, true),
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  addHost = (event) => {
    this.setState({ eventToAddHostsTo: event, showHostModal: true });
  };

  toggleActive = (eventTemplate, activeState) => {
    const { submitEditEventTemplate } = this.props;
    const t = eventTemplate;
    t.isActive = activeState;
    submitEditEventTemplate(t.id, t);
  };

  toggleAutoGenerate = (eventTemplate, activeState) => {
    const { submitEditEventTemplate } = this.props;
    const t = eventTemplate;
    t.isAutoGenerate = activeState;
    submitEditEventTemplate(t.id, t);
  };

  onCancelHostModal = () => {
    this.setState({ showHostModal: false, eventToAddHostsTo: null });
  };

  onCompleteAddHost = (hosts) => {
    const { submitEditEventTemplate } = this.props;
    const { eventToAddHostsTo } = this.state;
    // console.log("Complete Add Hosts", eventToAddHostsTo, hosts);
    eventToAddHostsTo.hosts = hosts;
    submitEditEventTemplate(eventToAddHostsTo.id, eventToAddHostsTo, false);
  };

  handleChange = (property, value) => {
    const { filter } = this.state;
    // console.log("Set Property", property, value);
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  handleFilter = (value) => {
    const { filter } = this.state;
    filter.isActive = value.isActive;
    filter.isAutoGenerate = value.isAutoGenerate;
    this.setState({ ...filter }, this.updateList);
  };

  updateList() {
    const { getAllEventTemplates } = this.props;
    const { filter } = this.state;
    getAllEventTemplates(filter);
  }

  render() {
    const { eventTemplates, eventTemplateListRequest } = this.props;
    const { eventToAddHostsTo, showHostModal } = this.state;
    return (
      <div className="page-container">
        <Header currentPage="/event-templates" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>EventTemplates</h1>
              </div>
              <div className="col text-right">
                <Link to="/event-templates/create" className="btn btn-success">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <EventTemplatesTable
              list={eventTemplates.data}
              pagination={eventTemplates.metadata}
              isLoading={eventTemplateListRequest.working}
              isSuccess={eventTemplateListRequest.success}
              isFailed={eventTemplateListRequest.failed}
              onAddHost={this.addHost}
              onRemoveHost={this.removeHost}
              onDeleteEvent={this.deleteEvent}
              onSearch={(val) => this.handleChange('search', val)}
              onFilter={(val) => this.handleFilter(val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
              onToggleActive={this.toggleActive}
              onToggleAutoGenerate={this.toggleAutoGenerate}
            />
            <AddHostModal
              onCancel={this.onCancelHostModal}
              onComplete={this.onCompleteAddHost}
              initialHostsList={eventToAddHostsTo && eventToAddHostsTo.hosts}
              show={showHostModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventTemplatesPage.propTypes = {
  getAllEventTemplates: PropTypes.func.isRequired,
  eventTemplates: PropTypes.objectOf(PropTypes.shape),

  needRefreshEventList: PropTypes.bool,

  submitEditEventTemplate: PropTypes.func.isRequired,
  submitDeleteEventTemplate: PropTypes.func.isRequired,
  eventTemplateListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
EventTemplatesPage.defaultProps = {
  needRefreshEventList: false,
  eventTemplates: {
    data: [],
    metadata: { pageNum: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.eventTemplates, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventTemplatesPage);
