import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'react-feather';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import BillingAccountsTable from '../../components/BillingAccounts/BillingAccountsTable';

class BillingAccountsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    // console.log("Set Property", property, value);
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  updateList() {
    const { getAllBillingAccounts } = this.props;
    const { filter } = this.state;
    getAllBillingAccounts(filter);
  }

  render() {
    const { billingAccounts, billingAccountListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/billing-accounts" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>BillingAccounts</h1>
              </div>
              <div className="col text-right">
                <Link to="/billing-accounts/create" className="btn btn-success">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <BillingAccountsTable
              list={billingAccounts.data}
              pagination={billingAccounts.metadata}
              isLoading={billingAccountListRequest.working}
              isSuccess={billingAccountListRequest.success}
              isFailed={billingAccountListRequest.failed}
              onSearch={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

BillingAccountsPage.propTypes = {
  getAllBillingAccounts: PropTypes.func.isRequired,
  billingAccounts: PropTypes.objectOf(PropTypes.shape),
  billingAccountListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
};
BillingAccountsPage.defaultProps = {
  billingAccounts: { data: [], metadata: { pageNum: 0, pageSize: 20, totalCount: 0 } },
};

const mapStateToProps = (state) => extend({}, state.billingAccounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(BillingAccountsPage);
