import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';

export default [
  {
    title: 'Tournaments',
    path: '/tournaments',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create Tournament',
    path: '/tournaments/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Tournament',
    path: '/tournaments/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Registered Tournament',
    path: '/tournaments/:id',
    component: View,
    options: { private: true },
  },
];
