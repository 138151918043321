import moment from 'moment';
import { EVENT_STATUS } from '../../config';
import { EVENT_TYPE } from '../../utils';

const eventFilters = [
  {
    key: 'active',
    name: 'Next 90 Days',
    obj: {
      startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(90, 'days').format('YYYY-MM-DD'),
      // status: [EVENT_STATUS.DRAFT, EVENT_STATUS.READY, EVENT_STATUS.PENDING],
      status: [],
      // isEventPublic: null,
    },
  },
  {
    key: 'allactive',
    name: 'All Active',
    obj: {
      startDate: '',
      endDate: '',
      status: [EVENT_STATUS.DRAFT, EVENT_STATUS.READY, EVENT_STATUS.PENDING],
      // isEventPublic: null,
    },
  },
  {
    key: 'twoweeks',
    name: '+/- Two Weeks',
    obj: {
      startDate: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
      endDate: moment().add(2, 'weeks').format('YYYY-MM-DD'),
      status: [EVENT_STATUS.DRAFT, EVENT_STATUS.READY, EVENT_STATUS.PENDING, EVENT_STATUS.COMPLETE],
      // isEventPublic: null,
    },
  },
  {
    key: 'private',
    name: 'Upcoming Private Events',
    obj: {
      startDate: '',
      endDate: '',
      eventTypes: [EVENT_TYPE.FUNDRAISER, EVENT_TYPE.LIVE, EVENT_TYPE.PRIVATE, EVENT_TYPE.TOURNAMENT],
      status: [EVENT_STATUS.DRAFT, EVENT_STATUS.READY, EVENT_STATUS.PENDING],
      // isEventPublic: null,
    },
  },
  // {
  //   key: 'unpaid',
  //   name: 'Unpaid Events',
  //   obj: {
  //     startDate: '',
  //     endDate: '',
  //     status: [],
  //     isInvoicePaid: false,
  //     isEventPublic: null,
  //   },
  // },
  // {
  //   key: 'paid',
  //   name: 'Paid Events',
  //   obj: {
  //     startDate: '',
  //     endDate: '',
  //     status: [],
  //     isInvoicePaid: true,
  //     isEventPublic: null,
  //   },
  // },
  {
    key: 'thisyear',
    name: 'This Year',
    obj: {
      startDate: moment(`${moment().format('YYYY')}-01-01`).format('YYYY-MM-DD'),
      endDate: moment(`${moment().format('YYYY')}-12-31`).format('YYYY-MM-DD'),
      status: [],
      // isEventPublic: null,
    },
  },
  {
    key: 'alltime',
    name: 'All Events',
    obj: {
      startDate: '',
      endDate: '',
      status: [],
      // isEventPublic: null,
    },
  },
];
export default eventFilters;
