import API from '../../utils/api/tournaments';

// import LeaderboardAPI from '../../utils/api/leaderboard';

const ACTION_PREFIX = 'TOURNAMENTS';

const getAllTournaments = (query) => (dispatch) => {
  // console.log('get');
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getTournaments(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getTournamentDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getTournament(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

// const getLeaderboard = (query) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_LEADERBOARD_STARTED` });
//   LeaderboardAPI.getLeaderboard(query)
//     .then((data) => dispatch({ type: `${ACTION_PREFIX}_LEADERBOARD_SUCCESS`, payload: data }))
//     .catch((errorMsg) =>
//       dispatch({
//         type: `${ACTION_PREFIX}_LEADERBOARD_FAILED`,
//         payload: errorMsg,
//       }),
//     );
// };

// const getTournamentHistory = (id, query) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_HISTORY_STARTED` });
//   API.getTournamentHistory(id, query)
//     .then((data) => dispatch({ type: `${ACTION_PREFIX}_HISTORY_SUCCESS`, payload: data }))
//     .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_HISTORY_FAILED`, payload: errorMsg }));
// };

const submitCreateTournament = (tournament) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createTournament(tournament)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditTournament = (id, tournament) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateTournament(id, tournament)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

export default {
  getAllTournaments,
  getTournamentDetails,
  // getLeaderboard,
  // getTournamentHistory,
  submitCreateTournament,
  submitEditTournament,
};
