import API from '../../utils/api/accounts';

const ACTION_PREFIX = 'ACCOUNTS';

const getAllAccounts = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getAccounts(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getAccountDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getAccount(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreateAccount = (account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createAccount(account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditAccount = (id, account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateAccount(id, account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

const getAllRegions = (query) => (dispatch) => {
  dispatch({ type: `REGION_LIST_STARTED` });

  API.getRegions(query)
    .then((data) => dispatch({ type: `REGION_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `REGION_LIST_FAILED`, payload: errorMsg }));
};

export default {
  getAllRegions,
  getAllAccounts,
  getAccountDetails,
  submitCreateAccount,
  submitEditAccount,
};
