import API from '../utils/api/global';

const checkAuth = () => (dispatch) => {
  dispatch({ type: 'AUTH_STARTED' });
  API.checkAuth((result) => {
    dispatch({ type: result ? 'AUTH_SUCCESS' : 'AUTH_FAILED' });
    // return dispatch(getMyProfile());
  });
};
const getMyProfile = () => (dispatch) => {
  dispatch({ type: 'FETCH_MY_PROFILE_STARTED' });
  API.getMyProfile((err, user) => {
    if (err) return dispatch({ type: 'FETCH_MY_PROFILE_FAILED', payload: err });
    dispatch({
      type: 'FETCH_MY_PROFILE_SUCCESS',
      payload: user,
    });
    return dispatch(checkAuth());
  });
};
export default {
  getLocalEmail: () => (dispatch) => {
    dispatch({ type: 'GET_LOCAL_EMAIL_STARTED' });
    API.getLocalEmail((email) => {
      dispatch({ type: 'GET_LOCAL_EMAIL_SUCCESS', payload: email });
    });
  },
  checkAuth,
  getMyProfile,
  logout: () => (dispatch) => {
    API.logout(() => {
      dispatch({ type: 'LOGOUT_SUCCESS' });
    });
  },
  toggleSidebar: () => (dispatch) => dispatch({ type: 'TOGGLE_SIDEBAR' }),
};
