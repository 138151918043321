import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get leaderboard
 * @param {object} query - the query
 */
function getLeaderboard(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.LEADERBOARD}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getLeaderboard,
};
