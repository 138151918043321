import React, { Component } from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Spinner from '../Spinner';

const eventCodeLetters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'J',
  'K',
  'M',
  'N',
  'P',
  'R',
  'S',
  'T',
  'U',
  'W',
  'X',
  'Y',
  'Z',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
];
class LiveStreamCreateForm extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) {
      resetForm();
    }
  }

  createLiveStreamCode = () => {
    const { setFieldValue } = this.props;
    let code = '';
    for (let i = 0; i < 4; i += 1) {
      code += eventCodeLetters[Math.round(Math.random() * eventCodeLetters.length - 1)];
    }
    setFieldValue('eventCode', code);
  };
  // const _handleSelect = (selectChoice) => {
  //   setFieldValue('imaginaryThingId', selectChoice.value);
  // };

  render() {
    const {
      // values,
      // touched,
      // errors,
      // dirty,
      isSubmitting,
      // handleChange,
      // setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      // handleReset,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && <div className="alert alert-success">LiveStream Created</div>}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="name">Name</label>
            <Field className="form-control" name="name" />
            <ErrorMessage name="name" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="dateTimeStart">Start Date</label>
            <Field className="form-control" type="datetime-local" name="dateTimeStart" />
            <ErrorMessage name="dateTimeStart" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="eventCode">Event Code</label>
            <Field className="form-control" name="eventCode" />
            <ErrorMessage name="eventCode" component="div" className="alert alert-danger" />
          </div>
          <div onClick={() => this.createLiveStreamCode()}>Gen</div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4">
            <label htmlFor="answers">Answers</label>
            <Field className="form-control" name="answers" component="textarea" />
            <ErrorMessage name="answers" component="div" className="alert alert-danger" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Link className="btn btn-default" to="/live-streams">
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
LiveStreamCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  // values added by Formik
  // values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
LiveStreamCreateForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',

  // values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  mapPropsToValues: ({ name, eventCode, dateTimeStart }) => ({
    name: name || '',
    eventCode: eventCode || '',
    dateTimeStart: dateTimeStart || `${moment(new Date()).format('YYYY-MM-DD')}T20:00:00`,
    answers: '',
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },
  displayName: 'LiveStream Creation Form',
})(LiveStreamCreateForm);
