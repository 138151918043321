import { map } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { CheckCircle, Edit, ExternalLink, PlusCircle, RefreshCw, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { PAYMENT_TYPE } from '../../utils';
import EventHostAvatar from '../Events/Widgets/EventHostAvatar';
import EventType from '../Events/Widgets/EventType';
import InvoicingStatusIcon from '../PaymentMethodIcon/InvoicingStatusIcon';
import Spinner from '../Spinner';

const LIMITS = [20, 50, 100];
const EventTemplatesTable = ({
  list,
  pagination,
  isLoading,
  isSuccess,
  isFailed,
  errorMessage,
  onPageSizeChange,
  onPageNumberChange,
  onSearch,
  onFilter,
  onAddHost,
  onRemoveHost,
  onDeleteEvent,
  onToggleActive,
  onToggleAutoGenerate,
}) => {
  const [isActive, setIsActive] = useState(true);
  const [isAutoGenerate, setIsAutoGenerate] = useState(null);

  useEffect(() => {
    onFilter({ isActive, isAutoGenerate });
  }, [isActive, isAutoGenerate]);

  return (
    <div>
      <div className="row">
        <div className="col-sm-4">
          <input type="text" placeholder="Search..." className="form-control" onChange={(e) => onSearch(e.target.value)} />
        </div>
        <div className="col-sm-4">
          Active:{' '}
          <Button className="status-button mb-2" color={isActive === true ? 'primary' : 'link'} size="sm" onClick={() => setIsActive(true)}>
            Yes
          </Button>
          <Button className="status-button mb-2" color={isActive === false ? 'primary' : 'link'} size="sm" onClick={() => setIsActive(false)}>
            No
          </Button>
          <Button className="status-button mb-2" color={isActive === null ? 'primary' : 'link'} size="sm" onClick={() => setIsActive(null)}>
            Both
          </Button>{' '}
          &nbsp;
        </div>
        <div className="col-sm-4">
          AutoGen:{' '}
          <Button
            className="status-button mb-2"
            color={isAutoGenerate === true ? 'primary' : 'link'}
            size="sm"
            onClick={() => setIsAutoGenerate(true)}
          >
            Enabled
          </Button>
          <Button
            className="status-button mb-2"
            color={isAutoGenerate === false ? 'primary' : 'link'}
            size="sm"
            onClick={() => setIsAutoGenerate(false)}
          >
            Disabled
          </Button>
          <Button
            className="status-button mb-2"
            color={isAutoGenerate === null ? 'primary' : 'link'}
            size="sm"
            onClick={() => setIsAutoGenerate(null)}
          >
            Both
          </Button>{' '}
          &nbsp;
        </div>
      </div>
      <Table striped hover responsive size="sm" className="element-list">
        <thead>
          <tr>
            <th />
            <th>Name</th>
            {/* <th>Account</th> */}
            <th className="text-center">When</th>
            <th className="text-right">Hosts</th>
            <th className="text-right">$$</th>

            {/* <th className="text-center">Inv</th> */}
            <th className="text-center">AutoGen</th>
            <th className="text-center">Billing</th>
            <th className="text-center">Invoicing</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {isFailed && (
            <tr>
              <td colSpan="12" className="danger">
                {errorMessage}
              </td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan="12">
                <Spinner />
              </td>
            </tr>
          )}
          {isSuccess && list.length < 1 && (
            <tr>
              <td colSpan="12">No Event Templates Exist</td>
            </tr>
          )}
          {isSuccess &&
            list &&
            map(list, (item) => (
              <tr key={item.id}>
                <td>
                  <EventType type={item.eventType} />
                </td>
                <td>
                  {/* <Link to={`/event-templates/${item.id}`} className="action"> */}
                  {item.name}
                  {/* </Link> */}
                  <div>
                    <small className="text-muted">
                      <a href={`/accounts/${item.account?.id}`} title={item.account?.name}>
                        {' '}
                        Account
                      </a>
                      {' | '}
                      <a href={`/locations/${item.location?.id}`} title={item.location?.name}>
                        Location
                      </a>
                    </small>
                  </div>
                </td>
                {/* <td>
                  
                </td> */}
                <td className="text-left">
                  {item.dayOfWeek} @ {moment(item.time, ['HH:mm']).format('h:mm a')}
                </td>
                <td className="text-right">
                  {item.hosts.map((host) => (
                    <EventHostAvatar key={host.id} host={host} event={item} onClickHost={onRemoveHost} />
                  ))}
                  <PlusCircle className="clickable-badge" size={15} onClick={() => onAddHost(item)} />
                </td>
                <td className="text-right">{`$${item.eventGross}`}</td>
                <td className="text-center">
                  {/* {item.isAutoGenerate ? <RefreshCw color="green" size={20} /> : <RefreshCw color="lightgray" size={20} />} */}
                  {item.isAutoGenerate && (
                    <span className="clickable-avatar" title="Weekly Events will Generate" onClick={() => onToggleAutoGenerate(item, false)}>
                      <RefreshCw color="green" />
                    </span>
                  )}
                  {!item.isAutoGenerate && (
                    <span className="clickable-avatar" title="Weekly Events will NOT Generate" onClick={() => onToggleAutoGenerate(item, true)}>
                      <RefreshCw color="lightgray" />
                    </span>
                  )}{' '}
                  {item.isActive && (
                    <span className="clickable-avatar" title="Venue is Active" onClick={() => onToggleActive(item, false)}>
                      <CheckCircle color="green" />
                    </span>
                  )}
                  {!item.isActive && (
                    <span className="clickable-avatar" title="Venue is Inactive" onClick={() => onToggleActive(item, true)}>
                      <CheckCircle color="lightgray" />
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <InvoicingStatusIcon event={item} />
                </td>
                <td className="text-center">
                  {item.paymentMethod}
                  {item.paymentMethod === PAYMENT_TYPE.ACCOUNT && (
                    <>
                      {item.billingAccountId && (
                        <Link to={`/billing-accounts/${item.billingAccountId}`}>
                          <ExternalLink size={15} />
                        </Link>
                      )}
                      {!item.billingAccountId && <div className="error">No Billing Acct Set!</div>}
                    </>
                  )}
                </td>
                <td className="actions">
                  <span className="rare-actions delete-action clickable-avatar" onClick={() => onDeleteEvent(item.id)}>
                    <Trash2 size={15} color="red" />
                  </span>

                  <Link to={`/event-templates/${item.id}/edit`} className="action">
                    <Edit color="#999" size={15} />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="row query-footer">
        <div className="page-size form-inline col-sm-6">
          <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
            {map(LIMITS, (opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>
          {' per page'}
        </div>
        <div className="col-sm-6 text-right">
          <Pagination
            onChange={onPageNumberChange}
            showLessItems
            current={pagination.pageNumber}
            total={pagination.totalCount}
            pageSize={pagination.pageSize || 20}
          />
        </div>
      </div>
    </div>
  );
};

EventTemplatesTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,

  onToggleActive: PropTypes.func.isRequired,
  onToggleAutoGenerate: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onAddHost: PropTypes.func.isRequired,
  onRemoveHost: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
EventTemplatesTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  list: [],
  pagination: {
    pageNumber: 1,
    pageSize: 20,
    totalCount: 1000,
  },
};

export default EventTemplatesTable;
