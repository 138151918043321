import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/Accounts/CreateForm';

const AccountCreatePage = ({ submitCreateAccount, accountDetails, accountCreateRequest }) => (
  <div className="account-page page-container">
    <Header currentPage="/accounts" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create an Account</h1>
        <CreateForm
          account={accountDetails}
          onSubmit={submitCreateAccount}
          accountDetails={accountDetails}
          loading={accountCreateRequest.working}
          success={accountCreateRequest.success}
          failed={accountCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

AccountCreatePage.propTypes = {
  submitCreateAccount: PropTypes.func.isRequired,
  accountCreateRequest: PropTypes.objectOf(PropTypes.shape),
  accountDetails: PropTypes.objectOf(PropTypes.shape),
  // account: PropTypes.objectOf(PropTypes.shape),
};
AccountCreatePage.defaultProps = {
  accountDetails: {},
  accountCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(AccountCreatePage);
