import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header/index';
import Spinner from '../../components/Spinner';
import EditForm from '../../components/LiveStream/EditForm';

class LiveStreamEditPage extends Component {
  componentDidMount() {
    const { getLiveStreamDetails, routeParams } = this.props;
    getLiveStreamDetails(routeParams.id);
  }

  render() {
    const { submitEditLiveStream, liveStreamDetails, liveStreamDetailRequest, liveStreamEditRequest } = this.props;
    return (
      <div className="liveStream-page page-container">
        <Header currentPage="/liveStreams" />
        <div className="container-fluid">
          <div className="content-box">
            <h1 className="section-title">Edit a LiveStream</h1>
            {liveStreamDetailRequest.working && <Spinner />}
            {!liveStreamDetailRequest.working && (
              <EditForm
                entity={liveStreamDetails}
                // onSubmit={submitEditLiveStream}
                loading={liveStreamEditRequest.working}
                success={liveStreamEditRequest.success}
                failed={liveStreamEditRequest.failed}
                onSubmit={submitEditLiveStream}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LiveStreamEditPage.propTypes = {
  submitEditLiveStream: PropTypes.func.isRequired,
  getLiveStreamDetails: PropTypes.func.isRequired,
  liveStreamDetailRequest: PropTypes.objectOf(PropTypes.shape),
  liveStreamEditRequest: PropTypes.objectOf(PropTypes.shape),
  liveStreamDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
LiveStreamEditPage.defaultProps = {
  liveStreamDetails: {},
  liveStreamEditRequest: {},
  liveStreamDetailRequest: {},
};

const mapStateToProps = (state) => extend({}, state.liveStream, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LiveStreamEditPage);
