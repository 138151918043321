import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'reactstrap';
import './dashboard.css';

const PendingApplicationsTable = ({ applications }) => (
  <div>
    <Table striped size="sm" hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Video</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {applications.data &&
          applications.data.length > 0 &&
          applications.data.map((application) => (
            <tr key={application.id}>
              <td>
                <Link to={`/contact/applications/${application.id}`}>{application.name}</Link>
                <div className="event-date">{moment(application.created).format('MM/DD/YY')}</div>
              </td>
              <td>{application.email}</td>
              <td>
                {application.youtubeURL && (
                  <a href={application.youtubeURL} target="noopener noreferrer">
                    URL
                  </a>
                )}
              </td>
              <td>{application.type}</td>
            </tr>
          ))}
        {applications.data && applications.data.length <= 0 && (
          <tr>
            <td colSpan="6">No pending applications</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

PendingApplicationsTable.propTypes = {
  applications: PropTypes.objectOf(PropTypes.shape).isRequired,
};
PendingApplicationsTable.defautProps = {
  applications: { data: [] },
};

export default PendingApplicationsTable;
