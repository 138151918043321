import { extend } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import ordinal from 'ordinal';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
// import Button from '../../components/Button';

import Header from '../Header';
import './styles.css';

// const YEARS = [
//   { id: 2019, name: 2019 },
//   { id: 2020, name: 2020 },
//   { id: 2021, name: 2021 },
// ];

class TournamentsDetailPage extends Component {
  // constructor(props) {
  //   super(props);
  // this.state = {
  //   selectedYear: {},
  // };
  // }

  componentDidMount() {
    //   this.onSelectChange(moment().format('Y'));
    this.updateList();
  }

  // onSelectChange = (v) => {
  //   this.setState({ selectedYear: v }, this.updateList);
  // };

  updateList() {
    const { getTournamentDetails, routeParams } = this.props;
    // const { selectedYear } = this.state;
    getTournamentDetails(routeParams.id);
    // getTournamentHistory(routeParams.id, historyFilter);
  }

  render() {
    const { tournamentDetails } = this.props;
    // const { selectedYear } = this.state;
    return (
      <div className="page-container">
        <Header currentPage="/tournaments" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col-12">
                <h3>Tournament Details</h3>

                <div>{`Name: ${tournamentDetails.name}`}</div>
                <div>{`Key: ${tournamentDetails.key}`}</div>

                <h3>Events:</h3>
                {/* <pre>{JSON.stringify(tournamentDetails, null, 2)}</pre> */}
                <Table striped hover responsive size="sm" className="element-list">
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Spots</th>
                      <th>Registered Teams</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tournamentDetails &&
                      tournamentDetails.events &&
                      tournamentDetails.events.map((e) => (
                        <tr key={e.id}>
                          <td>{e.name}</td>
                          <td>{e.tournamentMaxTeams}</td>
                          <td>{e.registeredTeamCount}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <h3>Teams:</h3>
                <Table striped hover responsive size="sm" className="element-list">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Semi-Final</th>
                      <th>Players</th>
                      <th>Paid</th>
                      <th>In Tournament</th>
                      <th>Place in Finals</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tournamentDetails &&
                      tournamentDetails.tournamentTeams &&
                      tournamentDetails.tournamentTeams.map((t) => (
                        <tr key={t.id}>
                          <td>{t.email}</td>
                          <td>{t.registeredTeam.teamUsername}</td>
                          <td>{t.registeredTeam.name}</td>
                          <td>
                            {t.event ? (
                              <span>
                                {t.event.name}
                                <br /> {t.event.location.name}
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{t.players}</td>
                          <td>${t.amount ? t.amount : 0}</td>
                          <td>
                            {t.inTournament === true && <div> Yes</div>}
                            {t.inTournament === false && <div> No</div>}
                          </td>
                          <td>{t.placeInFinals ? ordinal(t.placeInFinals) : ''}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className="col-4">
                <Link to={`/tournaments/${tournamentDetails.key}/edit`} className="btn btn-success">
                  Edit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TournamentsDetailPage.propTypes = {
  getTournamentDetails: PropTypes.func.isRequired,
  // getTournamentHistory: PropTypes.func.isRequired,
  tournamentDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
  // teamHistory: PropTypes.objectOf(PropTypes.shape),
};
TournamentsDetailPage.defaultProps = {
  tournamentDetails: {},
  // teamHistory: {},
};

const mapStateToProps = (state) => extend({}, state.tournaments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(TournamentsDetailPage);
