import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, uniq, xor } from 'lodash';
import { Button } from 'reactstrap';
import groups from '../../data/groups.json';

class UsersFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // quickFilter: userFilterPresets[0].key,
      // filters: userFilterPresets[0].obj,
      isVisible: true,
      filters: {
        isActive: '',
        groups: [groups[0].id],
      },
    };
    this.updateFilter = this.updateFilter.bind(this);
  }

  componentDidMount() {
    this.updateFilter();
  }

  toggleVisible = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  setActive = (state) => {
    const { filters } = this.state;
    filters.isActive = state;
    this.setState({ filters }, this.updateFilter);
  };

  toggleGroup = (group, exists) => {
    const { filters } = this.state;
    if (exists) filters.groups = xor(filters.groups, [group.id]);
    else filters.groups = uniq([...filters.groups, group.id]);

    // console.log(filters.groups, exists);
    this.setState({ filters }, this.updateFilter);
  };
  // toggleStatus = (status) => {
  //   const { filters } = this.state;
  //   if(filters.status.includes(status))
  //     filters.status = difference(filters.status, [status]);
  //   else
  //     filters.status.push(status);
  //   this.setState({filters, quickFilter:null}, this.updateFilter);
  // };

  // change = (property, value) => {
  //   const { filters } = this.state;
  //   filters[property] = value;
  //   this.setState({filters, quickFilter:null}, this.updateFilter);
  // };

  updateFilter = () => {
    const { onUpdateFilter } = this.props;
    const { filters } = this.state;
    // console.log("Filters", filters);
    const newFilters = { ...filters };
    onUpdateFilter(newFilters);
  };

  render() {
    const {
      isLoading,
      // onToggleWeekSeparator,
      // separateWeekRows,
    } = this.props;
    const {
      filters,
      // quickFilter,
      isVisible,
    } = this.state;

    return (
      <div className="filter-pane">
        <div className="row">
          <div className="col text-right">
            <Button color="link" onClick={() => this.toggleVisible()}>
              {isVisible ? '-' : '+'} Filters
            </Button>
          </div>
        </div>
        <div className={`row ${isVisible ? '' : 'd-none'}`}>
          <div className="col-4">
            <label>Groups</label>{' '}
            {groups.map((group) => (
              <span key={group.id}>
                <Button
                  disabled={isLoading}
                  color={includes(filters.groups, group.id) ? 'info' : 'link'}
                  size="sm"
                  onClick={() => this.toggleGroup(group, includes(filters.groups, group.id))}
                >
                  {group.name}
                </Button>{' '}
              </span>
            ))}
          </div>
          <div className="col-4">
            <Button disabled={isLoading} color={filters.isActive === '' ? 'info' : 'link'} onClick={() => this.setActive('')} size="sm">
              All
            </Button>
            <Button disabled={isLoading} color={filters.isActive === true ? 'info' : 'link'} onClick={() => this.setActive(true)} size="sm">
              Active
            </Button>
            <Button disabled={isLoading} color={filters.isActive === false ? 'info' : 'link'} onClick={() => this.setActive(false)} size="sm">
              Inactive
            </Button>
          </div>
          <div className="col text-right" />
        </div>
      </div>
    );
  }
}

UsersFilter.propTypes = {
  isLoading: PropTypes.bool,
  onUpdateFilter: PropTypes.func.isRequired,
};
UsersFilter.defaultProps = {
  isLoading: false,
};

export default UsersFilter;
