import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import GenericActions from '../../actions/genericActions';
import actions from './actions';

class TournamentSelector extends Component {
  componentDidMount() {
    const { getAllTournaments } = this.props;
    getAllTournaments({ active: true, pageSize: 50 });
  }

  onChange = (values) => {
    const { onUpdate } = this.props;
    onUpdate(values);
  };

  onInputChange = (searchString, action) => {
    const { getAllTournaments } = this.props;
    if (action.action === 'input-change') getAllTournaments({ active: true, name: searchString, pageSize: 50 });
  };

  render() {
    const { tournaments, selectedTournament, tournamentListRequest } = this.props;

    return (
      <Select
        classNamePrefix="select"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        isClearable
        isLoading={tournamentListRequest.working}
        value={selectedTournament}
        onChange={this.onChange}
        options={tournaments.data}
        onInputChange={this.onInputChange}
      />
    );
  }
}

TournamentSelector.propTypes = {
  getAllTournaments: PropTypes.func.isRequired,
  tournaments: PropTypes.objectOf(PropTypes.shape),
  tournamentListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  selectedTournament: PropTypes.objectOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
};
TournamentSelector.defaultProps = {
  selectedTournament: {},
  tournaments: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
};

const mapStateToProps = (state) => extend({}, state.tournaments, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(TournamentSelector);
