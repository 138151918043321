import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';

class EventTemplatesDetailPage extends Component {
  componentDidMount() {
    this.updateList();
  }

  updateList() {
    const { getEventTemplateDetails, routeParams } = this.props;
    getEventTemplateDetails(routeParams.id);
  }

  render() {
    const { eventTemplateDetails } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/eventTemplates" />
        <div className="container-fluid">
          <div className="content-box">
            <h3>EventTemplate Details</h3>
            <div>
              EventTemplate:
              {eventTemplateDetails.name}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EventTemplatesDetailPage.propTypes = {
  getEventTemplateDetails: PropTypes.func.isRequired,
  eventTemplateDetails: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
EventTemplatesDetailPage.defaultProps = {
  eventTemplateDetails: {},
};

const mapStateToProps = (state) => extend({}, state.eventTemplates, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventTemplatesDetailPage);
