import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import accountActions from '../Accounts/actions';
import Header from '../Header/index';
import CreateForm from '../../components/Events/CreateForm';

const EventCreatePage = ({
  submitCreateEvent,
  eventDetails,
  eventCreateRequest,
  // accounts,
  // getAllAccounts,
  // locations,
  // getAllLocations,
  getAccountDetails,
  accountDetails,
}) => (
  <div className="event-page page-container">
    <Header currentPage="/event" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create an Event</h1>
        <CreateForm
          onSubmit={submitCreateEvent}
          getAccountDetails={getAccountDetails}
          accountDetails={accountDetails}
          eventDetails={eventDetails}
          loading={eventCreateRequest.working}
          success={eventCreateRequest.success}
          failed={eventCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

EventCreatePage.propTypes = {
  submitCreateEvent: PropTypes.func.isRequired,
  eventCreateRequest: PropTypes.objectOf(PropTypes.shape),
  accountDetails: PropTypes.objectOf(PropTypes.shape),
  eventDetails: PropTypes.objectOf(PropTypes.shape),
  // event: PropTypes.objectOf(PropTypes.shape),
  getAccountDetails: PropTypes.func.isRequired,
};
EventCreatePage.defaultProps = {
  eventDetails: {},
  accountDetails: {},
  // event: {},
  eventCreateRequest: {},
};

const mapStateToProps = (state) => extend({}, state.events, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, accountActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventCreatePage);
