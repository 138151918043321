import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'reactstrap';
import Spinner from '../Spinner';

const LEAD_STATUSES = [
  { value: 'new', label: 'New' },
  { value: 'contacted', label: 'Contacted' },
  { value: 'booked', label: 'Booked' },
  { value: 'waiting', label: 'Waiting on Customer' },
  { value: 'lost', label: 'Lost' },
  { value: 'test', label: 'Test' },
];
class CreateLeadMessageForm extends Component {
  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) resetForm();
  }

  render() {
    const {
      values,
      touched,
      errors,
      // dirty,
      isSubmitting,
      // handleChange,
      setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      // eventDetails,
      // accountDetails,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && <div className="alert alert-success">Message Created</div>}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="status">Status</label>
                <Select
                  value={LEAD_STATUSES.filter(({ value }) => value === values.status)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  onChange={({ value }) => setFieldValue('status', value)}
                  // closeMenuOnSelect={false}
                  options={LEAD_STATUSES}
                  classNamePrefix="select"
                />
                {errors.status && touched.status && <div className="alert alert-danger">{errors.status}</div>}
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="name">Message</label>
                <Field name="message" className="form-control" />
                {errors.message && touched.message && <div className="alert alert-danger">{errors.message}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
CreateLeadMessageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
  // leadDetail: PropTypes.objectOf(PropTypes.shape),
};
CreateLeadMessageForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // leadDetail: {},
  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ leadDetail }) => ({
    message: '',
    status: leadDetail.status || 'new',
  }),

  validationSchema: Yup.object().shape({
    status: Yup.string().required('Please select a status'),
    message: Yup.string().required('Please enter a message'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const vals = { ...values };
    props.onSubmit(vals);
    setSubmitting(false);
  },
  displayName: 'Lead Message Creation Form',
})(CreateLeadMessageForm);
