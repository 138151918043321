import { map } from 'lodash';
import { META } from './config';
import HomePage from './containers/Home';
import LoginPage from './containers/Login';
import UpdatePasswordPage from './containers/UpdatePassword';
import UserProfilePage from './containers/UserProfile';

import AccountRoutes from './containers/Accounts/routes';
import PaymentRoutes from './containers/Payments/routes';
import LocationRoutes from './containers/Locations/routes';
import EventRoutes from './containers/Events/routes';
import InvoiceRoutes from './containers/Invoicing/routes';
import EventTemplateRoutes from './containers/EventTemplates/routes';
import UsersRoutes from './containers/Users/routes';
import ContactRoutes from './containers/Contact/routes';
import LiveStreamRoutes from './containers/LiveStream/routes';
// import HostsRoutes from './containers/Hosts/routes';
import TeamsRoutes from './containers/RegisteredTeams/routes';
import Tournaments from './containers/Tournaments/routes';
import LeadsRoutes from './containers/Leads/routes';
import BillingAccountsRoutes from './containers/BillingAccounts/routes';

import NotFoundPage from './containers/NotFoundPage/index';

const createPage = (path, title, component, options) => {
  const description = options ? options.description : META.PAGE_DESCRIPTION;
  const keywords = options ? options.keywords : META.PAGE_KEYWORDS;
  const privateRoute = !!options && !!options.private;

  return {
    path,
    title: `${title} | ${META.PAGE_TITLE_SUFFIX}`,
    description,
    keywords,
    component,
    private: privateRoute,
  };
};

const loadRoutes = (file) => map(file, (route) => createPage(route.path, route.title, route.component, route.options));

const exactRoutes = [
  createPage('/', 'Home', HomePage, { private: true }),
  ...loadRoutes(AccountRoutes),
  ...loadRoutes(LocationRoutes),
  ...loadRoutes(TeamsRoutes),
  ...loadRoutes(EventRoutes),
  ...loadRoutes(UsersRoutes),
  ...loadRoutes(PaymentRoutes),
  ...loadRoutes(EventTemplateRoutes),
  ...loadRoutes(ContactRoutes),
  ...loadRoutes(LeadsRoutes),
  ...loadRoutes(LiveStreamRoutes),
  ...loadRoutes(Tournaments),
  ...loadRoutes(InvoiceRoutes),
  ...loadRoutes(BillingAccountsRoutes),
  // ...loadRoutes(HostsRoutes),
  createPage('/profile', 'Profile', UserProfilePage, { private: true }),
  createPage('/login', 'Log In', LoginPage),
  // createPage('/register', 'Register', RegisterPage),
  createPage('/reset-password/:resetCode', 'Reset password', UpdatePasswordPage),

  createPage('/404', 'NotFound', NotFoundPage),
];
// console.log(exactRoutes);

const redirectRoutes = [
  // { from: '/rt', to: 'registered-teams' },
];

export default {
  exactRoutes,
  redirectRoutes,
};
