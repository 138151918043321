import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { difference } from 'lodash';
import { Button } from 'reactstrap';

// import eventFilterPresets from './eventFilters';
// import './eventsFilter.css';
const LEAD_STATUSES = [
  { key: 'new', value: 'New' },
  { key: 'contacted', value: 'Contacted' },
  { key: 'waiting', value: 'Waiting on Customer' },
  { key: 'booked', value: 'Booked' },
  { key: 'lost', value: 'Lost' },
  { key: 'test', value: 'Test' },
];

class LeadsFilter extends Component {
  constructor(props) {
    super(props);

    const { filter } = this.props;

    this.state = {
      filters: { ...filter, status: ['new', 'contacted'] },
    };
  }

  componentDidMount() {
    this.updateFilter();
  }

  toggleStatus = (status) => {
    const { filters } = this.state;
    if (filters.status.includes(status)) filters.status = difference(filters.status, [status]);
    else filters.status.push(status);
    this.setState({ filters }, this.updateFilter);
  };

  updateFilter = () => {
    const { onUpdateFilter } = this.props;
    const { filters } = this.state;
    onUpdateFilter({ ...filters });
  };

  render() {
    const { isLoading } = this.props;
    const { filters } = this.state;

    return (
      <div className="filter-pane">
        <div className="row mt-3">
          <div className="col-12 col-md-6 mb-3 flex-align-center">
            <div className="filter-label">Status </div>
            {LEAD_STATUSES.map((option) => (
              <span key={option.key}>
                <Button
                  className="status-button mb-2"
                  disabled={isLoading}
                  color={filters.status.includes(option.key) ? 'primary' : 'link'}
                  size="sm"
                  onClick={() => this.toggleStatus(option.key)}
                >
                  {option.value}
                </Button>{' '}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

LeadsFilter.propTypes = {
  // startDate: PropTypes.string,
  // endDate: PropTypes.string,
  // status: PropTypes.string,
  filter: PropTypes.objectOf(PropTypes.shape).isRequired,
  isLoading: PropTypes.bool,
  // isVisible: PropTypes.bool,
  onUpdateFilter: PropTypes.func.isRequired,
};

LeadsFilter.defaultProps = {
  isLoading: false,
};

export default LeadsFilter;
