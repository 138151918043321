import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { trim, forEach, every, omit } from 'lodash';
import moment from 'moment/moment';
import Select from 'react-select';
import classnames from 'classnames';
import Checkbox from '../Forms/formik-checkbox';
import { EVENT_STATUSES, EVENT_TYPES, PAYMENT_METHODS } from '../../utils';

import './eventsSidebar.css';

class EventsSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        selected: false,
        value: '',
      },
      dateTimeStart: {
        selected: false,
        value: '',
      },
      eventGross: {
        selected: false,
        value: 0,
      },
      invoiceToEmail: {
        selected: false,
        value: '',
      },
      togglePaidSection: {
        selected: false,
      },
      isInvoicePaid: {
        selected: false,
        value: false,
      },
      isAutoSendInvoice: {
        selected: false,
        value: false,
      },
      isCreateInvoice: {
        selected: false,
        value: false,
      },
      eventType: {
        selected: false,
        value: false,
      },
      paymentMethod: {
        selected: false,
        value: false,
      },
      paymentInfo: {
        selected: false,
        value: false,
      },
      status: {
        selected: false,
        value: false,
      },
      billing: {
        selected: false,
      },
    };

    this.updateFieldSelection = this.updateFieldSelection.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
    this.toggleBilling = this.toggleBilling.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.submitUpdates = this.submitUpdates.bind(this);
    this.onToggleSidebar = this.onToggleSidebar.bind(this);
  }

  onToggleSidebar() {
    const { isExpanded, toggleSidebar } = this.props;

    if (isExpanded) {
      this.setState({});
    }

    toggleSidebar();
  }

  updateFieldSelection(field, selected) {
    this.setState((prevState) => ({
      [field]: {
        ...prevState[field],
        selected,
      },
    }));
  }

  toggleBilling(selected) {
    this.setState((prevState) => ({
      billing: {
        selected,
      },
      // isAutoSendInvoice: {
      //   selected,
      //   value: false,
      // },
      // isCreateInvoice: {
      //   selected,
      //   value: false,
      // },
      invoiceToEmail: {
        ...prevState.invoiceToEmail,
        selected: false,
      },
    }));
  }

  updateFieldValue(field, value) {
    this.setState((prevState) => ({
      [field]: {
        ...prevState[field],
        value,
      },
      // ...(field === 'togglePaidSection' && !value
      //   ? {
      //       isInvoicePaid: {
      //         selected: true,
      //         value: false,
      //       },
      //     }
      //   : {}),
    }));
  }

  validateFields() {
    let isValid = false;

    every(omit(this.state, 'billing'), (field, key) => {
      // console.log(field, key);
      if (field.selected) {
        isValid = key === 'eventGross' ? true : !!field.value;
        if (key === 'togglePaidSection') {
          isValid = true;
        }
        return isValid;
      }
      return true;
    });

    return isValid;
  }

  submitUpdates() {
    const updates = {};
    const { paymentMethod, paymentInfo, isInvoicePaid, isCreateInvoice, isAutoSendInvoice } = this.state;
    forEach(omit(this.state, 'billing'), (field, key) => {
      // console.log(field);
      if (field.selected) {
        switch (key) {
          case 'dateTimeStart':
            updates[key] = moment(field.value).format('YYYY-MM-DDTHH:mm');
            break;
          case 'togglePaidSection':
            updates.paymentMethod = paymentMethod.value;
            updates.paymentInfo = paymentInfo.value;
            updates.isInvoicePaid = isInvoicePaid.value;
            updates.isCreateInvoice = isCreateInvoice.value;
            updates.isAutoSendInvoice = isAutoSendInvoice.value;
            if (isInvoicePaid.value === true) {
              updates.stripeInvoicePaidOn = moment().format('YYYY-MM-DDTHH:mm');
            } else {
              updates.stripeInvoicePaidOn = null;
            }
            break;

          default:
            updates[key] = trim(field.value);
        }
      }
    });
    // console.log('updates', updates);
    const { updateEvents } = this.props;
    updateEvents(updates);
  }

  render() {
    const { isExpanded } = this.props;
    const {
      name,
      dateTimeStart,
      eventGross,
      eventType,
      status,
      isInvoicePaid,
      paymentMethod,
      togglePaidSection,
      isCreateInvoice,
      isAutoSendInvoice,
    } = this.state;

    return (
      <aside className={`events-sidebar d-none d-sm-block ${isExpanded ? 'is-expanded' : ''}`}>
        <button type="button" className="btn btn-toggle" onClick={this.onToggleSidebar}>
          {isExpanded ? <span>&gt;&gt;</span> : <span>&lt;&lt;</span>}
        </button>

        {isExpanded && (
          <>
            <h2>Group Edit</h2>
            <div className="field-wrapper">
              <div className="header-row">
                <Checkbox name="Name" onChange={(e) => this.updateFieldSelection('name', e)} isChecked={name.selected} />
              </div>
              <input
                type="text"
                className="form-control"
                disabled={!name.selected}
                style={{ display: name.selected ? 'block' : 'none' }}
                value={name.value}
                onChange={(e) => this.updateFieldValue('name', e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <div className="header-row">
                <Checkbox name="Date" onChange={(e) => this.updateFieldSelection('dateTimeStart', e)} isChecked={dateTimeStart.selected} />
              </div>
              <input
                type="datetime-local"
                className="form-control"
                disabled={!dateTimeStart.selected}
                style={{ display: dateTimeStart.selected ? 'block' : 'none' }}
                value={dateTimeStart.value}
                onChange={(e) => this.updateFieldValue('dateTimeStart', e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <div className="header-row">
                <Checkbox name="Cost" onChange={(e) => this.updateFieldSelection('eventGross', e)} isChecked={eventGross.selected} />
              </div>
              <input
                type="number"
                className="form-control"
                disabled={!eventGross.selected}
                style={{ display: eventGross.selected ? 'block' : 'none' }}
                min={0}
                value={eventGross.value}
                onChange={(e) => this.updateFieldValue('eventGross', e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <div className="header-row">
                <Checkbox name="Event Type" onChange={(e) => this.updateFieldSelection('eventType', e)} isChecked={eventType.selected} />
              </div>
              <div className={classnames(eventType.selected ? '' : 'd-none')}>
                <Select
                  options={EVENT_TYPES}
                  name="eventType"
                  value={EVENT_TYPES.filter(({ value }) => value === eventType.value)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  onChange={(e) => this.updateFieldValue('eventType', e.value)}
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div className="field-wrapper">
              <div className="header-row">
                <Checkbox name="Event Status" onChange={(e) => this.updateFieldSelection('status', e)} isChecked={status.selected} />
              </div>
              <div className={classnames(status.selected ? '' : 'd-none')}>
                <Select
                  options={EVENT_STATUSES}
                  name="status"
                  value={EVENT_STATUSES.filter(({ value }) => value === status.value)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  onChange={(e) => this.updateFieldValue('status', e.value)}
                  classNamePrefix="select"
                />
              </div>
            </div>

            <div className="field-wrapper">
              <div className="header-row">
                <Checkbox
                  name="Payment Options"
                  onChange={(e) => this.updateFieldSelection('togglePaidSection', e)}
                  isChecked={togglePaidSection.selected}
                />
              </div>
              <div className={classnames(togglePaidSection.selected ? '' : 'd-none')}>
                <Checkbox
                  disabled={!togglePaidSection.selected}
                  name={isInvoicePaid.value ? 'Mark it Paid' : 'Mark it Unpaid'}
                  onChange={(e) => this.updateFieldValue('isInvoicePaid', e)}
                  isChecked={isInvoicePaid.value}
                />
                <div>
                  <Select
                    options={PAYMENT_METHODS}
                    name="paymentMethod"
                    value={PAYMENT_METHODS.filter(({ value }) => value === paymentMethod.value)}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    // onChange={({ value }) => setFieldValue('paymentMethod', value)}
                    onChange={(e) => this.updateFieldValue('paymentMethod', e.value)}
                    classNamePrefix="select"
                  />
                </div>
                <div>
                  <input
                    className="form-control"
                    name="paymentInfo"
                    placeholder="Payment Notes"
                    onChange={(e) => this.updateFieldValue('paymentInfo', e.target.value)}
                  />
                  <Checkbox
                    name="Enable Create Invoice Item?"
                    onChange={(e) => this.updateFieldValue('isCreateInvoice', e)}
                    isChecked={isCreateInvoice.value}
                  />
                  <Checkbox
                    name="Enable Auto-Send Invoice Item"
                    onChange={(e) => this.updateFieldValue('isAutoSendInvoice', e)}
                    isChecked={isAutoSendInvoice.value}
                  />
                </div>
              </div>
            </div>

            {/* <div className="field-wrapper">
              <h3>
                <Checkbox name="Billing" onChange={(e) => this.toggleBilling(e)} isChecked={billing.selected} />
              </h3>

              <div className={classnames('header-row', billing.selected ? '' : 'd-none')}>
                <Checkbox
                  name="Invoice Email"
                  onChange={(e) => this.updateFieldSelection('invoiceToEmail', e)}
                  disabled={!billing.selected}
                  isChecked={invoiceToEmail.selected}
                />
              </div>
              <input
                type="text"
                className={classnames('form-control', billing.selected ? '' : 'd-none')}
                disabled={!invoiceToEmail.selected}
                value={invoiceToEmail.value}
                onChange={(e) => this.updateFieldValue('invoiceToEmail', e.target.value)}
              />
            </div> */}

            <button type="button" className="btn btn-primary" disabled={!this.validateFields()} onClick={() => this.submitUpdates()}>
              Submit
            </button>
          </>
        )}
      </aside>
    );
  }
}

EventsSidebar.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateEvents: PropTypes.func.isRequired,
};

export default EventsSidebar;
