import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Placement from '../../Placement';
import './style.css';
import FinalAnswer from '../../FinalAnswer';

const EventResultsTable = ({ event }) => {
  if (event && event.eventTeams) {
    return (
      <div>
        {event && (
          <div className="row summary">
            <div className="col-sm-4">
              Players
              <div className="summary-number">{event.numberOfPlayers}</div>
            </div>
            <div className="col-sm-4">
              Teams
              <div className="summary-number">{event.numberOfTeams}</div>
            </div>
            <div className="col-sm-4">
              Reg. Teams
              <div className="summary-number">{event.numberOfRegisteredTeams}</div>
            </div>
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Place</th>
                <th>Team</th>
                <th className="text-right"># of Players</th>
                <th className="text-right">Questions</th>
                <th className="text-right">Score</th>
                <th className="text-right">Wager</th>
                <th className="text-right">Final Answer</th>
                <th className="text-right">Final Score</th>
              </tr>
            </thead>
            <tbody>
              {event.eventTeams.map((team) => (
                <tr key={team.id} className={!team.isValid ? '' : ''}>
                  <td>
                    <Placement position={team.placement} />
                  </td>
                  <td className="team-name">
                    <span>{team.name}</span>
                    {team.registeredTeam ? (
                      <div>
                        <div className="team-username">
                          <strong>Username: </strong>
                          <Link to={`/teams/${team.registeredTeam.id}`}>{team.registeredTeam.teamUsername}</Link>
                        </div>
                        <div className="team-displayName">
                          <strong>Leaderboard:</strong> {team.registeredTeam.name}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {!team.isValid && (
                      <div className="alert alert-danger">
                        <div>
                          <strong>ERROR!</strong>
                        </div>
                        {team.importNotes.split('\n').map((item, key) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))}
                      </div>
                    )}
                  </td>
                  <td className="text-right">{team.numberOfPlayers}</td>
                  <td className="text-right">
                    {team.totalQuestionsCorrect} | {team.totalQuestionsIncorrect} | {team.totalQuestionsNotAnswered}
                  </td>
                  <td className="text-right">
                    <span title="Score at the Half | Score before Wager">
                      {team.totalScoreAtHalf} | {team.totalScoreBeforeFinal}
                    </span>
                  </td>
                  <td className="text-right">
                    {team.finalWager === team.totalScoreBeforeFinal ? (
                      <span style={{ color: 'green' }}>{team.finalWager}</span>
                    ) : (
                      <span>{team.finalWager}</span>
                    )}
                  </td>
                  <td className="text-right">
                    <FinalAnswer answer={team.finalAnswer} />
                  </td>
                  <td className="text-right">
                    {team.totalScore}
                    <span className="points-label">pts</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  return <div />;
};

EventResultsTable.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};
EventResultsTable.defaultProps = {
  event: {},
};
export default EventResultsTable;
