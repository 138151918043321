import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import LogPaymentForm from './LogPaymentForm';
import useEvent from '../../hooks/useEvent';
import Spinner from '../Spinner';
import { PAYMENT_TYPE } from '../../utils';
import { getJWT } from '../../utils/api/helpers';
import axios from 'axios';
import { ENDPOINTS } from '../../config';
import toastActions from '../../actions/toastActions';

const PaymentModal = ({ eventId, onCancel, show, className }) => {
  const { event, loading, error, updateEvent, mutateEvent } = useEvent(eventId);
  const onFormSubmit = async (id, vals) => {
    mutateEvent(await updateEvent(vals));
    // onComplete();
  };

  const onClickCancel = () => {
    onCancel();
  };

  useEffect(() => {
    if (show) {
      mutateEvent();
    }
  }, [show]);

  const onSendStripeInvoiceItem = async () => {
    const p = { pendingAction: 'create-stripe-invoice-item', paymentMethod: PAYMENT_TYPE.ACCOUNT };
    await updateEvent(p);
  };

  const onSendStripeInvoice = async () => {
    console.log('event billing account', event.billingAccount.id);
    const token = getJWT();
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios.put(`${ENDPOINTS.BILLING_ACCOUNTS}/${event.billingAccount.id}/send-invoice`, {}, options);
      mutateEvent();
    } catch (e) {
      toastActions.displayErrorToast(e.response.data.message);
    }
    // const p = { pendingAction: 'create-stripe-invoice-item', paymentMethod: PAYMENT_TYPE.ACCOUNT };
    // await updateEvent(p);
  };

  const onVoidStripeInvoice = async () => {
    const p = { pendingAction: 'cancel-stripe-invoice-item' };
    await updateEvent(p);
  };

  const onStripeInvoicePaid = async () => {
    const p = { pendingAction: 'mark-stripe-paid' };
    await updateEvent(p);
  };

  return (
    <Modal isOpen={show} toggle={onCancel} className={className}>
      <ModalHeader toggle={onCancel}>Event Payment Info</ModalHeader>
      <ModalBody>
        {loading && <Spinner />}
        {error && <div>{error.message || JSON.stringify(error)}</div>}
        <div>{event && event.name}</div>
        <div>{event && moment(event.dateTimeStart).format('ddd, MMM Do, YYYY h:mma')}</div>
        <br />
        <LogPaymentForm
          eventDetails={event}
          onVoidStripeInvoice={onVoidStripeInvoice}
          onSendStripeInvoiceItem={onSendStripeInvoiceItem}
          onSendStripeInvoice={onSendStripeInvoice}
          onMarkStripeInvoicePaid={onStripeInvoicePaid}
          onSubmit={onFormSubmit}
          onCancel={onClickCancel}
        />
      </ModalBody>
    </Modal>
  );
};
PaymentModal.propTypes = {
  eventId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  show: PropTypes.bool,
  className: PropTypes.string,
};
PaymentModal.defaultProps = {
  eventId: null,
  show: false,
  className: '',
};
export default PaymentModal;
