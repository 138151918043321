import API from '../../utils/api/registeredTeams';

import LeaderboardAPI from '../../utils/api/leaderboard';

const ACTION_PREFIX = 'REGTEAMS';

const getAllRegisteredTeams = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getRegisteredTeams(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getRegisteredTeamDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getRegisteredTeam(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const getLeaderboard = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LEADERBOARD_STARTED` });
  LeaderboardAPI.getLeaderboard(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LEADERBOARD_SUCCESS`, payload: data }))
    .catch((errorMsg) =>
      dispatch({
        type: `${ACTION_PREFIX}_LEADERBOARD_FAILED`,
        payload: errorMsg,
      }),
    );
};

const getRegisteredTeamHistory = (id, query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_HISTORY_STARTED` });
  API.getRegisteredTeamHistory(id, query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_HISTORY_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_HISTORY_FAILED`, payload: errorMsg }));
};

const submitCreateRegisteredTeam = (account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createRegisteredTeam(account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditRegisteredTeam = (id, account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateRegisteredTeam(id, account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

export default {
  getAllRegisteredTeams,
  getRegisteredTeamDetails,
  getLeaderboard,
  getRegisteredTeamHistory,
  submitCreateRegisteredTeam,
  submitEditRegisteredTeam,
};
