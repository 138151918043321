import { useState } from 'react';
import { axiosClient } from './axios_config';

export const fetcher = async (args) => {
  const { data, headers } = await axiosClient(typeof args === 'string' ? args : { ...args });

  const result = { data };
  const xTotalCount = headers?.['x-total-count'];
  const xPerPage = headers?.['x-per-page'];

  if (xTotalCount && xPerPage) {
    result['pagination'] = {
      totalCount: parseInt(xTotalCount),
      perPage: parseInt(xPerPage),
    };
  }

  return result;
};

export const loadingMiddleware = (useSWRNext) => (key, fetcher, config) => {
  const [loading, setLoading] = useState(false);

  const extendedFetcher = async (...args) => {
    setLoading(true);
    try {
      return await fetcher(...args);
    } finally {
      setLoading(false);
    }
  };

  const swr = useSWRNext(key, extendedFetcher, config);

  return { ...swr, loading: loading || (!swr?.data && !swr?.error) };
};
