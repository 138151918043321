import React from 'react';
import { SOCIAL_LINKS } from '../../config';
import './style.css';

const Footer = () => (
  <footer className="footer container-fluid">
    <div className="content">
      <span>{`© ${new Date().getFullYear()} whattrivia.com | All Rights Reserved`}</span>
      <div className="social-links">
        <span>Follow us on:</span>
        <ul>
          <li>
            <a href={SOCIAL_LINKS.FACEBOOK} rel="noopener noreferrer" target="_blank">
              <span className="facebook-icon" />
            </a>
          </li>
          <li>
            <a href={SOCIAL_LINKS.TWITTER} rel="noopener noreferrer" target="_blank">
              <span className="twitter-icon" />
            </a>
          </li>
          <li>
            <a title="Book us on GigSalad" href={SOCIAL_LINKS.GIG_SALAD} rel="noopener noreferrer" target="_blank">
              <span className="gigsalad-icon" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
