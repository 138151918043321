import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import LeaderboardDesktop from '../../components/Leaderboard/desktop';

class RegisteredTeamLeaderboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        startDate: `${moment().format('Y')}-01-01`,
        endDate: `${moment().format('Y')}-12-31`,
      },
    };
  }

  componentDidMount() {
    this.updateList();
  }

  updateList() {
    const { getLeaderboard } = this.props;
    const { query } = this.state;
    getLeaderboard(query);
  }

  render() {
    const { leaderboard } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/teams" />
        <div className="container-fluid">
          <div className="content-box">
            <h3>Leaderboard</h3>
            <LeaderboardDesktop latestEventDateTime={leaderboard.latestEvent} leaderboard={leaderboard.standings} />
          </div>
        </div>
      </div>
    );
  }
}

RegisteredTeamLeaderboardPage.propTypes = {
  getLeaderboard: PropTypes.func.isRequired,
  leaderboard: PropTypes.objectOf(PropTypes.shape),
};
RegisteredTeamLeaderboardPage.defaultProps = {
  leaderboard: {},
};

const mapStateToProps = (state) => extend({}, state.registeredTeams, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegisteredTeamLeaderboardPage);
