import API from '../../utils/api/users';

const ACTION_PREFIX = 'USERS';

const getAllUsers = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getUsers(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getUserDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getUser(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreateUser = (account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createUser(account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((err) =>
      dispatch({
        type: `${ACTION_PREFIX}_CREATE_FAILED`,
        payload: err.response ? err.response.data.message : err.message,
      }),
    );
};

const createUserCalendar = (userId) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_CALENDAR_STARTED` });
  API.createUserCalendar(userId)
    .then((data) => {
      dispatch(getUserDetails(userId));
      return dispatch({ type: `${ACTION_PREFIX}_CREATE_CALENDAR_SUCCESS`, payload: data });
    })
    .catch((err) =>
      dispatch({
        type: `${ACTION_PREFIX}_CREATE_CALENDAR_FAILED`,
        payload: err.response ? err.response.data.message : err.message,
      }),
    );
};

const submitEditUser = (id, account) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updateUser(id, account)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((err) =>
      dispatch({
        type: `${ACTION_PREFIX}_EDIT_FAILED`,
        payload: err.response ? err.response.data.message : err.message,
      }),
    );
};

export default {
  getAllUsers,
  getUserDetails,
  submitCreateUser,
  submitEditUser,
  createUserCalendar,
};
