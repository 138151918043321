import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getUsers(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  console.log('query', query);
  return axios
    .get(`${ENDPOINTS.USERS}?${qs.stringify(query, { arrayFormat: 'bracket' })}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create user
 * @param {object} userData - the user data
 */
function createUser(userData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.USERS}`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

function createUserCalendar(userId) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(
      `${ENDPOINTS.USERS}/${userId}/calendar`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => res.data);
}

/**
 * Get user by ID
 * @param {string} id - the user ID
 */
function getUser(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.USERS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

/**
 * Update user
 * @param {string} id - the user ID
 * @param {object} newData - the user data
 */
function updateUser(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.USERS}/${id}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

/**
 * Delete user
 * @param {string} id - the user ID
 */
function deleteUser(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.USERS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  createUserCalendar,
};
