import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import Spinner from '../../Spinner';
import Checkbox from '../checkbox';
import '../style.css';
import './style.css';
import 'icheck/skins/all.css';

const LoginForm = (props) => {
  const {
    // values,
    touched,
    errors,
    isSubmitting,
    // handleChange,
    // handleBlur,
    handleSubmit,
    loading,
    failed,
    errorMessage,
  } = props;
  return (
    <form className="login-form form-block" onSubmit={handleSubmit}>
      {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      <div className="col-xs-12">
        <label htmlFor="email">Email</label>
        <Field type="email" className="form-control" name="email" />
        {errors.email && touched.email && <div className="alert alert-danger">{errors.email}</div>}
      </div>
      <div className="col-xs-12">
        <label htmlFor="pw">Password</label>
        <Field type="password" className="form-control" name="pw" />
        {errors.pw && touched.pw && <div className="alert alert-danger">{errors.pw}</div>}
      </div>
      <div className="check-group col-xs-12">
        <Field component={Checkbox} className="form-control" name="rememberMe" id="rememberMe" label="Remember Me" />
      </div>
      <div className="col-xs-12">
        {isSubmitting || loading ? (
          <Spinner />
        ) : (
          <button type="submit" className="btn btn-primary btn-block">
            Log In
          </button>
        )}
      </div>
    </form>
  );
};
LoginForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,

  loading: PropTypes.bool,
  // success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,
  // email: PropTypes.string.isRequired,
  // rememberMe: PropTypes.bool,

  // --- values added by Formik
  // values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
LoginForm.defaultProps = {
  // email: '',
  loading: false,
  failed: false,
  errorMessage: '',
  // rememberMe: false,
  // --- values added by Formik
  // values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ email, rememberMe }) => ({
    email,
    pw: '',
    rememberMe,
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
    pw: Yup.string().required('Please enter your password'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values.email, values.pw, values.rememberMe); // omit(values, ['recaptcha']));
    setSubmitting(false);
  },
  displayName: 'Complete Profile Form',
})(LoginForm);
