import { extend } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
// import { FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import FormActions from '../../actions/formActions';
import GenericActions from '../../actions/genericActions';
import Button from '../../components/Button';
import { API_URL } from '../../config';
import Header from '../Header';
import actions from './actions';

class UsersDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // teamName: '',
    };
  }

  componentDidMount() {
    this.updateList();
  }

  // setTeamName = (teamName) => {
  //   this.setState({ teamName });
  // };

  updateList() {
    const { getUserDetails, routeParams } = this.props;
    getUserDetails(routeParams.id);
  }

  render() {
    const {
      userDetails,
      submitRequestPasswordReset,
      passwordResetRequest,
      // registerRequest,
      // submitRegistration,
      createUserCalendar,
      userCreateCalendarRequest,
    } = this.props;
    // const { teamName } = this.state;
    return (
      <div className="page-container">
        <Header currentPage="/users" />
        <div className="container-fluid">
          <div className="content-box">
            <Link to={`/users/${userDetails.id}/edit`} className="btn btn-success">
              Edit
            </Link>

            <h3>User Details</h3>
            <div>First Name: {userDetails.firstName}</div>
            <div>Last Name: {userDetails.lastName}</div>
            <div>Nickname: {userDetails.nickname}</div>
            {/* <div>
              Google Folder Id: {userDetails.googleFolderId}
            </div> */}
            <div>Username: {userDetails.username}</div>
            <div>Last Login: {userDetails.lastLogin ? moment(userDetails.lastLogin).format('ddd, MMM Do, YYYY @ h:mma') : 'Never'}</div>

            <h3>Password Reset</h3>
            <div>
              <Button className="btn-info" disabled={passwordResetRequest.loading} onClick={() => submitRequestPasswordReset(userDetails.username)}>
                Send Forgotten Password
              </Button>
              {passwordResetRequest.loading && <PulseLoader />}
              {passwordResetRequest.failed && <div className="alert alert-danger">{passwordResetRequest.errorMessage}</div>}
              {passwordResetRequest.success && <div className="alert alert-success">The user has been sent an email to reset their password.</div>}
            </div>

            <h3>Calendar URL</h3>
            {/* {JSON.stringify(userDetails.groups.find((o) => o.name === 'Hosts'))} */}
            {userDetails.calendars?.length <= 0 && (
              <Button className="btn-info" disabled={userCreateCalendarRequest.loading} onClick={() => createUserCalendar(userDetails.id)}>
                Create Calendar
              </Button>
            )}
            {userCreateCalendarRequest.loading && <PulseLoader />}
            {userCreateCalendarRequest.failed && <div className="alert alert-danger">{userCreateCalendarRequest.errorMessage}</div>}
            {userDetails.calendars?.length > 0 && (
              <>
                <p>{`${API_URL.V1}/hosts/calendar.ics?key=${userDetails.calendars[0].calendarKey}`}</p>
                <p>Last Requested: {moment(userDetails.calendars[0].lastRequested).format('ddd, MMM Do, YYYY @ h:mma') || 'Never'}</p>
              </>
            )}

            <h3>Connected to Accounts</h3>
            <div>
              {userDetails?.accounts &&
                userDetails.accounts.map((a) => (
                  <div key={a.id}>
                    <Link to={`/accounts/${a.id}`}>{a.name}</Link>
                  </div>
                ))}
            </div>

            {/* <h3>Create Team</h3>
            <p>
              This will create a team for <strong>{userDetails.username}</strong> and send them an email to have them complete their profile.
            </p>
            <div>
              <div>
                <FormGroup>
                  <Label for="teamName">Team Name</Label>
                  <Input type="text" name="teamName" className="col-md-6" onChange={(e) => this.setTeamName(e.target.value)} />
                </FormGroup>
              </div>
              <Button className="btn-info" disabled={registerRequest.loading} onClick={() => submitRegistration(teamName, userDetails.username)}>
                Create Team
              </Button>
              {registerRequest.loading && <PulseLoader />}
              {registerRequest.failed && <div className="alert alert-danger">{registerRequest.errorMessage}</div>}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

UsersDetailPage.propTypes = {
  getUserDetails: PropTypes.func.isRequired,
  createUserCalendar: PropTypes.func.isRequired,
  userDetails: PropTypes.objectOf(PropTypes.shape),
  passwordResetRequest: PropTypes.objectOf(PropTypes.shape),
  userCreateCalendarRequest: PropTypes.objectOf(PropTypes.shape),
  submitRequestPasswordReset: PropTypes.func.isRequired,
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,

  // submitRegistration: PropTypes.func.isRequired,
  // registerRequest: PropTypes.objectOf(PropTypes.shape),
};
UsersDetailPage.defaultProps = {
  passwordResetRequest: {},
  userCreateCalendarRequest: {},
  userDetails: {},
  // registerRequest: {},
};

const mapStateToProps = (state) => extend({}, state.users, state.forms, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions, FormActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(UsersDetailPage);
