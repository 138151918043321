import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';

export default [
  {
    title: 'Live Stream',
    path: '/live-streams',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create Live Stream',
    path: '/live-streams/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit Live Stream',
    path: '/live-streams/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'Live Stream Details',
    path: '/live-streams/:id',
    component: View,
    options: { private: true },
  },
];
