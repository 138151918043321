import { ErrorMessage, Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Trash } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import BillingAccountSelector from '../../containers/BillingAccounts/BillingAccountSelector';
import UserSelector from '../../containers/Users/UserSelector';
import { GROUPS, PAYMENT_METHODS } from '../../utils';
import Spinner from '../Spinner';

const AccountEditForm = ({
  entity,
  // touched,
  // errors,
  dirty,
  values,
  isSubmitting,
  // handleChange,
  setFieldValue,
  // handleBlur,
  handleSubmit,

  loading,
  success,
  failed,
  errorMessage,
  // handleReset,
}) => {
  const addBillingAccount = (ba) => {
    if (!ba) {
      return false;
    }
    const billingAccountExists = entity.billingAccounts.find((a) => ba.id === a.id);
    if (billingAccountExists) {
      return false;
    }

    if (ba && ba.id) {
      setFieldValue('billingAccounts', [ba, ...values.billingAccounts]);
      return true;
    }
  };

  const removeBillingAccount = (ba) => {
    if (confirm('Remove Billing Account from Account?')) {
      const billingAccountExists = values.billingAccounts?.find((a) => ba.id === a.id);
      if (billingAccountExists) {
        const newBillingAccounts = values.billingAccounts.filter((o) => o.id !== ba.id);
        setFieldValue('billingAccounts', newBillingAccounts);
        console.log('removing billing account', newBillingAccounts);
        return true;
      }
    }
    return false;
  };

  const addUser = (u) => {
    if (!u) {
      return false;
    }
    const userExists = entity.users.find((a) => u.id === a.id);
    if (userExists) {
      return false;
    }

    if (u && u.id) {
      setFieldValue('users', [u, ...values.users]);
      return true;
    }
  };

  const removeUser = (u) => {
    if (confirm('Remove User from Account?')) {
      const userExists = values.users?.find((a) => u.id === a.id);
      if (userExists) {
        const newUsers = values.users.filter((o) => o.id !== u.id);
        setFieldValue('users', newUsers);
        console.log('removing users', newUsers);
        return true;
      }
    }
    return false;
  };
  // createStripeAccount = () => {
  //   const { entity, onSubmit } = this.props;
  //   // console.log('createStripeAccount');
  //   // console.log('values');
  //   onSubmit(entity.id, { actionCreateStripeCustomer: true });
  // };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {success && <div className="alert alert-success">Account Edited</div>}
        {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="name">Name</label>
          <Field className="form-control" name="name" />
          <ErrorMessage name="name" component="div" className="alert alert-danger" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="name">Account Type</label>
          <Field component="select" className="form-control" name="type">
            <option value="1">Public</option>
            <option value="2">Corporate</option>
            <option value="3">Fundraiser</option>
            <option value="4">Other</option>
          </Field>
        </div>
      </div>
      <h3>Billing</h3>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="paymentMethod">Usual Payment Method</label>
          <Field className="form-control" component="select" name="paymentMethod">
            {PAYMENT_METHODS.map((m) => (
              <option key={m.value} value={m.value}>
                {m.label}
              </option>
            ))}
          </Field>
          <ErrorMessage name="paymentMethod" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <h3>Billing Accounts</h3>
          <div>
            <Link to={`/billing-accounts/create?accountId=${entity.id}&name=${encodeURIComponent(entity.name)}`}>Create a Billing Account</Link>
          </div>
          <div>
            <BillingAccountSelector selectedBillingAccount={values.billingAccount} onUpdate={addBillingAccount} />
          </div>
          <div>
            <ul className="list">
              {values.billingAccounts?.map((ba) => (
                <li key={ba.id}>
                  <Link title="View this billing account" to={`/billing-accounts/${ba.id}`}>
                    {ba.name}
                  </Link>{' '}
                  <span title="Remove this billing account from the account" style={{ cursor: 'pointer' }} onClick={() => removeBillingAccount(ba)}>
                    <Trash size={15} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div className="form-row">
        <div className="form-group col-md-6">
          <h3>Associated Users</h3>
          <div className="small">User must be in Owners group</div>
          <div>
            <UserSelector onUpdate={addUser} groups={[GROUPS.OWNER]} />
          </div>
          <ul className="list">
            {values.users &&
              values.users.length > 0 &&
              values.users?.map((u) => (
                <li key={u.id}>
                  <Link to={`/users/${u.id}`}>{u.username}</Link>{' '}
                  <span title="Remove this user from the account" style={{ cursor: 'pointer' }} onClick={() => removeUser(u)}>
                    <Trash size={15} />
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <br />
      <div className="form-row">
        <div className="form-group col-md-6">
          <Link className="btn btn-default" to="/accounts">
            Cancel
          </Link>{' '}
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <Button type="submit" color="primary" disabled={!dirty}>
              Save
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

AccountEditForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  entity: PropTypes.objectOf(PropTypes.shape).isRequired,

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
AccountEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  dirty: false,
  errorMessage: '',

  values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity }) => ({
    name: entity.name || '',
    type: entity.type || 1,
    paymentMethod: entity.paymentMethod || 'cash',
    billingAccounts: entity.billingAccounts || [],
    users: entity.users || [],
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.entity.id, values);
    setSubmitting(false);
  },
  displayName: 'Account Edit Form',
})(AccountEditForm);
