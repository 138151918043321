import API from '../../utils/api/payments';

const ACTION_PREFIX = 'PAYMENTS';

const getAllPayments = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getPayments(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getPaymentDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getPayment(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreatePayment = (payment) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createPayment(payment)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditPayment = (id, payment) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
  API.updatePayment(id, payment)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

export default {
  getAllPayments,
  getPaymentDetails,
  submitCreatePayment,
  submitEditPayment,
};
