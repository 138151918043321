import React, { Component } from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';
import Spinner from '../Spinner';

class TournamentEditForm extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resetForm, success } = this.props;
    if (prevProps.success === false && success === true) {
      resetForm();
    }
  }
  // const _handleSelect = (selectChoice) => {
  //   setFieldValue('imaginaryThingId', selectChoice.value);
  // };

  render() {
    const {
      entity,
      // values,
      // touched,
      // errors,
      // dirty,
      isSubmitting,
      // handleChange,
      // setFieldValue,
      // handleBlur,
      handleSubmit,

      loading,
      success,
      failed,
      errorMessage,
      // handleReset,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          {success && <div className="alert alert-success">Tournament Edited</div>}
          {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
        </div>

        {/* <div>
          {JSON.stringify(entity)}
        </div> */}
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="name">Name</label>
            <Field className="form-control" name="name" />
            <ErrorMessage name="name" component="div" className="alert alert-danger" />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="key">Key</label>
            <Field className="form-control" name="key" />
            <ErrorMessage name="key" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="openDate">Open Date</label>
            <Field className="form-control" type="datetime-local" name="openDate" />
            <ErrorMessage name="openDate" component="div" className="alert alert-danger" />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="cost">Base Cost</label>
            <Field className="form-control" name="cost" />
            <ErrorMessage name="cost" component="div" className="alert alert-danger" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="earlyDate">Discounted Entry Before</label>
            <Field className="form-control" type="datetime-local" name="earlyDate" />
            <ErrorMessage name="earlyDate" component="div" className="alert alert-danger" />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="earlyCost">Early Cost</label>
            <Field className="form-control" name="earlyCost" />
            <ErrorMessage name="earlyCost" component="div" className="alert alert-danger" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <Link className="btn btn-default" to={`/tournaments/${entity.id}`}>
              Cancel
            </Link>{' '}
            {isSubmitting || loading ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary">
                Submit
              </Button>
            )}
          </div>
        </div>
        <div>
          <h3>Events</h3>
          <ul className="list">
            {entity &&
              entity.events &&
              entity.events.map((e) => (
                <li key={e.id}>
                  <Link to={`/events/${e.id}`}>{e.name}</Link>
                </li>
              ))}
          </ul>
        </div>
      </form>
    );
  }
}
TournamentEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  entity: PropTypes.objectOf(PropTypes.shape).isRequired,

  // values added by Formik
  // values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  // dirty,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  resetForm: PropTypes.func.isRequired,
};
TournamentEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',

  // values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  mapPropsToValues: ({ entity }) => ({
    name: entity.name || '',
    key: entity.key || '',
    openDate: moment(entity.openDate).format('YYYY-MM-DDTHH:mm') || `${moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}T18:00:00.000` || '',
    earlyDate: moment(entity.earlyDate).format('YYYY-MM-DDTHH:mm') || `${moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}T18:00:00.000` || '',
    earlyCost: entity.earlyCost || '',
    cost: entity.cost || '',
    isActive: entity.isActive || '',
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    key: Yup.string().required(),
    cost: Yup.number(),
    earlyCost: Yup.number(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.entity.id, values);
    setSubmitting(false);
  },
  displayName: 'Tournament Edit Form',
})(TournamentEditForm);
