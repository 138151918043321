const defaultState = {
  // Form Request Status
  tournaments: { data: [], metadata: {} },
  leaderboard: {},
  teamHistory: {},
  tournamentDetails: {},
  tournamentListRequest: {},
  tournamentDetailRequest: {},
  tournamentCreateRequest: {},
  tournamentEditRequest: {},
  tournamentDeleteRequest: {},
  tournamentLeaderboardRequest: {},
  tournamentHistoryRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'TOURNAMENTS_LIST_STARTED':
      return { ...state, tournamentListRequest: { working: true } };
    case 'TOURNAMENTS_LIST_FAILED':
      return { ...state, tournamentListRequest: { failed: true } };
    case 'TOURNAMENTS_LIST_SUCCESS':
      return {
        ...state,
        tournaments: action.payload,
        tournamentListRequest: { success: true },
      };

    case 'TOURNAMENTS_DETAIL_STARTED':
      return { ...state, tournamentDetailRequest: { working: true } };
    case 'TOURNAMENTS_DETAIL_FAILED':
      return { ...state, tournamentDetailRequest: { failed: true } };
    case 'TOURNAMENTS_DETAIL_SUCCESS':
      return {
        ...state,
        tournamentDetails: action.payload,
        tournamentDetailRequest: { success: true },
      };

    case 'TOURNAMENTS_CREATE_STARTED':
      return { ...state, tournamentCreateRequest: { working: true } };
    case 'TOURNAMENTS_CREATE_FAILED':
      return { ...state, tournamentCreateRequest: { failed: true } };
    case 'TOURNAMENTS_CREATE_SUCCESS':
      return {
        ...state,
        tournamentDetails: action.payload,
        tournamentCreateRequest: { success: true },
      };

    case 'TOURNAMENTS_EDIT_STARTED':
      return { ...state, tournamentEditRequest: { working: true } };
    case 'TOURNAMENTS_EDIT_FAILED':
      return {
        ...state,
        tournamentEditRequest: {
          failed: true,
          errorMessage: action.payload,
        },
      };
    case 'TOURNAMENTS_EDIT_SUCCESS':
      return {
        ...state,
        tournamentDetails: action.payload,
        tournamentEditRequest: { success: true },
      };
    // case 'TOURNAMENTS_LEADERBOARD_STARTED':
    //   return { ...state, tournamentLeaderboardRequest: { working: true } };
    // case 'TOURNAMENTS_LEADERBOARD_FAILED':
    //   return { ...state, tournamentLeaderboardRequest: { failed: true } };
    // case 'TOURNAMENTS_LEADERBOARD_SUCCESS':
    //   return {
    //     ...state,
    //     leaderboard: action.payload,
    //     tournamentLeaderboardRequest: { success: true },
    //   };

    // case 'TOURNAMENTS_HISTORY_STARTED':
    //   return { ...state, tournamentHistoryRequest: { working: true } };
    // case 'TOURNAMENTS_HISTORY_FAILED':
    //   return { ...state, tournamentHistoryRequest: { failed: true } };
    // case 'TOURNAMENTS_HISTORY_SUCCESS':
    //   return {
    //     ...state,
    //     teamHistory: action.payload,
    //     tournamentHistoryRequest: { success: true },
    //   };

    default:
      return state;
  }
};
