import qs from 'query-string';
import axios from 'axios';
import { reject, getJWT } from './helpers';
import { ENDPOINTS } from '../../config';
import globals from './global';

/**
 * Get list
 * @param {object} query - the query
 */
function getTournaments(query) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(
      `${ENDPOINTS.TOURNAMENTS}?${qs.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Create tournament
 * @param {object} tournamentData - the tournament data
 */
function createTournament(tournamentData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .post(`${ENDPOINTS.TOURNAMENTS}`, tournamentData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Get tournament by ID
 * @param {string} id - the tournament ID
 */
function getTournament(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .get(`${ENDPOINTS.TOURNAMENTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

// /**
//  * Get tournament History by ID
//  * @param {string} id - the tournament ID
//  * @param {obj} query - query string object
//  */
// function getTournamentHistory(id, query) {
//   const token = getJWT();
//   if (!token) return reject('NOT_LOGGED_IN');
//   return axios
//     .get(
//       `${ENDPOINTS.TOURNAMENTS}/${id}/history?${qs.stringify(query, {
//         arrayFormat: 'bracket',
//       })}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     )
//     .catch((err) => globals.handleAPIError(err))
//     .then((res) => res.data);
// }

/**
 * Update tournament
 * @param {string} id - the tournament ID
 * @param {object} newData - the tournament data
 */
function updateTournament(id, newData) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .put(`${ENDPOINTS.TOURNAMENTS}/${newData.key}`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

/**
 * Delete tournament
 * @param {string} id - the tournament ID
 */
function deleteTournament(id) {
  const token = getJWT();
  if (!token) return reject('NOT_LOGGED_IN');
  return axios
    .delete(`${ENDPOINTS.TOURNAMENTS}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err) => globals.handleAPIError(err))
    .then((res) => res.data);
}

export default {
  getTournaments,
  getTournament,
  // getTournamentHistory,
  createTournament,
  updateTournament,
  deleteTournament,
};
