import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import formActions from '../../actions/formActions';
import Header from '../Header';
import UserProfileForm from '../../components/Forms/UserProfileForm';

const UserProfilePage = ({ submitUpdateUserProfile, updateProfileRequest, userProfile }) => (
  <div className="profile-page page-container">
    <Header currentPage="/profile" />
    <h1 className="page-title">My Profile</h1>
    <div className="container-fluid">
      <div className="content-box">
        <div className="row">
          <div className="col-md-9 col-xs-12">
            <UserProfileForm
              onSubmit={submitUpdateUserProfile}
              loading={updateProfileRequest.working}
              failed={updateProfileRequest.failed}
              errorMessage={updateProfileRequest.errorMessage}
              success={updateProfileRequest.success}
              emailAddress={userProfile.username}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

UserProfilePage.propTypes = {
  submitUpdateUserProfile: PropTypes.func.isRequired,
  updateProfileRequest: PropTypes.objectOf(PropTypes.shape),
  userProfile: PropTypes.objectOf(PropTypes.shape),
};
UserProfilePage.defaultProps = {
  updateProfileRequest: {},
  userProfile: {},
};

const mapStateToProps = (state) => extend({}, state.forms, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, formActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(UserProfilePage);
