import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
// import GenericIdNameOption from '../../components/SelectComponents/GenericIdNameOption';

class LocationSelector extends Component {
  componentDidMount() {
    const { getAllLocations } = this.props;
    getAllLocations({ pageSize: 50 });
  }

  onChange = (values) => {
    const { onUpdate } = this.props;
    onUpdate(values);
  };

  onInputChange = (searchString, action) => {
    const { getAllLocations } = this.props;
    if (action.action === 'input-change') getAllLocations({ name: searchString, pageSize: 50 });
  };

  render() {
    const { locations, selectedLocation, locationListRequest, isMulti } = this.props;

    return (
      <Select
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        isClearable
        isMulti={isMulti}
        isLoading={locationListRequest.working}
        value={selectedLocation}
        onChange={this.onChange}
        options={locations.data}
        onInputChange={this.onInputChange}
        classNamePrefix="select"
      />
    );
  }
}

LocationSelector.propTypes = {
  getAllLocations: PropTypes.func.isRequired,
  locations: PropTypes.objectOf(PropTypes.shape),
  locationListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,

  isMulti: PropTypes.bool,
  selectedLocation: PropTypes.objectOf(PropTypes.shape),
  onUpdate: PropTypes.func.isRequired,
};
LocationSelector.defaultProps = {
  selectedLocation: {},
  locations: {
    data: [],
    metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 },
  },
  isMulti: false,
};

const mapStateToProps = (state) => extend({}, state.locations, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LocationSelector);
