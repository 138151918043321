const defaultState = {
  // Form Request Status
  liveStream: { data: [], metadata: {} },
  liveStreamResponses: {},
  liveStreamListRequest: {},
  liveStreamResponseRequest: {},
  liveStreamGetScoreboardRequest: {},
  liveStreamScoreboard: {},
  // liveStreamCreateRequest: {},
  // liveStreamEditRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'LIVE_STREAM_LIST_STARTED':
      return { ...state }; // , liveStreamListRequest: { working: true } };
    case 'LIVE_STREAM_LIST_FAILED':
      return { ...state, liveStreamListRequest: { failed: true } };
    case 'LIVE_STREAM_LIST_SUCCESS':
      return {
        ...state,
        liveStream: action.payload,
        liveStreamListRequest: { success: true },
      };

    case 'LIVE_STREAM_RESPONSE_STARTED':
      return { ...state }; // , liveStreamDetailRequest: { working: true } };
    case 'LIVE_STREAM_RESPONSE_FAILED':
      return { ...state, liveStreamResponseRequest: { failed: true } };
    case 'LIVE_STREAM_RESPONSE_SUCCESS':
      return {
        ...state,
        liveStreamResponses: action.payload,
        liveStreamResponseRequest: { success: true },
      };

    case 'LIVE_STREAM_GETSCOREBOARD_STARTED':
      return { ...state };
    case 'LIVE_STREAM_GETSCOREBOARD_FAILED':
      return { ...state, liveStreamGetScoreboardRequest: { failed: true } };
    case 'LIVE_STREAM_GETSCOREBOARD_SUCCESS':
      return {
        ...state,
        liveStreamScoreboard: action.payload,
        liveStreamGetScoreboardRequest: { success: true },
      };

    // case 'LIVE_STREAM_CREATE_STARTED':
    //   return { ...state, liveStreamCreateRequest: { working: true } };
    // case 'LIVE_STREAM_CREATE_FAILED':
    //   return { ...state, liveStreamCreateRequest: { failed: true } };
    // case 'LIVE_STREAM_CREATE_SUCCESS':
    //   return {
    //     ...state,
    //     liveStreamDetails: action.payload,
    //     liveStreamCreateRequest: { success: true },
    //   };
    // case 'LIVE_STREAM_EDIT_STARTED':
    //   return { ...state, liveStreamEditRequest: { working: true } };
    // case 'LIVE_STREAM_EDIT_FAILED':
    //   return { ...state, liveStreamEditRequest: { failed: true } };
    // case 'LIVE_STREAM_EDIT_SUCCESS':
    //   return {
    //     ...state,
    //     liveStreamDetails: action.payload,
    //     liveStreamEditRequest: { success: true },
    //   };
    default:
      return state;
  }
};
