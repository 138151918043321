import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import { Table } from 'reactstrap';
import { ChevronUp, ChevronDown } from 'react-feather';
import { PulseLoader } from 'react-spinners';
import { xor, map, includes } from 'lodash';
import Checkbox from '../Forms/formik-checkbox';
import EventsTableRow from './EventsTableRow';

// import { EVENT_STATUS } from '../../config';

const LIMITS = [20, 50, 100];
class EventsTable extends Component {
  lastWeekNum = null;

  constructor(props) {
    super(props);

    this.toggleAllEvents = this.toggleAllEvents.bind(this);
    this.toggleEventSelection = this.toggleEventSelection.bind(this);
  }

  toggleEventSelection(eventId) {
    const { selectedEventIds, onUpdateSelectedEvents } = this.props;
    onUpdateSelectedEvents(xor(selectedEventIds, [eventId]));
  }

  toggleAllEvents(e) {
    const { list, onUpdateSelectedEvents } = this.props;
    onUpdateSelectedEvents(e ? map(list, ({ id }) => id) : []);
  }

  render() {
    const {
      list,
      pagination,
      isLoading,
      selectedEventIds,
      onPageSizeChange,
      onPageNumberChange,
      isFailed,
      errorMessage,
      separateWeekRows,
      onUpdateFilter,
      filter,
      onPublishEvent,
      onApproveEvent,
      onSendInvoice,
      onCreateGoogleDoc,
      onCloneEvent,
      onDeleteEvent,
      onAddHost,
      onShowPaymentModal,
      onRemoveHost,
      onImportGoogle,
      onSearch,
    } = this.props;

    return (
      <div>
        {isFailed && <div className="alert alert-danger">{errorMessage}</div>}
        <input
          type="text"
          placeholder="Search Event Name or Stripe Invoice Number..."
          className="form-control"
          onChange={(e) => onSearch(e.target.value)}
        />
        <Table striped hover responsive size="sm" className="element-list">
          <thead>
            <tr>
              <th className="d-none d-sm-table-cell text-center">
                <Checkbox
                  name=""
                  onChange={(e) => list && list.length > 0 && this.toggleAllEvents(e)}
                  isChecked={list && list.length > 0 && selectedEventIds.length === list.length}
                />
              </th>
              <th className="d-none d-lg-table-cell" />
              <th>Event</th>
              {/* <th className="d-none d-sm-table-cell text-center">Wk</th> */}
              <th className="d-none d-sm-table-cell">
                <span>Date</span>
                <span>
                  {filter.orderDir === 'ASC' ? (
                    <ChevronDown onClick={() => onUpdateFilter({ ...filter, orderBy: 'dateTimeStart', orderDir: 'DESC' })} />
                  ) : (
                    <ChevronUp onClick={() => onUpdateFilter({ ...filter, orderBy: 'dateTimeStart', orderDir: 'ASC' })} />
                  )}
                </span>
              </th>
              {/* <th className="d-none d-sm-table-cell text-center">$$</th> */}
              <th className="d-none d-md-table-cell text-center">P/T/RT</th>
              <th className="d-none d-sm-table-cell">Hosts</th>
              <th className="d-none d-md-table-cell text-center" />
              <th className="d-none d-md-table-cell text-center">Status</th>
              <th className="d-none d-sm-table-cell text-center">Payment</th>

              {/* <th className="d-none d-sm-table-cell text-center">Updated</th> */}
              <th>
                <PulseLoader color="#027bc7" sizeUnit="px" size={12} loading={isLoading} />
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.length > 0 &&
              list.map((event, i) => {
                let className = '';
                if (separateWeekRows && event.weekNum !== this.lastWeekNum && i > 0) className = 'space';
                this.lastWeekNum = event.weekNum;
                return (
                  <EventsTableRow
                    key={event.id}
                    event={event}
                    className={className}
                    isSelected={includes(selectedEventIds, event.id)}
                    onCreateGoogleDoc={onCreateGoogleDoc}
                    onPublishEvent={onPublishEvent}
                    onApproveEvent={onApproveEvent}
                    onSendInvoice={onSendInvoice}
                    onDeleteEvent={onDeleteEvent}
                    onCloneEvent={onCloneEvent}
                    onRemoveHost={onRemoveHost}
                    onAddHost={onAddHost}
                    onShowPaymentModal={onShowPaymentModal}
                    onImportGoogle={onImportGoogle}
                    onToggleRowSelection={() => this.toggleEventSelection(event.id)}
                  />
                );
              })}
            {(!list || list.length <= 0) && (
              <tr>
                <td colSpan={20} className="text-center">
                  No Events Match Selected Filters
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="row query-footer">
          <div className="page-size form-inline col">
            <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
              {LIMITS.map((opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
            {'  per page'}
          </div>
          <div className="col col-8">
            <Pagination
              onChange={onPageNumberChange}
              showLessItems
              current={pagination.pageNumber}
              total={pagination.totalCount}
              pageSize={pagination.pageSize || 20}
            />
          </div>
          <div className="col text-right">{`Total Count: ${pagination.totalCount}`}</div>
        </div>
      </div>
    );
  }
}

EventsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  selectedEventIds: PropTypes.arrayOf(PropTypes.number),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({ orderDir: PropTypes.string }).isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  onCreateGoogleDoc: PropTypes.func.isRequired,
  onPublishEvent: PropTypes.func.isRequired,
  onApproveEvent: PropTypes.func.isRequired,
  onSendInvoice: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
  onCloneEvent: PropTypes.func.isRequired,
  onImportGoogle: PropTypes.func.isRequired,

  onSearch: PropTypes.func.isRequired,

  onRemoveHost: PropTypes.func.isRequired,
  onAddHost: PropTypes.func.isRequired,
  onShowPaymentModal: PropTypes.func.isRequired,
  onUpdateSelectedEvents: PropTypes.func.isRequired,
  separateWeekRows: PropTypes.bool,

  isLoading: PropTypes.bool,
  // isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
EventsTable.defaultProps = {
  list: [],
  isLoading: false,
  selectedEventIds: [],
  // isSuccess: false,
  isFailed: false,
  separateWeekRows: false,
  errorMessage: '',
  pagination: {
    pageNumber: 1,
    pageSize: 20,
    totalCount: 1000,
  },
};

export default EventsTable;
