import { ErrorMessage, Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Trash } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import Spinner from '../Spinner';
import StripeLink from '../StripeLink';

const BillingAccountEditForm = ({
  entity,
  // touched,
  // errors,
  dirty,
  values,
  isSubmitting,
  // handleChange,
  setFieldValue,
  // handleBlur,
  handleSubmit,
  onSubmit,

  loading,
  success,
  failed,
  errorMessage,
  // handleReset,
}) => {
  const createStripeAccount = () => {
    onSubmit(entity.id, { actionCreateStripeCustomer: true });
  };
  const addAccount = (a) => {
    const accountExists = entity.accounts.find((acct) => a.id === acct.id);
    if (accountExists) {
      return false;
    }

    setFieldValue('accounts', [a, ...values.accounts]);
    return true;
  };
  const removeAccount = (acct) => {
    const accountExists = values.accounts?.find((a) => acct.id === a.id);
    if (accountExists) {
      const newAccounts = values.accounts.filter((o) => o.id !== acct.id);
      setFieldValue('accounts', newAccounts);
      console.log('removing account', newAccounts);
      return true;
    }
    return false;
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        {success && <div className="alert alert-success">Billing Account Edited</div>}
        {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="name">Name</label>
          <Field className="form-control" name="name" />
          <ErrorMessage name="name" component="div" className="alert alert-danger" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="stripeInvoiceFrequency">Stripe Invoice Frequency</label>
          <Field className="form-control" component="select" name="stripeInvoiceFrequency">
            <option value="1">Every Week</option>
            <option value="2">Every 2 Weeks</option>
            <option value="4">Every 4 Weeks</option>
          </Field>
          <ErrorMessage name="stripeInvoiceFrequency" component="div" className="alert alert-danger" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="invoiceToEmail">Stripe Invoice Email to</label>
          <Field className="form-control" type="email" name="invoiceToEmail" />
          <ErrorMessage name="invoiceToEmail" component="div" className="alert alert-danger" />
          {!entity.invoiceToEmail && <div className="small">Add an email, save the account, then you can connect stripe.</div>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          {entity.invoiceToEmail && !entity.stripeCustomerId && (
            <Button color="success" onClick={(e) => createStripeAccount(e)}>
              Connect Stripe Account
            </Button>
          )}
          {entity.stripeCustomerId && (
            <div>
              <h4>Stripe Customer</h4>
              <Field className="form-control" name="stripeCustomerId" />
              <StripeLink type="customer" id={entity.stripeCustomerId} linkText="View Customer in Stripe" />
            </div>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-4">
          <h3>Accounts</h3>
          <AccountSelector onUpdate={(a) => addAccount(a)} />
          {values.accounts &&
            values.accounts.map((a) => (
              <div key={a.id}>
                <Link to={`/accounts/${a.id}`}>{a.name}</Link>{' '}
                <span title="Remove this account" onClick={() => removeAccount(a)}>
                  <Trash />
                </span>
              </div>
            ))}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <Link className="btn btn-default" to="/billing-accounts">
            Cancel
          </Link>{' '}
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <Button type="submit" color="primary" disabled={!dirty}>
              Save
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

BillingAccountEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  entity: PropTypes.objectOf(PropTypes.shape).isRequired,

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  // touched: PropTypes.objectOf(PropTypes.shape),
  // errors: PropTypes.objectOf(PropTypes.shape),
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
BillingAccountEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  dirty: false,

  values: {},
  // touched: {},
  // errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity }) => ({
    name: entity.name || '',
    stripeCustomerId: entity.stripeCustomerId || null,
    stripeInvoiceFrequency: entity.stripeInvoiceFrequency || 1,
    invoiceToEmail: entity.invoiceToEmail || '',
    accounts: entity.accounts || [],
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(props.entity.id, values);
    setSubmitting(false);
  },
  displayName: 'BillingAccount Edit Form',
})(BillingAccountEditForm);
