const defaultState = {
  // Form Request Status
  hostApplications: { data: [], metadata: {} },
  leads: { data: [], metadata: {} },
  // accountDetails: {},
  hostApplicationsListRequest: {},
  leadsListRequest: {},
  leadDetailRequest: {},
  leadDetail: {},
  hostApplicationDetails: {},
  hostApplicationDetailsRequest: {},
  hostApplicationEditRequest: {},
  // accountCreateRequest: {},
  // accountEditRequest: {},
  // accountDeleteRequest: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'HOST_APPLICATIONS_LIST_STARTED':
      return { ...state, hostApplicationsListRequest: { working: true } };
    case 'HOST_APPLICATIONS_LIST_FAILED':
      return { ...state, hostApplicationsListRequest: { failed: true } };
    case 'HOST_APPLICATIONS_LIST_SUCCESS':
      return {
        ...state,
        hostApplications: action.payload,
        hostApplicationsListRequest: { success: true },
      };

    case 'HOST_APPLICATIONS_DETAIL_STARTED':
      return { ...state, hostApplicationDetailsRequest: { working: true } };
    case 'HOST_APPLICATIONS_DETAIL_FAILED':
      return { ...state, hostApplicationDetailsRequest: { failed: true } };
    case 'HOST_APPLICATIONS_DETAIL_SUCCESS':
      return {
        ...state,
        hostApplicationDetails: action.payload,
        hostApplicationDetailsRequest: { success: true },
      };
    case 'HOST_APPLICATIONS_EDIT_STARTED':
      return { ...state, hostApplicationEditRequest: { working: true } };
    case 'HOST_APPLICATIONS_EDIT_FAILED':
      return { ...state, hostApplicationEditRequest: { failed: true } };
    case 'HOST_APPLICATIONS_EDIT_SUCCESS':
      return {
        ...state,
        hostApplicationDetails: action.payload,
        hostApplicationEditRequest: { success: true },
      };

    case 'LEADS_LIST_STARTED':
      return { ...state, leadsListRequest: { working: true } };
    case 'LEADS_LIST_FAILED':
      return { ...state, leadsListRequest: { failed: true } };
    case 'LEADS_LIST_SUCCESS':
      return {
        ...state,
        leads: action.payload,
        leadsListRequest: { success: true },
      };

    case 'LEADS_DETAIL_STARTED':
      return { ...state, leadDetailRequest: { working: true } };
    case 'LEADS_DETAIL_FAILED':
      return { ...state, leadDetailRequest: { failed: true } };
    case 'LEADS_DETAIL_SUCCESS':
      return {
        ...state,
        leadDetail: action.payload,
        leadDetailRequest: { success: true },
      };

    // case 'HOSTS_CREATE_STARTED':
    //   return { ...state, accountCreateRequest: { working: true } };
    // case 'HOSTS_CREATE_FAILED':
    //   return { ...state, accountCreateRequest: { failed: true } };
    // case 'HOSTS_CREATE_SUCCESS':
    //   return {
    //     ...state,
    //     accountDetails: action.payload,
    //     accountCreateRequest: { success: true },
    //   };

    // case 'HOSTS_EDIT_STARTED':
    //   return { ...state, accountEditRequest: { working: true } };
    // case 'HOSTS_EDIT_FAILED':
    //   return { ...state, accountEditRequest: { failed: true } };
    // case 'HOSTS_EDIT_SUCCESS':
    //   return {
    //     ...state,
    //     accountDetails: action.payload,
    //     accountEditRequest: { success: true },
    //   };

    default:
      return state;
  }
};
