import HOST_API from '../../utils/api/hostApplications';

const APPLICATION_PREFIX = 'HOST_APPLICATIONS';

const getAllHostApplications = (query) => (dispatch) => {
  dispatch({ type: `${APPLICATION_PREFIX}_LIST_STARTED` });

  HOST_API.getHostApplications(query)
    .then((data) => dispatch({ type: `${APPLICATION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${APPLICATION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getHostApplicationDetails = (id) => (dispatch) => {
  dispatch({ type: `${APPLICATION_PREFIX}_DETAIL_STARTED` });
  HOST_API.getHostApplicationDetails(id)
    .then((data) => dispatch({ type: `${APPLICATION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) =>
      dispatch({
        type: `${APPLICATION_PREFIX}_DETAIL_FAILED`,
        payload: errorMsg,
      }),
    );
};

const submitEditHostApplication = (id, app) => (dispatch) => {
  dispatch({ type: `${APPLICATION_PREFIX}_EDIT_STARTED` });
  HOST_API.updateHostApplication(id, app)
    .then((data) => dispatch({ type: `${APPLICATION_PREFIX}_EDIT_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${APPLICATION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
};

// const submitCreateAccount = account => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
//   API.createAccount(account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
// };

// const submitEditAccount = (id, account) => (dispatch) => {
//   dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
//   API.updateAccount(id, account)
//     .then(data => dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data }))
//     .catch(errorMsg => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
// };

export default {
  getAllHostApplications,
  getHostApplicationDetails,
  submitEditHostApplication,
};
