import List from './index';
import Create from './create';
import Edit from './edit';
import View from './view';

export default [
  {
    title: 'EventTemplates',
    path: '/event-templates',
    component: List,
    options: { private: true },
  },
  {
    title: 'Create EventTemplate',
    path: '/event-templates/create',
    component: Create,
    options: { private: true },
  },
  {
    title: 'Edit EventTemplate',
    path: '/event-templates/:id/edit',
    component: Edit,
    options: { private: true },
  },
  {
    title: 'EventTemplate Details',
    path: '/event-templates/:id',
    component: View,
    options: { private: true },
  },
];
