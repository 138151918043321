import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import GenericActions from '../../actions/genericActions';
import actions from './actions';
import Header from '../Header';
import UsersTable from '../../components/Users/Table';
import UsersFilter from '../../components/Users/Filter';

class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageSize: 50,
        pageNumber: 1,
      },
    };
  }

  handleChange = (property, value) => {
    const { filter } = this.state;
    filter[property] = value;
    this.setState({ filter }, this.updateList);
  };

  toggleActive = (user, activeState) => {
    const { submitEditUser } = this.props;
    const u = user;
    u.isActive = activeState;
    submitEditUser(user.id, u);
  };

  onFilterChange = (filters) => {
    const { filter } = this.state;
    // console.log({ ...filter, ...filters });
    this.setState({ filter: { ...filter, ...filters } }, this.updateList);
  };

  updateList() {
    const { getAllUsers } = this.props;
    const { filter } = this.state;
    getAllUsers(filter);
  }

  render() {
    const { users, userListRequest } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/users" />
        <div className="container-fluid">
          <div className="content-box">
            <div className="row">
              <div className="col">
                <h1>Users</h1>
              </div>
              <div className="col text-right">
                <Link to="/users/create" className="btn btn-success">
                  <PlusCircle /> New
                </Link>
              </div>
            </div>
            <UsersFilter onUpdateFilter={this.onFilterChange} />
            <UsersTable
              list={users.data}
              pagination={users.metadata}
              isLoading={userListRequest.working}
              isSuccess={userListRequest.success}
              isFailed={userListRequest.failed}
              onToggleActive={this.toggleActive}
              onSearchUsers={(val) => this.handleChange('search', val)}
              onPageNumberChange={(pageNumber) => this.handleChange('pageNumber', pageNumber)}
              onPageSizeChange={(pageSize) => this.handleChange('pageSize', pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  users: PropTypes.objectOf(PropTypes.shape),
  userListRequest: PropTypes.objectOf(PropTypes.shape).isRequired,
  submitEditUser: PropTypes.func.isRequired,
};
UsersPage.defaultProps = {
  users: { data: [], metadata: { pageNumber: 0, pageSize: 20, totalCount: 0 } },
};

const mapStateToProps = (state) => extend({}, state.users, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(UsersPage);
