import axios from 'axios';
import { getJWT } from './api/helpers';

export const axiosClient = axios.create();
// axiosClient.defaults.baseURL = 'https://example.com/api/v1';
axiosClient.interceptors.request.use(
  async (request) => {
    const token = await getJWT();
    if (token) request.headers.set('Authorization', `Bearer ${token}`);
    return request;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export function getRequest(URL) {
  return axiosClient.get(`${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`${URL}`).then((response) => response);
}
