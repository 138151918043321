import API from '../../utils/api/eventTemplates';
import toastActions from '../../actions/toastActions';

const ACTION_PREFIX = 'EVENT_TEMPLATES';

const refreshEventList = () => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_REFRESH_LIST` });
};

const getAllEventTemplates = (query) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_LIST_STARTED` });

  API.getEventTemplates(query)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_LIST_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_LIST_FAILED`, payload: errorMsg }));
};

const getEventTemplateDetails = (id) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_DETAIL_STARTED` });
  API.getEventTemplate(id)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DETAIL_FAILED`, payload: errorMsg }));
};

const submitCreateEventTemplate = (eventTemplate) => (dispatch) => {
  dispatch({ type: `${ACTION_PREFIX}_CREATE_STARTED` });
  API.createEventTemplate(eventTemplate)
    .then((data) => dispatch({ type: `${ACTION_PREFIX}_CREATE_SUCCESS`, payload: data }))
    .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_CREATE_FAILED`, payload: errorMsg }));
};

const submitEditEventTemplate =
  (id, eventTemplate, refreshList = false) =>
  (dispatch) => {
    dispatch({ type: `${ACTION_PREFIX}_EDIT_STARTED` });
    // console.log("Edit Event ", eventTemplate);
    API.updateEventTemplate(id, eventTemplate)
      .then((data) => {
        if (refreshList) dispatch(refreshEventList());
        toastActions.displaySuccessToast(`Event Template #${id} Updated!`);
        return dispatch({ type: `${ACTION_PREFIX}_EDIT_SUCCESS`, payload: data });
      })
      .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_EDIT_FAILED`, payload: errorMsg }));
  };

const submitDeleteEventTemplate =
  (id, refreshList = false) =>
  (dispatch) => {
    dispatch({ type: `${ACTION_PREFIX}_DELETE_STARTED` });
    API.deleteEventTemplate(id)
      .then((data) => {
        if (refreshList) dispatch(refreshEventList());
        toastActions.displaySuccessToast(`Event Template #${id} Deleted!`);
        return dispatch({
          type: `${ACTION_PREFIX}_DELETE_SUCCESS`,
          payload: data,
        });
      })
      .catch((errorMsg) => dispatch({ type: `${ACTION_PREFIX}_DELETE_FAILED`, payload: errorMsg }));
  };

export default {
  getAllEventTemplates,
  getEventTemplateDetails,
  submitCreateEventTemplate,
  submitEditEventTemplate,
  submitDeleteEventTemplate,
};
