import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FilePlus, ExternalLink } from 'react-feather';
import { HashLoader } from 'react-spinners';
import { Button } from 'reactstrap';
import { EVENT_STATUS } from '../../../config';

class GoogleDocFileLink extends Component {
  createGoogleDoc = () => {
    const { event, onCreateGoogleDoc } = this.props;
    onCreateGoogleDoc(event.id);
  };

  render() {
    const { event } = this.props;
    if (event.googleDocURL) {
      return (
        <a href={event.googleDocURL} rel="noopener noreferrer" title="Open Google Doc in a New Tab" target="_blank">
          <ExternalLink color="#33DD33" />
        </a>
      );
    }
    if (event.pendingAction === 'create-sheet') return <HashLoader size={15} color="#027bc7" />;

    if (event.status === EVENT_STATUS.DRAFT || event.status === EVENT_STATUS.READY) {
      return (
        <Button onClick={this.createGoogleDoc} color="link">
          <FilePlus color="#DDDDDD" />
        </Button>
      );
    }

    return <span />;
  }
}

GoogleDocFileLink.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape).isRequired,
  onCreateGoogleDoc: PropTypes.func.isRequired,
};

export default GoogleDocFileLink;
