import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extend } from 'lodash';
import PropTypes from 'prop-types';
import GenericActions from '../../actions/genericActions';
import accountActions from '../Accounts/actions';
import actions from './actions';
import Header from '../Header/index';
import CreateForm from '../../components/EventTemplates/CreateForm';

const EventTemplateCreatePage = ({
  submitCreateEventTemplate,
  eventTemplateDetails,
  eventTemplateCreateRequest,
  getAccountDetails,
  accountDetails,
}) => (
  <div className="eventTemplate-page page-container">
    <Header currentPage="/eventTemplates" />
    <div className="container-fluid">
      <div className="content-box">
        <h1 className="section-title">Create an Event Template</h1>
        <CreateForm
          eventTemplate={eventTemplateDetails}
          onSubmit={submitCreateEventTemplate}
          getAccountDetails={getAccountDetails}
          accountDetails={accountDetails}
          loading={eventTemplateCreateRequest.working}
          success={eventTemplateCreateRequest.success}
          failed={eventTemplateCreateRequest.failed}
        />
      </div>
    </div>
  </div>
);

EventTemplateCreatePage.propTypes = {
  submitCreateEventTemplate: PropTypes.func.isRequired,
  getAccountDetails: PropTypes.func.isRequired,
  eventTemplateCreateRequest: PropTypes.objectOf(PropTypes.shape),
  eventTemplateDetails: PropTypes.objectOf(PropTypes.shape),
  accountDetails: PropTypes.objectOf(PropTypes.shape),
  // eventTemplate: PropTypes.objectOf(PropTypes.shape),
};
EventTemplateCreatePage.defaultProps = {
  accountDetails: {},
  // eventTemplate: {},
  eventTemplateCreateRequest: {},
  eventTemplateDetails: {},
};

const mapStateToProps = (state) => extend({}, state.eventTemplates, state.accounts, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, accountActions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(EventTemplateCreatePage);
